import React, {Component} from 'react'
import {get, flowRight as compose, map, isEmpty, first, find, isNil, orderBy} from 'lodash'
import {graphql, withApollo} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {prizes as prizesLocalization} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import {isMobile} from '../../common/utils/browser'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY, PRIZES_QUERY} from '../../graphql/queries'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import {Loading} from '../Common/Loading'
import AppContext from '../Common/contexts/AppContext'
import config from '../../config'
import {Button, Grid, Typography} from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import ConfirmationDialog from './ConfirmationDialog'
import PartnerLoyaltyDetails from './PartnerLoyaltyDetails'
import {hasAffiliateAccount, hasIntroducingBrokerAccount, hasWalletProductAccount, isAffiliateClient,
  isAffiliatesAccount, isIntroducingBrokerAccount} from '../../common/utils/accounts'
import {AFFILIATE_SSO_LINK_QUERY, IB_SSO_LINK_MUTATION} from '../../graphql/mutations'
import moment from 'moment'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  displayInline: {
    display: 'inline-flex'
  },
  moreStatisticsLink: {
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 30,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 30,
  },
  moreStatisticsLinkMobile: {
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 0,
    paddingRight: 11,
    border: 'none'
  },
  enterCompetition: {
    right: 37,
    top: 20,
  },
  enterCompetitionMobile: {
    fontSize: 14,
    right: 17,
  },
  link: {
    color: theme.palette.primary.main,
  },
  card: {
    display: 'flex',
    padding: '10px 10px 10px 0',
    borderBottom: 'none',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '&:hover':{
      cursor: 'default',
      backgroundColor: theme.palette.background.default,
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 80,
    height: 80,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 12,
    backgroundSize: '90%',
  },
  btnImIn: {
    paddingTop: 10
  },
  imInTerms: {
    marginTop: 10,
    color: theme.palette.secondary.dark,
  },
  pageTitle: {
    marginTop: 0,
  },
})


class LoyaltyProgram extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      showMessage: true,
      loading: false,
      showPopup: false,
      status: ''
    }
  }

  componentDidMount() {
    const {common:{partnersLoyaltyTerminationDate}, partnersLoyaltyProgram} = config
    const {history, viewer, linkedPartnerClientId} = this.props
    const {accounts} = this.context
    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId
    if (get(config , 'partnersLoyaltyProgram') === false || isNil(partnersLoyaltyProgram) || !isIbOrAffiliate) history.push('/accounts')
    const partnersLoyaltyAnalytics = first(get(viewer, 'partnersLoyaltyAnalytics'))
    const today = moment()
    const eligiblePrize = find(get(partnersLoyaltyAnalytics, 'partnersLoyaltyEligibility'), (prize) => moment(get(prize, 'endEligibilityDate')) > today)
    const partnerLoyaltyTerminated = moment() > moment(partnersLoyaltyTerminationDate)
    if (partnerLoyaltyTerminated && isEmpty(eligiblePrize)) {
      history.push('/accounts')
    }
  }

  toPartnerPortal() {
    const {client: apolloClient, accounts} = this.props
    const {clientType} = this.context
    const newTab = window.open('about:blank', '_blank')
    if (isAffiliateClient(clientType)) {
      const affiliateAccount = hasAffiliateAccount(accounts) && find(accounts, (__typename) => isAffiliatesAccount(__typename))
      apolloClient.query({query: AFFILIATE_SSO_LINK_QUERY, variables: {accountId: affiliateAccount.id}, fetchPolicy:'network-only'}).then((res) => {
        newTab.location = res.data.data.link
      }).catch((e) => {
        newTab.close()
      })
    } else {
      const {createIBSingleSignOnLink, accounts} = this.props
      const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
      createIBSingleSignOnLink({variables: {accountId: ibAccount.id}}).then((res) => {
        newTab.location = res.data.data.link
      }).catch((e) => {
        newTab.close()
      })
    }
  }

  render() {
    const {clientLoading, loading, t, classes, prizes, viewer, accounts} = this.props
    const {showPopup} = this.state
    const {locale, companyObject} = this.context
    const company = companyObject.whiteLabelBrand || companyObject.brandLabel
    if (clientLoading || loading) return <Loading />
    const showLoyaltyInitialScreen = isEmpty(get(viewer, 'partnersLoyalty'))
    const partnerLoyalty = first(get(viewer, 'partnersLoyalty', []))
    const partnersLoyaltyAnalytics = first(get(viewer, 'partnersLoyaltyAnalytics'))
    const prizesSorted = orderBy(map(prizes, (prize) => ({...prize, prizeThreshold: parseFloat(prize.prizeThreshold)})), 'prizeThreshold', 'asc')

    return (
      <React.Fragment>
        <PageTitle
          hideArrow
          withoutPadding={isMobile()}
          title={t(messages.partnerLoyaltyProgram.i18nKey, messages.partnerLoyaltyProgram.defaults)}
          customClasses={{header: classes.pageTitle}}
          rightAction={!showLoyaltyInitialScreen && !isMobile() && <Button
            onClick={() => this.toPartnerPortal()}
            disableFocusRipple
            disableRipple
            variant="text"
            color="primary"
          >
            <Trans {...messages.seeMoreStatistics} />
          </Button>}
        >
        </PageTitle>
        <PageBody removePadding>
          {showLoyaltyInitialScreen && <Grid container>
            <Grid item xs={12} style={{marginBottom: 24}}>
              <Grid container sx={{mt: {xs:1, sm: 0}}}>
                <Grid item xs={12} style={{paddingBottom: 0}}>
                  <Typography variant='body1'>
                    <Trans {...messages.partnerLoyaltyProgramEntryText} values={{company}} /></Typography>
                </Grid>
                <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 0}}>
                  <Typography variant='body1'>
                    <Trans {...messages.fullTermsAndConditions}
                      components={[<a className={classes.link} href={config.weblinks.termsAndConditions.replace('{lang}', locale)} rel="noopener noreferrer" target="_blank"> </a>]}/></Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {map(prizesSorted, (prize) =>
                  (<Grid item xs={isMobile() ? 12 : 6} key={prize.prizeKey}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.cover}
                        image={prize.imageLink}
                        title="Live from space album cover"
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography variant="body1">
                            {prizesLocalization[prize.prizeKey].localization.t(locale)}
                          </Typography>
                          <Typography variant="caption">
                            <Trans {...messages.prizeSubText} values={{amount: prize.prizeThreshold.toFixed(2)}} />
                          </Typography>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>)
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.btnImIn}>
              <Button
                id='in'
                onClick={() => this.setState({showPopup: true})}
                variant="contained"
                size="large"
                color="primary"
                fullWidth={isMobile()}
              >
                <Trans {...messages.imIn} />
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.imInTerms}>
              <Typography variant='caption'><Trans {...messages.imInTerms} /></Typography>
            </Grid>
          </Grid>}
          {!showLoyaltyInitialScreen && <PartnerLoyaltyDetails prizes={prizesSorted}
            partnerLoyalty={partnerLoyalty} partnersLoyaltyAnalytics={partnersLoyaltyAnalytics}
            clientId={get(viewer, 'id')} accounts={accounts} />}
        </PageBody>
        <ConfirmationDialog
          open={showPopup}
          company={company}
          clientId={get(viewer, 'id')}
          onClose={() => this.setState({showPopup: false})}
        />
      </React.Fragment>
    )
  }
}

export default compose(
  withApollo,
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(ACCOUNTS_QUERY, {
    skip: (props) => !props.viewer,
    props: ({data: {loading:loadingAccounts, error:errorAccounts}, data}) => ({
      loadingAccounts,
      errorAccounts,
      accounts: get(data, 'viewer.accounts')
    })
  }),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
  graphql(PRIZES_QUERY, {
    props: ({data: {error, loading: prizesLoading}, data}) => ({
      error,
      prizesLoading,
      prizes: get(data, 'prizes'),
    })
  }),
)(LoyaltyProgram)
