import React, {useState, useRef, useEffect, useCallback} from 'react'
import {countries} from '@bdswiss/common-enums'
import {FixedSizeList} from 'react-window'
import MuiMenuItem from '@mui/material/MenuItem'
import Select from './Select'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import FlagIcon from '../FlagIcon'
import classNames from 'classnames'

const MenuItem = styled(MuiMenuItem)(({theme}) => ({
  height: 148,
  '&:hover, &.active': {
    backgroundColor: theme.palette.extralightgrey.color,
  },
  '& .flag-icon': {
    fontSize: '1.633em',
    borderRadius: 3,
    marginRight: 12,
  },
  '& .MuiSvgIcon-root': {
    position: 'absolute',
    right: 12,
    color: theme.palette.primary.main,
  },
}))

const LIST_WIDTH = 455
const LIST_HEIGHT = 336
const LIST_ITEM_SIZE = 48

const CountriesSelectV2 = ({name, value, onChange, placeholder, countryList, ...SelectProperties}) => {
  const ref = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  const openMenuHandle = useCallback(() => {
    setMenuOpen(true)
  }, [])

  const closeMenuHandle = useCallback(() => {
    if (!menuOpen) return
    setMenuOpen(false)
  }, [menuOpen])

  const menuItemClickHandle = useCallback((e) => {
    const {value} = e.currentTarget.dataset
    onChange({target: {value, name}})
    ref.current?.blur?.()
    closeMenuHandle()
  }, [name, onChange, closeMenuHandle])

  const renderValue = useCallback((value) => {
    const country = countries[value]
    if (!country) return placeholder

    return (
      <Box display="flex" alignItems="center">
        <FlagIcon code={value} size={'lg'} /> {country?.label}
      </Box>
    )
  }, [placeholder])

  return (
    <Select ref={ref} name={name} value={value} open={menuOpen} onOpen={openMenuHandle} renderValue={renderValue}
      MenuProps={{
        PaperProps: {
          style: {
            transform: 'translateY(8px)',
          },
        },
      }}
      {...SelectProperties}
    >
      {loaded ? (
        <FixedSizeList
          width={ref?.current?.clientWidth || LIST_WIDTH}
          height={LIST_HEIGHT}
          itemSize={LIST_ITEM_SIZE}
          itemCount={countryList.length}
          overscanCount={LIST_HEIGHT / LIST_ITEM_SIZE}
        >
          {({index, style}) => {
            const {label, value: itemValue} = countryList[index] || {}
            const active = value === itemValue
            return (
              <MenuItem style={style} key={index} data-value={itemValue} onClick={menuItemClickHandle} className={classNames({'active': active})}>
                <FlagIcon
                  code={itemValue}
                  size={'lg'}
                />
                {label}
                {active && <DoneOutlinedIcon />}
              </MenuItem>
            )
          }}
        </FixedSizeList>
      ): (
        <Box display="flex" justifyContent="center" alignItems="center" height={400}>
          <CircularProgress size={30}/>
        </Box>
      )}
    </Select>
  )
}

export default CountriesSelectV2
