import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../../Common/Loading'
import {dueDiligenceQuestions, dueDiligenceSteps, documentTypes, kycStatuses} from '@bdswiss/common-enums'
import {find, mapValues, omit, isEmpty, get, keys, filter, pickBy, concat, omitBy,
  includes, pull, values, last, identity, map, orderBy, reject, flowRight as compose, cloneDeep, every, isEqual, has} from 'lodash'
import Grid from '@mui/material/Grid'
import DueDiligenceQuestions from './DueDiligenceQuestions'
import DueDiligenceButtonsToolbar from './DueDiligenceButtonsToolbar'
import PageTitle from '../../../Common/PageTitle'
import messages from '../../../../assets/messages'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import AppContext from '../../../Common/contexts/AppContext'
import {scrollElementIntoView, putFile, getMissingDocs, isMobile} from '../../../../common/utils'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom'
import {DUE_DILIGENCE_MUTATION, SIGN_UPLOAD_URL_MUTATION,
  CREATE_OWN_DOCUMENT_MUTATION} from '../../../../graphql/mutations'
import {isAffiliateClient} from '../../../../common/utils/accounts'
import config from '../../../../config'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  orangeButton: {
    backgroundColor: '#ef9756',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef7e2b',
    }
  },
  submitBtn: {
    paddingTop: 32,
    [theme.breakpoints.down('sm')]: {
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 16,
      paddingBottom: 16,
    },
  }
})

class DueDiligence extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      progress: 50,
      form: {answers: this.initDueDiligenceForm(), questions:{}},
      formErrors : this.initDueDiligenceForm(),
      formState: 'pending',
      signedUploadedFile1: '',
      signedUploadedFile2: '',
      formChanged: {},
    }
  }

  componentDidMount() {
    const {clientType} = this.context
    if (!config.common.dueDilligenceRequired[clientType]) {
      this.props.history.push('/settings/profile')
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {dueDiligence} = nextProps
    const {form, formErrors} = prevState
    const edd = dueDiligence && cloneDeep(dueDiligence)
    const hasDueDiligence = !isEmpty(edd) && {answers: edd.answers}
    const answerKeys = keys(omitBy(hasDueDiligence.answers, isEmpty))
    const emptyForm = every(values(form.answers), (answer) => answer === '')
    prevState.form = emptyForm && hasDueDiligence ? hasDueDiligence : form
    prevState.formErrors = (hasDueDiligence && omit(formErrors, answerKeys)) || formErrors
    return prevState
  }

  initDueDiligenceForm() {
    return mapValues(dueDiligenceQuestions, (q) => '')
  }

  getDueDiligenceQuestions(answers, activeStep) {
    const {companyObject} = this.context
    return pickBy(dueDiligenceQuestions, (q) => {
      const {step: {stepPosition}} = q
      const checkCompanies = filter(q.company, (o) => o.value === companyObject.key)
      return (stepPosition === activeStep && !isEmpty(checkCompanies) && q.relevant(answers,'key') && !has(q, ['deprecated']))
    })
  }

  handleNextStep() {
    this.scrollUp()
    const {hasEmptyQuestion} = this.validateStep(this.state.activeStep)
    if (isEmpty(hasEmptyQuestion)) {
      this.setState({activeStep: this.state.activeStep + 1, progress: this.state.progress + 50})
    } else {
      this.setState({formState: 'validation'},this.scrollUp(this.state.formErrors))
    }
  }

  handlePreviousStep() {
    this.setState({activeStep: this.state.activeStep - 1, progress: this.state.progress - 50})
  }

  handleClose(history, state) {
    const {t} = this.props
    return (state && state.force) ? this.context.logout(t(messages.dueDiligence.i18nKey, messages.dueDiligence.defaults))
      : history.push('/settings/profile')
  }

  handleOptionInput(event, key) {
    const {dueDiligence} = this.props
    const {form, formErrors} = this.state
    const questionExists = get(form.answers, key)
    if (event && event.type === 'radio') {
      const {name: questionKey, value: answerKey} = event
      form.answers[questionKey] = answerKey
      const newFormErrors = omit(formErrors, [questionKey])
      this.setState(state => ({
        form: form,
        formErrors: newFormErrors,
        formChanged:{
          ...state.formChanged,
          [questionKey]: isEmpty(dueDiligence) || (answerKey !== dueDiligence['answers'][questionKey]),
        }
      }))
    } else if (event && event.type === 'checkbox') {
      let newFormErrors = []
      if (questionExists) {
        const answerExist = includes(questionExists, event.value)
        if (!answerExist &&  event.checked) {
          form.answers[key].push(event.value)
        } else if (answerExist && !event.checked) {
          form.answers[key] = pull(form.answers[key],event.value)
        }
      } else {
        form.answers[key] = [event.value]
        newFormErrors = omit(formErrors, [key])
      }
      this.setState(state => ({
        form: form,
        formErrors: newFormErrors,
        formChanged:{
          ...state.formChanged,
          [key]: isEmpty(dueDiligence) || !isEqual(form.answers[key], dueDiligence['answers'][key]),
        }
      }))
    } else {
      form.answers[key] = event
      const newFormErrors = omit(formErrors, [key])
      this.setState(state => ({
        form: form,
        formErrors: newFormErrors,
        formChanged:{
          ...state.formChanged,
          [key]: isEmpty(dueDiligence) || (event !== dueDiligence['answers'][key]),
        }
      }))
    }
  }

  async uploadDocument(docType, file, internalTag) {
    if (!file) return Promise.resolve()
    const {form} = this.state
    const {signUploadUrl, createOwnDocument, clientId} = this.props
    return signUploadUrl({variables: {clientId: clientId}}).then((res) => {
      const {key, signedUrl, plainUrl} = res.data.signedDetails
      if (docType === documentTypes.businessLicense.key) {
        form.answers.relevantLicensesUpload = plainUrl
        this.setState({form:{answers:{...form.answers}}})
      }
      if (docType === documentTypes.promotionalMaterial.key) {
        form.answers.servicesOfferUpload = plainUrl
        this.setState({form:{answers:{...form.answers}}})
      }
      return putFile(file, signedUrl).then((res) =>
        createOwnDocument({variables: {type: docType, key, internalTag}})
      )
    })
  }

  validateStep(activeStep) {
    const {form, formErrors} = this.state
    const activeStepObject = find(dueDiligenceSteps, ['stepPosition', activeStep])
    const activeStepQuestions = filter(dueDiligenceQuestions, ['step', activeStepObject])
    const activeQuestions =  this.getDueDiligenceQuestions(form.answers, activeStep)
    let newFormErrors=[]
    const hasEmptyQuestion = filter(activeQuestions, (question) => {
      const mandatoryField = dueDiligenceQuestions[question.key].mandatory(form.answers, 'key')
      if (isEmpty(form.answers[question.key]) && mandatoryField) {
        formErrors[question.key] = ''
        return question
      } else {
        newFormErrors = concat(newFormErrors,question.key)
      }
    })

    filter(activeStepQuestions, (question) => {
      if (!activeQuestions[question.key]) {
        newFormErrors = concat(newFormErrors,question.key)
        form.answers = omit(form.answers, question.key)
      }
    })
    const updatedFormErrors = omit(omit(formErrors, 'existingClients'), newFormErrors)
    this.setState({form: form, formErrors: updatedFormErrors})
    return {form, hasEmptyQuestion, updatedFormErrors}
  }

  validateForm() {
    this.setState({formState: 'validation'})
    const {hasEmptyQuestion, updatedFormErrors} = this.validateStep(this.state.activeStep)
    if (isEmpty(hasEmptyQuestion) && isEmpty(updatedFormErrors)) {
      return true
    } else {
      this.scrollUp(this.state.formErrors)
      return false
    }
  }

  scrollUp(errors) {
    const scrollTo = errors ? Object.keys(errors)[0] : gridScroll
    this.setState(() => scrollElementIntoView(scrollTo, 250))
  }

  generateDocumentTag(clientId, documentType) {
    documentType = (documentType || '').replace(/(Front|Back)/, '')
    return `${clientId}-${documentType}-${Date.now()}`
  }
  redirectPage() {
    const {viewer} = this.props
    if ((viewer.kycStatus === kycStatuses.approved.value || isEmpty(reject(getMissingDocs(viewer), (doc) => doc === false)))
      || isAffiliateClient(viewer.clientType)) {
      return this.props.history.push('/accounts')
    } else {
      return this.props.history.push('/settings/profile/documents')
    }
  }

  submitDueDiligence() {
    let answers = this.state.form.answers
    this.setState({formState: 'submit',status: '', submitLoading: true})
    if (answers.__typename) {
      answers = omit(this.state.form.answers, ['__typename'])
    }
    if (answers.termsConditions) {
      pull(answers.termsConditions,'termsAnswer')
      map(answers.termsConditions, (t, key)=> `agreedTerms${key+1}`)
    }

    this.props.updateOwnDueDiligence({variables: {answers: pickBy(answers, identity)}})
      .then((res) => {
        this.context.showNotification({
          type: 'document-upload',
          status: 'success',
          content: this.props.t(messages.dueDiligenceSubmitted.i18nKey, messages.dueDiligenceSubmitted.defaults),
          buttonMessage: this.props.t(messages.continue.i18nKey, messages.continue.defaults),
          onClose: () => this.redirectPage(),
        })
        return res.data
      })
      .catch((err) => {
        this.setState({
          submitLoading: false, status: 'failure',
        })
        this.context.showNotification({
          type: 'document-upload',
          status: 'failure',
          content: get(err, 'graphQLErrors[0].message') || err.message,
          buttonMessage: <Trans {...messages.cancel} />
        })
      })
  }

  onUploadDocument() {
    const {selectedDocTypeKey: docType, form} = this.state
    this.setState({uploadLoading: true, submitLoading: true})
    const {classes, clientId} = this.props
    const internalTag = this.generateDocumentTag(clientId, docType)
    // form.answers.servicesOfferUpload &&
    //   this.uploadDocument(documentTypes.promotionalMaterial.key, form.answers.servicesOfferUpload, internalTag).then((res) => {
    //     if (!form.answers.servicesOfferUpload) return Promise.resolve(res)
    //     form.answers.relevantLicensesUpload &&
    //       this.uploadDocument(documentTypes.businessLicense.key, form.answers.relevantLicensesUpload, internalTag).then((res) => {
    //         if (!form.answers.relevantLicensesUpload) return Promise.resolve(res)
    //         return this.submitDueDiligence()
    //       })
    //     !form.answers.relevantLicensesUpload && this.submitDueDiligence()
    //   })

    form.answers.servicesOfferUpload &&
    this.uploadDocument(documentTypes.promotionalMaterial.key, form.answers.servicesOfferUpload, internalTag).then((res) => {
      if (!form.answers.servicesOfferUpload) return Promise.resolve(res)
      return this.submitDueDiligence()
    })
  }

  submitForm() {
    if (this.validateForm()) {
      const answers = this.state.form.answers
      if (answers.servicesOfferUpload || answers.relevantLicensesUpload) {
        this.onUploadDocument()
      } else {
        this.submitDueDiligence()
      }
      return
    }
  }

  render() {
    const {t, loading, dueDiligence, history, location: {state}, classes} = this.props
    const {locale, themePreference} = this.context
    const {submitLoading, status, formChanged}= this.state

    if (loading || !dueDiligence) return <Loading />

    const isFormChanged = includes(omit(formChanged, 'termsConditions'), true)
    const {activeStep, form, formState, formErrors} = this.state
    const dueDiligenceQuestions = this.getDueDiligenceQuestions(form.answers, activeStep)
    const dueDiligenceComponent =
      <Grid container id={gridScroll}>
        <Grid container>
          <Grid item xs={12}>
            <PageTitle
              themePreference={themePreference}
              withoutPadding={isMobile()}
              title={t(messages.dueDiligence.i18nKey, messages.dueDiligence.defaults)}
              onBack={() => this.handleClose(history, state)}
            />
          </Grid>
        </Grid>
        <Grid container sx={{p: {xs: 2, lg: 0}}}>
          <Grid item xs={12}>
            <DueDiligenceQuestions
              locale={locale}
              activeStep={activeStep}
              form={form}
              formState={formState}
              errors={formErrors}
              questions={dueDiligenceQuestions}
              onChange={(e, questionKey) => this.handleOptionInput(e, questionKey)}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.submitBtn} sx={{p: {xs: 2, lg: 0}}}>
          <Grid item xs={12}>
            <DueDiligenceButtonsToolbar
              activeStep={1}
              nextStep={() => this.handleNextStep()}
              previousStep={() => this.handlePreviousStep()}
              submitForm={() => this.submitForm()}
              submitButtonFeatures ={{submitLoading , status}}
              disableSubmit={!isFormChanged}
            />
          </Grid>
        </Grid>
      </Grid>

    return (
      <React.Fragment>
        {dueDiligenceComponent}
      </React.Fragment>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const clientId = get(data, 'viewer.id')
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        clientId,
        viewer,
      }
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const dueDiligences = get(data, 'viewer.dueDiligences')
      let dueDiligence = last(orderBy(filter(dueDiligences, ['status', 'approved']), ['createdAt']))
      if (!dueDiligence) {
        dueDiligence = last(orderBy(dueDiligences, ['createdAt']))
      }
      if (get(dueDiligence,'answers')) {
        dueDiligence.answers = omit(dueDiligence.answers,
          [dueDiligenceQuestions.servicesOfferUpload.key, dueDiligenceQuestions.relevantLicensesUpload.key])
      }
      return {
        error,
        loading,
        dueDiligence: dueDiligence || {},
      }
    }
  }),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(CREATE_OWN_DOCUMENT_MUTATION, {
    name: 'createOwnDocument',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    }
  }),
  graphql(DUE_DILIGENCE_MUTATION, {
    name: 'updateOwnDueDiligence',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY},{query: CLIENT_DATA_QUERY}],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(DueDiligence)
