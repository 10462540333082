import {createTheme} from '@mui/material/styles'
import grey from '@mui/material/colors/grey'
import merge from 'lodash/fp/merge'
import flow from 'lodash/fp/flow'
import {isMobile} from '../common/utils'
import {
  rtlOverrides, mobileOverrides, swissMarketsOverrides, accuracyAccountsOverrides, regalxOverrides, bdsTradingOverrides,
  vivernoOverrides, swissMarketsDarkOverrides, accuracyAccountsDarkOverrides, regalxDarkOverrides,
  bdsTradingDarkOverrides, vivernoDarkOverrides,
} from './overrides'

const applyBaseProperties = (props) => merge(props, {
  typography: {
    useNextVariants: true,
  },
  mainBodyWidth: '1400px',
  sidebarWidth: '256px',
  emptyListsIcon: {
    maxWidth: 280,
    minWidth: 220,
    width: '85%',
  },
  inputColor: props.palette?.mode === 'dark' ? 'rgba(255,255,255)' : 'rgba(0,0,0, 0.87)',
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
})

const applyBasePaletteProperties = (props) => {
  switch (props.palette?.mode) {
    case 'dark':
      return merge(props, {
        palette: {
          mode: 'dark',
          primary: {
            main: '#419CFF',
            dark: '#0055B2',
            light: '#3394FF',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#999999',
            dark: '#FFFFFF',
            light: '#FFFFFF',
          },
          error: {
            main: '#FC4950',
            dark: '#FC4950',
            light: '#FFD4D6',
          },
          background: {
            default: '#0C0C0C',
            paper: '#0C0C0C',
          },
          green: {
            color: '#00AD7B',
            textColor: '#FFFFFF',
          },
          lightgreen: {
            color: '#0B373A',
            textColor: '#57EBC0',
          },
          lightblue: {
            color: '#0C3059',
            textColor: '#FFFFFF',
          },
          yellow: {
            color: '#EE8A00',
          },
          lightyellow: {
            color: '#463313',
          },
          red: {
            color: '#FC4950',
            textColor: '#FFFFFF',
          },
          lightred: {
            color: '#491B1B',
          },
          grey: {
            color: '#C2C2C2',
            contrastBackground: '#5C5C5C',
          },
          lightgrey: {
            color: '#1F1F1F',
          },
          extralightgrey: {
            color: 'rgba(255,255,255,0.05)',
          },
          extralightgreyBackground: {
            color: '#181818',
          },
          freshGrey: {
            color: '#9094A1',
            backgroundColor: '#2E3035',
          },
          cardBackground: {
            color: 'rgba(255,255,255,0.05)',
          },
          black: {
            color: '#FFFFFF',
          },
          purple: {
            color: '#E65CE6',
          },
          lightpurple: {
            color: '#7A1F7A30',
          },
          cyan: {
            color: '#00D3FF',
          },
          lightcyan: {
            color: '#00CCFF24',
          },
          lightorange: {
            color: '#D7890824',
          },
          lightgreyBackgroundSolid: {
            color: '#333333',
          },
          gold: {
            color: '#E6C200',
          },
          harlequin: {
            color: '#3cd10b',
          },
          lightharlequin: {
            color: '#e8ffe030',
          },
          mint: {
            color: '#00AD7B'
          },
          lightMint: {
            color: '#C7F7E9'
          },
          premium: {
            color: '#474644',
          },
          lightpremium: {
            color: '#DEDDDB',
          },
          pink: {
            color: '#E55CE5',
          },
          lightpink: {
            color: '#491B49',
          },
          notificationBar: {
            redBackground: '#3C181A',
            redTextColor: '#FC4950',
            blueBackground: '#0A2542',
            blueTextColor: '#419CFF',
            greenBackground: '#162B1E',
            greenTextColor: '#00C85C',
            yellowBackground: '#382308',
            yellowTextColor: '#FF9400'
          },
        },
      })
    case 'light':
    default:
      return merge(props, {
        palette: {
          mode: 'light',
          primary: {
            main: '#007AFF',
            dark: '#0055B2',
            light: '#3394FF',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#858585',
            dark: '#000000',
            light: '#FFFFFF',
            contrastText: '#FFFFFF',
          },
          error: {
            main: '#ED1C24',
            dark: '#ED1C24',
            light: '#FFD4D6',
          },
          background: {
            default: '#fff',
          },
          green: {
            color: '#009469',
            textColor: '#FFFFFF',
          },
          lightgreen: {
            color: '#D2F3EF',
            textColor: '#27b28a',
          },
          lightblue: {
            color: '#D6EAFF',
            textColor: '#297dd8',
          },
          yellow: {
            color: '#F87700',
          },
          lightyellow: {
            color: '#FFEFD5',
          },
          red: {
            color: '#ED1C24',
            textColor: '#FFFFFF',
          },
          lightred: {
            color: '#FFEDEE',
          },
          grey: {
            color: '#858585',
            contrastBackground: '#C2C2C2',
          },
          lightgrey: {
            color: '#CCC',
          },
          extralightgrey: {
            color: '#fafafa',
          },
          extralightgreyBackground: {
            color: '#FFFFFF',
          },
          freshGrey: {
            color: '#818593',
            backgroundColor: '#EBECF0',
          },
          cardBackground: {
            color: '#FFFFFF',
          },
          black: {
            color: '#000000',
          },
          purple: {
            color: '#6F00FF',
          },
          lightpurple: {
            color: '#EEE0FF',
          },
          cyan: {
            color: '#00D3FF',
          },
          lightcyan: {
            color: '#E0FAFF',
          },
          lightorange: {
            color: '#FFF6E6',
          },
          lightgreyBackgroundSolid: {
            color: '#FFFFFF',
          },
          gold: {
            color: '#E6C200',
          },
          harlequin: {
            color: '#3cd10b',
          },
          lightharlequin: {
            color: '#e8ffe0',
          },
          mint: {
            color: '#00AD7B'
          },
          lightMint: {
            color: '#C7F7E9'
          },
          premium: {
            color: '#474644',
          },
          lightpremium: {
            color: '#DEDDDB',
          },
          pink: {
            color: '#CC33CC',
          },
          lightpink: {
            color: '#FFEBFF',
          },
          text: {
            hint: 'rgba(0, 0, 0, 0.38)',
          },
          notificationBar: {
            redBackground: '#FFEDEE',
            redTextColor: '#ED1C24',
            blueBackground: '#E3F1FF',
            blueTextColor: '#007AFF',
            greenBackground: '#DFF8E4',
            greenTextColor: '#008A40',
            yellowBackground: '#FFF5D9',
            yellowTextColor: '#F87700',
          },
        }
      })
  }
}

const applyBaseShadowStyleProperties = (props) => {
  switch (props.palette?.mode) {
    case 'dark':
      return merge(props, {
        boxShadowStyle: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
        boxShadowStyleOpacified: '0 1px 4px 0 rgba(255,255,255,0.04), 0 4px 16px 0 rgba(255,255,255,0.08)',
      })
    case 'light':
    default:
      return merge(props, {
        boxShadowStyle: '0px 3px 5px -3px #ccc, 0px 6px 10px 0px #f1f1f1, 0px 1px 18px 0px #eee',
        boxShadowStyleOpacified: '0px 3px 5px -3px #ccc, 0px 6px 10px 0px #f1f1f1, 0px 1px 18px 0px #eee',
      })
  }
}

const applyBaseComponentsProperties = (props) => {
  switch (props.palette?.mode) {
    case 'dark':
      return merge(props, {
        components: {
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                fontWeight: '400',
                borderRadius: 6,
              },
              sizeLarge: {
                padding: '10px 50px',
                fontSize: '1rem',
              },
              contained: {
                '&.Mui-disabled': {
                  color: 'rgba(255,255,255,0.55)',
                },
              },
              containedSecondary: {
                color: props.palette.secondary.light,
                backgroundColor: props.palette.extralightgrey.color,
                '&:hover': {
                  backgroundColor: props.palette.extralightgrey.color,
                },
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              h1: {
                fontSize: '2.1rem',
                color: props.palette.black.color,
              },
              h2: {
                fontSize: '2rem',
                color: props.palette.black.color,
              },
              h3: {
                color: props.palette.black.color,
                fontSize: 24,
                fontWeight: 300,
                lineHeight: '42px',
                marginTop: 30,
                marginBottom: 20,
              },
              h4: {
                fontSize: '1.3rem',
                fontWeight: 300,
              },
              h5: {
                fontWeight: 300,
                fontSize: 18,
              },
              subtitle1: {
                color: props.palette.black.color,
                fontWeight: 300,
                fontSize: 18,
              },
              subtitle2: {
                color: props.palette.black.color,
                fontSize: 14,
              },
              caption: {
                color: props.palette.secondary.main,
                fontSize: '0.85rem',
                display: 'block',
              },
              body1: {
                color: props.palette.black.color,
                fontSize: 16,
                lineHeight: '1.8rem',
                letterSpacing: 'normal',
                fontWeight: 300,
                '&a': {
                  fontWeight: 400,
                },
              },
              body2: ({theme}) => ({
                color: theme.palette.black.color,
                fontSize: 16,
                lineHeight: '2.5rem',
                '&a': {
                  fontWeight: 500,
                },
                [theme.breakpoints.down('md')]: {
                  lineHeight: '1.5rem',
                },
              }),
            },
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                lineHeight: '1.5rem',
              },
              selectMenu: {
                fontWeight: 300,
                fontSize: 18,
              },
              select: {
                '&:focus': {
                  backgroundColor: '#0C0C0C',
                },
                '& option': {
                  backgroundColor: '#0C0C0C',
                },
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color',
                  backgroundColor: props.palette.background.default,
                  WebkitTextFillColor: `${props.inputColor} !important`,
                },
                height: 'unset',
                color: `${props.inputColor} !important`,
                padding: '6px 0px 7px',
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              root: {
                lineHeight: '1.5rem',
              },
              focused: {},
              disabled: {},
              error: {},
              underline: {
                '&:before': {
                  borderBottom: `1px solid ${props.palette.lightgrey.color}`,
                },
                '&:after': {
                  borderBottom: `1px solid ${props.palette.secondary.main}`,
                },
                '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
                  borderBottom: `1px solid ${props.palette.secondary.main}`,
                },
                '&:hover:not(.Mui-disabled):before': {
                  borderBottom: `1px solid ${props.palette.secondary.main}`,
                },
              },
            }
          },
          MuiCard: {
            styleOverrides: {
              root: {
                padding: 20,
                boxShadow: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
                backgroundColor: props.palette.extralightgreyBackground.color,
                borderRadius: 8,
                '&:hover': {
                  'cursor': 'pointer',
                  backgroundColor: props.palette.lightgrey.color,
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: ({theme}) => ({
                marginBottom: theme.spacing(3),
                boxShadow: 'none',
              }),
              indicator: {
                backgroundColor: props.palette.red.color,
              },
              flexContainer: {
                borderBottom: '1px solid #1F1F1F',
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: ({theme}) => ({
                textTransform: 'initial',
                color: theme.palette.secondary.main,
                fontSize: 15,
                fontWeight: 400,
                minWidth: 10,
                [theme.breakpoints.up('md')]: {
                  color: theme.palette.secondary.main,
                  fontSize: 15,
                  minWidth: 10,
                  padding: '20px 24px',
                },
                '&.Mui-selected': {
                  color: props.palette.black.color,
                  backgroundColor: 'transparent',
                }
              }),
              textColorInherit: {
                color: props.palette.secondary.main,
                opacity: 1,
              },
              labelIcon: {
                paddingTop: 20,
                paddingBottom: 20,
              },
              fullWidth: {
                flexBasis: 'auto',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              body: {
                fontSize: 15,
                fontWeight: 300,
              },
              head: {
                color: props.palette.secondary.dark,
                fontSize: 14,
                textTransform: 'none',
                fontWeight: 400,
              },
              paddingCheckbox: {
                width: 'unset',
              },
            },
          },
          MuiListItemText: {
            styleOverrides: {
              root: {
                fontSize: '16px',
              },
            },
          },
          MuiListItem: {
            styleOverrides: {
              root: {
                paddingTop: 11,
                paddingBottom: 11,
                '&.Mui-selected': {
                  backgroundColor: props.palette.extralightgrey.color,
                },
                '&.Mui-selected:hover': {
                  backgroundColor: props.palette.extralightgrey.color,
                },
              },
              button: {
                '&:hover': {
                  backgroundColor: props.palette.extralightgrey.color,
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                fontWeight: 300,
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paperFullScreen: ({theme}) => ({
                background: theme.palette.background.paper,
                flexDirection: 'unset',
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                  margin: 0,
                },
              }),
              paper: {
                background: props.palette.lightgreyBackgroundSolid.color,
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                textAlign: 'center',
                color: props.palette.secondary.dark,
              },
            },
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                fontSize: 16,
                fontWeight: 300,
                color: props.palette.secondary.dark,
                padding: '0 24px 24px',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: props.palette.background.default,
                backgroundImage: 'none',
              },
              elevation1: {
                boxShadowStyle: props.boxShadowStyle,
              },
              elevation2: {
                backgroundColor: props.palette.background.default,
                boxShadowStyle: props.boxShadowStyle,
                border: `1px solid ${props.palette.lightgreyBackgroundSolid.color}`,
              },
              elevation8: {
                boxShadowStyle: props.boxShadowStyle,
                border: `1px solid ${props.palette.lightgreyBackgroundSolid.color}`,
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: props.palette.background.default,
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: grey[500],
                lineHeight: 1,
              },
            },
          },
          MuiFab: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.05)',
                },
              },
            },
          },
          MuiLinearProgress: {
            styleOverrides: {
              root: {
                height: 2,
              },
              colorPrimary: {
                backgroundColor: 'rgba(255,255,255,0.24)',
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: ({theme, ownerState}) => ({
                whiteSpace: 'unset',
                overflow: 'unset',
                textOverflow: 'unset',
                maxWidth: 'unset',
                ...(ownerState.formControl && {
                  transform: 'translate(0, 24px) scale(1)',
                }),
                ...(ownerState.size === 'small' && {
                  transform: 'translate(0, 21px) scale(1)',
                }),
                ...(ownerState.shrink && {
                  transform: 'translate(0, 1.5px) scale(0.75)',
                  maxWidth: 'unset',
                }),
                ...(!ownerState.disableAnimation && {
                  transition: theme.transitions.create(['color', 'transform'], {
                    duration: theme.transitions.duration.shorter,
                    easing: theme.transitions.easing.easeOut,
                  }),
                }),
                ...(ownerState.variant === 'filled' && {
                  transform: 'translate(12px, 20px) scale(1)',
                  maxWidth: 'unset',
                  ...(ownerState.size === 'small' && {
                    transform: 'translate(12px, 17px) scale(1)',
                  }),
                  ...(ownerState.shrink && {
                    transform: 'translate(12px, 10px) scale(0.75)',
                    maxWidth: 'unset',
                    ...(ownerState.size === 'small' && {
                      transform: 'translate(12px, 7px) scale(0.75)',
                    }),
                  }),
                  ...(ownerState.variant === 'outlined' && {
                    transform: 'translate(14px, 20px) scale(1)',
                    maxWidth: 'unset',
                    ...(ownerState.size === 'small' && {
                      transform: 'translate(14px, 12px) scale(1)',
                    }),
                    ...(ownerState.shrink && {
                      transform: 'translate(14px, -6px) scale(0.75)',
                      maxWidth: 'unset',
                    }),
                  }),
                }),
              }),
            },
          },
        },
      })
    case 'light':
    default:
      return merge(props, {
        components: {
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                fontWeight: '400',
                borderRadius: 6,
              },
              sizeLarge: {
                padding: '10px 50px',
                fontSize: '1rem',
              },
              contained: {
                boxShadow: props.boxShadowStyle,
                '&:hover': {
                  '@media (hover:none)': {
                    backgroundColor: props.palette.extralightgrey.color,
                    boxShadow: 'none',
                  },
                },
              },
              containedSecondary: {
                color: props.palette.primary.main,
                backgroundColor: props.palette.secondary.light,
                '&:hover': {
                  backgroundColor: props.palette.extralightgrey.color,
                },
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              h1: {
                fontSize: '2.1rem',
              },
              h2: {
                fontSize: '2rem',
              },
              h3: {
                color: props.palette.black.color,
                fontSize: 24,
                fontWeight: 300,
                lineHeight: '42px',
                marginTop: 30,
                marginBottom: 20,
              },
              h4: {
                fontSize: '1.3rem',
                fontWeight: 300,
              },
              h5: {
                fontWeight: 300,
                fontSize: 18,
              },
              subtitle1: {
                color: props.palette.black.color,
                fontWeight: 300,
                fontSize: 18,
              },
              subtitle2: {
                color: props.palette.black.color,
                fontWeight: 300,
                fontSize: 14,
              },
              caption: {
                color: props.palette.secondary.main,
                fontSize: '0.85rem',
                display: 'block',
              },
              body1: {
                color: props.palette.black.color,
                fontSize: 16,
                lineHeight: '1.8rem',
                letterSpacing: 'normal',
                fontWeight: 300,
                '&a': {
                  fontWeight: 400,
                },
              },
              body2: ({theme}) => ({
                color: theme.palette.black.color,
                fontSize: 16,
                lineHeight: '2.5rem',
                '&a': {
                  fontWeight: 500,
                },
                [theme.breakpoints.down('md')]: {
                  lineHeight: '1.5rem',
                },
              }),
            },
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                lineHeight: '1.5rem',
              },
              select: {
                '&:focus': {
                  background: props.palette.extralightgrey.color,
                },
              },
              selectMenu: {
                fontWeight: 300,
                fontSize: 18,
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  transitionDelay: '9999s',
                  transitionProperty: 'background-color, color',
                  backgroundColor: '#0C0C0C',
                  WebkitTextFillColor: `${props.inputColor} !important`,
                },
                height: 'unset',
                color: `${props.inputColor} !important`,
                padding: '6px 0px 7px',
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              root: {
                lineHeight: '1.5rem',
              },
              focused: {},
              disabled: {},
              error: {},
              underline: {
                '&:before': {
                  borderBottom: `1px solid ${props.palette.lightgrey.color}`,
                },
                '&:after': {
                  borderBottom: `1px solid ${props.palette.secondary.main}`,
                },
                '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
                  borderBottom: `1px solid ${props.palette.secondary.main}`,
                },
                '&:hover:not(.Mui-disabled):before': {
                  borderBottom: `1px solid ${props.palette.secondary.main}`,
                },
              },
            }
          },
          MuiCard: {
            styleOverrides: {
              root: {
                padding: 20,
                boxShadow: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
                borderRadius: 8,
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: props.palette.extralightgrey.color,
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: ({theme}) => ({
                marginBottom: theme.spacing(3),
                boxShadow: 'none',
              }),
              indicator: {
                backgroundColor: props.palette.red.color,
                boxShadow: 'none',
              },
              flexContainer: {
                borderBottom: '1px solid #EBEBEB',
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: ({theme}) => ({
                textTransform: 'initial',
                color: theme.palette.secondary.main,
                fontSize: 15,
                fontWeight: 400,
                minWidth: 10,
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                  color: theme.palette.secondary.main,
                  fontSize: 15,
                  minWidth: 10,
                  padding: '20px 24px',
                },
                '&.Mui-selected': {
                  color: theme.palette.black.color,
                  backgroundColor: 'transparent',
                }
              }),
              textColorInherit: {
                color: props.palette.secondary.main,
                opacity: 1,
              },
              labelIcon: {
                paddingTop: 20,
                paddingBottom: 20,
              },
              fullWidth: {
                flexBasis: 'auto',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              body: {
                fontSize: 15,
                fontWeight: 300,
              },
              head: {
                color: props.palette.secondary.main,
                fontSize: 14,
                textTransform: 'none',
                fontWeight: 400,
              },
              paddingCheckbox: {
                width: 'unset',
              },
            },
          },
          MuiListItemText: {
            styleOverrides: {
              root: {
                fontSize: '16px',
              },
            },
          },
          MuiListItem: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: props.palette.extralightgrey.color,
                  '&:hover': {
                    backgroundColor: props.palette.extralightgrey.color,
                  },
                },
                paddingTop: 11,
                paddingBottom: 11,
              },
              button: {
                '&:hover': {
                  backgroundColor: props.palette.extralightgrey.color,
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                fontWeight: 300,
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paperFullScreen: ({theme}) => ({
                flexDirection: 'unset',
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                  margin: 0,
                },
              }),
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                textAlign: 'center',
              },
            }
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                fontSize: 16,
                fontWeight: 300,
                padding: '0 24px 24px',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundImage: 'none',
              },
              elevation1: {
                boxShadow: props.boxShadowStyle,
              },
              elevation2: {
                boxShadow: props.boxShadowStyle,
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: props.palette.secondary.dark,
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: grey[500],
                lineHeight: 1,
              },
            },
          },
          MuiFab: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: '#fafafa',
                },
              },
            },
          },
          MuiBottomNavigationAction: {
            styleOverrides: {
              label: ({theme}) => ({
                '&.Mui-selected': {
                  color: `${theme.palette.primary.main} !important`,
                },
              }),
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: ({theme, ownerState}) => ({
                whiteSpace: 'unset',
                overflow: 'unset',
                textOverflow: 'unset',
                maxWidth: 'unset',
                ...(ownerState.formControl && {
                  transform: 'translate(0, 24px) scale(1)',
                }),
                ...(ownerState.size === 'small' && {
                  transform: 'translate(0, 21px) scale(1)',
                }),
                ...(ownerState.shrink && {
                  transform: 'translate(0, 1.5px) scale(0.75)',
                  maxWidth: 'unset',
                }),
                ...(!ownerState.disableAnimation && {
                  transition: theme.transitions.create(['color', 'transform'], {
                    duration: theme.transitions.duration.shorter,
                    easing: theme.transitions.easing.easeOut,
                  }),
                }),
                ...(ownerState.variant === 'filled' && {
                  transform: 'translate(12px, 20px) scale(1)',
                  maxWidth: 'unset',
                  ...(ownerState.size === 'small' && {
                    transform: 'translate(12px, 17px) scale(1)',
                  }),
                  ...(ownerState.shrink && {
                    transform: 'translate(12px, 10px) scale(0.75)',
                    maxWidth: 'unset',
                    ...(ownerState.size === 'small' && {
                      transform: 'translate(12px, 7px) scale(0.75)',
                    }),
                  }),
                  ...(ownerState.variant === 'outlined' && {
                    transform: 'translate(14px, 20px) scale(1)',
                    maxWidth: 'unset',
                    ...(ownerState.size === 'small' && {
                      transform: 'translate(14px, 12px) scale(1)',
                    }),
                    ...(ownerState.shrink && {
                      transform: 'translate(14px, -6px) scale(0.75)',
                      maxWidth: 'unset',
                    }),
                  }),
                }),
              }),
            },
          },
        },
      })
  }
}

const applyMobileOverridesProperties = (props) => isMobile() ? merge(props, mobileOverrides) : props

const [
  [bdSwiss, bdSwissRtl],
  [swissMarkets, swissMarketsRtl],
  [accuracyAccounts, accuracyAccountsRtl],
  [regalx, regalxRtl],
  [bdsTrading, bdsTradingRtl],
  [viverno, vivernoRtl],
  [bdSwissDark, bdSwissDarkRtl],
  [swissMarketsDark, swissMarketsDarkRtl],
  [accuracyAccountsDark, accuracyAccountsDarkRtl],
  [regalxDark, regalxDarkRtl],
  [bdsTradingDark, bdsTradingDarkRtl],
  [vivernoDark, vivernoDarkRtl],
] = [
  {name: 'bdSwiss', palette: {mode: 'light'}},
  {name: 'swissMarkets', swissMarketsOverrides},
  {name: 'accuracyAccounts', accuracyAccountsOverrides},
  {name: 'regalx', regalxOverrides},
  {name: 'bdsTrading', bdsTradingOverrides},
  {name: 'viverno', vivernoOverrides},
  {name: 'bdSwissDark', palette: {mode: 'dark'}},
  {name: 'swissMarketsDark', swissMarketsDarkOverrides},
  {name: 'accuracyAccountsDark', accuracyAccountsDarkOverrides},
  {name: 'regalxDark', regalxDarkOverrides},
  {name: 'bdsTradingDark', bdsTradingDarkOverrides},
  {name: 'vivernoDark', vivernoDarkOverrides},
].map(flow(
  applyBaseProperties,
  applyBasePaletteProperties,
  applyBaseShadowStyleProperties,
  applyBaseComponentsProperties,
  applyMobileOverridesProperties,
  (props) => {
    const theme = createTheme(props)
    return [theme, merge(theme, rtlOverrides)]
  }
))

const themes = {
  bdSwiss, bdSwissRtl,
  swissMarkets, swissMarketsRtl,
  accuracyAccounts, accuracyAccountsRtl,
  regalx, regalxRtl,
  bdsTrading, bdsTradingRtl,
  viverno, vivernoRtl,
  bdSwissDark, bdSwissDarkRtl,
  swissMarketsDark, swissMarketsDarkRtl,
  accuracyAccountsDark, accuracyAccountsDarkRtl,
  regalxDark, regalxDarkRtl,
  bdsTradingDark, bdsTradingDarkRtl,
  vivernoDark, vivernoDarkRtl,
}

export default themes
