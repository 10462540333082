import styled from '@mui/system/styled'
import MuiInputLabel from '@mui/material/InputLabel'

const InputLabel = styled((props) => <MuiInputLabel {...props}/>)(({theme, disabled}) => ({
  color: disabled ? theme.palette.secondary.main : theme.palette.secondary.dark,
  position: 'relative',
  transform: 'none',
  maxWidth: 'unset',
  marginBottom: 4,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  textAlign: 'left',
}))

export default InputLabel
