import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AppContext from '../Common/contexts/AppContext'
import {flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {FormGroup, FormControlLabel, Switch} from '@mui/material'
import classNames from 'classnames'

const styles = theme => ({
  root: {},
  switchRoot: {
    float: 'right',
    margin: 0,
  },
  switchLabel:{
    display:'inline-block',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      display: 'inherit'
    }
  },
  formControlLabel:{
    float: [theme.direction === 'rtl' ? 'none' : 'left'],
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 15,
  },
  track: {},
  switchBase: {}
})

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: '2px 4px 2px 2px',
    '&$checked': {
      padding: '2px 2px 2px 4px',
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.secondary.light,
  },
  track: {
    borderRadius: 24 / 2,
    border: `1px solid ${theme.palette.grey.contrastBackground}`,
    backgroundColor: theme.palette.grey.contrastBackground,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({classes, ...props}) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
))

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.green.color,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  checked: {},
}))(Switch)

class SwitchButton extends Component {
  static contextType = AppContext

  render() {
    const {classes, checked, value, onChange, label, classesNameLabel, classesLabel, labelPlacement, id, disabled, iOSSwitch} = this.props
    return <FormGroup>
      <FormControlLabel
        control={
          iOSSwitch
            ? <IOSSwitch
              checked={checked} onChange={(e)=> onChange(e)}
              classes={{root: classes.switchRoot, track: classes.track, switchBase: classes.switchBase}}
            />
            : <AntSwitch
              id={id || 'switchButton'}
              checked={checked}
              onChange={(e)=> onChange(e)}
              value={value}
              disabled={!!disabled}
              classes={{root: classes.switchRoot}}
            />
        }
        labelPlacement={labelPlacement || 'start'}
        label={label}
        className={label ? classNames(classesNameLabel || [classes.underline, classes.switchLabel]) : ''}
        classes={(label || classesLabel)? classesLabel || {label: classes.formControlLabel, labelPlacementStart: classes.labelPlacementStart,
          root: classes.root} : {}}
      />
    </FormGroup>
  }
}

SwitchButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(SwitchButton)
