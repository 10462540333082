import React, {Component} from 'react'
import {flowRight as compose, forEach, get, isEmpty, map, orderBy, size} from 'lodash'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../../Common/contexts/AppContext'
import Carousel from 'react-multi-carousel'
import {getCurrentTheme, isDarkTheme, isMobile} from '../../../common/utils'
import {getResponsiveObject, promotionalBannersOptions} from './utils'
import classNames from 'classnames'
import PromotionalBannerSlide from './PromotionalBannerSlide'
import PromotionalBannerButtonGroup from './PromotionalBannerButtonGroup'
import 'react-multi-carousel/lib/styles.css'
import PromotionalBannerLoadingWrapper from './PromotionalBannerLoadingWrapper'

const styles = (theme) => ({
  itemClassNextSlide: {
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: theme.spacing(0.5),
    },
  },
  itemClassHiddenSlides: {
    '&[aria-hidden=true]': {
      height: 0,
      [theme.breakpoints.down('md')]: {
        height: 'initial',
      },
    }
  },
  containerClass: {
    containerType: 'inline-size',
    minWidth: 320,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
  hideCarousel: {
    visibility: 'hidden',
    maxHeight: 0,
  },
})

class PromotionalBanners extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      isMoving: false,
      imgLoaded: false,
    }
  }

  componentDidMount() {
    this.preCacheImages()
  }

  preCacheImages = () => {
    const items = get(promotionalBannersOptions, 'banners', {})
    const promises = []
    forEach(items, (item) => {
      promises.push(
        new Promise((resolve, reject) => {
          const img = new Image()
          img.src = item.imageSrc
          img.onload = resolve
          img.onerror = reject
        }))
    })
    Promise.all(promises)
      .then(() => this.setState({imgLoaded: true}))
  }

  forceDraggableState = () => {
    if (isMobile()) return false
    const arrowsElement = document.querySelector('#promotionalBannerNavigation')
    if (!arrowsElement) return true
    return window.getComputedStyle(arrowsElement).display === 'none'
  }

  render() {
    const {classes, theme} = this.props
    const {imgLoaded, isMoving} = this.state
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    const responsive = getResponsiveObject(theme)
    const forceDraggable = this.forceDraggableState()
    const banners = get(promotionalBannersOptions, 'banners', {})
    const options = get(promotionalBannersOptions, 'options', {})
    const rtl = theme.direction === 'rtl'
    const hasManyBanners = size(banners) > 1
    if (isEmpty(banners)) return null
    return (
      <React.Fragment>
        {!imgLoaded && <PromotionalBannerLoadingWrapper isDark={isDark} />}
        <Carousel
          responsive={responsive}
          customButtonGroup={<PromotionalBannerButtonGroup hide={!hasManyBanners} hideOnDeviceTypes={['xs', 'sm']} />}
          itemClass={classNames(classes.itemClassNextSlide, {[classes.itemClassHiddenSlides]: hasManyBanners})}
          containerClass={classNames(classes.containerClass, {[classes.hideCarousel]: !imgLoaded})}
          autoPlaySpeed={options.interval}
          showDots={options.showDots}
          arrows={options.arrows}
          draggable={forceDraggable}
          rtl={rtl}
          partialVisible={hasManyBanners}
          shouldResetAutoplay={hasManyBanners}
          swipeable={hasManyBanners}
          infinite={hasManyBanners}
          autoPlay={hasManyBanners}
          beforeChange={() => this.setState({isMoving: true})}
          afterChange={() => this.setState({isMoving: false})}
        >
          {map(orderBy(banners, 'order', 'asc'), (item, i) =>
            <PromotionalBannerSlide
              item={item}
              key={i}
              isDark={isDark}
              isMoving={isMoving}
            />
          )}
        </Carousel>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
)(PromotionalBanners)
