import React, {Component} from 'react'
import {find, flowRight as compose, isEmpty, isNil, map} from 'lodash'
import {Button, Grid} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import Filter from './Filter'
import messages from '../../../assets/messages'
import classNames from 'classnames'
import AppContext from '../../Common/contexts/AppContext'
import {getCurrentTheme, isDarkTheme} from '../../../common/utils'
import PropTypes from 'prop-types'

const styles = theme => ({
  filters: {
    display: 'flex',
    zIndex: '10',
    flexDirection: 'row',
  },
  filtersContainer: {
    display: 'flex',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: '8px',
    },
  },
  filter: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '45%',
    }
  },
  clearButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  clearButton: {
    background: theme.palette.extralightgrey.color,
    color: theme.palette.primary.main,
    padding: '0px 24px',
    '&.disabled': {
      color: theme.palette.grey[400],
      '&.dark': {
        background: theme.palette.grey[900],
        color: theme.palette.grey[800],
      }
    },
    '&.dark': {
      background: theme.palette.grey[900],
      color: theme.palette.secondary.light,
    }
  },
})

class Filters extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {classes, filters, onClearFiltersHandle} = this.props
    const clearButtonDisabled = isNil(find(filters, (f) => !isEmpty(f.value)))
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    return (
      <Grid className={classes.filters} container>
        <Grid className={classes.filtersContainer} item sm={6} xs={12}>
          {map(filters, (f) =>
            <Grid className={classes.filter} key={f.id} item sm={4} xs={6}>
              <Filter {...f}/>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.clearButtonContainer} item sm={6}>
          <Button
            className={classNames(classes.clearButton, clearButtonDisabled ? 'disabled': '', isDark ? 'dark' : '')}
            align='right'
            disabled={clearButtonDisabled}
            size='small'
            onClick={() => onClearFiltersHandle(filters)}>
            <Trans {...messages.promoMaterialsClear}/>
          </Button>
        </Grid>
      </Grid>
    )
  }
}

Filters.propTypes = {
  filters: PropTypes.array,
  onClearFiltersHandle: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(Filters)
