import React, {forwardRef} from 'react'
import MuiSelect from '@mui/material/Select'
import styled from '@mui/system/styled'
import {useFormControl} from '@mui/material/FormControl'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import InputLabel from './InputLabel'

const StyledSelect = styled(MuiSelect)(({theme, value, size, disabled}) => ({
  height: size === 'small' ? 40 : 48,
  color: !value ? '#999' : '#000',
  fontSize: 14,
  '& fieldset': {
    borderColor: theme.palette.mode === 'dark' ? '#474747' : '#D6D6D6',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
    cursor: disabled ? 'auto' : 'pointer',
  },
  '& .MuiOutlinedInput-input': {
    ...(!value && {color: `${theme.palette.mode === 'dark' ? 'rgb(133,133,133)' : 'rgb(162,162,162)'} !important`}),
  },
  '&:hover fieldset, &.Mui-focused fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.primary.main} !important`,
  },
  '& .flag-icon': {
    fontSize: '1.633em',
    borderRadius: 3,
    marginRight: 12,
  },
}))

const Select = forwardRef(({value, label, placeholder, children, disabled, InputLabelProps, ...SelectProps}, ref) => {
  const {hiddenLabel, error} = useFormControl()
  return (
    <>
      {!hiddenLabel && <InputLabel shrink={true} disabled={disabled} {...InputLabelProps}>{label}</InputLabel>}
      <StyledSelect
        ref={ref}
        variant="outlined"
        value={value}
        error={error}
        IconComponent={ExpandMoreOutlinedIcon}
        renderValue={(value) => value || placeholder}
        {...SelectProps}
      >
        {children}
      </StyledSelect>
    </>
  )
})

export default Select
