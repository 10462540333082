import React, {Component} from 'react'
import {get, isEmpty, some, flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import {graphql} from 'react-apollo'
import {FormHelperText} from '@mui/material'
import {Trans, withNamespaces} from 'react-i18next'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import DialogContent from '@mui/material/DialogContent'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../../../Common/contexts/AppContext'
import config from '../../../../config'
import messages from '../../../../assets/messages'
import LoadingButton from '../../../Common/LoadingButton'
import {UPDATE_OWN_DETAILS_MUTATION} from '../../../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../../../graphql/queries'

const style = (theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: `${theme.spacing(1)} 0`
  },
  textField: {
    marginTop: 0,
  },
  expiryDate: {
    paddingTop: 4,
  },
  paddingField: {
    paddingTop: 4,
  },
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  errorDiv:{
    '& div': {
      '& div': {
        borderBottom: `1px solid ${theme.palette.error.main}`,
      }
    }
  },
  button:{
    marginTop: 0
  },
  fullNameField:{
    paddingLeft:'0px'
  },
  fullHeight: {
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
})

export class RecurlyProvider extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.recurlyRef = React.createRef()
    this.state = {
      form: {
        firstName: get(props.client, 'firstName', '') || '',
        lastName: get(props.client, 'lastName', '') || '',
        address1: get(props.client, 'address.line1', '') || '',
        postalCode: get(props.client, 'address.zip', '') || ''
      },
      errors: {},
      loading: false, status: '',
    }
  }

  componentDidMount() {
    window.recurly && window.recurly.configure({
      publicKey: config.recurlyPublicKey,
      fields: {
        // affects all fields
        all: {
          style: {
            font: 'inherit',
            color: 'currentColor',
            width: '100%',
            border: 0,
            margin: 0,
            padding: '6px 0 7px',
            background: 'none',
            '-webkit-tap-highlight-color': 'transparent',
          }
        },
        number:{
          format: true,
        }
      }
    })
    setTimeout(() => this.getThreedDialogContent(),  10000)
  }

  componentDidUpdate(prevProps) {
    const container = document.querySelector('#three-d-container')
    const {tokenThreeD, parentToken} = this.props
    if (container && tokenThreeD && tokenThreeD !== prevProps.tokenThreeD) {
      const risk = window.recurly.Risk()
      const threeDSecure = risk.ThreeDSecure({actionTokenId: tokenThreeD})
      threeDSecure.on('error', (err) => {
        this.props.onThreeDError(err.message || messages.somethingWrongError.defaults)
        container.removeChild(container.firstChild)
      })
      threeDSecure.on('token', (token) => {
        this.props.recurlyAction('token', parentToken, get(token, 'id'))
      })
      threeDSecure.attach(container)
    }
  }

  getThreedDialogContent() {
    this.setState({threedDialogContent: document.getElementById('three-d-container') && document.getElementById('three-d-container').innerHTML})
  }

  doRecurly() {
    const {client} = this.props
    const {form: {address1, postalCode}} = this.state
    this.setState({status: '', loading: true})
    const errors = {}
    for (const field of Object.keys(this.state.form)) {
      errors[field] = isEmpty(this.state.form[field])
    }

    if (!some(errors))
    {
      window.recurly.token(document.querySelector('form'), (err, token) => {
        this.setState({loading: false})
        if (err) {
          for (const field of err.fields)
            errors[field] = true
          this.setState({status: 'failure', errors, recurlyErrorMsg: err.message})
        } else {
          this.props.recurlyAction('token', token.id)
          if (( isEmpty(client['address']['line1']) || isEmpty(client['address']['zip']) ) ) {
            const variables = {'line1':address1, 'zip':postalCode}
            this.props.updateDetails({variables})
          }
        }
      })
    } else {
      this.setState({loading: false, errors})
    }
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  render() {
    const {classes, recurlyError, loadingProp, openDialogThreeD} = this.props
    const {status, loading, recurlyErrorMsg, form:{firstName,lastName,address1,postalCode}} = this.state
    const statusProp = (recurlyError)? 'failure': ''

    return <React.Fragment>
      <div id="three-d-container" ref={this.recurlyRef}></div>
      <Dialog fullWidth disableEscapeKeyDown fullScreen open={openDialogThreeD} aria-labelledby="recurly-three-d" className={openDialogThreeD ? '' : classes.hidden}
        disableEnforceFocus={true}>
        <DialogContent className={classes.fullHeight} dangerouslySetInnerHTML={{__html: this.state.threedDialogContent}} />
      </Dialog>
      <form className={classes.root}>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <InputLabel htmlFor="cardholder">
              <Trans {...messages.cardholderName} />
            </InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={6} className={classes.fullNameField}>
                <TextField
                  variant="standard"
                  id="first_name"
                  type="text"
                  fullWidth
                  className={classes.textField}
                  data-recurly="first_name"
                  inputProps={{
                    'data-recurly': 'first_name',
                  }}
                  margin="normal"
                  value={firstName}
                  error={this.state.errors.first_name}
                  onChange={(e) => this.handleChange('firstName', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="last_name"
                  type="text"
                  fullWidth
                  className={classes.textField}
                  data-recurly="last_name"
                  inputProps={{
                    'data-recurly': 'last_name',
                  }}
                  margin="normal"
                  value={lastName}
                  error={this.state.errors.last_name}
                  onChange={(e) => this.handleChange('lastName', e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="address1">
              <Trans {...messages.address} />
            </InputLabel>
            <TextField
              id="address1"
              type="text"
              fullWidth
              className={classes.textField}
              data-recurly="address1"
              inputProps={{
                'data-recurly': 'address1',
              }}
              margin="normal"
              value={address1}
              error={this.state.errors.address1}
              onChange={(e) => this.handleChange('address1', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="postal_code">
              <Trans {...messages.zipLabel} />
            </InputLabel>
            <TextField
              id="postal_code"
              type="text"
              fullWidth
              className={classes.textField}
              data-recurly="postal_code"
              inputProps={{
                'data-recurly': 'postal_code',
              }}
              margin="normal"
              value={postalCode}
              error={this.state.errors.postalCode}
              onChange={(e) => this.handleChange('postalCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} classes={(this.state.errors.number)?{item: classes.errorDiv}:{}}>
            <InputLabel htmlFor="cardNumber">
              <Trans {...messages.cardNumber} />
            </InputLabel>
            <div data-recurly="card"></div>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              id='loadingButton'
              size="large"
              onClick={() => this.doRecurly()}
              disabled={(loading && !statusProp) || loadingProp || false}
              status={status || statusProp}
            ><Trans {...messages.proceedButton} />
            </LoadingButton>
            {(status==='failure' || statusProp==='failure') &&
              <FormHelperText className={classes.errorMessage}>{(recurlyError && recurlyError.toString()) || recurlyErrorMsg}</FormHelperText>}
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withStyles(style),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
)(RecurlyProvider)
