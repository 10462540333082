import React, {Component} from 'react'
import {flowRight as compose, find, get, isEmpty, map, some} from 'lodash'
import {FormControl, Grid, Grow, MenuItem, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY, PARTNER_FAQ_QUERY} from '../../graphql/queries'
import LoadingButton from '../Common/LoadingButton'
import {validateEmail, validatePhone} from '../../common/utils/validations'
import {CREATE_EVENT_REQUEST_FORM} from '../../graphql/mutations'
import Loading from '../Common/Loading'
import AccordionCustom from '../Common/AccordionCustom'
import Select from '../../components/Common/Select'
import TextField from '../../components/Common/TextField'
import {natureOfRequest as natureOfRequestEnum} from '@bdswiss/common-enums'
import NotificationBar from '../Common/NotificationBar'

const styles = _theme => ({
  root: {
    width: '100%',
  },
  supportForm: {
    paddingTop: '24px',
  },
  sectionHeader: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.18px',
    textAlign: 'left',
  },
  loadingPadding: {
    padding: 20,
  },
  seeAll: {
    paddingBottom: 10,
  }
})
export class Support extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errors: {},
      form: {
        natureOfRequest: '',
        phone: '',
        email: '',
        details: '',
      },
      supportFormLoading: false,
      supportFormStatus: '',
    }
    this.handleChangeFormField = this.handleChangeFormField.bind(this)
  }

  handleChangeFormField(e) {
    const {name, value} = e.target
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  onSubmit() {
    const {viewer: {id}} = this.props
    const {form, form: {natureOfRequest, phone, email, details}, errors} = this.state
    for (const field of Object.keys(form)) {
      if (field === 'email') {
        errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
      } else if (field === 'phone') {
        errors[field] = isEmpty(form[field]) || !validatePhone(form[field], true)
      } else {
        errors[field] = isEmpty(form[field])
      }
    }
    if (some(errors)) {
      this.setState({errors})
      return
    }
    this.setState({supportFormLoading: true, supportFormStatus: ''})
    this.props.createEventRequestForm({variables: {clientId: id, email, phone, details, natureOfRequest, type: 'supportForm'}})
      .then(() => {
        this.setState({
          supportFormStatus: 'success',
          supportFormLoading: false,
          form: {
            natureOfRequest: '',
            phone: '',
            email: '',
            details: '',
          },
        })
      })
      .catch((_) => this.setState({supportFormLoading: false, supportFormStatus: 'error'}))
  }

  handleRenderNatureOfRequest(selection) {
    const {locale} = this.context
    const {t} = this.props
    if (!selection) return t(messages.natureOfRequestSelectType.i18nKey, messages.natureOfRequestSelectType.defaults)
    return find(natureOfRequestEnum, {value: selection})?.localization.t(locale) ?? selection
  }

  // Should check if all fields are filled up
  isFormValid () {
    const {form} = this.state
    return Object.values(form).every((field) => field.trim() !== '')
  }

  render() {
    const {locale} = this.context
    const {t, classes, partnerFaqs, loadingFaq} = this.props
    const {errors, form: {email, phone, details, natureOfRequest}, supportFormLoading, supportFormStatus} = this.state
    if (loadingFaq) return <Loading speed={1} className={classes.loadingPadding} />
    return (
      <React.Fragment>
        <Grid container>
          {['success', 'error'].includes(supportFormStatus) && (
            <Grow in={true}>
              <Grid item xs={12} sx={{pb: {xs: 2}}}>
                <NotificationBar
                  status={supportFormStatus}
                  title={<Trans {...(supportFormStatus === 'success' ? messages.supportTicketSubmittedContent : messages.somethingWrongError)} />}
                />
              </Grid>
            </Grow>
          )}
          <Grid item xs={12}>
            <Typography variant='h1' className={classes.sectionHeader}>
              <Trans {...messages.faqs} />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.root} sx={{pt: 0.5}}>
            <AccordionCustom items={partnerFaqs} viewMore={true} initialItems={7}/>
          </Grid>
          <Grid item xs={12} sx={{mt: 2}}>
            <Typography variant='h1' className={classes.sectionHeader}>
              <Trans {...messages.stillHaveQuestions} />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.supportForm}>
            <Grid container spacing={1} gap={2} justifyContent='center'>
              <Grid item xs={12} lg md>
                <FormControl error={errors.natureOfRequest} fullWidth required>
                  <Select
                    name='natureOfRequest'
                    placeholder={t(messages.natureOfRequestSelectType.i18nKey, messages.natureOfRequestSelectType.defaults)}
                    label={t(messages.natureOfRequest.i18nKey, messages.natureOfRequest.defaults)}
                    value={natureOfRequest || ''}
                    onChange={this.handleChangeFormField}
                    renderValue={(selection) => this.handleRenderNatureOfRequest(selection)}
                    displayEmpty
                  >
                    {map(natureOfRequestEnum, (item) =>
                      (<MenuItem key={item.key} value={item.value}>
                        {item.localization.t(locale)}
                      </MenuItem>)
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg md>
                <FormControl error={errors.phone} fullWidth required>
                  <TextField
                    name='phone'
                    placeholder='+999 99 999 999'
                    label={t(messages.phone.i18nKey, messages.phone.defaults)}
                    value={phone}
                    onChange={this.handleChangeFormField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg md>
                <FormControl error={errors.email} fullWidth required>
                  <TextField
                    name='email'
                    placeholder='example@mail.com'
                    label={t(messages.email.i18nKey, messages.email.defaults)}
                    value={email}
                    onChange={this.handleChangeFormField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant='standard' error={errors['details']} fullWidth required>
                  <TextField
                    name='details'
                    placeholder={t(messages.supportHelperText.i18nKey, messages.supportHelperText.defaults)}
                    label={t(messages.details.i18nKey, messages.details.defaults)}
                    value={details}
                    onChange={this.handleChangeFormField}
                    multiline
                    rows={4}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <LoadingButton
                  id='loadingButton'
                  onClick={() => this.onSubmit()}
                  disabled={supportFormLoading || !this.isFormValid()}
                  hideProgressBar={!supportFormLoading}
                  fullWidth={true}
                  noMargin
                >
                  <Trans {...messages.submit} />
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
  graphql(PARTNER_FAQ_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {loading, error}, data}) => ({
      loadingFaq: loading,
      errorFaq: error,
      partnerFaqs: get(data, 'partnerFaq'),
    })
  }),
  graphql(CREATE_EVENT_REQUEST_FORM, {
    name: 'createEventRequestForm',
  }),
)(Support)
