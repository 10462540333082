import React from 'react'
import {get, isEmpty, isFunction, isNil} from 'lodash'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import HeaderDialog from './HeaderDialog'
import SidebarDialog from './SidebarDialog'
import withStyles from '@mui/styles/withStyles'
import {isMobile} from '../../../common/utils/browser'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '../LoadingButton'
import {FormHelperText} from '@mui/material'
import classNames from 'classnames'

const styles = (theme) => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'2rem auto 4rem',
  },
  sidebar: {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    maxWidth: `${theme.sidebarWidth}`,
    height: '100%',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 0,
  },
  main: {
    marginTop: 30,
    [theme.breakpoints.down('lg')]: {
      [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 10,
    },
  },
  closeButton: {
    position: 'absolute',
    [theme.direction === 'rtl' ? 'left' : 'right']: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  dialogTitle: {
    '&&:first-child': {
      marginRight: '35px',
    }
  },
  alertBody: {
    minWidth: 270,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  errorMessage:{
    color:  theme.palette.error.main,
    textAlign: theme.direction === 'rtl' ? 'left' : 'right'
  },
  successMessage:{
    color:  theme.palette.green.color,
    textAlign: 'right'
  },
})

const AlertDialogComponent = (props) =>  (
  <div>
    <Dialog
      open={props.open}
      onClose={props.onClose}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={props.maxWidth}
      disableEnforceFocus={!isNil(props.disableEnforceFocus) ? props.disableEnforceFocus : true}
      classes={get(props, 'customClasses.classes', {})}
      BackdropProps={get(props, 'backdropProps', {})}
    >
      {props.title &&
      <DialogTitle
        id="alert-dialog-title"
        className={get(props, 'customClasses.dialogTitle', '') || props.classes.dialogTitle}>
        {props.title}
        {props.closeBtn || !props.agreeText ? (
          <IconButton
            aria-label="Close"
            className={props.classes.closeButton}
            onClick={props.onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>}
      <DialogContent classes={{root: classNames(props.classes.alertBody, props.alertBodyClass, get(props, 'customClasses.dialogContent'))}}>
        {props.children}
      </DialogContent>
      <DialogActions className={get(props, 'customClasses.dialogActions', '')}>
        <Grid
          container
          direction="row"
          justifyContent={props.justify ? props.justify : 'flex-end' }
          alignItems={props.alignItems ? props.alignItems : 'flex-end' }
          spacing={1}
          className={get(props, 'customClasses.buttonGroup', '')}
        >
          {props.disagreeText && <Grid item className={get(props, 'customClasses.disagreeButtonContainer', '')}>
            <Button fullWidth={isMobile() ? true: false} color="secondary" size="medium" variant="contained"
              onClick={props.onDisagree ? props.onDisagree : props.onClose} className={get(props, 'customClasses.disagreeButton', '')}>
              {props.disagreeText}
            </Button></Grid>}
          {props.agreeText && <Grid item className={get(props, 'customClasses.agreeButtonContainer', '')}>
            <LoadingButton
              id='loadingButton'
              onClick={props.onAgree ? props.onAgree : props.onClose}
              autoFocus
              fullWidth={isMobile()}
              color="primary"
              variant="contained"
              size="medium"
              disabled={props.buttonLoading || !isEmpty(props.errorText) || !isEmpty(props.successText) || props.buttonDisabled || false}
              status={props.buttonStatus || ''}
              noMargin={true}
              hideProgressBar={(!props.buttonLoading && !isEmpty(props.buttonStatus)) || props.hideProgressBar}
              className={get(props, 'customClasses.agreeButton', '')}
            >
              {props.agreeText}
            </LoadingButton> </Grid>}
          {props.errorText && <Grid item xs={12}>
            <FormHelperText className={props.classes.errorMessage}>{props.errorText}</FormHelperText></Grid>}
          {props.successText && <Grid item xs={12}>
            <FormHelperText className={props.classes.successMessage}>{props.successText}</FormHelperText></Grid>}
        </Grid>

      </DialogActions>
    </Dialog>
  </div>
)
export const AlertDialog = withStyles(styles)(AlertDialogComponent)

export const CustomDialog =  withStyles(styles)((props) => (
  <div>
    <Dialog
      open={props.open}
      onClose={props.onClose}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={get(props, 'customClasses.dialog', '')}
      maxWidth={props.maxWidth}
      fullScreen={props.fullScreen}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      classes={get(props, 'customClasses.classes', {})}
      disableEnforceFocus={!isNil(props.disableEnforceFocus) ? props.disableEnforceFocus : true}
      BackdropProps={get(props, 'backdropProps', {})}
    >
      {isFunction(props.onCloseIcon) && isNil(props.customCloseComponent)
      && <CloseIcon className={get(props, 'customClasses.closeIcon')} onClick={() => props.onCloseIcon()}/>}
      {!isNil(props.customCloseComponent) && props.customCloseComponent}
      {props.title &&
      <DialogTitle
        id="alert-dialog-title"
        className={get(props, 'customClasses.dialogTitle', '')}>
        {props.title}
      </DialogTitle>
      }
      <DialogContent className={get(props, 'customClasses.dialogContent',props.classes.dialogContent)}>
        {props.children}
      </DialogContent>
    </Dialog>
  </div>
))

const FullScreenDialogComponent = (props) =>  {
  const {classes} = props
  if (isMobile() && props.desktopOnly) {
    return props.component || props.children
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      disableEnforceFocus={!isNil(props.disableEnforceFocus) ? props.disableEnforceFocus : true}
    >
      <Grid container className={classes.mainBody} id={props.id}>
        <HeaderDialog linkTo={'/accounts'}/>
        <Grid item className={classes.sidebar} xs={props.left || 3}>
          <SidebarDialog  onClick={props.onClose} smallSidebar={props.smallSidebar} />
        </Grid>
        <Grid item xs={props.right || 9}>
          <main className={classes.main}>{props.component || props.children}</main>
        </Grid>
      </Grid>
    </Dialog>)
}

export const FullScreenDialog = withStyles(styles)(FullScreenDialogComponent)
