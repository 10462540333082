import React, {Component} from 'react'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import {graphql} from 'react-apollo'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import TableRow from '@mui/material/TableRow'
import ListItem from '@mui/material/ListItem'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@mui/material/Typography'
import {get, find, map, reject, isEmpty, flowRight as compose, has, minBy} from 'lodash'
import ListItemText from '@mui/material/ListItemText'
import withStyles from '@mui/styles/withStyles'
import {currencies, withdrawalPaymentVendors} from '@bdswiss/common-enums'
import messages from '../../../assets/messages'
import PageSubTitle from '../../Common/PageSubTitle'
import {isMobile} from '../../../common/utils/browser'
import {PROFILE_SETTINGS_QUERY, WITHDRAWAL_PAYMENT_OPTIONS} from '../../../graphql/queries'
import {getFormattedAmount} from '../../../common/utils/general'
import {Link} from 'react-router-dom'
import {getAccountLabel, getAccountSubtype, isCentAccount} from '../../../common/utils/accounts'
import FormHelperText from '@mui/material/FormHelperText'
import NotificationBar from '../../Common/NotificationBar'
import HelpOutlineOutlined from '@mui/icons-material/HelpOutline'

const styles = theme => ({
  cell: {
    padding: 0,
    width: '1%'
  },
  lastCell: {
    maxWidth:150
  },
  messageGrid: {
    marginTop: 30,
  },
  highlight: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  amountLeft: {
    color: theme.palette.green.color,
    fontWeight: 400,
  },
  noPadding: {
    padding: 0,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 500,
    color: 'inherit'
  },
  paddingLeft: {
    paddingLeft: 40
  },
  textBold: {
    fontWeight: 400
  },
  textItalic: {
    textDecoration: 'underline',
    fontStyle: 'italic'
  },
  paddingTop:{
    paddingTop:'1rem'
  }
})

const renderProcessingTime = function(paymentMethod, props) {
  const {withdrawalPaymentOptions: {withdrawalPaymentOptions}} = props
  const selectedPaymentOption = find(withdrawalPaymentOptions, {key: paymentMethod})

  let processingTimeLabel = ''
  const processingTimeValue = get(selectedPaymentOption, 'processingTimeValue', null)
  const processingTime = get(selectedPaymentOption, 'processingTime', null)

  if (isEmpty(processingTimeValue) && isEmpty(processingTime)) {
    return processingTimeLabel
  }

  if (processingTimeValue === '1' && processingTime === 'days') {
    processingTimeLabel = processingTimeValue + ' ' + messages.day?.defaults
  } else if (processingTimeValue === '1' && processingTime === 'hours') {
    processingTimeLabel = processingTimeValue + ' ' + messages.hour?.defaults
  } else if (processingTimeValue === '1' && processingTime === 'minutes') {
    processingTimeLabel = processingTimeValue + ' ' + messages.minute?.defaults
  } else {
    processingTimeLabel = processingTimeValue + ' ' + messages[processingTime]?.defaults
  }

  return processingTimeLabel
}

const renderTransactionFee = function(currency, paymentMethod, props) {
  const {withdrawalPaymentOptions: {withdrawalPaymentOptions}} = props

  const selectedPaymentOption = find(withdrawalPaymentOptions, {key: paymentMethod})

  if (selectedPaymentOption?.transactionFee === 0) {
    return <span><Trans {...messages.noFees} /></span>
  } else if (selectedPaymentOption?.transactionFee > 0) {
    return selectedPaymentOption?.transactionFee + ' ' + currency
  }

}

const renderMinMaxAmount = function(paymentMethod, account, props) {

  //maxWithdrawalAmounts
  const {withdrawalPaymentOptions: {withdrawalPaymentOptions}} = props
  const selectedPaymentOption = find(withdrawalPaymentOptions, {key: paymentMethod})

  let currencyMinWithdrawal = get(selectedPaymentOption, 'minWithdrawalAmounts', null)
  currencyMinWithdrawal = find(currencyMinWithdrawal, {currency: account.currency})

  let currencyMaxWithdrawal = get(selectedPaymentOption, 'maxWithdrawalAmounts', null)
  currencyMaxWithdrawal = find(currencyMaxWithdrawal, {currency: account.currency})

  let minPaymentOptionAmount
  let maxPaymentOptionAmount
  let res = ''

  if (currencyMinWithdrawal) {
    if (has(currencies[account.currency], 'baseCurrency')) {
      minPaymentOptionAmount = currencyMinWithdrawal.amount / currencies[account.currency].baseCurrencyRate
    } else {
      minPaymentOptionAmount = currencyMinWithdrawal.amount
    }
  } else {
    if (has(currencies[account.currency], 'baseCurrency')) {
      minPaymentOptionAmount = get(account, 'minimumWithdrawal') / currencies[account.currency].baseCurrencyRate
    } else {
      minPaymentOptionAmount = get(account, 'minimumWithdrawal')
    }
  }


  if (currencyMaxWithdrawal) {
    if (has(currencies[account.currency], 'baseCurrency')) {
      maxPaymentOptionAmount = currencyMaxWithdrawal.amount / currencies[account.currency].baseCurrencyRate
    } else {
      maxPaymentOptionAmount = currencyMaxWithdrawal.amount
    }
  } else {
    if (has(currencies[account.currency], 'baseCurrency')) {
      maxPaymentOptionAmount = get(account, 'maximumWithdrawal') / currencies[account.currency].baseCurrencyRate
    } else {
      maxPaymentOptionAmount = get(account, 'maximumWithdrawal')
    }
  }

  if (!isEmpty(minPaymentOptionAmount) && !isEmpty(maxPaymentOptionAmount)) {
    res = minPaymentOptionAmount + ' - ' + maxPaymentOptionAmount + ' ' + account.currency
  } else if (!isEmpty(minPaymentOptionAmount)) {
    res = 'Min' + minPaymentOptionAmount
  } else if (!isEmpty(maxPaymentOptionAmount)) {
    res =  'Max ' + maxPaymentOptionAmount
  } else {
    res = ''
  }

  return res
}

const renderWithdrawalOptionsWithDetails = function(paymentOption, account, props) {
  const processingTime = renderProcessingTime(paymentOption.key, props)
  const transactionFee = renderTransactionFee(account.currency, paymentOption.key, props)
  const minMaxAmount = renderMinMaxAmount(paymentOption.key, account, props)

  const paymentVendorDetails = [processingTime, transactionFee, minMaxAmount].filter(el => el !== '').map((element, i) => <span key={i}> {i > 0 && ', '} {element}</span>)

  return <div style={{display:'flex', flexDirection: 'column'}}>
    <div style={{display:'flex', flexDirection: 'row'}}>
      <div>{paymentOption.logoUrl && <img style={{marginRight: '5px'}} src={paymentOption.logoUrl} width="35" responsive={true} alt='logo' />}</div>
      <div>{paymentOption.label || paymentOption.label}</div>
    </div>
    <div>
      <FormHelperText>{paymentVendorDetails}</FormHelperText>
    </div>
  </div>
}

class ChooseAvailableOption extends Component {

  renderHelperTooltip(paymentMethod, amount, account) {
    let errorMessage = null
    const {withdrawalPaymentOptions: {withdrawalPaymentOptions}} = this.props
    const selectedPaymentOption = find(withdrawalPaymentOptions, {key: paymentMethod})

    let currencyMinWithdrawal = get(selectedPaymentOption, 'minWithdrawalAmounts', null)
    currencyMinWithdrawal = find(currencyMinWithdrawal, {currency: account.currency})

    let currencyMaxWithdrawal = get(selectedPaymentOption, 'maxWithdrawalAmounts', null)
    currencyMaxWithdrawal = find(currencyMaxWithdrawal, {currency: account.currency})

    let minPaymentOptionAmount
    let maxPaymentOptionAmount

    if (currencyMinWithdrawal) {
      if (has(currencies[account.currency], 'baseCurrency')) {
        minPaymentOptionAmount = currencyMinWithdrawal.amount / currencies[account.currency].baseCurrencyRate
      } else {
        minPaymentOptionAmount = currencyMinWithdrawal.amount
      }
    }

    if (currencyMaxWithdrawal) {
      if (has(currencies[account.currency], 'baseCurrency')) {
        maxPaymentOptionAmount = currencyMaxWithdrawal.amount / currencies[account.currency].baseCurrencyRate
      } else {
        maxPaymentOptionAmount = currencyMaxWithdrawal.amount
      }
    }

    if (!isEmpty(minPaymentOptionAmount) && minPaymentOptionAmount > amount ) {
      errorMessage = messages.withdrawalMinAmount
    } else if (!isEmpty(maxPaymentOptionAmount) && maxPaymentOptionAmount < amount ) {
      errorMessage = messages.withdrawalMaxAmount
    }

    return (
      errorMessage ? <Tooltip title={<Trans {...errorMessage} />}
        placement="top-start"
      >
        <HelpOutlineOutlined fontSize='small'/>
      </Tooltip> : ''
    )
  }

  render() {
    const {classes, account, amount, goBack, viewer: {locale}, withdrawablePaymentMethods, t,
      withdrawalPaymentOptions: {withdrawalPaymentOptions}} = this.props
    const accountLabel = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
    const formattedAmount = isCentAccount(account)
      ? getFormattedAmount({amount: amount / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})

    //const sortedMethods = sortBy(reject(withdrawablePaymentMethods, (m) => m.amountLeft <= 0), 'order')
    const sortedMethods = map(withdrawablePaymentMethods, (v) => {
      let label = null
      if (!isEmpty(withdrawalPaymentOptions)) {
        const withdrawalPaymentOption = find(withdrawalPaymentOptions, {provider: v.vendor})

        if (!isEmpty(withdrawalPaymentOption)) {
          label = withdrawalPaymentOption.label
        }
      }

      return {...v, label: label}

    })

    let firstVendor = get(minBy(withdrawablePaymentMethods, 'order'), 'vendor')
    if (firstVendor && get(withdrawalPaymentVendors, [firstVendor, 'showWithdrawalMethods'], false)) {
      firstVendor = undefined
    }

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.messageGrid}>
          <PageSubTitle>
            <Trans
              {...messages.withdrawalMessage}
              values={{amount: formattedAmount, accountLabel}}
              components={[
                <span onClick={() => goBack()} className={classes.highlight}>amount</span>,
                <span onClick={() => goBack()} className={classes.highlight}>account</span>
              ]}
            />
          </PageSubTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cell}><Trans {...messages.paymentMethod} /></TableCell>
                    <TableCell className={classes.cell}><Trans {...messages.details} /></TableCell>
                    <TableCell className={classes.cell}><Trans {...messages.totalDeposits} /></TableCell>
                    <TableCell className={classes.cell}><Trans {...messages.amountLeft} /></TableCell>
                    <TableCell className={classes.cell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(reject(sortedMethods, (m) => m.vendor !== firstVendor), (m, i) =>
                    (<TableRow key={i} aria-label={m.vendor}>
                      <TableCell className={classes.cell} component="th" scope="row">
                        <Typography variant="body1">
                          {withdrawalPaymentOptions && renderWithdrawalOptionsWithDetails(find(withdrawalPaymentOptions, {key: m.vendor}), account, this.props)}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography variant="body1">{m.details}</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography variant="body1">
                          {isCentAccount(account)
                            ? getFormattedAmount({amount: m.amount / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency, locale})
                            : getFormattedAmount({amount: m.amount, currency: account.currency, locale})
                          }
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography variant="body1" className={classes.amountLeft}>
                          {isCentAccount(account)
                            ? getFormattedAmount({amount: m.amountLeft / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency, locale})
                            : getFormattedAmount({amount: m.amountLeft, currency: account.currency, locale})
                          }
                        </Typography>
                      </TableCell>
                      <TableCell className={classnames([classes.cell, classes.lastCell])}>
                        <Tooltip
                          title={m.vendor !== firstVendor ? t(messages.refundOptionDisabled.i18nKey, messages.refundOptionDisabled.defaults) : ''}
                          placement="top-start"
                        >
                          <span>
                            <Button
                              disableFocusRipple
                              disableRipple
                              variant="text"
                              color="primary"
                              disabled={m.vendor !== firstVendor}
                              onClick={() => this.props.onContinue(m)}
                            >
                              <Trans {...messages.createWithdrawal} />
                            </Button>
                          </span>
                        </Tooltip>

                        {this.renderHelperTooltip(m.key, amount, account)}
                      </TableCell>
                    </TableRow>)
                  )
                  }
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12} sm={11} style={{backgroundColor: 'l'}}>
              <NotificationBar status='warning' ctaMessage={
                <>
                  <PageSubTitle>
                    <Trans {...messages.attention} />
                  </PageSubTitle>
                  <Typography variant='body1'>
                    <Trans {...messages.withdrawalOptionsSameMethod}/>
                  </Typography>
                  <Typography variant="body2">
                    <Trans
                      {...messages.withdrawalOptionsAnotherOption}
                      components={[
                        <span className={classes.textBold}>another</span>,
                        <span className={classes.textItalic}>only</span>,
                      ]}
                    />
                  </Typography>
                  <Typography>
                    <Trans {...messages.methodNotAvailable}
                      components={[
                        <Link to={'/support'}>here</Link>,
                        <span onClick={() => this.props.skipRefund()}> here</span>,
                      ]}
                    />
                  </Typography>
                </>}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

class MobileChooseAvailableOption extends Component {

  render() {
    const {classes, account, amount, goBack, viewer: {locale}, withdrawablePaymentMethods, t,  withdrawalPaymentOptions: {withdrawalPaymentOptions}} = this.props

    const accountLabel = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
    const formattedAmount = isCentAccount(account)
      ? getFormattedAmount({amount: amount / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})

    const sortedMethods = map(withdrawablePaymentMethods, (v) => {
      let label = null
      if (!isEmpty(withdrawalPaymentOptions)) {
        const withdrawalPaymentOption = find(withdrawalPaymentOptions, {provider: v.vendor})

        if (!isEmpty(withdrawalPaymentOption)) {
          label = withdrawalPaymentOption.label
        }
      }

      return {...v, label: label}

    })

    const firstVendor = get(minBy(withdrawablePaymentMethods, 'order'), 'vendor')

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.messageGrid}>
          <PageSubTitle>
            <Trans
              {...messages.withdrawalMessage}
              values={{amount: formattedAmount, accountLabel}}
              components={[
                <span onClick={() => goBack()} className={classes.highlight}>amount</span>,
                <span onClick={() => goBack()} className={classes.highlight}>account</span>
              ]}
            />
          </PageSubTitle>
        </Grid>
        <Grid item xs={12} sm={12} lg={10}>
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              {map(reject(sortedMethods, (m) => m.vendor !== firstVendor), m => (<React.Fragment key={m.id}>
                <ListItem dense disableGutters>
                  <ListItemText>
                    <Grid container justifyContent="space-between" direction="row">
                      <Grid item>
                        <Typography variant="body1">{withdrawalPaymentOptions && renderWithdrawalOptionsWithDetails(find(withdrawalPaymentOptions, {provider: m.vendor}), account, this.props)}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{m.details}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" direction="row" alignItems="center">
                      <Grid item>
                        <Typography variant="body1" className={classes.amountLeft}>
                          {isCentAccount(account)
                            ? getFormattedAmount({amount: m.amountLeft / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency, locale})
                            : getFormattedAmount({amount: m.amountLeft, currency: account.currency, locale})
                          }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          disableFocusRipple
                          disableRipple
                          variant="text"
                          color="primary"
                          classes={{root: classes.noPadding}}
                          disabled={m.vendor !== firstVendor}
                          onClick={() => this.props.onContinue(m)}
                        >
                          <Trans {...messages.createWithdrawal} />
                        </Button>
                      </Grid>
                    </Grid>
                    {m.vendor !== firstVendor && <Grid container justifyContent="space-between" direction="row">
                      <Typography variant="caption"><Trans {...messages.refundOptionDisabled} /></Typography>
                    </Grid>}
                  </ListItemText>
                </ListItem>
                <Divider />
              </React.Fragment>))}
            </Grid>
            <Grid item xs={12} className={classes.paddingTop}>
              <Grid container justifyContent="center">
                <Grid item xs={11}>
                  <Typography variant="caption" align="center">
                    <i><Trans {...messages.methodNotAvailable}
                      className={classes.note}
                      components={[
                        <Link to={'/support'} className={classes.link}>here</Link>,
                        <span className={classes.link} onClick={() => this.props.skipRefund()}> here</span>,
                      ]}
                    /></i>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <ul key="information">
                <li className={classes.listItem}>
                  <Typography variant='body1'><Trans {...messages.withdrawalOptionsSameMethod}/></Typography>
                </li>
                <li className={classes.listItem}>
                  <Typography variant='body1'>
                    <Trans
                      {...messages.withdrawalOptionsAnotherOption}
                      components={[
                        <span className={classes.textBold}>another</span>,
                        <span className={classes.textItalic}>only</span>,
                      ]}
                    />
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading, viewer}}) => {
      const documents = get(viewer, 'documents', [])
      return {
        error,
        loading,
        documents,
      }
    }}
  ),
  graphql(WITHDRAWAL_PAYMENT_OPTIONS, {
    name: 'withdrawalPaymentOptions'
  })
)(isMobile() ? MobileChooseAvailableOption : ChooseAvailableOption)
