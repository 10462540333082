import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import Grid from '@mui/material/Grid'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose, get} from 'lodash'
import queryString from 'qs'
import {PaymentProviderFactory} from './PaymentProviders'
import messages from '../../../assets/messages'
import AppContext from '../../Common/contexts/AppContext'
import Typography from '@mui/material/Typography'
import {isMobile, safeParseJSON} from '../../../common/utils'
import {beginDepositResults, depositVendors, kycStatuses} from '@bdswiss/common-enums'
import {CLIENT_PROFILE_QUERY} from '../../../graphql/queries'
import {Loading} from '../../Common/Loading'
import config from '../../../config'
import NotificationBar from '../../Common/NotificationBar'

const styles = theme => ({
  item: {
    paddingLeft: 20,
    fontSize: '1em',
    cursor: 'pointer',
    'white-space': 'pre',
  },
  paymentProviderSection: {
    padding: isMobile() ? '12px' : '100px 12px 12px 12px',
  },
  errorMessage: {
    'text-align': 'center',
    color: theme.palette.red.color,
  },
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
})

const PaymentMethodProviderInfoGridId = 'payment-provider-grid'

class InitiateDeposit extends Component {

  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  handlePaymentError(e) {
    this.setState({hasError: true})
    const company = get(config, 'forcedCompanyDeails.brand') || 'bdswiss'
    window.location = `${company}://payment?result=${beginDepositResults.failed.value}`
  }

  render() {
    const {viewer, classes, history, viewerLoading, bonusAmount} = this.props
    const urlParams = queryString.parse(this.props.location.search.replace('?', ''))
    const {amount, vendor: provider, providerName, args, account} = urlParams
    const {paymentKey, bankCode, bankDetails} = safeParseJSON(args)
    const selectedPaymentOption = {
      provider,
      paymentKey: paymentKey ? paymentKey : '',
      name: providerName,
      providerBankCode: bankCode,
      bankDetails,
    }

    if (!viewer || viewerLoading) return <Loading />
    let PaymentProvider = null
    if (PaymentProviderFactory.hasImplementation(provider)) {
      PaymentProvider = PaymentProviderFactory.get(provider, paymentKey)
    }
    const kycRequired = get(depositVendors[provider], 'kycRequired', false)

    return (
      <React.Fragment>
        <Grid container justifyContent="center" alignItems="center" direction="row" spacing={3}>
          {PaymentProvider && !this.state.hasError && (
            <Grid id={PaymentMethodProviderInfoGridId} item xs={12} sm={12} lg={12} className={classes.paymentProviderSection}>
              {kycRequired && viewer.kycStatus !== kycStatuses.approved.value
                ? (
                  <NotificationBar
                    status="warning"
                    title={<Trans {...messages.paymentMethodKycRequired} />}
                    ctaMessage={<Trans {...messages.verifyAccount} />}
                    ctaAction={() => history.push('/settings/profile/documents')}
                  />
                )
                : (
                  <PaymentProvider
                    viewer={viewer}
                    account={JSON.parse(account)}
                    amount={Number(amount)}
                    providerProperties={selectedPaymentOption}
                    onError={e => this.handlePaymentError(e)}
                    history={history}
                    useVirtualPaymentPage
                    bonusAmount={Number(bonusAmount)}
                  />
                )}
            </Grid>
          )}
          {(!PaymentProvider || this.state.hasError) &&
            <Grid item xs={12} sm={12} lg={6}>
              <Typography variant="caption" className={classes.errorMessage}>
                <Trans {...messages.paymentMethodUnavailable}/>
              </Typography>
            </Grid>
          }
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_PROFILE_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        viewerLoading: loading,
        viewer,
      }
    },
  }),
)(InitiateDeposit)
