import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, map, get, find, includes, isEmpty, findKey, some, size, filter, omit, keys, split, head, pickBy,
  groupBy, keyBy, merge, lowerCase, isNil, has, values, every, difference} from 'lodash'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {Link, withRouter} from 'react-router-dom'
import {documentTypes, beneficiaryTypeOptions, relationTypeOptions, corporateSteps, corporateStatuses} from '@bdswiss/common-enums'
import Grid from '@mui/material/Grid'
import {checkFileMimeType} from '../../common/utils/validations'
import {safeParseJSON, scrollElementIntoView, getDatesDifference} from '../../common/utils'
import messages from '../../assets/messages'
import {graphql} from 'react-apollo'
import {CREATE_OWN_DOCUMENT_MUTATION, SIGN_UPLOAD_URL_MUTATION, ADD_CORPORATE_STEP} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY} from '../../graphql/queries'
import ButtonsToolbar from './ButtonsToobar'
import CorporateStepper from './CorporateStepper'
import {Button, List, ListItem, ListItemText, Typography} from '@mui/material'
import SelectDocument from '../Settings/Documents/SelectDocument'
import Images from '../Common/Images'
import {maxFileSize} from '../../common/utils/variables'
import {isMobile, putFile} from '../../common/utils/browser'
import Promise from 'bluebird'
import {FullScreenDialog} from '../Common/Dialog'
import classNames from 'classnames'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined'
import PageSubTitle from '../Common/PageSubTitle'
import CheckIcon from '@mui/icons-material/Check'
import NotificationBar from '../Common/NotificationBar'
import MakeSureSection from '../Settings/Documents/MakeSureSection'
import {documentHelperText} from '../../common/utils/uioptions'
import moment from 'moment'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  error:{
    color: theme.palette.red.color,
  },
  div: {
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  formControl: {
    marginBottom: 30
  },
  grid: {
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    }
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  editButton: {
    '&:hover': {backgroundColor:'transparent'}
  },
  displayFlex: {
    display: 'flex'
  },
  iconRight: {
    marginRight: 10
  },
  add: {
    marginTop: 15
  },
  checkIcon: {
    color: theme.palette.primary.main
  },
  listItem: {
    borderBottom: '1px solid #e0e0e0',
  },
  caption: {
    marginBottom: 15
  },
  addButton: {
    paddingLeft: 12
  },
  h3: {
    marginTop: 0,
    marginBottom: 0
  },
  uploadIcon: {
    height: 30,
    paddingRight: 5
  },
  subtitle:{
    marginBottom: 0
  },
  notificationBox: {
    marginTop: 20,
    position: 'relative'
  },
  ownersDocs: {
    marginTop: 30
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
})

class DocumentsStep extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const activeStep = corporateSteps.documents.rank
    this.state = {
      form: {},
      errors : {},
      loading: false,
      status: '',
      formSubmitted: false,
      uploadedFile: {},
      errorFiles: {},
      owners: {},
      ownersDocuments: {},
      uploadedOwnersFile: {},
      showPopup: false,
      selectedOwner: {},
      showOther: {company: []},
      uploadedOtherFile: {},
      initialForm: {},
      formChanged: {},
      otherDocuments: {},
      activeStep,
      nextStep: find(corporateSteps, (step) => step.rank === activeStep + 1),
      prevStep: find(corporateSteps, (step) => step.rank === activeStep - 1),
      optionalDocs: [],
    }
  }

  componentDidMount() {
    this.getStepQuestions()
  }

  getOptionalDocuments(baseObject, category) {
    return map(filter(documentTypes, (dt) =>
      dt.category === category
      && !!dt.passedYearsCheck
      && getDatesDifference(
        moment(moment().format(dt.passedYearsCheckDateField.dateFormat), dt.passedYearsCheckDateField.dateFormat),
        moment(moment(get(baseObject, dt.passedYearsCheckDateField.value), dt.passedYearsCheckDateField.dateFormat)
          .add(dt.passedYearsCheck, 'years'), dt.passedYearsCheckDateField.dateFormat)).millisecondsSum >= 0
    ), 'key')
  }

  getStepQuestions() {
    const {viewer} = this.props
    const {activeStep} = this.state
    const clientDocuments = get(viewer, 'documents') || []
    const owners = safeParseJSON(get(viewer, 'corporateDetails.beneficiariesDirectors')) || {}
    const generalInformation = safeParseJSON(get(viewer, 'corporateDetails.general')) || {}
    const corporateDocuments = map(filter(documentTypes, {category : 'corporate', corporateStep: activeStep}), 'key')
    const form = {}
    const ownersDocuments = {}
    let otherDocuments = {}
    const showOther ={}
    filter(clientDocuments, (clientDoc, key) => {
      const isCorporate = includes(corporateDocuments, clientDoc.type)
      const documentType = find(documentTypes, (documentType) => documentType.key === clientDoc.type)
      if (isCorporate && !clientDoc.owner) {
        form[clientDoc.type] = clientDoc || ''
      } else if (clientDoc.owner) {
        if (clientDoc.type === documentTypes.otherCorporate.key) {
          const documents = otherDocuments[clientDoc.owner] || []
          documents.push(clientDoc)
          otherDocuments = {
            ...otherDocuments,
            [clientDoc.owner]: documents
          }
        } else {
          form[`${documentType.key}-${clientDoc.owner}`] = clientDoc || ''
          ownersDocuments[`${documentType.category}-${clientDoc.owner}`] = (documentType.doubleSided) ? documentType.groupName : clientDoc.type
        }
      }
      return isCorporate
    })
    filter(corporateDocuments, (clientDoc, key) => {
      const corporateDocument = find(clientDocuments, (doc) =>  doc.type === clientDoc)
      if (!corporateDocument) {
        form[clientDoc] = ''
      }
      return corporateDocument
    })
    filter(owners, (owner, key) => {
      const isIndividual = owner.beneficiaryType === beneficiaryTypeOptions.individual.value
      if (isIndividual) {
        if (!find(keys(form), (formKey) => {
          const splitFormKey = split(formKey, '-')
          return splitFormKey[1] === `${owner.docName}`
        })) {
          form[`POI-${owner.docName}`] = ''
          form[`POR-${owner.docName}`] = ''
        }
      } else {
        showOther[owner.docName] = []
        if (size(filter(keys(form), (formKey) => {
          const splitFormKey = split(formKey, '-')
          return splitFormKey[1] === owner.docName
        })) < size(corporateDocuments))
          form[`${owner.docName}`] = ''
      }
      return isIndividual
    })
    const optionalDocs = [...this.getOptionalDocuments(generalInformation, 'corporate')]
    this.setState({form, owners, ownersDocuments, showOther:{company: [], ...showOther}, otherDocuments,
      initialForm: form, formSubmitted: !find(keys(form), (keyForm) => isEmpty(form[keyForm])), optionalDocs})
  }


  handleChange (name, value) {
    const {initialForm} = this.state
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: isEmpty(initialForm) || (value !== initialForm[name]),
      },
      status: ''
    }))
  }

  scrollUp(errors) {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : `drop_zone-${findKey(errors)}`, 250))
  }

  async onUploadDocuments() {
    const {viewer: {id}} = this.props
    const {uploadedFile, uploadedOwnersFile, uploadedOtherFile} = this.state
    const keys = Object.keys(uploadedFile)
    const ownerKeys = Object.keys(uploadedOwnersFile)
    const otherKeys = Object.keys(uploadedOtherFile)
    const files = map(Object.assign({}, this.state.uploadedFile, this.state.uploadedOwnersFile, this.state.uploadedOtherFile),
      (value, key) => ({[key]: value}))
    return await Promise.map(files, async (file, key) => {
      const fileKey = Object.keys(file)[0]
      if (file[fileKey] instanceof File) {
        const fileKey = Object.keys(file)[0]
        const formKey = includes(keys, fileKey) ? fileKey : ''
        const ownerKey = includes(ownerKeys, fileKey) ? split(fileKey, '-') : []
        const otherKey = includes(otherKeys, fileKey) ? split(fileKey, '-') : []
        const keySelected = formKey || ownerKey[0] || otherKey[0]
        const internalTag = `${id}-${keySelected}-${Date.now()}`
        await this.uploadDocument(keySelected, file[fileKey], internalTag, file[fileKey].name,
          (ownerKey.length > 1 || otherKey.length > 1) ? (ownerKey.length > 1 ? ownerKey[1] : otherKey[2]) : null, file[fileKey].type).then((res) => {
        }).then(() => true)
      }
    })
  }

  async uploadDocument(docType, file, internalTag, fileDescription, owner, fileType) {

    if (!file) return Promise.resolve()
    const {signUploadUrl, createOwnDocument, viewer: {id}} = this.props

    return signUploadUrl({variables: {clientId: id}}).then((res) => {
      const {key, signedUrl} = res.data.signedDetails
      if (size(fileDescription) > 20) fileDescription = `${fileDescription.substring(0, 17)}...`
      return putFile(file, signedUrl).then(() =>
        createOwnDocument({variables: {type: docType, key, internalTag, fileDescription, owner, fileType}}).then(() => true)
      )
    })
  }

  validate() {
    const {form, owners, optionalDocs, activeStep, errorFiles} = this.state
    const errors = {}
    const keys = Object.keys(form)
    const corporateDocuments = difference(map(filter(documentTypes, {category : 'corporate', corporateStep: activeStep}), 'key'), optionalDocs)
    const poiPorCategories = map(filter(documentTypes, (type) => includes(['POI', 'POR'], type.category)), 'key')

    for (const field of keys) {
      if (form[field] === 'single' || form[field] === 'doubleSided') {
        const splitField = split(field, '-')
        const categoryFields = map(filter(documentTypes, (type) => type.category === splitField[0]), 'key')
        const filesSize = size(filter(keys, (value) => {
          const splitValue = split(value, '-')
          return includes(categoryFields, splitValue[0]) && splitValue[1] === splitField[1] && form[value]
        }))
        if ((form[field] === 'single' && !filesSize) || (form[field] === 'doubleSided' && filesSize < 2)) {
          errors[field] = true
          errors[splitField[1]] = true
        } else if (errors[splitField[1]]) {
          errors[field] = false
        }
      }
      else if (find(owners, (owner) => owner.beneficiaryType === beneficiaryTypeOptions.company.value && owner.docName === field)) {
        if (size(filter(keys, (formKey) => {
          const splitFormKey = split(formKey, '-')
          return includes(corporateDocuments, splitFormKey[0]) && splitFormKey[1] === field && !!form[formKey]
        })) >= size(corporateDocuments)) {
          errors[field] = false
        } else {
          errors[field] = true
        }
      }
      else if (find(owners, (owner) => owner.beneficiaryType === beneficiaryTypeOptions.individual.value
        && !includes(poiPorCategories, split(field, '-')[0])
        && `${owner.docName}` === split(field, '-')[1])) {
        errors[field] = !form[field]
        errors[split(field, '-')[1]] = !form[field]
      }
      else if (includes(optionalDocs, split(field, '-')[0]) && !form[field]) {
        errors[field] = !!form[field]
      }
      else {
        errors[field] = (!!errorFiles[field] || (isEmpty(form[field]) && !(form[field] instanceof File) && form[field] !== undefined)) && !includes(field, documentTypes.otherCorporate.key)
      }
    }
    return errors
  }

  async handleSubmit() {
    const {viewer, history} = this.props
    const {activeStep, errorFiles, nextStep} = this.state
    let errors = {}
    const corporateStatus = get(viewer, 'corporateStatus')

    errors = this.validate()

    const hasErrors = some(errors)
    const hasErrorFiles = !isEmpty(errorFiles) && some(values(errorFiles), (value) => value !== false)

    if (hasErrors || hasErrorFiles) {
      this.setState({errors},this.scrollUp(hasErrors ? errors : errorFiles))
      return
    }

    this.setState({loading: true, status: ''})
    await this.onUploadDocuments().then(() => {
      if (get(viewer, 'corporateSignupStep') === activeStep) {
        this.props.addCorporateStep({variables: {step: activeStep + 1}}).then(() =>
          history.push({pathname: `/corporate/${nextStep.key}`, state: {step: activeStep + 1, prevPath: corporateStatus && get(this.props, 'location.state.prevPath')}}))
      }
      else {
        history.push({pathname: `/corporate/${nextStep.key}`, state: {prevPath: corporateStatus && get(this.props, 'location.state.prevPath')}})
      }
      this.setState({loading: false, status: 'success', formSubmitted: true})
    }).catch(() => {
      this.setState({loading: false, status: 'failure', uploadedFile: '', secUploadedFile: ''})
    })
  }

  setOuterState (name, key ,value, displayTypes) {
    const {form, uploadedOwnersFile} = this.state

    const selectedDocType = find(displayTypes, {key: value})
    const splitField = split(key, '-')
    const categoryFields = map(filter(documentTypes, (type) => type.category === splitField[0]), 'key')
    const keysForm = filter(keys(form), (keyValue) => {
      const splitValue = split(keyValue, '-')
      return includes(categoryFields, splitValue[0]) && splitValue[1] === splitField[1] && uploadedOwnersFile[keyValue]
    })

    this.setState(state => ({
      [name]: {
        ...state[name],
        [key]: value
      },
      form: {
        ...state.form,
        [key]: get(selectedDocType, 'doubleSided') ? 'doubleSided' : 'single'
      },
    }))
    if (!isEmpty(keysForm)) {
      map(keysForm, (keyForm) => {
        this.setState(state => ({
          form: {
            ...state.form,
            [keyForm]: undefined
          },
          uploadedOwnersFile: {
            ...state.uploadedOwnersFile,
            [keyForm]: undefined
          },
        }))
      })
    }
  }


  handleFileUpload(prop, e, questionKey, files) {
    const {form, initialForm} = this.state
    const filesToUpload =  get(e, 'target.files') ? e.target.files : files
    const updatedProp = filesToUpload[0]
    this.setState(prevState => ({
      [prop]: {
        ...prevState[prop],
        [questionKey]: updatedProp,
      }
    }))
    const {errorFiles} = this.state
    map(filesToUpload,(file) => {
      try {
        checkFileMimeType(file).then( res => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [questionKey]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              },
              form: {
                ...prevState.form,
                [questionKey]: undefined
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[questionKey])
            this.setState({errorFiles:errors}, this.handleChange(questionKey, filesToUpload[0]))
          }
        })

      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
    if (isEmpty(filesToUpload) && (form[questionKey] || form[questionKey] === undefined) && !initialForm[questionKey]) {
      this.setState(prevState => ({form: {...prevState.form, [questionKey]: ''},
        errors: {...prevState.errors, [questionKey]: false}, errorFiles: {...prevState.errorFiles, [questionKey]: false}
      }))
    }
  }

  setOuterStateOnly (name, key ,value) {
    !isNil(value) ? this.setState(state => ({
      [name]: {
        ...state[name],
        [key]: value
      }
    })) : this.setState({[name]: key})
  }

  renderCompanyFilesSection() {
    const {classes, viewer} = this.props
    const {errors, uploadedFile, errorFiles, form, selectedOwner, uploadedOwnersFile, showOther, activeStep, otherDocuments,
      showPopup, initialForm, optionalDocs} = this.state
    const {locale, themePreference} = this.context

    const selected = !isEmpty(selectedOwner)
    const uploadedFiles = (selected) ? uploadedOwnersFile : uploadedFile
    const showOtherName = (selected) ? selectedOwner.docName : 'company'
    const disabled = !!get(viewer, 'corporateStatus') && !get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false)
    const mobileFullScreen = showPopup && !isEmpty(selectedOwner)

    return <Grid container spacing={3}>
      {selected && <Grid item xs={12}>
        <Typography variant="h3" className={classes.h3}>
          {selectedOwner.companyName}
        </Typography>
      </Grid>}
      {map(filter(documentTypes, (doc) => doc.category === 'corporate' && doc.corporateStep === activeStep), (document) => {
        const documentKey = (selected) ? `${document.key}-${selectedOwner.docName}` : document.key

        return <Grid item xs={12} key={documentKey} className={classes.div}>
          <Typography variant="h3">
            {document.localization.t(locale)}
          </Typography>
          {(document.alternativeComment || document.comment) && <Typography variant="caption" className={classNames(classes.caption, classes.div)}>
            {optionalDocs.includes(document.key) && document.alternativeComment ? document.alternativeComment.localization.t(locale) : document.comment.localization.t(locale)}
          </Typography>}
          <Grid container>
            <Grid item xs={12} sm={9}>
              <SelectDocument
                uploadedFile={uploadedFiles[documentKey]}
                onChange={(e, files) => this.handleFileUpload((selected) ? 'uploadedOwnersFile' : 'uploadedFile', e, documentKey, files)}
                key={documentKey}
                type={'upload'}
                status={(uploadedFiles[documentKey] && (includes(keys(errorFiles), documentKey) ? 'error': 'success')) || (errors[documentKey] && 'error')}
                errors={!isEmpty(errorFiles) && errorFiles[documentKey]}
                fileKey={documentKey}
                category={`document-${themePreference}`}
                src={Images['upload-icon.png']}
                classesNames={classes}
                corporate={true}
                showFile={!uploadedFiles[documentKey] && (get(form[documentKey], 'url') ? form[documentKey] : initialForm[documentKey])}
                disabled={disabled}
                setOuterState={(name, key ,value) => this.setOuterStateOnly(name, key ,value)}
                initialForm={initialForm}
              />
            </Grid>
          </Grid>
        </Grid>
      })}
      {!isEmpty(otherDocuments[showOtherName]) && <Grid item xs={12}>
        <Typography variant="body1" className={classes.grid}>
          <Trans {...messages.otherDocuments} />
        </Typography>
      </Grid>}
      {map(otherDocuments[showOtherName], (doc, key) => <Grid item xs={12} className={classes.add} key={key}>
        {this.renderOtherDocument(showOtherName, key)}
      </Grid>
      )}
      <Button className={classNames(classes.addButton, classes.editButton, classes.grid)} size="medium" color="primary"
        onClick={() => {
          showOther[showOtherName].push(showOther[showOtherName].length+1)
          this.setState(state => ({...state, showOther: {...state.showOther, [showOtherName]: showOther[showOtherName]}}))
        }}
        disabled={disabled}>
        <AddCircleOutlineOutlined />
        <Trans {...messages.addNewDocument} />
      </Button>
      {map(showOther[showOtherName], (num) => <Grid item xs={12} className={classes.add} key={`otherCorporate-${num}-${showOtherName}`}>
        {this.renderOtherDocument(`otherCorporate-${num}-${showOtherName}`)}
      </Grid>
      )}
      {mobileFullScreen && <Grid item xs={12} className={classes.ownersDocs}>
        <Button variant="contained" color="primary" size="large"
          onClick={() => this.afterPopupClose(selectedOwner.docName)} fullWidth={isMobile()}>
          <Trans {...messages.submit} />
        </Button>
      </Grid>}
    </Grid>
  }

  renderOtherDocument(documentKey, index) {
    const {uploadedOtherFile, errorFiles, errors, form, otherDocuments, initialForm} = this.state
    const {themePreference} = this.context
    const {classes, viewer} = this.props
    const disabled = (!!get(viewer, 'corporateStatus') && !get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false))
      || (documentKey && !isNil(index) && (otherDocuments[documentKey][index]))

    return <Grid container>
      <Grid item xs={12} sm={9}>
        <SelectDocument
          uploadedFile={uploadedOtherFile[documentKey] || (documentKey && !isNil(index) && otherDocuments[documentKey][index])}
          onChange={(e, files) => this.handleFileUpload('uploadedOtherFile', e, documentKey, files)}
          key={documentKey}
          type={'upload'}
          status={(uploadedOtherFile[documentKey] && (includes(keys(errorFiles), documentKey) ? 'error': 'success')) || (errors[documentKey] && 'error')}
          errors={!isEmpty(errorFiles) && errorFiles[documentKey]}
          fileKey={documentKey}
          category={`document-${themePreference}`}
          src={Images['upload-icon.png']}
          classesNames={classes}
          corporate={true}
          showFile={!uploadedOtherFile[documentKey] && ((get(form[documentKey], 'url') ? form[documentKey] : initialForm[documentKey]) || (otherDocuments[documentKey] && otherDocuments[documentKey][index]))}
          disabled={disabled}
          initialForm={initialForm}
          setOuterState={(name, key ,value) => this.setOuterStateOnly(name, key ,value)}
        />
      </Grid>
    </Grid>
  }

  afterPopupClose(check) {
    const errors = this.validate()
    const errorsRelatedCheck = filter(keys(errors), (error) => includes(error, check))
    const submittedCheck = every(errorsRelatedCheck, (keyError) => !errors[keyError])
    const submittedKey = `showUploaded${check}`
    this.setState({showPopup: false, selectedOwner: {}, [submittedKey]: submittedCheck})
  }

  renderOwnerFilesSection() {
    const {classes, viewer, t} = this.props
    const {selectedOwner, ownersDocuments, uploadedOwnersFile, errorFiles, form, showPopup, initialForm} = this.state
    const {locale, themePreference} = this.context

    const availableTypes = filter(documentTypes, 'beneficiaryDirector')
    const doubleSidedTypes = groupBy(pickBy(availableTypes, 'doubleSided'), 'groupName')
    const normalisedDoubleSidedTypes = keyBy(map(doubleSidedTypes, (t) => ({
      key: head(t).groupName,
      label: messages[head(t).groupName],
      doubleSided: true,
      category: head(t).category
    })), 'key')
    const displayTypes = map(merge({}, pickBy(availableTypes, (t) => !t.doubleSided), normalisedDoubleSidedTypes))
    const categories = keys(groupBy(displayTypes, 'category'))
    const ownerName = `${selectedOwner.firstName} ${selectedOwner.lastName}`
    const disabled = !!get(viewer, 'corporateStatus') && !get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false)
    const mobileFullScreen = showPopup && !isEmpty(selectedOwner)

    return <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.h3}>
          {ownerName}
        </Typography>
      </Grid>

      {map(categories, (category, index) => {
        let selectedDocType = find(displayTypes, {key: ownersDocuments[`${category}-${selectedOwner.docName}`]})
        const frontKey = selectedDocType && ((selectedDocType.doubleSided) ? `${selectedDocType.key}Front-${selectedOwner.docName}`: `${selectedDocType.key}-${selectedOwner.docName}`)
        const backKey = selectedDocType && ((selectedDocType.doubleSided) ? `${selectedDocType.key}Back-${selectedOwner.docName}`: '')
        const categoryDisplayTypes = filter(displayTypes, (displayType) => displayType.category === category)
        const showSubmitBtn = mobileFullScreen && index === size(categories) - 1

        return <Grid container spacing={3} key={category}>
          <Grid item xs={12}>
            <PageSubTitle>
              <Trans {...messages[`${lowerCase(category)}Title`]} />
            </PageSubTitle>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans {...messages.documentType} />
            </Typography>
          </Grid>
          <Grid item sm={7} xs={12}>
            <List>
              {map(categoryDisplayTypes, (type) => {
                if (!selectedDocType) {
                  selectedDocType = head(categoryDisplayTypes)
                  selectedOwner.docName && this.setOuterState('ownersDocuments', `${category}-${selectedOwner.docName}`, selectedDocType.key, displayTypes)
                }
                return <ListItem
                  className={classes.listItem}
                  key={type.key}
                  button
                  onClick={(e) => this.setOuterState('ownersDocuments', `${category}-${selectedOwner.docName}`, type.key, displayTypes)}
                  disabled={disabled}
                >
                  <ListItemText primary={has(type, 'label.i18nKey') ? t(type.label.i18nKey, type.label.defaults) : type.localization.t(locale)} />
                  {get(selectedDocType, 'key') === type.key && <CheckIcon className={classes.checkIcon} />}
                </ListItem>}
              )}
            </List>
            <Grid container className={classes.uploadDiv}>
              <Grid item xs={12} sm={9} className={classes.ownersDocs}>
                {ownersDocuments[`${category}-${selectedOwner.docName}`] && <Grid item xs={12} className={classes.formControl}>
                  <SelectDocument
                    uploadedFile={uploadedOwnersFile[frontKey]}
                    onChange={(e, files) => this.handleFileUpload('uploadedOwnersFile', e, frontKey, files)}
                    showLabel= {selectedDocType.doubleSided}
                    status={uploadedOwnersFile[frontKey] && (includes(keys(errorFiles), frontKey) ? 'error': 'success')}
                    errors={!isEmpty(errorFiles) && errorFiles[frontKey]}
                    fileKey={frontKey}
                    category={`document-${themePreference}`}
                    src={Images['upload-icon.png']}
                    classesNames={classes}
                    corporate={true}
                    showFile={!uploadedOwnersFile[frontKey] && form[frontKey] !=='single' && form[frontKey] !=='doubleSided' && (get(form[frontKey], 'url') ? form[frontKey] : initialForm[frontKey])}
                    disabled={disabled}
                    initialForm={initialForm}
                    setOuterState={(name, key ,value) => this.setOuterStateOnly(name, key ,value)}
                    showSubmitButton={!isMobile() && showSubmitBtn && !selectedDocType.doubleSided ? () => this.afterPopupClose(selectedOwner.docName) : false}
                  />
                </Grid>}
                {ownersDocuments[`${category}-${selectedOwner.docName}`] && selectedDocType.doubleSided && <Grid item xs={12} className={classes.formControl}>
                  <SelectDocument
                    uploadedFile={uploadedOwnersFile[backKey]}
                    onChange={(e, files) => this.handleFileUpload('uploadedOwnersFile', e, backKey,  files)}
                    showLabel= {selectedDocType.doubleSided}
                    status={uploadedOwnersFile[backKey] && (includes(keys(errorFiles), backKey) ? 'error': 'success')}
                    errors={!isEmpty(errorFiles) && errorFiles[backKey]}
                    fileKey={`${backKey}2`}
                    category={`document-${themePreference}`}
                    src={Images['upload-icon.png']}
                    classesNames={classes}
                    corporate={true}
                    showFile={!uploadedOwnersFile[backKey] && form[frontKey] !=='single' && form[frontKey] !=='doubleSided' && (get(form[backKey], 'url') ? form[backKey] : initialForm[backKey])}
                    disabled={disabled}
                    initialForm={initialForm}
                    setOuterState={(name, key ,value) => this.setOuterStateOnly(name, key ,value)}
                    showSubmitButton={!isMobile() && showSubmitBtn ? () => this.afterPopupClose(selectedOwner.docName) : ''}
                  />
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={5}>
            <MakeSureSection documentHelperText={documentHelperText} category={lowerCase(category)} selectedDocTypeKey={selectedDocType.key} displayTypes={keyBy(categoryDisplayTypes, 'key')} />
          </Grid>
        </Grid>
      })}
      {isMobile() && mobileFullScreen && <Grid item xs={12}>
        <Button variant="contained" color="primary" size="large"
          onClick={() => this.afterPopupClose(selectedOwner.docName)} fullWidth={isMobile()}>
          <Trans {...messages.submit} />
        </Button>
      </Grid>}
    </Grid>
  }

  renderFilesSection() {
    const {selectedOwner} = this.state
    const company = selectedOwner.beneficiaryType === beneficiaryTypeOptions.company.value
    return (company) ? this.renderCompanyFilesSection() : this.renderOwnerFilesSection()
  }

  render() {
    const {classes, t, viewer, history} = this.props
    const {locale} = this.context
    const {errors, owners, disabled, loading, status, formSubmitted, showPopup, formChanged, selectedOwner, activeStep, prevStep, nextStep}= this.state
    const isFormChanged = includes(formChanged, true)
    const missingCorporateDocuments = get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false)
    const mobileFullScreen = isMobile() && showPopup && !isEmpty(selectedOwner)
    const corporateStatus = get(viewer, 'corporateStatus')
    const showText = corporateStatus && !missingCorporateDocuments
    const showNotification = !!corporateStatus && !get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false)

    return <React.Fragment>
      {!corporateStatus && <CorporateStepper activeStep={activeStep - 1} viewerStep={get(viewer, 'corporateSignupStep')}/>}
      {showNotification && <NotificationBar
        status="info"
        classes={{notificationBox: classes.notificationBox}}
        title={<Trans {...messages.corporateCannotChangeDetails}
          components={[<Link className={classes.infoCTA} to={'/support'}>support</Link>]}
          values={{corporateStatus: corporateStatus && corporateStatuses[corporateStatus].localization.t(locale)}}
        />}
      />}
      {mobileFullScreen ? <FullScreenDialog
        fullScreen
        open={showPopup}
        onClose={() => this.setState({showPopup: false, selectedOwner: {}})}
        desktopOnly
      >
        {this.renderFilesSection()}
      </FullScreenDialog>
        : <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.subtitle}>
                <Trans {...messages.beneficialOwners} />
              </Typography>
            </Grid>
            {owners.length > 0 &&
            <Grid item xs={12} sm={9} className={classes.grid}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} className={classes.tableRow}>
                  <Grid container>
                    <Grid item xs={4} className={classes.caption}>
                      <Typography variant='caption'><Trans {...messages.fullName} /></Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.caption}>
                      <Typography variant='caption'><Trans {...messages.relationType} /></Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.caption}>
                      <Typography variant='caption'><Trans {...messages.documents} /></Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {map(owners, (value, key) => {
                  const company = value.beneficiaryType === beneficiaryTypeOptions.company.value
                  const name = (company) ? value.companyName.replace(/ /g, '') : `${value.firstName} ${value.lastName}`
                  const nameDoc = value.docName
                  return <Grid item xs={12} key={key} className={classes.tableRow}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid item xs={4} className={classes.displayFlex}>
                        {errors[nameDoc] ? <ReportProblemOutlinedIcon className={classNames(classes.error, classes.iconRight)}/> : ''}
                        <Typography variant='body1'>
                          {name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='body1'>
                          {map(value.relationType, (type, key) => `${relationTypeOptions[type].localization.t(locale)}${(key+1 < size(value.relationType)) ? ', ' : ''}`)}
                          {(company) && ` (${t(messages.company.i18nKey, messages.company.defaults)})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Button className={classNames(classes.editButton, errors[nameDoc] && classes.error)} variant='text' color='primary'
                          onClick={() => this.setState(state=> ({showPopup: true, selectedOwner: value, errors: {...state.errors, [nameDoc]: false}}))}
                        >
                          {!showText && <img alt={'upload'} src={Images[`upload-icon${errors[nameDoc] ? '-error' : ''}.png`]} className={classes.uploadIcon} />}
                          <Trans {...messages[(showText) ? 'show' : (this.state[`showUploaded${nameDoc}`]) ? 'uploaded' : 'upload']} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>})}
              </Grid>
            </Grid>}
            <Grid item xs={12}>
              <Typography variant="h3"  className={classes.subtitle}>
                <Trans {...messages.companyDocuments} />
              </Typography>
            </Grid>
            {isEmpty(selectedOwner) && this.renderCompanyFilesSection()}
            <Grid item xs={12}>
              <ButtonsToolbar
                handleSubmit={() => (isFormChanged || !formSubmitted) ? this.handleSubmit() : history.push({pathname: `/corporate/${nextStep.key}`,
                  state: {prevPath: corporateStatus && get(this.props, 'location.state.prevPath')}})}
                disabled={disabled || loading || false}
                hideProgressBar={disabled}
                status={status}
                label={t(messages[(isFormChanged || !formSubmitted) ? 'submitAndProceed' : 'next'].i18nKey, messages[(isFormChanged || !formSubmitted) ? 'submitAndProceed' : 'next'].defaults)}
                onBack={() => history.push(`/corporate/${prevStep.key}`)}
                hideBack={corporateStatus}
                hideButton={corporateStatus && !missingCorporateDocuments}
              />
            </Grid>
          </Grid>
          <FullScreenDialog
            fullScreen
            open={showPopup}
            onClose={() => this.setState({showPopup: false, selectedOwner: {}})}
          >
            {this.renderFilesSection()}
          </FullScreenDialog>
        </React.Fragment>
      }
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer'),
      documents: get(data, 'viewer.documents', [])
    })
  }),
  graphql(ADD_CORPORATE_STEP, {
    name: 'addCorporateStep',
  }),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(CREATE_OWN_DOCUMENT_MUTATION, {
    name: 'createOwnDocument',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    }
  }),
)(DocumentsStep)
