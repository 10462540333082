import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../../Common/Loading'
import {appropTestQuestionsV2,apTestSteps, scoringResultsV2, corporateSteps} from '@bdswiss/common-enums'
import {find, mapValues, omit, isEmpty, get, keys, head, filter, pickBy, concat, omitBy, includes, flowRight as compose,
  values, cloneDeep, every, upperCase} from 'lodash'
import Grid from '@mui/material/Grid'
import APTestHeader from './APTestHeader'
import APTestQuestions from './APTestQuestions'
import APTestButtonsToolbar from './APTestButtonsToolbar'
import PageTitle from '../../../Common/PageTitle'
import messages from '../../../../assets/messages'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY, CONFIG_QUERY, CLIENT_HAS_OPEN_POSITIONS_QUERY} from '../../../../graphql/queries'
import {ADD_CORPORATE_STEP, APPROP_TEST_MUTATION} from '../../../../graphql/mutations'
import AppContext from '../../../Common/contexts/AppContext'
import {isMobile, scrollElementIntoView, setCookie} from '../../../../common/utils'
import Button from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles'
import {withRouter, Link} from 'react-router-dom'
import {fireAppropriatenessTestDoneEvent} from '../../../../common/utils/tagsManager'
import {APTestPopupMessage} from './APTestPopupMessage'
import  config from '../../../../config'
import {getMissingVerifications} from '../../../../common/utils/general'
import {europeCountries} from '../../../../common/utils/uioptions'
import {logEventCustomParams} from '../../../../common/utils/firebaseEvents'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  orangeButton: {
    backgroundColor: '#ef9756',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef7e2b',
    }
  },
  highlight:{
    fontWeight:400
  },
  link: {
    color: theme.palette.primary.main,
  },
  wrap: {
    '& .MuiGrid-spacing-xs-3': {
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(2),
      }
    }
  }
})

class AppropriatenessTest extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      progress: 33,
      form: {answers: this.initAppropriatenessTestForm(), questions:{}},
      formErrors : this.initAppropriatenessTestForm(),
      formState: 'pending',
      notificationSeen:true,
      formChanged: {},
    }
  }

  componentDidMount() {
    const {companyObject, clientId} = this.context
    logEventCustomParams('apStarted_web', {
      entity: get(companyObject, 'label'),
      userId: clientId,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {appropTest, viewer} = nextProps
    const {form, formErrors} = prevState
    logEventCustomParams('apTestUpdateIntent', {source: 'manual', userId: get(viewer, 'id')})
    const approp = appropTest && cloneDeep(appropTest)
    const hasAppropTest = !isEmpty(approp) && {answers:approp.answers}
    const answerKeys = keys(omitBy(hasAppropTest.answers,isEmpty))
    const emptyForm = every(values(form.answers), (answer) => answer === '')
    prevState.form = emptyForm && hasAppropTest ? hasAppropTest : form // Acts like this.setState({form: ... whatever ...})
    prevState.formErrors = (hasAppropTest && omit(formErrors, answerKeys)) || formErrors// Acts like this.setState({formErrors: ... whatever ...})
    return prevState // But here we need to return what we want to be returned to the state
  }

  initAppropriatenessTestForm() {
    return mapValues(appropTestQuestionsV2, (q) => '')
  }

  getAppropTestQuestions(answers, activeStep) {
    const {companyObject} = this.context
    return pickBy(appropTestQuestionsV2, (q) => {
      const {step: {stepPosition}} = q
      const checkCompanies = filter(q.company, (o) => o.value === companyObject.key)
      return (stepPosition === activeStep && !isEmpty(checkCompanies) &&  q.relevant(answers,'key'))
    })
  }

  handleNextStep() {
    this.scrollUp()
    const {hasEmptyQuestion} = this.validateStep(this.state.activeStep)
    if (isEmpty(hasEmptyQuestion)) {
      this.setState({activeStep: this.state.activeStep + 1, progress: this.state.progress + 34})
    } else {
      this.setState({formState: 'validation'},this.scrollUp(this.state.formErrors))
    }
  }

  handlePreviousStep() {
    this.setState({activeStep: this.state.activeStep - 1, progress: this.state.progress - 34})
  }

  handleOptionInput(radioGroup) {
    const {name: questionKey, value: answerKey} = radioGroup
    const {form, formErrors} = this.state
    const {appropTest} = this.props
    form.answers[questionKey] = answerKey
    const newFormErrors = omit(formErrors, [questionKey])
    this.setState(state => ({
      form: form,
      formErrors: newFormErrors,
      formChanged:{
        ...state.formChanged,
        [questionKey]: isEmpty(appropTest) || (answerKey !== appropTest['answers'][questionKey]),
      }
    }))
  }

  validateStep(activeStep) {
    const {form, formErrors} = this.state
    const activeStepObject = find(apTestSteps, ['stepPosition', activeStep])
    const activeStepQuestions = filter(appropTestQuestionsV2, ['step', activeStepObject])
    const activeQuestions =  this.getAppropTestQuestions(form.answers, activeStep)
    let newFormErrors=[]

    const hasEmptyQuestion = filter(activeQuestions, (question) => {
      if (isEmpty(form.answers[question.key])) {
        formErrors[question.key] = ''
        return question
      } else {
        newFormErrors = concat(newFormErrors,question.key)
      }
    })

    filter(activeStepQuestions, (question) => {
      if (!activeQuestions[question.key]) {
        newFormErrors = concat(newFormErrors,question.key)
        form.answers = omit(form.answers, question.key)
      }
    })

    const updatedFormErrors = omit(formErrors, newFormErrors)
    this.setState({form: form, formErrors: updatedFormErrors})
    return {form, hasEmptyQuestion, updatedFormErrors}
  }

  validateForm() {
    this.setState({formState: 'validation'})
    const {hasEmptyQuestion, updatedFormErrors} = this.validateStep(this.state.activeStep)

    if (isEmpty(hasEmptyQuestion) && isEmpty(updatedFormErrors)) {
      return true
    } else {
      this.scrollUp(this.state.formErrors)
      return false
    }
  }

  scrollUp(errors) {
    const scrollTo = errors ? Object.keys(errors)[0] : gridScroll
    this.setState(() => scrollElementIntoView(scrollTo, 250))
  }

  showNotification(scoreResult) {
    const {classes, viewer} = this.props
    const {featuresConfig} = config
    const {notificationSeen} = this.state
    const isEuCountry = includes(europeCountries, upperCase(get(viewer, 'address.country')))

    return  notificationSeen && (get(featuresConfig.approptest,'stricted') || isEuCountry ||
      (!get(featuresConfig.approptest,'stricted') &&
      [scoringResultsV2.productNotSuitableLeverage100.value,
        scoringResultsV2.cannotTrade.value].includes(scoreResult)))
  }

  submitForm(formChanged) {
    const {corporate, viewer, activeStep, history} = this.props
    const nextStep =  corporate && find(corporateSteps, (step) => step.rank === activeStep + 1)
    const addCorporateStep = corporate && !get(viewer, 'corporateStatus') && get(viewer, 'corporateSignupStep') === corporateSteps.appropiatenessTest.rank
    if (!formChanged && corporate) {
      history.push(`/corporate/${nextStep.key}`)
    }
    else {
      if (this.validateForm()) {
        let answers = this.state.form.answers
        this.setState({formState: 'submit',status: '', submitLoading: true})

        if (answers.__typename) {
          answers = omit(this.state.form.answers, ['__typename'])
        }
        this.props.updateOwnAppropTest({variables: {answers: answers}})
          .then((res) => {
            if (addCorporateStep) {
              if (get(viewer, 'corporateSignupStep') === activeStep) {
                this.props.addCorporateStep({variables: {step: activeStep + 1}}).then(() =>
                  history.push({pathname: `/corporate/${nextStep.key}`, state: {step: activeStep + 1}}))
              } else {
                history.push(`/corporate/${nextStep.key}`)
              }
            } else {
              this.setState({notificationSeen: true})
              setCookie('approp_test_completed_step1', true, 1)
              setCookie('approp_test_notification', true, 1)
              fireAppropriatenessTestDoneEvent(this.props.clientId)
              logEventCustomParams('apTestUpdateCompleted', {userId: get(this.props, 'clientId')})
              this.context.showNotification({
                type: 'document-upload',
                status: 'success',
                content: this.props.t(messages.appropriatenessTestSubmitted.i18nKey, messages.appropriatenessTestSubmitted.defaults),
                buttonMessage: this.props.t(messages.continue.i18nKey, messages.continue.defaults),
                onClose: () => this.showNotification(res.data.createOwnAppropTestV2.scoreResult) ?
                  this.setState({submitLoading: false, score:res.data.createOwnAppropTestV2.forexPoints,
                    scoreResult:res.data.createOwnAppropTestV2.scoreResult})
                  : this.hideAppropTestMessage(),
              })
            }
          })
          .then(() => {
            setCookie('approp_test_completed', true, 1)
            this.props.register && this.props.history.push('/register/step3')
          })
          .catch((err) => {
            this.setState({
              submitLoading: false, status: 'failure',
            })
            logEventCustomParams('apTestUpdateError', {
              reason: get(err, 'graphQLErrors[0].message') || err.message,
              userId: get(this.props, 'clientId'),
            })
            this.context.showNotification({
              type: 'document-upload',
              status: 'failure',
              content: get(err, 'graphQLErrors[0].message') || err.message,
              buttonMessage: <Trans {...messages.cancel} />
            })
          })
      }
    }
  }

  handleClose(history, state) {
    const {t, viewer} = this.props
    const {companyObject} = this.context
    logEventCustomParams('apClosed_web', {
      entity: get(companyObject, 'label'),
      userId: get(viewer, 'id'),
    })
    return (state && state.force) ? this.context.logout(t(messages.appropriatenessTest.i18nKey, messages.appropriatenessTest.defaults))
      : history.push('/settings/profile')
  }

  hideAppropTestMessage(retakeTest) {
    this.setState({notificationSeen: false})
    setCookie('approp_test_completed', true, 1)
    setCookie('approp_test_notification', false, -1)

    const {accountVerification, cysecRegulator} = config
    const {companyObject, clientId} = this.context
    const {viewer, appropTest, globalQuestionnaire} = this.props
    const {scoreResult} = this.state
    const verificationFields = accountVerification
    const missingVerifications  = getMissingVerifications(viewer, appropTest, globalQuestionnaire, '', verificationFields)
    const missingEP = includes(missingVerifications, 'economicProfile')
    const missingPersonalDetails = includes(missingVerifications, 'profile')
    const params = {
      entity: companyObject.brandLabel,
      score: scoreResult,
      option: retakeTest ? 'Trading Academy' : 'Proceed',
      userId: clientId,
    }
    retakeTest && logEventCustomParams('apTestFailed_web', params)
    if (!retakeTest) {
      let pathRedirect
      if (cysecRegulator && missingPersonalDetails) {
        pathRedirect = '/settings/profile/personal-profile/details'
      } else if (cysecRegulator && missingEP) {
        pathRedirect = '/settings/profile/economic-profile'
      } else {
        pathRedirect = '/accounts'
      }
      this.props.history.push(pathRedirect)
    }
  }

  getScoreMessage(scoreResult, hasOpenPositions) {
    const {classes, configVariables:{failedAppropTestTrainingPeriod}} = this.props
    const {locale, companyObject} = this.context
    const {emailDomain} = companyObject
    const {featuresConfig, cysecRegulator, appropTestMessageCysec, weblinks} = config
    const failedMessage = hasOpenPositions ? messages.level1AppropTestMessageOpenPosition :
      cysecRegulator ?
        <Trans {...messages[appropTestMessageCysec]} values={{emailDomain}}
          components={[
            <p key='p1'/>,
            <a href={get(featuresConfig, 'tools.tradingAcademy', '').replace('{lang}', locale)} target="_blank" rel="noopener noreferrer" key='p0' className={classes.link}>website</a>,
            <a href={`mailto:support@${emailDomain}`} target="_blank" rel="noopener noreferrer" key='p0' className={classes.link}>{`support@${emailDomain}`}</a>
          ]}
        />
        : <Trans {...messages.level1AppropTestMessage} values={{failedAppropTestTrainingPeriod}}
          components={[<p key='p1'/>,<a href={get(featuresConfig, 'tools.tradingAcademy', '').replace('{lang}', locale)} target="_blank" rel="noopener noreferrer" key='p0' className={classes.link}>Academy</a>]} />

    let popupMessage,popupTitle, popupAction, retakeTest = false
    if (get(featuresConfig,'approptest') && get(featuresConfig.approptest,'stricted')) {
      popupMessage = scoreResult === scoringResultsV2.advancedUnderstanding.value
        ? <Trans {...messages.level2AppropTestMessage} values={{company: companyObject.brandLabel}}  />
        : scoreResult === scoringResultsV2.productNotSuitableLeverage100.value
          ? <Trans {...messages.level15AppropTestMessage} values={{company: companyObject.brandLabel}}
            components={[<p key='p1'/>,<p key='p2'/>,<a href={get(featuresConfig, 'tools.tradingAcademy', '').replace('{lang}', locale)} target="_blank" rel="noopener noreferrer" key='p0' className={classes.link}>Academy</a>,
              <p key='p3'/>,<span className={classes.highlight}>training</span>]} />
          : failedMessage
      popupTitle = <Trans {...messages.importantNotice} />

      popupAction = scoreResult === scoringResultsV2.cannotTrade.value ?
        <Trans {...messages.continue} /> : <Trans {...messages.proceedButton} />
    } else {
      popupMessage = scoreResult === scoringResultsV2.advancedUnderstanding.value ?
        <Trans {...messages.level2AppropTestMessage} values={{company: companyObject.brandLabel}} />
        : scoreResult === scoringResultsV2.productNotSuitableLeverage100.value ?
          <Trans {...messages[`level15AppropTestMessageMau${companyObject.brand}`]}
            values={{leverage: scoringResultsV2.productNotSuitableLeverage100.leverage[companyObject.key]}}
            components={[<p key='p1'/>,<p key='p2'/>,<a href={get(featuresConfig, 'tools.tradingAcademy', get(weblinks, 'tradingAcademy', '')).replace('{lang}', locale)} target="_blank" rel="noopener noreferrer" key='p0' className={classes.link}>Academy</a>,<p key='p3'/>,
              <span className={classes.highlight}>training</span>, <Link to="/support" className={classes.link}> </Link>,<p key='p4'/>]}  />
          : scoreResult===scoringResultsV2.cannotTrade.value &&
          <Trans {...messages[`level1AppropTestMessageMau${companyObject.brand}`]} values={{company: companyObject.brandLabel}}
            components={[<a href={get(featuresConfig, 'tools.tradingAcademy', get(weblinks, 'tradingAcademy', '')).replace('{lang}', locale)} target="_blank" rel="noopener noreferrer" key='p0' className={classes.link}>Academy</a>,<p key='p1'/>,<p key='p2'/>,
              <Link to="/support" className={classes.link}> </Link>]}  />

      popupTitle = scoreResult === scoringResultsV2.cannotTrade.value ?
        <Trans {...messages.importantNotice} /> : <Trans {...messages.almostDone}/>

      popupAction = scoreResult === scoringResultsV2.cannotTrade.value ?
        <Trans {...messages.retakeTest} /> : <Trans {...messages.proceedButton} />

      retakeTest = scoreResult===scoringResultsV2.cannotTrade.value
    }
    return {popupMessage, popupTitle, popupAction, retakeTest}
  }

  render() {
    const {t, loading, appropTest, history, classes, register, location:{state}, configLoading, viewer, hasOpenPositions, corporate, disabledCorporate, corporateBack} = this.props
    const {themePreference, locale} = this.context
    const {submitLoading, status, score, scoreResult, formChanged}= this.state
    const {euRegulation: {showApTestMessage}} = config
    const redirectedFromMigration =
      !!showApTestMessage && get(viewer, 'fromCompany') && get(viewer, 'verificationActions.forcedVerification') === 'appropriatenessTest'

    if (loading || !appropTest || configLoading) return <Loading />

    const isFormChanged = includes(formChanged, true)
    const {activeStep, form, formState, formErrors, progress} = this.state
    const appropTestQuestions = this.getAppropTestQuestions(form.answers, activeStep)
    const appTestComponent =
      <Grid container id={gridScroll} className={classes.wrap}>
        {!register && !corporate &&
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PageTitle
              themePreference={themePreference}
              onBack={() => this.handleClose(history, state)}
              title={t(messages.appropriatenessTest.i18nKey, messages.appropriatenessTest.defaults)}
              withoutPadding={isMobile()}
            />
          </Grid>
        </Grid>
        }
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <APTestHeader
              activeStep={activeStep}
              progress={progress}
              forced={state && state.force}
              afterDepositPage ={state && state.afterDeposit}
              redirectedFromMigration={redirectedFromMigration}
              appropTest={appropTest}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <APTestQuestions
              locale={locale}
              activeStep={activeStep}
              form={form}
              formState={formState}
              errors={formErrors}
              questions={appropTestQuestions}
              onChange={(e) => this.handleOptionInput(e)}
              disabledCorporate={disabledCorporate}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <APTestButtonsToolbar
              activeStep={activeStep}
              nextStep={() => this.handleNextStep()}
              previousStep={() => this.handlePreviousStep()}
              submitForm={() => this.submitForm(isFormChanged)}
              submitButtonFeatures ={{submitLoading , status}}
              disableSubmit={!isFormChanged}
              corporate={corporate}
              disabledCorporate={disabledCorporate}
              corporateStatus={get(viewer, 'corporateStatus')}
              corporateBack={corporateBack}
            />
          </Grid>
        </Grid>
        {register &&
          <Grid container spacing={3}>
            <Grid item>
              <Button className={classes.orangeButton} variant="contained" size="large" onClick={() => this.props.history.push('/register/step3')}>
                <Trans {...messages.skipToStep} values={{step:3}}/>
              </Button>
            </Grid>
          </Grid>
        }
      </Grid>
    const notificationContent = this.getScoreMessage(scoreResult, hasOpenPositions)
    return (
      <div>
        {!register && !corporate && <React.Fragment>
          {score && <APTestPopupMessage
            open={this.showNotification(scoreResult)}
            title={notificationContent.popupTitle}
            agreeText={notificationContent.popupAction}
            children={notificationContent.popupMessage}
            onAgree={() => this.hideAppropTestMessage(notificationContent.retakeTest)}
          />}
          {appTestComponent}
        </React.Fragment>
        }
        {(register || corporate) && appTestComponent}
      </div>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const clientId = get(data, 'viewer.id')
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        viewer,
        clientId,
      }
    },
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const appropTests = get(data, 'viewer.appropTests')
      const appropTest = (appropTests && head(appropTests)) || appropTests
      const globalQuestionnaire = get(data, 'viewer.globalQuestionnaire')
      return {
        error,
        loading,
        globalQuestionnaire,
        appropTest,
      }
    }
  }),
  graphql(CONFIG_QUERY, {
    props: ({data: {error, loading: configLoading}, data}) => ({
      error,
      configLoading,
      configVariables: get(data, 'config'),
    })
  }),
  graphql(APPROP_TEST_MUTATION, {
    name: 'updateOwnAppropTest',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(CLIENT_HAS_OPEN_POSITIONS_QUERY, {
    options: (props) => ({variables: {accountId: props.match.params.accountId}}),
    props: ({data: {error: errorPositions, loading: loadingPositions}, data}) => ({
      errorPositions,
      loadingPositions,
      hasOpenPositions: get(data, 'viewer.hasOpenPositions'),
    }),
  }),
  graphql(ADD_CORPORATE_STEP, {
    name: 'addCorporateStep',
  }),
)(AppropriatenessTest)
