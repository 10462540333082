import React, {Component, useContext} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {
  assign, find, flowRight as compose, forEach, get, isEmpty, isNil, map, sortBy, unionBy,
  filter, pick, defaults, intersectionBy, isEqual, debounce, some, every,
} from 'lodash'
import AppContext from '../../Common/contexts/AppContext'
import {PROMO_MATERIALS_QUERY} from '../../../graphql/queries'
import {REQUEST_MARKETING_MATERIALS_MUTATION} from '../../../graphql/mutations'
import {languages, promoMaterialDimensions, promoMaterialTopics} from '@bdswiss/common-enums'
import {Box, Grid, Grow, Link, SvgIcon, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../assets/messages'
import Filters from './Filters'
import {ReactComponent as SadFace} from '../../../assets/images/sadFace.svg'
import {withApollo} from 'react-apollo'
import PropTypes from 'prop-types'
import {getCurrentTheme, getPlatform, isDarkTheme, isIos} from '../../../common/utils'
import StackGrid from 'react-stack-grid'
import Asset from '../Asset'
import {withBreakpoint} from '../../Common'
import {AlertDialog} from '../../Common/Dialog'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import PromoMaterialsContext from './PromoMaterialsContext'
import NotificationBar from '../../Common/NotificationBar'
import config from '../../../config'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
    },
  },
  noAssets: {
    display: 'flex',
    justifyContent:'center',
    paddingTop: '40px',
  },
  noAssetsTypography: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  noAssetsSvg: {
    verticalAlign: 'middle',
  },
  materialLinksWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& .MuiLink-root': {
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  marketingMaterialFormWrap: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-root': {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    '& .MuiFormLabel-root': {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      position: 'relative',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
    '& .MuiButton-root': {
      marginLeft: `${theme.spacing(1)} !important`,
    },
  },
  dialogTitle: {
    textAlign: 'left',
  },
  dialogContent: {
    paddingBottom: 0,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  dialogAgreeButtonContainer: {
    marginLeft: theme.spacing(1),
  },
})

const filtersCustomStyle = (theme, isDark) => ({
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    color: isDark ? theme.palette.grey[400]: theme.palette.secondary.light,
    '&:hover' : {
      color: isDark ? theme.palette.grey[400]: theme.palette.secondary.light,
    },
  }),
})

const defaultColsWidthFromScreenSize = {xs: '100%', sm: '50%', md: '33%', lg: '33%', xl: '25%'}
const marketingMaterialFormInitialValues = {details: ''}

class PromoMaterialLayout extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      size: '',
      campaign: '',
      language: '',
      topic: '',
      filters: [],
      allAssets: [],
      promoMaterialsFilter: null,
      offset: 0,
      isFetching: false,
      marketingMaterialModalOpen: false,
      marketingMaterialFormState: {
        values: marketingMaterialFormInitialValues,
        errors: {},
        status: null,
      },
    }

    this.gridRef = React.createRef(null)
    this.handleWheel = this.handleWheel.bind(this)
    this.debouncedFetchMoreRecords = debounce(this.fetchMoreRecords, 50)
    this.handleResize = this.handleResize.bind(this)
    this.debouncedRedrawGrid = debounce(this.redrawGrid, 200)
    this.toggleMarketingMaterialModal = this.toggleMarketingMaterialModal.bind(this)
    this.handleMarketingMaterialFormFieldChanged = this.handleMarketingMaterialFormFieldChanged.bind(this)
    this.handleMarketingMaterialFormSend = this.handleMarketingMaterialFormSend.bind(this)
  }

  // Set the initial offset value
  static getDerivedStateFromProps(nextProps, prevState) {
    const {offset} = prevState
    const {promoMaterials} = nextProps
    return offset !== promoMaterials?.length ? {offset: promoMaterials?.length} : null
  }

  componentDidMount() {
    const {client: apolloClient, type} = this.props
    /*
        Because wheel event is not supported in iOS Safari devices we use scroll event instead.
        We could also use only the scroll event for both types (mobile, desktop) but in
        desktop mode the scenario where there is no scroll bar is not covered by the scroll event.
        The resize event is used to redraw the masonry grid layout in case the window was resized.
    */
    if (isIos() && get(getPlatform(), 'browser') === 'Safari') window.addEventListener('scroll', this.handleWheel)
    else window.addEventListener('wheel', this.handleWheel)
    window.addEventListener('resize', this.handleResize)
    apolloClient.query({
      query: PROMO_MATERIALS_QUERY,
      variables: {type, uniqueAssetName: undefined},
      fetchPolicy: 'no-cache'
    })
      .then(({data:{promoMaterials}}) => this.setState({allAssets: promoMaterials}))
      .catch((_) => this.setState({allAssets: []}))
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleWheel)
    window.removeEventListener('scroll', this.handleWheel)
    window.removeEventListener('resize', this.handleResize)
  }

  /*
     When refetching the updated promoMaterials we need to re-render the masonry grid
     Because filters depend each other, we have to update the values of each filter and also refetch the promo materials
     FYI: In order to remove a graphql variable we assign its' value as undefined
     We need to handle the size filter because it is a combination of width and height fields
  */
  componentDidUpdate(prevProps, prevState) {
    const {refetchPromoMaterials, promoMaterials, type} = this.props
    const {promoMaterials:prevPromoMaterials} = prevProps
    const {filters, allAssets} = this.state
    const {allAssets: prevAllAssets} = prevState
    if (!isEqual(prevPromoMaterials, promoMaterials) && this.state.isFetching) this.setState({isFetching: false})
    if (!isEqual(prevAllAssets, allAssets)) return this.setFilterData(allAssets)
    forEach(filters, ({id, options}) => {
      if (get(prevState, id) !== get(this.state, id)) {
        const keyStateVal = get(this.state, id)
        const multipleValues = id === 'size' ? defaults(pick(find(options, (o) => o.value === keyStateVal), ['width', 'height']), {width: undefined, height: undefined}) : null
        const apolloFilter = id === 'size' ? {...multipleValues}: keyStateVal ? {[id]: keyStateVal} : {[id]: undefined}
        const promoMaterialsFilter = assign({}, ...map(filter(filters, ({id}) => !isEmpty(this.state[id])), ({key, id, options}) => id === 'size' ?
          pick(find(options, (o) => o.value === this.state[id]), ['width', 'height']) : ({[key]: this.state[id]})))
        return refetchPromoMaterials({type, uniqueAssetName: !isEmpty(promoMaterialsFilter) ? undefined : true, ...apolloFilter})
          .then((_) => this.setState({offset: promoMaterials?.length, promoMaterialsFilter}, this.setFilterData(filter(allAssets, promoMaterialsFilter))))
          .catch(() => {})
      }
    })
  }

  handleResize() {
    this.debouncedRedrawGrid()
  }

  redrawGrid() {
    this.gridRef && this.gridRef.updateLayout && this.gridRef.updateLayout()
  }

  handleWheel() {
    const {promoMaterials, promoMaterialsCount} = this.props
    const {scrollTop, scrollHeight, clientHeight} = document.documentElement
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10
    const hasMore = promoMaterials?.length < promoMaterialsCount
    if (!this.state.isFetching && hasMore && isAtBottom) this.debouncedFetchMoreRecords()
  }

  onChangeHandle(key, e) {
    this.setState({[key]: get(e, 'value') || get(e, 'target.value') || ''})
  }

  onClearFiltersHandle(filters) {
    const {refetchPromoMaterials, type} = this.props
    this.setState(assign({}, {promoMaterialsFilter: null}, ...map(filters, ({id}) => ({[id]: ''}))),
      () => refetchPromoMaterials({type, uniqueAssetName: true, ...assign({}, ...map(filters, ({id}) => ({[id]: undefined})))}))
  }

  // Modifies the options of the current select element
  constructOption(promoMaterials, options, key, emptySelectionLabelKey) {
    const {t} = this.props
    const emptySelection = {key: 'clear', value: '', label: t(messages[emptySelectionLabelKey].i18nKey, messages[emptySelectionLabelKey].defaults)}
    const modifiedOptions = key === 'size'
      ? sortBy(unionBy(map(promoMaterials, ({width, height, [key]:keyVal}) => {
        const size = find(options, (o) => o.value === keyVal)
        const sizeOrder = size?.order ?? Number.MAX_SAFE_INTEGER
        const sizeKeyValue = `size_${width}_${height}`
        const sizeCustomLabel = `${width}x${height}`
        const sizeLabel = size?.showLabel ? size?.label ?? sizeCustomLabel : sizeCustomLabel
        return ({order: sizeOrder, width, height, key: sizeKeyValue, value: sizeKeyValue, label: sizeLabel})}), 'label'), ['order', 'label'])
      : unionBy(sortBy(map(promoMaterials, ({[key]:keyVal}) => !isNil(options) ?
        ({...find(options, (o) => o.value === keyVal)}) : ({key: keyVal, value: keyVal, label: keyVal})), 'label'), 'label')
    modifiedOptions.unshift(emptySelection)
    return modifiedOptions
  }

  /*
     id: is used for the graphql variables
     key: is used for the asset properties
  */
  setFilterData(promoMaterials) {
    const {size, campaign, language, topic} = this.state
    const {theme, filterIds} = this.props
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    const customStyles = filtersCustomStyle(theme, isDark)
    const filters = [{id: 'size', key: 'size', title: messages.promoMaterialsSize.i18nKey, value: size, onChangeHandle: (key, e) => this.onChangeHandle(key, e),
      options: this.constructOption(promoMaterials, promoMaterialDimensions, 'size', messages.promoMaterialsSize.i18nKey), customStyles},
    {id: 'campaign', key: 'campaignName', title: messages.promoMaterialsCampaigns.i18nKey, value: campaign, onChangeHandle: (key, e) => this.onChangeHandle(key, e),
      options: this.constructOption(promoMaterials, null, 'campaignName', messages.promoMaterialsCampaigns.i18nKey), customStyles},
    {id: 'language', key: 'language', title: messages.promoMaterialsLanguages.i18nKey, value: language, onChangeHandle: (key, e) => this.onChangeHandle(key, e),
      options: this.constructOption(promoMaterials, languages, 'language', messages.promoMaterialsLanguages.i18nKey), customStyles},
    {id: 'topic', key: 'topic', title: messages.promoMaterialsTopics.i18nKey, value: topic, onChangeHandle: (key, e) => this.onChangeHandle(key, e),
      options: this.constructOption(promoMaterials, promoMaterialTopics, 'topic', messages.promoMaterialsTopics.i18nKey), customStyles}]
    this.setState({...this.state, filters: intersectionBy(filters, filterIds, 'id')})
  }

  onClickFetchSiblings(promoMaterial) {
    const {client: apolloClient} = this.props
    const {assetName, type} = promoMaterial
    return apolloClient.query({query: PROMO_MATERIALS_QUERY, variables: {type, assetName}, fetchPolicy: 'no-cache'})
  }

  fetchMoreRecords() {
    const {fetchMore, promoMaterialsCount, type} = this.props
    const {offset, promoMaterialsFilter} = this.state
    if (fetchMore && promoMaterialsCount > offset) {
      this.setState({isFetching: true})
      fetchMore({offset, type, uniqueAssetName: !isEmpty(promoMaterialsFilter) ? undefined : true})
        .then(({data: promoMaterials}) => this.setState({offset: promoMaterials?.length, isFetching: false}),
          () => this.gridRef && this.gridRef.updateLayout && this.gridRef.updateLayout())
        .catch((_) => {this.setState({isFetching: false})})
    }
  }

  getCustomWidth() {
    const {breakpoint, colsWidth = defaultColsWidthFromScreenSize} = this.props
    return get(colsWidth, breakpoint, 150)
  }

  toggleMarketingMaterialModal() {
    this.setState((prevState) => ({
      ...prevState,
      marketingMaterialModalOpen: !prevState.marketingMaterialModalOpen,
    }))
  }

  handleMarketingMaterialFormFieldChanged(e) {
    const {name, value} = e.target
    this.setState((prevState) => ({
      ...prevState,
      marketingMaterialFormState: {
        ...prevState.marketingMaterialFormState,
        values: {
          ...prevState.marketingMaterialFormState.values,
          [name]: value,
        },
        errors: {
          ...prevState.marketingMaterialFormState.errors,
          [name]: !value,
        },
      },
    }))
  }

  handleMarketingMaterialFormSend() {
    this.props.setMarketingMaterialFormStatus('loading')
    this.setState((prevState) => ({
      marketingMaterialFormState: {
        ...prevState.marketingMaterialFormState,
        status: 'loading',
      },
    }))

    this.props.client.mutate({
      mutation: REQUEST_MARKETING_MATERIALS_MUTATION,
      variables: this.state.marketingMaterialFormState.values,
    }).then(() => {
      this.props.setMarketingMaterialFormStatus('success')
      this.setState((prevState) => ({
        marketingMaterialModalOpen: false,
        marketingMaterialFormState: {
          ...prevState.marketingMaterialFormState,
          status: 'success',
          values: marketingMaterialFormInitialValues,
          errors: {},
        },
      }))
    }).catch(() => {
      this.props.setMarketingMaterialFormStatus('error')
      this.setState((prevState) => ({
        marketingMaterialFormState: {
          ...prevState.marketingMaterialFormState,
          status: 'error',
        },
      }))
    })
  }

  render() {
    const {classes, theme, promoMaterials, accounts, innerFilterIds, t, type} = this.props
    const {filters, marketingMaterialModalOpen, marketingMaterialFormState} = this.state
    const customWidth = this.getCustomWidth()
    const disabledSubmit = some(marketingMaterialFormState.errors) || every(marketingMaterialFormState.values, (value) => !value)

    return (
      <Grid container className={classes.container}>
        <Box className={classes.materialLinksWrap}>
          <Link onClick={this.toggleMarketingMaterialModal}><Trans {...messages.requestMarketingMaterial}/></Link>
          {get(config, 'weblinks.brandingGuidelinesDocLink', false) && <Link href={get(config, 'weblinks.brandingGuidelinesDocLink')} target="_blank"><Trans {...messages.brandingGuidelines}/></Link>}
          {!!marketingMaterialModalOpen && (
            <AlertDialog
              disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
              agreeText={t(messages.sendRequest.i18nKey, messages.sendRequest.defaults)}
              customClasses={{
                dialogTitle: classes.dialogTitle,
                dialogContent: classes.dialogContent,
                dialogActions: classes.dialogActions,
                agreeButtonContainer: classes.dialogAgreeButtonContainer,
              }}
              buttonDisabled={disabledSubmit}
              hideProgressBar={disabledSubmit}
              buttonLoading={marketingMaterialFormState.status === 'loading'}
              open={marketingMaterialModalOpen}
              onClose={this.toggleMarketingMaterialModal}
              onAgree={this.handleMarketingMaterialFormSend}
              title={t(messages.requestMarketingMaterial.i18nKey, messages.requestMarketingMaterial.defaults)}
              closeBtn
            >
              <Box className={classes.marketingMaterialFormWrap}>
                <Typography variant='body2'><Trans {...messages.marketingMaterialFormInformativeText}/></Typography>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="details" shrink={true}><Trans {...messages.details}/></InputLabel>
                  <TextField id="details" name="details"
                    variant="outlined"
                    placeholder={t(messages.marketingMaterialFormDetailsPlaceholder.i18nKey, messages.marketingMaterialFormDetailsPlaceholder.defaults)}
                    value={this.state.marketingMaterialFormState.values?.details}
                    error={this.state.marketingMaterialFormState.errors?.details}
                    onChange={this.handleMarketingMaterialFormFieldChanged}
                    InputLabelProps={{shrink: false}}
                    multiline
                    required
                    rows={4}
                  />
                </FormControl>
                {marketingMaterialFormState.status === 'error' && <Grow in={true}><Box pt={2}><NotificationBar
                  status={marketingMaterialFormState.status}
                  title={<Trans {...messages.somethingWrongError} />}
                /></Box></Grow>}
              </Box>
            </AlertDialog>
          )}
        </Box>
        <Filters filters={filters} onClearFiltersHandle={() => this.onClearFiltersHandle(filters)}/>
        {!isEmpty(promoMaterials) ?
          <StackGrid
            key={type}
            gutterWidth={24}
            gutterHeight={24}
            columnWidth={customWidth}
            rtl={theme?.direction === 'rtl'}
            gridRef={gridRef => this.gridRef = gridRef}
            monitorImagesLoaded={true}
            onLayout={() => Object.values(get(this.gridRef, ['grid', 'items'], {}))
              .forEach((item) => item.node.style.opacity = 1)
            }
          >
            {map(promoMaterials, (p, i) => (
              <Asset
                key={`${p.id}_${p.assetName}_${p.type}_${i}`}
                promoMaterial={p}
                onClickFetchSiblings={() => this.onClickFetchSiblings(p)}
                innerFilterIds={innerFilterIds}
                accounts={accounts}
                gridRef={this.gridRef}
              />)
            )}
          </StackGrid>
          : <Grid className={classes.noAssets} xs={12} sm={12} item>
            <Typography className={classes.noAssetsTypography} variant='body2'>
              <Trans {...messages.promoMaterialsNoAssets}/>{' '}
              <SvgIcon className={classes.noAssetsSvg}>
                <SadFace />
              </SvgIcon>
            </Typography>
          </Grid>}
      </Grid>
    )
  }
}

PromoMaterialLayout.propTypes = {
  promoMaterials: PropTypes.array,
  accounts: PropTypes.array,
  fetchMore: PropTypes.func,
  promoMaterialsCount: PropTypes.number,
  type: PropTypes.string.isRequired,
  filterIds: PropTypes.array.isRequired,
  innerFilterIds: PropTypes.array.isRequired,
  refetchPromoMaterials: PropTypes.func,
  colsWidth: PropTypes.object,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withApollo,
  withBreakpoint,
  (WrappedComponent) => (props) => {
    const {marketingMaterialFormStatus, setMarketingMaterialFormStatus} = useContext(PromoMaterialsContext)
    return <WrappedComponent {...props} marketingMaterialFormStatus={marketingMaterialFormStatus} setMarketingMaterialFormStatus={setMarketingMaterialFormStatus} />
  },
)(PromoMaterialLayout)
