import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Login from './components/Auth/Login'
import ForgotPassword from './components/Auth/ForgotPassword'
import NotFound from './components/NotFound'
import Migrate from './components/Migrate'
import App from './components/App'
import EntitySelection from './components/Auth/EntitySelection'
import RegisterStep1 from './components/Auth/Registration/RegisterStep1'
import RegisterStep2 from './components/Auth/Registration/RegisterStep2'
import RegisterStep3 from './components/Auth/Registration/RegisterStep3'
import ChooseDocument from './components/Settings/Documents/ChooseDocument'
import Language from './components/Auth/Language'
import InitiateDeposit from './components/Payments/Deposits/InitiateDeposit'
import WebinarDialog from './components/Common/WebinarDialog'
import LoginVerification from './components/Auth/LoginVerification'
import RegisterWidget from './components/Auth/Registration/RegisterWidget'
import Maintenance from './components/Auth/Maintenance'
import InformativePage from './components/Auth/InformativePage'
import RegisterWidgetLP from './components/Auth/Registration/RegisterWidgetLP'
import RegisterWidgetLP2 from './components/Auth/Registration/RegisterWidgetLP2'

const TopLevelRoutes = (props) => {
  const querystring = props?.location?.search
  const redirectToDefaultSignupUrl = props?.location?.pathname?.replace('/register-v2', '/register')
  return (<Switch>
    <Route exact path={'/migrate'} component={Migrate} />
    <Route exact path="/login" component={Login} />
    <Redirect exact from={'/register/ib'} to={{pathname: '/register/partner', search: querystring}} />
    <Redirect exact from={'/register/affiliate'} to={{pathname: '/register/partner', search: querystring}} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/choose-entity" component={EntitySelection} />
    <Route exact path="/register/step2" component={RegisterStep2} />
    <Route exact path="/register/step3" component={RegisterStep3} />
    <Route exact path="/register/step3/:category" component={ChooseDocument} />
    <Route exact path="/register/:type?" component={RegisterStep1} />
    <Route exact path="/register-widget/:type?" component={RegisterWidget} />
    <Route exact path="/register-widget-lp/" component={RegisterWidgetLP} />
    <Route exact path="/register-widget-lp2/:type?" component={RegisterWidgetLP2} />
    <Route exact path="/register/:type?/:subType?/:contractor" component={RegisterStep1} />
    <Route exact path="/reset-password/:resetPasswordToken" component={ForgotPassword} />
    <Route exact path={'/language'} component={Language} />
    <Route exact path={'/initiate-deposit'} component={InitiateDeposit} />
    <Redirect exact from={'/register-v2/:type?/:subType?'} to={{pathname: redirectToDefaultSignupUrl, search: querystring}} />
    <Redirect exact from={'/reset-password'} to={'/forgot-password'} />
    <Redirect exact from={'/register-step1'} to={'/register'} />
    <Redirect exact from={'/register-demo'} to={'/register/demo'} />
    <Route exact path={'/webinar'} component={WebinarDialog} />
    <Route exact path="/login-verification" component={LoginVerification} />
    <Route exact path="/maintenance" component={Maintenance} />
    <Route exact path="/informativePage" component={InformativePage} />
    <Route path="/" component={App} />
    <Route component={NotFound} />
  </Switch>)
}

export default TopLevelRoutes
