import React, {Component} from 'react'
import {get, find, first, findIndex, values, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import SettingRoutes from './routes'
import messages from '../../assets/messages'
import {PROFILE_SETTINGS_QUERY} from '../../graphql/queries'
import CustomNotificationAlert from '../Common/CustomNotificationAlert'
import {isMobile} from '../../common/utils'

const styles = theme => ({})

const settingsTabs = [
  {key: 'profile', title: messages.profile, relativePath: '/profile'},
  {key: 'privacy', title: messages.privacy, relativePath: '/privacy'},
  {key: 'changePassword', title: messages.changePasswordLowerCase, relativePath: '/change-password'},
]

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: findIndex(settingsTabs, first(settingsTabs)),
    }
  }

  handleChange(value) {
    const {history, match} = this.props
    const currentTab = settingsTabs[value]
    history.push(`${match.url}${currentTab.relativePath}`)
    this.setState({value: value})
  }

  render() {
    const {t, location, match, history, classes} = this.props
    const subRoute = values(settingsTabs).filter(settings => `${match.url}${settings.relativePath}`===location.pathname).length > 0

    const currentTab =
      find(settingsTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1) || first(settingsTabs)

    const currentTabIndex = findIndex(settingsTabs, currentTab)

    return (
      <React.Fragment>
        {subRoute && <PageTitle
          hideArrow={!isMobile()}
          withoutPadding={isMobile()}
          title={t(messages.profileAndSettings.i18nKey, messages.profileAndSettings.defaults)}
          onBack={() => isMobile ? history.push('/more') : history.push('/accounts')}
        >
          <Tabs
            value={currentTabIndex}
            onChange={(_, i) => this.handleChange(i)}
            variant={!isMobile() ? 'standard' : 'fullWidth'}
          >
            <Tab label={t(messages.settingsProfileTab.i18nKey, messages.settingsProfileTab.defaults)} />
            <Tab label={t(messages.privacy.i18nKey, messages.privacy.defaults)} />
            <Tab label={t(messages.changePasswordLowerCase.i18nKey, messages.changePasswordLowerCase.defaults)} />
          </Tabs>
        </PageTitle>
        }
        <PageBody removePadding={!subRoute}>
          <CustomNotificationAlert />
          <SettingRoutes />
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      documents: get(data, 'viewer.documents'),
      appropTests: get(data, 'viewer.appropTests'),
      profileChanges: get(data, 'viewer.profileChanges'),
      globalQuestionnaire: get(data, 'viewer.globalQuestionnaire'),
    })
  }),
)(Settings)
