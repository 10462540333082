import React, {Component} from 'react'
import {filter, find, first, flowRight as compose, get, isEmpty, last, map, orderBy, size, some} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import {withApollo, graphql} from 'react-apollo'
import {Button, FormControl, TextField, Typography} from '@mui/material'
import messages from '../../assets/messages'
import classNames from 'classnames'
import WarningIcon from '@mui/icons-material/Warning'
import AppContext from '../Common/contexts/AppContext'
import VerifiedIcon from '@mui/icons-material/CheckCircleOutlined'
import {AlertDialog, CustomDialog} from '../Common/Dialog'
import {validateEmail, validatePhone} from '../../common/utils/validations'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY} from '../../graphql/queries'
import {currencies, dueDiligenceStatuses, kycStatuses} from '@bdswiss/common-enums'
import {IB_SSO_LINK_MUTATION, UPGRADE_CAMPAIGN_TYPE_FORM} from '../../graphql/mutations'
import {isMobile} from '../../common/utils'
import {hasIntroducingBrokerAccount, isIntroducingBrokerAccount} from '../../common/utils/accounts'
import Loading from '../Common/Loading'
import {PartnerStatus} from './PartnerStatus'
import {ClientLinkSection} from './ClientLinkSection'
import CustomTooltip from '../Common/CustomTooltip'
import Card from '@mui/material/Card'
import HelpIcon from '@mui/icons-material/HelpOutline'

const styles = theme => ({
  displayFlex: {
    display: 'flex'
  },
  icon: {
    marginBottom: -3,
    marginLeft: 3,
    fontSize: 16,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardHover: {
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.cardBackground.color,
      cursor: 'default',
    },
  },
  inline: {
    display: 'inline-block'
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  subLink: {
    fontWeight: 400,
    paddingLeft: 5,
    cursor: 'pointer',
    display: 'inline',
  },
  statusIcon: {
    verticalAlign: 'text-top',
    fontSize: 20,
    marginRight: '4px',
  },
  doneIcon: {
    color: theme.palette.green.color
  },
  goToPortal: {
    display: 'flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
  yellow: {
    color: theme.palette.yellow.color,
  },
  button: {
    margin: 5,
    minWidth: 150
  },
  grid: {
    textAlign: 'center',
  },
  tooltipText: {
    color: '#ffffff',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: '16px',
  },
  displayPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
})

export class Overview extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    const ibAccount = hasIntroducingBrokerAccount(props.accounts) && find(props.accounts, (__typename) => isIntroducingBrokerAccount(__typename))
    const initialAccount = first(get(ibAccount, 'partnerDetails'))
    this.state = {
      open: false,
      openStatus: false,
      clickedEvent: false,
      upgradeDialog: false,
      errors: {},
      partnerStatusTooltip: false,
      campaignTooltip: false,
      details: '',
      email: '',
      phone: '',
      openSelectAccount: false,
      selectedAccount: get(initialAccount, 'ibId'),
      unpaidComm: get(initialAccount, 'unpaidComm'),
      clientCampaignUrl: get(initialAccount, 'clientCampaignUrl'),
      ibCampaignUrl: get(initialAccount, 'ibCampaignUrl'),
      campaignName: get(initialAccount, 'campaigns'),
      loading: false,
      updgradeDialogStatus: '',
      updgradeDialogLoading: false,
    }
  }

  copyLink() {
    this.setState({clickedEvent: true})
  }

  handleChange(prop,event) {
    const {errors} = this.state
    this.setState({
      [prop]: event.target.value,
      errors: {
        ...errors,
        [prop]: !event.target.value,
      }},this.checkForm)
  }

  handleSubmit = () => {
    const {viewer:{id}} = this.props
    const {details, email, phone, selectedAccount} = this.state
    const errors = {}
    if (isEmpty(details))
      errors.details = true
    if (isEmpty(email) || !validateEmail(email))
      errors.email = true
    if (isEmpty(phone) || !validatePhone(phone, true))
      errors.phone = true
    if (some(errors)) {
      this.setState({errors})
      return
    }
    this.setState({updgradeDialogLoading: true})
    this.props.upgradeCampaignTypeTicket({variables: {clientId: id, email, phone, message: details, sendRequestToPartnerManagerEmail: true, ibId: selectedAccount}})
      .then(() => {
        this.setState({updgradeDialogLoading:false, updgradeDialogStatus: '', name: '', email: '', phone: '', details: '', message: '', sendLoading: false, upgradeDialog: false,
          sendStatus: 'success'})
        this.context.showNotification({
          subTitle: <Trans {...messages.supportTicketSubmitted} />,
          subTitleCenter: true,
          type: 'document-upload',
          status: 'success',
          content: <Trans {...messages.supportTicketSubmittedContent} />,
          buttonMessage: <Trans {...messages.close} />,
          onClose: () => this.props.history.push('/partner-zone/overview'),
        })
      })
  }

  selectAccount(value) {
    const {accounts} = this.props
    const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
    const selectedAccount = find(get(ibAccount, 'partnerDetails'), {'ibId': value})
    this.setState({
      selectedAccount: get(selectedAccount, 'ibId'),
      clientCampaignUrl: get(selectedAccount, 'clientCampaignUrl'),
      ibCampaignUrl: get(selectedAccount, 'ibCampaignUrl'),
      campaignName: get(selectedAccount, 'campaigns'),
      unpaidComm: get(selectedAccount, 'unpaidComm'),
      openSelectAccount: false,
    })
  }

  toIBPortal(ibId) {
    const {createIBSingleSignOnLink, accounts} = this.props
    const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')
    createIBSingleSignOnLink({variables: {accountId: ibAccount.id, ibId}}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  render() {
    const {classes, t, viewer, accounts, loading} = this.props
    const {errors, phone, email, details, selectedAccount, clientCampaignUrl, ibCampaignUrl, unpaidComm,
      campaignName, updgradeDialogLoading, updgradeDialogStatus} = this.state
    if (loading) return <Loading />
    const dueDiligences = get(viewer, 'dueDiligences')
    const dueDiligence = last(orderBy(filter(dueDiligences, (a)=> a.status !== dueDiligenceStatuses.rejected.value)))
    const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
    const partnerStatus = (get(viewer, 'kycStatus') === kycStatuses.approved.value) && !isEmpty(dueDiligence) &&  get(ibAccount, 'approved') && get(ibAccount, 'portalAccess') ?
      <span><VerifiedIcon className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>{t(messages.approved.i18nKey)}</span> :
      <span><WarningIcon className={classNames({[classes.statusIcon]: true, [classes.yellow]: true})} /> {t(messages.pending.i18nKey)}</span>
    const masterIb = !isEmpty(ibCampaignUrl)
    const singleClientLink = size(get(ibAccount, 'partnerDetails')) === 1
    const multipleClientLink = size(get(ibAccount, 'partnerDetails')) > 1
    const showUpgrade = get(ibAccount, 'approved')
    const pendingCurrency = get(currencies[get(ibAccount, 'currency')], 'symbol')
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} className={classNames(classes.displayFlex, classes.displayPadding)}>
            <Grid container spacing={isMobile() ? 2 : 3}>
              <Grid item xs={12} md={12} lg={!masterIb && singleClientLink ? 5 : 5} className={classes.displayFlex} pb={1}>
                <ClientLinkSection t={t} classes={classes} masterIb={masterIb} singleClientLink={singleClientLink}
                  multipleClientLink={multipleClientLink} selectedAccount={selectedAccount} clientCampaignUrl={clientCampaignUrl}
                  changeAccount={()=> this.setState({openSelectAccount: true})}
                />
              </Grid>
              {!masterIb && <Grid item xs={12} lg={!masterIb && singleClientLink ? 7 : 4} className={classNames(classes.displayFlex)}>
                <Card classes={{root: classes.cardHover}}>
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item xs={!isMobile() ? 3 : 6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant='caption' className={classes.inline}><Trans {...messages.yourStatus} /></Typography>
                          <CustomTooltip
                            title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.partnerStatusTooltip.i18nKey, messages.partnerStatusTooltip.defaults)}</Typography>}
                            placement={'top'}
                          >
                            <HelpIcon className={classes.icon} onClick={() => this.setState({partnerStatusTooltip: true})}/>
                          </CustomTooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' className={classes.inline}>
                            {partnerStatus}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={!isMobile() ? 3 : 6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant='caption' className={classes.inline}><Trans {...messages.campaign} /></Typography>
                          <CustomTooltip
                            title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.campaignTooltip.i18nKey, messages.campaignTooltip.defaults)}</Typography>}
                            placement={'top'}
                          >
                            <HelpIcon className={classes.icon} onClick={() => this.setState({campaignTooltip: true})}/>
                          </CustomTooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' className={classes.inline}>{campaignName}</Typography>
                          {showUpgrade &&  <Typography variant='body1' onClick={() => this.setState({upgradeDialog: true})}
                            className={classNames(classes.link, classes.subLink)}><Trans {...messages.upgrade} />
                          </Typography>}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={!isMobile() ? 3 : 6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant='caption' className={classes.inline}><Trans {...messages.pending} /></Typography>
                          <CustomTooltip
                            title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.pendingTooltip.i18nKey, messages.pendingTooltip.defaults)}</Typography>}
                            placement={'top'}
                          >
                            <HelpIcon className={classes.icon} onClick={() => this.setState({openStatus: true})}/>
                          </CustomTooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1'>
                            {`${pendingCurrency} ${unpaidComm}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={!isMobile() ? 3 : 6} className={classes.goToPortal}>
                      <Button
                        onClick={() => this.toIBPortal(selectedAccount)}
                        variant="contained"
                        size={multipleClientLink ? 'small' : 'medium'}
                        color="primary"
                      >
                        <Trans {...messages.goToPortal} />
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>}
              {masterIb &&
                <Grid item xs={12} lg={7} className={classes.displayFlex}>
                  <PartnerStatus partnerStatus={partnerStatus} campaignName={campaignName} t={t} classes={classes}
                    showUpgrade={showUpgrade} pendingCurrency={pendingCurrency} unpaidComm={unpaidComm} selectAccount={selectedAccount}
                    accounts={accounts} toIBPortal={()=> this.toIBPortal(selectedAccount)} openShowUpgrade={() => this.setState({upgradeDialog: true})}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={this.state.upgradeDialog}
          onClose={() => this.setState({upgradeDialog: false})}
          onAgree={() => this.handleSubmit()}
          onDisagree={() => this.setState({upgradeDialog: false})}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          agreeText={t(messages.sendRequest.i18nKey, messages.sendRequest.defaults)}
          title={t(messages.updgradeCampaignTitle.i18nKey, messages.updgradeCampaignTitle.defaults)}
          buttonLoading={updgradeDialogLoading}
          buttonStatus={updgradeDialogStatus}
          errorMessage={updgradeDialogStatus === 'failure' ? t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults) : ''}
        >
          <Grid container spacing={1}>
            <Typography variant='body1' paragraph>
              <Trans {...messages.updgradeCampaignText1} />
            </Typography>
            <Typography variant='body1' paragraph>
              <Trans {...messages.updgradeCampaignText2} />
            </Typography>
            <Typography variant='body1' paragraph>
              <Trans {...messages.updgradeCampaignText3} />
            </Typography>
            <Grid item xs={6} lg={6}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <TextField
                  variant="standard"
                  required
                  label={t(messages.phone.i18nKey, messages.phone.defaults)}
                  value={phone}
                  onChange={(e)=>this.handleChange('phone',e)}
                  error={errors.phone} />
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <TextField
                  variant="standard"
                  required
                  label={t(messages.email.i18nKey, messages.email.defaults)}
                  value={email}
                  onChange={(e)=>this.handleChange('email',e)}
                  error={errors.email} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <TextField
                  variant="standard"
                  required
                  multiline
                  label={t(messages.provideMoreDetails.i18nKey, messages.provideMoreDetails.defaults)}
                  value={details}
                  onChange={(e)=>this.handleChange('details',e)}
                  error={errors.details} />
              </FormControl>
            </Grid>
          </Grid>
        </AlertDialog>
        <CustomDialog
          open={this.state.openSelectAccount}
          onClose={() => this.setState({openSelectAccount: false})}
          title={t(messages.selectAccount.i18nKey, messages.selectAccount.defaults)}
        >
          <Grid container direction={isMobile() ? 'column' : 'row'} justifyContent="center" spacing={1} alignItems="center">
            <Grid item xs={12} className={classes.grid}>
              <Typography variant="body1"><Trans {...messages.pleaseChooseAccount} /></Typography>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              {map(get(ibAccount, 'ibId', []), (ibId) => (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  key={ibId}
                  onClick={()=> this.selectAccount(ibId)}
                >
                  {ibId}
                </Button>
              ))}
            </Grid>
          </Grid>
        </CustomDialog>
      </React.Fragment>
    )
  }
}

export default compose (
  withStyles(styles, {withTheme: true}),
  withApollo,
  withNamespaces(),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      accounts: get(data, 'viewer.accounts'),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
  graphql(UPGRADE_CAMPAIGN_TYPE_FORM, {
    name: 'upgradeCampaignTypeTicket',
  }),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
)(Overview)
