import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {assign, filter, flowRight as compose, get} from 'lodash'
import {promoMaterialTypes} from '@bdswiss/common-enums'
import {ACCOUNTS_QUERY, PROMO_MATERIALS_QUERY} from '../../../graphql/queries'
import {graphql, withApollo} from 'react-apollo'
import {isIntroducingBrokerAccount} from '../../../common/utils'
import PromoMaterialLayout from './PromoMaterialLayout'

const styles = () => ({})

class PrintAds extends Component {
  render() {
    const {promoMaterials, accounts, fetchMore, promoMaterialsCount, refetchPromoMaterials} = this.props
    return (
      <PromoMaterialLayout
        promoMaterials={promoMaterials}
        accounts={accounts}
        fetchMore={fetchMore}
        promoMaterialsCount = {promoMaterialsCount}
        type={promoMaterialTypes.print_ad.key}
        filterIds={[{id: 'size'}, {id: 'campaign'}, {id: 'language'}]}
        innerFilterIds={[{id: 'language'}, {id: 'size'}]}
        refetchPromoMaterials={refetchPromoMaterials}
      />
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withApollo,
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      accounts: filter(get(data, 'viewer.accounts'), isIntroducingBrokerAccount),
    })
  }),
  graphql(PROMO_MATERIALS_QUERY, {
    name: 'fetchPromoMaterials',
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        type: promoMaterialTypes.print_ad.key,
        uniqueAssetName: true,
        limit: 5,
        offset: 0,
      }
    }),
    props: ({fetchPromoMaterials:{error, loading, promoMaterials, promoMaterialsCount, refetch, fetchMore}}) => ({
      loading,
      error,
      promoMaterials,
      promoMaterialsCount,
      refetchPromoMaterials: refetch,
      fetchMore: (variables) => fetchMore({
        variables,
        updateQuery: (prev, {variables, fetchMoreResult}) => {
          const {promoMaterials:prevPromoMaterials} = prev
          const {promoMaterials:nextPromoMaterials} = fetchMoreResult
          if (!fetchMoreResult || prevPromoMaterials?.length > variables?.offset) return prev
          return assign({}, prev, {promoMaterials: [...prevPromoMaterials, ...nextPromoMaterials]})
        }
      })
    }),
  }),
)(PrintAds)
