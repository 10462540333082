import React, {Component} from 'react'
import {map, get, isEmpty, some, omitBy, findKey, size, reject, filter, includes, debounce, forEach, keys, has,
  flowRight as compose, trim, orderBy, upperCase} from 'lodash'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import {UPDATE_ECONOMIC_PROFILE_MUTATION} from '../../../../graphql/mutations'
import {appropTestIsUsReportable, appropTestPoliticallyExposed, economicProfileQuestions, appropTestPoliticallyExposedReason,
  appropTestTinReason, countries, profileChangeFields} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import NotificationBar from '../../../Common/NotificationBar'
import AppContext from '../../../Common/contexts/AppContext'
import {Link, withRouter} from 'react-router-dom'
import {getMissingVerifications, scrollElementIntoView} from '../../../../common/utils'
import TextField from '@mui/material/TextField'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import {isMobile} from '../../../../common/utils/browser'
import classNames from 'classnames'
import {validateLength} from '../../../../common/utils/validations'
import config from '../../../../config'
import {logEventCustomParams} from '../../../../common/utils/firebaseEvents'
import CustomTooltip from '../../../Common/CustomTooltip'
import HelpOutline from '@mui/icons-material/HelpOutline'
import PepDialog from '../../../Common/PepDialog'
import {AlertDialog} from '../../../Common/Dialog'
import PageTitle from '../../../Common/PageTitle'
import PageBody from '../../../Common/PageBody'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    fontSize: '0.9rem'
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  textLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  firstItemHide: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    formControl: {
      fontSize: '0.8rem'
    }
  },
  button:{
    marginTop: 0
  },
  usButton: {
    padding: '8px 0',
    '&:hover':{
      backgroundColor: 'transparent',
    },
    '&:active':{
      backgroundColor: 'transparent',
    }
  },
  subtitle: {
    paddingBottom: 20
  },
  error: {
    color: theme.palette.error.main,
  },
  textRight: {
    textAlign: 'right'
  },
  textHelp: {
    color: theme.palette.grey[400],
    '&:hover':{
      color: theme.palette.primary.main,
    },
  },
  tooltipText:{
    color: theme.palette.secondary.light,
    fontSize:14
  },
  helpIcon: {
    marginTop: 22,
    cursor: 'pointer',
    marginLeft: -4
  },
  successCTA: {
    color:  theme.palette.notificationBar.greenTextColor,
  },
  errorCTA: {
    color:  theme.palette.notificationBar.redTextColor,
  },
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
})

export class EconomicProfile extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)

    this.state = {
      form: props.client, errors: {}, politicallyExposed: false, usCitizen: false, taxJurisdictionCountry: false,
      expectedCountryOfOriginDestinationFunds: false, loading: false,
      status: '', submitMessageError: '', countryReadOnly: false, formChanged: {}, usCitizenNotication: '', checked: [],
      showMessage: false
    }
  }

  componentWillUnmount () {
    const {companyObject} = this.context
    logEventCustomParams('epClosed_web', {
      entity: get(companyObject, 'label'),
      userId: get(this.context, 'clientId'),
    })
  }

  componentDidMount() {
    const {clientData} = this.props
    const {companyObject} = this.context
    logEventCustomParams('epStarted_web', {
      entity: get(companyObject, 'label'),
      userId: get(this.context, 'clientId'),
    })
    const {euRegulation: {showEpTestMessage}} = config
    logEventCustomParams('economicProfileUpdateIntent', {
      source: 'manual',
      userId: get(this.context, 'clientId'),
    })
    this.setState({showMessage: !!showEpTestMessage && get(clientData, 'fromCompany') && get(clientData, 'verificationActions.forcedVerification') === 'economicProfile'})
  }

  componentDidUpdate(prevProps) {
    if (this.props.client !== prevProps.client) {
      this.setState({form: this.props.client})
    }
  }

  handleClose(history, state) {
    const {t} = this.props

    return (state && state.force) ? this.context.logout(t(messages.economicProfile.i18nKey, messages.economicProfile.defaults))
      : history.push('/settings/profile')
  }

  handleChange(name, value, alert) {
    const {form} = this.state
    const tinDisabledCitizen = (name === economicProfileQuestions.usCitizen.value) && (value === appropTestIsUsReportable.yes.value)
    const tinDisabledCountry = (name === economicProfileQuestions.taxJurisdictionCountry.value) && (value === countries.us.key)
    const resetUsCitizen = (get(form, 'usCitizen') === appropTestIsUsReportable.yes.value) && (name === economicProfileQuestions.taxJurisdictionCountry.value) && (value !== countries.us.key)
    const resetCountry = (name === economicProfileQuestions.usCitizen.value) && (value === appropTestIsUsReportable.no.value)
    const pepRemoveReason = get(form, 'politicallyExposedReason') && (name === economicProfileQuestions.politicallyExposed.value) && value === appropTestIsUsReportable.no.value

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value,
        tinReason: tinDisabledCitizen ||  tinDisabledCountry ? appropTestTinReason.iHaveTin.key : (name === economicProfileQuestions.tinReason.value) ? value : form.tinReason,
        usCitizen: tinDisabledCountry ? appropTestIsUsReportable.yes.value : (name === economicProfileQuestions.usCitizen.value) ? value : (resetUsCitizen) ? '' : form.usCitizen,
        taxJurisdictionCountry: tinDisabledCitizen ? countries.us.key : (name === economicProfileQuestions.taxJurisdictionCountry.value) ? value : (resetCountry) ? '' : form.taxJurisdictionCountry,
        politicallyExposedReason: (pepRemoveReason) ?  '' : (name === 'politicallyExposedReason') ? value : form.politicallyExposedReason
      },
      errors: {
        ...state.errors,
        [name]: !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: (value !== this.props.client[name]) ? true : false,
      },
      status: '',
      pepCheckboxError: false
    }))
    if (alert && alert(value))
      this.setState({[name]: true})
  }

  onContinue() {
    const {form: {politicallyExposedReason}} = this.state
    if (!politicallyExposedReason)
      this.setState({pepCheckboxError: true})
    else
      this.setState(state => ({
        form: {
          ...state.form,
          politicallyExposed: (politicallyExposedReason === appropTestPoliticallyExposedReason.none.value) ? appropTestPoliticallyExposed.no.value : appropTestPoliticallyExposed.yes.value,
        },
        politicallyExposed: false,
        pepCheckboxError: false
      }))
  }


  scrollUp(errors) {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : `${findKey(errors)}-simple`, 250))
  }

  logOut() {
    const {clientData} = this.props
    const clientId = get(clientData, 'id')
    const params = {
      reason: `politicallyExposedOrUS - ${clientId}`,
      userId: clientId,
    }
    logEventCustomParams('logout', params)
    return this.context.logout()
  }

  handleSubmit() {
    const {form} = this.state
    const {client, t} = this.props
    const errors = {}
    const {companyObject} = this.context

    map(economicProfileQuestions, (q) => {
      const checkCompanies = filter(q.company, (o) => o.value === companyObject.value)
      const usCountry = (q.value === economicProfileQuestions.tin.value && (get(form, 'taxJurisdictionCountry') === countries.us.key))
      const usCitizen = (q.value === economicProfileQuestions.usCitizen.value && (get(form, 'usCitizen') === appropTestIsUsReportable.yes.value))
      const usRelevant = [economicProfileQuestions.taxJurisdictionCountry.value, economicProfileQuestions.tinReason.value]
      const tinCheck = (usCountry || usCitizen) && q.value === economicProfileQuestions.tin.key
      if (q.relevant(form) && !q.disable && !isEmpty(checkCompanies)) {
        if (!tinCheck) {
          errors[q.value] = isEmpty(trim(form[q.value]))
        } else {
          const validation = !(/^9/i.test(form[q.value])) || !validateLength(form[q.value], null, null, 9)
          errors[q.value] = validation
          errors.tinUsError =  validation
        }
      } else {
        errors[q.value] = false
        if (!(get(form, 'taxJurisdictionCountry') === countries.us.key && includes(usRelevant, q.value)))
          form[q.value] = ''
      }
    })
    logEventCustomParams('economicProfileUpdateAttempted', {
      userId: get(this.context, 'clientId'),
    })

    if (some(errors)) {
      this.setState({errors}, this.scrollUp(errors))
      return
    }

    const variables = omitBy(form, (value, key) => ((isEmpty(value) && isEmpty( client[key])) || client[key] === value))
    this.setState({status: '', loading: true})
    this.props.updateDetails({variables}).then((res) => {
      const usCitizen = get(res, 'data.updateOwnDetails.answers.globalQuestionnaire.usCitizen') === appropTestIsUsReportable.yes.value
      // This line is no longer needed after removing the tabs from personal details.
      // In case of reverting back to tabs please comment in this line back
      // this.props.removeForced()
      logEventCustomParams('economicProfileUpdateCompleted', {
        userId: get(this.context, 'clientId'),
      })
      if (usCitizen) {
        this.props.profileSettings.refetch()
          .then((res) => this.setState({loading: false, formChanged: false, submitMessageError: '', usCitizenNotication: 'hasOpenPositions'}))
          .catch((err) => this.setState({loading: false, formChanged: false, submitMessageError: '', usCitizenNotication: 'noOpenPositions'},
            debounce(() => {this.logOut()}, 20000)()))
      } else {
        this.setState({loading: false, formChanged: false, status: 'success', submitMessageError: '', usCitizenNotication: ''}, this.scrollUp())
      }
    })
      .catch((err) => {
        if (err.networkError) {
          logEventCustomParams('economicProfileUpdateError', {
            reason: 'networkError',
            userId: get(this.context, 'clientId'),
          })
          this.setState({
            loading: false, status: 'failure', usCitizenNotication: '',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          logEventCustomParams('economicProfileUpdateError', {
            reason: get(err, 'graphQLErrors[0].message') || err.message,
            userId: get(this.context, 'clientId'),
          })
          this.setState({
            loading: false, status: 'failure', usCitizenNotication: '',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  renderQuestion(q, answerKey, locale) {
    const {classes, client, clientData:{address:{country}}, t} = this.props
    const {form, countryReadOnly} = this.state
    const {companyObject} = this.context
    const checkCompanies = filter(q.company, (o) => o.value === companyObject.value)

    return (
      <React.Fragment key={q.value}>
        {!q.disable && q.dropdown && q.relevant(form) && !isEmpty(checkCompanies) && <React.Fragment>
          <Grid item xs={q.tooltip ? 11 : 12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor={`${q.value}-simple`} className={classes.formControl}>
                {!isEmpty(messages[`${q.value}Question${locale}${upperCase(country)}`])? <Trans {...messages[`${q.value}Question${locale}${upperCase(country)}`]} /> : q.localization.t(locale)}
              </InputLabel>
              <Select
                variant="standard"
                native
                error={this.state.errors[q.value]}
                id={`${q.value}-simple`}
                name={q.value}
                value={answerKey}
                onChange={(e) => this.handleChange(q.value, e.target.value, q.alert)}
                disabled={(q.value === economicProfileQuestions.taxJurisdictionCountry.value && countryReadOnly ? true : false)}>
                <option value='' key='' className={classes.firstItemHide}></option>
                {map(q.options, (option) => {
                  const checkName =`${option.key}Option${locale}${upperCase(country)}`
                  return (!option.disable) && (!option.hidden) && <option key={option.key} value={option.key}>
                    {!isEmpty(messages[checkName]) ?
                      t(messages[checkName].i18nKey, messages[checkName].defaults) :
                      q.options[option.key].localization.t(locale)}
                  </option>
                })}
              </Select>
              {q.value === economicProfileQuestions.politicallyExposed.value &&
            (client.politicallyExposedReason || form.politicallyExposedReason) &&
            <Typography variant="caption" className={classNames(classes.textLink, classes.textRight)} onClick={() => this.setState({politicallyExposed: true})}>
              <Trans {...messages.viewDetails} /></Typography>}
            </FormControl>
          </Grid>
          {q.tooltip &&
          <Grid item xs={1}>
            <CustomTooltip
              hideArrow={true}
              title={<Typography variant='caption' className={classes.tooltipText}>
                <span dangerouslySetInnerHTML={{__html: q.tooltip.localization.t(locale)}} />
              </Typography>}
              placement={'left'}
            >
              <HelpOutline className={classNames(classes.helpIcon, classes.textHelp)} />
            </CustomTooltip>
          </Grid>}
        </React.Fragment>}
        {!q.disable && q.textfield && q.relevant(form) && !isEmpty(checkCompanies) && <Grid item xs={12} key={q.value}>
          <TextField
            variant="standard"
            required
            id={q.value}
            name={q.value}
            label={!isEmpty(messages[`${q.value}Question${locale}${upperCase(country)}`]) ? <Trans {...messages[`${q.value}Question${locale}${upperCase(country)}`]} /> : q.localization.t(locale)}
            fullWidth
            value={answerKey}
            error={this.state.errors[q.value]}
            onChange={(e) => this.handleChange(q.value, e.target.value, q.alert)} />
          {q.value === economicProfileQuestions.tin.value && this.state.errors.tinUsError &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.tinUsError} /></FormHelperText>}
        </Grid>}
      </React.Fragment>
    )
  }

  render() {
    const {classes, viewer, t, latestAptest, client, clientData, history} = this.props
    const {locale, companyObject, themePreference} = this.context
    const {accountVerification, cysecRegulator} = config
    const {politicallyExposed, status, form, loading, submitMessageError, usCitizenNotication, pepCheckboxError, showMessage} = this.state
    const lengthEconomic = Math.ceil(size(economicProfileQuestions) / 2)
    const firstColumn = reject(economicProfileQuestions,(q) => q.order > lengthEconomic)
    const secondColumn = reject(economicProfileQuestions,(q) => q.order <= lengthEconomic)
    const verificationFields = accountVerification
    const missingVerifications =  getMissingVerifications(clientData, latestAptest, client, '', verificationFields)
    const checkMissingVerifications = cysecRegulator && includes(missingVerifications, 'profile')
    return (
      <React.Fragment>
        <PageTitle
          withoutPadding
          themePreference={themePreference}
          onBack={() => history.push('/settings/profile')}
          title={t(messages.economicProfile.i18nKey, messages.economicProfile.defaults)}
        />
        <PepDialog open={politicallyExposed} onChange={(e) => this.handleChange('politicallyExposedReason', e.target.value)}
          onAgree={() => this.onContinue()} pepCheckboxError={pepCheckboxError} form={form}/>
        <PageBody>
          <Grid container id={gridScroll} spacing={3}>
            {(status === 'success'  || usCitizenNotication) &&
              <Grid item xs={12} sm={12}>
                {(usCitizenNotication)
                  ? (usCitizenNotication === 'hasOpenPositions')
                    ? <NotificationBar
                      status='warning'
                      title={<Trans {...messages.openPositionsUsCitizen}
                        values={{company: companyObject['brandLabel']}}
                        components={[<Link to={'/support'} className={classes.warningCTA}>contanctSupport</Link>]}
                      />}
                    />
                    : <NotificationBar
                      status='error'
                      title={<React.Fragment>
                        <Trans {...messages.openPositionsUsCitizen} values={{company: companyObject['brandLabel']}} components={[]} />
                        <Trans {...messages.registrationRejectedUsCitizen} />
                      </React.Fragment>}
                      ctaMessage={<Trans {...messages.logout} />}
                      ctaAction={() => this.logOut()}
                    />
                  : checkMissingVerifications
                    ? <NotificationBar
                      status='success'
                      title={<Trans {...messages.economicProfileChanged} />}
                      ctaMessage={<Trans {...messages.goToPersonalDetails} />}
                      ctaAction={() => history.push('/settings/profile/personal-profile/details')}
                    />
                    : <NotificationBar
                      status='success'
                      title={<Trans {...messages.economicProfileChanged} />}
                      ctaMessage={<Trans {...messages.goToDashboard} />}
                      ctaAction={() => history.push('/')}
                    />
                }
              </Grid>
            }
            <Grid item>
              <form className={classes.root}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={3}>
                      {viewer && map(firstColumn, (q) => {
                        const answerKey = get(form, q.value, '')
                        return this.renderQuestion(q, answerKey, locale)
                      })
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={3}>
                      {viewer && map(secondColumn, (q) => {
                        const answerKey = get(form, q.value, '')
                        return this.renderQuestion(q, answerKey, locale)
                      })
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <LoadingButton
                      id='loadingButton'
                      onClick={(e) => this.handleSubmit(e)}
                      disabled={loading}
                      status={status}
                      className={isMobile() ? classes.button : ''}
                    ><Trans {...messages.saveButtonSettings} />
                    </LoadingButton>
                    {status==='failure' &&
                      <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <AlertDialog
              open={showMessage}
              onClose={() => this.setState({showMessage: false})}
              agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
            >
              <Typography variant="subtitle1">
                <Trans {...messages.migrateEpTestMessage} components={{company: get(companyObject, 'trademark')}}/>
              </Typography>
            </AlertDialog>
          </Grid>
        </PageBody>
      </React.Fragment>
    )
  }
}

EconomicProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

function applyPendingChanges(client, changes) {
  return {
    transactionPurpose: get(changes, 'globalQuestionnaire.transactionPurpose') ||
      get(client, 'globalQuestionnaire.transactionPurpose') || '',
    jobTitle: get(changes, 'globalQuestionnaire.jobTitle') ||
      get(client, 'globalQuestionnaire.jobTitle') || '',
    approxYearlyIncome: get(changes, 'globalQuestionnaire.approxYearlyIncome') ||
      get(client, 'globalQuestionnaire.approxYearlyIncome') || '',
    approxNetWorth: get(changes, 'globalQuestionnaire.approxNetWorth') ||
      get(client, 'globalQuestionnaire.approxNetWorth') || '',
    approxExpectedDeposit: get(changes, 'globalQuestionnaire.approxExpectedDeposit') ||
      get(client, 'globalQuestionnaire.approxExpectedDeposit') || '',
    taxJurisdictionCountry: get(changes, 'globalQuestionnaire.taxJurisdictionCountry') ||
      get(client, 'globalQuestionnaire.taxJurisdictionCountry') || '',
    sourceOfFunds: get(changes, 'globalQuestionnaire.sourceOfFunds') ||
      get(client, 'globalQuestionnaire.sourceOfFunds') || '',
    natureOfTransactions: get(changes, 'globalQuestionnaire.natureOfTransactions') ||
      get(client, 'globalQuestionnaire.natureOfTransactions') || '',
    originOfFunds: get(changes, 'globalQuestionnaire.originOfFunds') ||
      get(client, 'globalQuestionnaire.originOfFunds') || '',
    politicallyExposed: get(changes, 'globalQuestionnaire.politicallyExposed') ||
      get(client, 'globalQuestionnaire.politicallyExposed') || '',
    usCitizen: get(changes, 'globalQuestionnaire.usCitizen') ||
      get(client, 'globalQuestionnaire.usCitizen') || '',
    tinReason: get(changes, 'globalQuestionnaire.tinReason') ||
      get(client, 'globalQuestionnaire.tinReason') || '',
    tin: get(changes, 'globalQuestionnaire.tin') ||
    get(client, 'globalQuestionnaire.tin') || '',
    tinClarify: get(changes, 'globalQuestionnaire.tinClarify') ||
    get(client, 'globalQuestionnaire.tinClarify') || '',
    transactionPurposeClarify: get(changes, 'globalQuestionnaire.transactionPurposeClarify') ||
    get(client, 'globalQuestionnaire.transactionPurposeClarify') || '',
    natureOfTransactionsClarify: get(changes, 'globalQuestionnaire.natureOfTransactionsClarify') ||
    get(client, 'globalQuestionnaire.natureOfTransactionsClarify') || '',
    sourceOfFundsClarify: get(changes, 'globalQuestionnaire.sourceOfFundsClarify') ||
    get(client, 'globalQuestionnaire.sourceOfFundsClarify') || '',
    politicallyExposedReason: get(changes, 'globalQuestionnaire.politicallyExposedReason') ||
      get(client, 'globalQuestionnaire.politicallyExposedReason') || '',
    expectedCountryOfOriginDestinationFunds: get(changes, 'globalQuestionnaire.expectedCountryOfOriginDestinationFunds') ||
      get(client, 'globalQuestionnaire.expectedCountryOfOriginDestinationFunds') || '',
    nameOfEmployer: get(changes, 'globalQuestionnaire.nameOfEmployer') ||
      get(client, 'globalQuestionnaire.nameOfEmployer') || '',
    addressOfEmployer: get(changes, 'globalQuestionnaire.addressOfEmployer') ||
      get(client, 'globalQuestionnaire.addressOfEmployer') || '',
    jobTitleClarify: get(changes, 'globalQuestionnaire.jobTitleClarify') ||
      get(client, 'globalQuestionnaire.jobTitleClarify') || '',
  }
}

function mergeProfileChanges(profileChanges) {
  let answers = {globalQuestionnaire: {}}
  forEach(profileChanges, (profileChange) => {
    const globalQuestionnaire = get(profileChange.answers, 'globalQuestionnaire')
    if (globalQuestionnaire) {
      forEach(keys(globalQuestionnaire), (field) => {
        if (has(profileChangeFields, field)) {
          const value = globalQuestionnaire[field]
          if (value !== null) {
            answers = {
              globalQuestionnaire: {
                ...answers.globalQuestionnaire,
                [field]: value,
              }
            }
          }
        }
      })
    } else {
      return
    }
  })

  return answers
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading}, data, ownProps}) => {
      const viewer = get(data, 'viewer')
      const profileChanges = orderBy(get(viewer, 'profileChanges', {}), ['id'], ['asc'])
      const mergedProfileChanges = mergeProfileChanges(profileChanges)
      return {
        error,
        loading,
        viewer,
        client: applyPendingChanges(viewer, mergedProfileChanges)
      }
    }
  }),
  graphql(UPDATE_ECONOMIC_PROFILE_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}, {query: PROFILE_SETTINGS_QUERY}],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    name: 'profileSettings',
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data, ownProps}) => ({
      clientData: get(data, 'viewer'),
    })
  }),
)(EconomicProfile)
