import {withStyles} from '@mui/styles'
import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import Loading from '../../Common/Loading'
import PropTypes from 'prop-types'
import {containerQueryBreakpointMappings} from './utils'

const styles = theme => ({
  container: {
    maxHeight: '100%',
    width: '100%',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(containerQueryBreakpointMappings.md.max).replace('@media', '@container')]: {
      padding: theme.spacing(3),
      marginTop: 'initial',
      marginBottom: 4,
    },
    backgroundColor: ({isDark}) => isDark ? theme.palette.cardBackground.color : theme.palette.extralightgrey.color,
    borderRadius: theme.spacing(1),
    border: ({isDark}) => `1px solid ${isDark ? theme.palette.lightgrey.color : '#EBEBEB'}`,
    '& svg': {
      height: 120,
    },
  },
})

export class PromotionalBannerLoadingWrapper extends Component {
  render() {
    const {classes} = this.props
    return (<div className={classes.container}><Loading /></div>)
  }
}

PromotionalBannerLoadingWrapper.propTypes = {
  isDark: PropTypes.bool.isRequired
}

export default compose(withStyles(styles, {withTheme: true}))(PromotionalBannerLoadingWrapper)
