import React, {Component} from 'react'
import {find, first, flowRight as compose, get, map} from 'lodash'
import {withStyles} from '@mui/styles'
import {Trans, withNamespaces} from 'react-i18next'
import AppContext from '../../Common/contexts/AppContext'
import PromoMaterialsRoutes from './routes'
import {Grid, Grow, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import {promoMaterialTypes} from '@bdswiss/common-enums'
import classNames from 'classnames'
import messages from '../../../assets/messages'
import {getCurrentTheme, isDarkTheme} from '../../../common/utils'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import PromoMaterialsContext from './PromoMaterialsContext'
import NotificationBar from '../../Common/NotificationBar'

const styles = theme => ({
  root: {
    gap: theme.spacing(2),
    paddingTop: 'unset',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
    },
  },
  section: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
    borderBottom: '1px solid #EBEBEB',
    '&.dark': {
      borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
    },
  },
  container: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(2),
      padding: '0px 0px 16px',
    },
  },
  button: {
    color: theme.palette.secondary.dark,
    borderRadius: '6px',
    border: '1px solid #EBEBEB',
    '&:hover': {
      backgroundColor: theme.palette.extralightgrey.color,
    },
    '&.dark': {
      border: `1px solid ${theme.palette.lightgrey.color}`,
      '&:hover': {
        backgroundColor: theme.palette.extralightgreyBackground.color,
      },
    },
    '&.active': {
      border: `1.5px solid ${theme.palette.primary.main}`,
    },
  },
  buttonSpan: {
    padding: '4px 6px 4px 6px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    textWrap: 'nowrap',
  },
  headerTitle: {
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.18px',
    color: theme.palette.secondary.main,
    '&.active': {
      color: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
})

const categoryItems = [
  {key: promoMaterialTypes.banner.key, title: messages.promoMaterialsBanners, relativePath: '/banners', disabled: false, headerKey: 'online'},
  {key: promoMaterialTypes.logo.key, title: messages.promoMaterialsLogos, relativePath: '/logos', disabled: false, headerKey: 'online'},
  {key: promoMaterialTypes.presentation.key, title: messages.promoMaterialsPresentations, relativePath: '/presentations', disabled: false, headerKey: 'online'},
  {key: promoMaterialTypes.video.key, title: messages.promoMaterialsVideos, relativePath: '/videos', disabled: false, headerKey: 'online'},
  {key: promoMaterialTypes.brochure.key, title: messages.promoMaterialsBrochures, relativePath: '/brochures', disabled: false, headerKey: 'online'},
  {key: promoMaterialTypes.landing_page.key, title: messages.promoMaterialsLandingPages, relativePath: '/landing-pages', disabled: false, headerKey: 'online'},
  {key: promoMaterialTypes.roll_up.key, title: messages.promoMaterialsRollUps, relativePath: '/roll-ups', disabled: false, headerKey: 'offline'},
  {key: promoMaterialTypes.print_ad.key, title: messages.promoMaterialsPrintAds, relativePath: '/print-ads', disabled: false, headerKey: 'offline'},
  {key: promoMaterialTypes.outdoor.key, title: messages.promoMaterialsOutdoors, relativePath: '/outdoors', disabled: false, headerKey: 'offline'},
]

const headerItems = [
  {key: 'online', title: messages.promoMaterialsOnlineMaterials},
  {key: 'offline', title: messages.promoMaterialsOfflineMaterials},
]

class PromoMaterials extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const defaultItem = first(categoryItems)
    this.state = {
      activeCategory: defaultItem.key,
      activeHeader: defaultItem.headerKey,
      marketingMaterialFormStatus: null,
    }
    this.setMarketingMaterialFormStatus = this.setMarketingMaterialFormStatus.bind(this)
  }

  setMarketingMaterialFormStatus(status) {
    this.setState({marketingMaterialFormStatus: status})
  }

  setActiveCategory(categoryKey) {
    const defaultItem = first(categoryItems)
    // When you click on a specific category
    if (categoryKey) {
      const categoryItem = find(categoryItems, (c) => c.key === categoryKey) ?? defaultItem
      return this.setState({activeCategory: categoryItem.key, activeHeader: categoryItem.headerKey})
    }
    const {location} = this.props
    const path = get(location, 'pathname', '')
    const lastPath = path.substring(path.lastIndexOf('/'))
    // When you access the route /promo-materials without specifying the category
    if (lastPath === '/promo-materials') return this.setState({activeCategory: defaultItem.key, activeHeader: defaultItem.headerKey})
    // When you navigate through a specific category via url
    const categoryItem = find(categoryItems, (p) => p.relativePath === lastPath) ?? defaultItem
    this.setState({activeCategory: categoryItem.key, activeHeader: categoryItem.headerKey})
  }

  componentDidMount() {
    this.setActiveCategory()
  }

  handleSetActiveCategory(key, relativePath) {
    const {history, match} = this.props
    history.push(`${match.url}${relativePath}`)
    this.setActiveCategory(key)
  }

  handleSetActiveHeader(key) {
    const {history, match} = this.props
    const filteredCategories = categoryItems.filter((c) => c.headerKey === key)
    if (!filteredCategories.length) return
    const defaultCategoryItem = first(filteredCategories)
    history.push(`${match.url}${defaultCategoryItem.relativePath}`)
    this.setActiveCategory(defaultCategoryItem.key)
  }

  render() {
    const {classes} = this.props
    const {activeCategory, activeHeader} = this.state
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    return (
      <PromoMaterialsContext.Provider value={{
        marketingMaterialFormStatus: this.state.marketingMaterialFormStatus,
        setMarketingMaterialFormStatus: this.setMarketingMaterialFormStatus,
      }}>
        <React.Fragment>
          <Grid className={classes.root} container direction='column'>
            {this.state.marketingMaterialFormStatus === 'success' && (
              <Grow in={true}>
                <Grid item xs sx={{pb: {xs: 2, md: 3}}}>
                  <NotificationBar
                    status={this.state.marketingMaterialFormStatus}
                    title={<Trans {...messages.marketingMaterialFormRequestSubmitted} />}
                  />
                </Grid>
              </Grow>
            )}
            <Grid className={classNames(classes.section, {dark: isDark})} item xs>
              <Grid direction='column' container gap={3}>
                <Grid container display='flex'>
                  <Grid item xs display='flex' gap={3}>
                    {map(headerItems, ({key, title}) =>
                      <Typography
                        key={key}
                        className={classNames(classes.headerTitle, {active: activeHeader === key})}
                        variant='h2'
                        onClick={() => this.handleSetActiveHeader(key)}>
                        <Trans {...title} />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs className={classes.container}>
                  {map(categoryItems, ({key, title, relativePath, headerKey, disabled}) => headerKey === activeHeader &&
                  <Button
                    key={key}
                    onClick={() => this.handleSetActiveCategory(key, relativePath)}
                    disabled={disabled}
                    className={classNames(classes.button, {active: activeCategory === key, dark: isDark})}>
                    <span className={classes.buttonSpan}>
                      <Trans {...title}/>
                    </span>
                  </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <PromoMaterialsRoutes />
            </Grid>
          </Grid>
        </React.Fragment>
      </PromoMaterialsContext.Provider>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
)(PromoMaterials)
