import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {withRouter} from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {graphql, withApollo} from 'react-apollo'
import {Box, Card, Grid, SvgIcon, Typography} from '@mui/material'
import {accountTypes, kycStatuses} from '@bdswiss/common-enums'
import {flowRight as compose, filter, find, get, includes, isEmpty, map, reverse, sortBy} from 'lodash'
import CustomCarouselArrow from '../CustomCarouselArrow'
import Amount from '../../Common/Amount'
import Images from '../../Common/Images'
import messages from '../../../assets/messages'
import AppContext from '../../Common/contexts/AppContext'
import {serversLabel} from '../../../common/utils/uioptions'
import {getAccountLabel, getAccountSubtype, getAccountTypeStyles, isAffiliatesAccount, isBitnukAccount, isIntroducingBrokerAccount,
  isLdClientWalletAccount, isLdWalletAccount, isMobile, isWalletAccount, isForexAccount, logEventCustomParams} from '../../../common/utils'
import LoadingWrapper from '../LoadingWrapper'
import {AFFILIATE_SSO_LINK_QUERY, IB_SSO_LINK_MUTATION} from '../../../graphql/mutations'
import {AlertDialog} from '../../Common/Dialog'
import config from '../../../config'
import IbAccountSelectModal from '../../Accounts/Ib/IbAccountSelectModal'
import {ReactComponent as CompetitionBadgeBlue} from '../../../assets/images/badge-competition-blue.svg'
import {ReactComponent as CompetitionBadgeBlueDark} from '../../../assets/images/badge-competition-blue-dark.svg'
import {ReactComponent as CompetitionBadgePink} from '../../../assets/images/badge-competition-pink.svg'
import {ReactComponent as CompetitionBadgePinkDark} from '../../../assets/images/badge-competition-pink-dark.svg'
import {ReactComponent as CompetitionBadgePurple} from '../../../assets/images/badge-competition-purple.svg'
import {ReactComponent as CompetitionBadgePurpleDark} from '../../../assets/images/badge-competition-purple-dark.svg'
import {ReactComponent as BonusBadgePinkDark} from '../../../assets/images/badge-bonus-pink-dark.svg'
import {ReactComponent as BonusBadgeBlueDark} from '../../../assets/images/badge-bonus-blue-dark.svg'
import {ReactComponent as BonusBadgeBlue} from '../../../assets/images/badge-bonus-blue.svg'
import {ReactComponent as BonusBadgePink} from '../../../assets/images/badge-bonus-pink.svg'
import {ReactComponent as ProvidingCopyingBlue} from '../../../assets/images/badge-providing-copying-blue.svg'
import {ReactComponent as ProvidingCopyingBlueDark} from '../../../assets/images/badge-providing-copying-blue-dark.svg'
import {ReactComponent as ProvidingCopyingPink} from '../../../assets/images/badge-providing-copying-pink.svg'
import {ReactComponent as ProvidingCopyingPinkDark} from '../../../assets/images/badge-providing-copying-pink-dark.svg'
import {ReactComponent as ProvidingCopyingPurple} from '../../../assets/images/badge-providing-copying-purple.svg'
import {ReactComponent as ProvidingCopyingPurpleDark} from '../../../assets/images/badge-providing-copying-purple-dark.svg'

const styles = (theme) => ({
  carouselRoot: {
    height: '135px',
    position: 'unset',
  },
  sliderClass: {
    gap: '24px',
  },
  itemClass: {
    maxWidth: (props) => props.maxItemWidth.lgUp,
    [theme.breakpoints.down('lg')]: {
      maxWidth: (props) => props.maxItemWidth.lgDown,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: (props) => props.maxItemWidth.mdDown,
    },
  },
  carouselItemContainer: {
    gap: '20px',
    height: '134px',
    display: 'flex',
    boxShadow: 'none',
    padding: '20px 24px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    borderRadius: theme.spacing(1),
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },
  badgesContainer: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'row',
  },
  badgeLabel: {
    fontWeight: 400,
    padding: '0px 8px',
    lineHeight: '24px',
    borderRadius: '4px',
    letterSpacing: '0.1px',
    height: 'fit-content'
  },
  labelBalanceContainer: {
    gap: '2px',
    display: 'flex',
    flexDirection: 'column',
    '&.notShowBadges': {
      height: '100%',
      justifyContent: 'space-between'
    }
  },
  accountLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  balanceLabel: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: theme.palette.secondary.main,
  },
  addNewAccountCardContainer: {
    display: 'flex',
    height: '134px',
    boxShadow: 'none',
    padding: '20px 24px',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    backgroundColor: 'transparent',
    border: theme.palette.mode === 'dark' ? '1px dashed #1F1F1F' : '1px dashed #EBEBEB',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },
  addNewAccountCardContent: {
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  lightGreyLoading: {
    backgroundColor: theme.palette.extralightgrey.color,
  },
  addAccount:{
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  accountIcons:{
    width: '24px',
    height: '24px'
  }
})

const hideSensitiveData = '***'

class AccountsCarousel extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      showIBPopup: false,
      additionalTransfrom: 0,
      showIbAccountSelectionModal: false,
      ibAccountSelected: {},
    }
  }

  sortAccountsByIsDemoAndBalance() {
    const {accounts} = this.props

    let sortedAccounts = []
    const liveAccounts = filter(accounts, {isDemo: false})
    const demoAccounts = filter(accounts, {isDemo: true})

    sortedAccounts = [...reverse(sortBy(liveAccounts, ['balance', 'currency'])), ...reverse(sortBy(demoAccounts, ['balance', 'currency']))]

    return sortedAccounts
  }

  getResponsiveObject(theme) {
    if (!theme) {
      return ({
        'xl': {
          items: 3,
          breakpoint: {max: 10000, min: 901},
        },
        'lg': {
          items: 2,
          breakpoint: {max: 900, min: 601},
        },
        'sm': {
          items: 2,
          breakpoint: {max: 600, min: 376},
        },
        'xs': {
          items: 1,
          partialVisibilityGutter: 64,
          breakpoint: {max: 375, min: 0},
        }
      })
    }

    return ({
      'xl': {
        items: 3,
        slidesToSlide: 3,
        breakpoint: {max: 10000, min: theme.breakpoints.values.xl}
      },
      'lg': {
        items: 3,
        slidesToSlide: 3,
        breakpoint: {max: theme.breakpoints.values.xl - 1, min: theme.breakpoints.values.md}
      },
      'sm': {
        items: 2,
        slidesToSlide: 2,
        breakpoint: {max: theme.breakpoints.values.md - 1, min: theme.breakpoints.values.sm},
      },
      'xs': {
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 64,
        breakpoint: {max: theme.breakpoints.values.sm - 1, min: theme.breakpoints.values.xs},
      }
    })
  }

  getItemTransform(nextSlide, carouselState) {
    const {theme} = this.props
    const {deviceType, currentSlide} = carouselState

    const isRtlTheme = theme.direction === 'rtl'

    switch (deviceType) {
      case 'xl': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 8
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -8
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'lg': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 8
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -8
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'sm': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 10
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -10
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'xs': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 24
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -24
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      default: {
        this.setState({additionalTransfrom: 0})
      }
    }
  }

  toAffiliatePortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')

    apolloClient.query({query: AFFILIATE_SSO_LINK_QUERY, variables: {accountId: account.id}, fetchPolicy:'network-only'}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  toIBPortal(account) {
    const {createIBSingleSignOnLink} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')
    createIBSingleSignOnLink({variables: {accountId: account.id}}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  onClickAccount(account) {
    const verifiedAffiliate = (isAffiliatesAccount(account) && includes(['Approved'],
      get(account, 'serviceFields.affiliateStatus')))
    const approvedIb = (isIntroducingBrokerAccount(account) && get(account, 'portalAccess', false))
    const {history, kycStatus} = this.props
    if (isAffiliatesAccount(account)) {
      !verifiedAffiliate ? this.setState({showAffiliatePopupClick: true, showAffiliatePopup: true}) : this.toAffiliatePortal(account)
    } else if (isLdWalletAccount(account)) {
      history.push('/transactions/local-depositor-transfer')
    } else if (isLdClientWalletAccount(account)) {
      history.push('/transactions/withdraw')
    } else if (isIntroducingBrokerAccount(account)) {
      if (!approvedIb) {
        this.setState({showIBPopup: true})
      } else if (get(account, 'ibId', []).length > 1) {
        this.setState({showIbAccountSelectionModal: true, ibAccountSelected: account})
      } else {
        this.toIBPortal(account)
      }
    } else if (isBitnukAccount(account) && kycStatus !== kycStatuses.approved.value) {
      this.setState({showBitnukPopup: true})
    }
    else {
      history.push(`/accounts/${account.id}`)
    }
  }

  renderAccountCard(account) {
    const {locale, country} = this.context
    const {t, theme, classes, showSensitiveData, loading} = this.props
    const accountType = accountTypes[account.__typename]
    const accountSubtype = getAccountSubtype(account.accountSubtype)
    const accountLabel = getAccountLabel(account, locale, accountSubtype, t, country, false)
    const accountTypeStyles = getAccountTypeStyles(theme, account)

    const isPaymentAgentWallet = isLdWalletAccount(account) || isLdClientWalletAccount(account)
    const showBadges = !isIntroducingBrokerAccount(account) && !isAffiliatesAccount(account)
      && !isPaymentAgentWallet
    const pinkSubtypes = ['raw', 'zero', 'black']
    const blueCategories = ['forexMt4', 'forexMt5', 'cent', 'centMt5']
    const accountCategory = accountType.category
    const hasActiveCompetition = !!(get(account, 'competitions') && find(account.competitions, {active: true}))
    const copyTradingEnabled = get(account.copyTrading,'isActive')
    const provideCopyTrading =  get(account, 'provideCopyTrading')
    const hasActiveBonus = !!account.credit

    return <Card className={classes.carouselItemContainer} onClick={() => this.onClickAccount(account)} key={account?.id}>
      {showBadges && <Box className={classes.badgesContainer}>
        <LoadingWrapper loading={loading}>
          <Typography variant='subtitle2' className={classes.badgeLabel} style={accountTypeStyles}>
            {account.isDemo ? t(messages.demo.i18nKey, messages.demo.defaults) : t(messages.live.i18nKey, messages.live.defaults)}
          </Typography>
        </LoadingWrapper>
        {!isEmpty(serversLabel[accountType.category]) && <LoadingWrapper loading={loading}>
          <Typography variant='subtitle2' className={classes.badgeLabel} style={accountTypeStyles}>
            {serversLabel[accountType.category]}
          </Typography>
        </LoadingWrapper>}
        {hasActiveCompetition && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
        <LoadingWrapper loading={loading}>
          <SvgIcon>
            {accountSubtype && includes(pinkSubtypes, accountSubtype.key) ?
              (theme.palette.mode==='dark'? <CompetitionBadgePinkDark/> : <CompetitionBadgePink/>) : accountCategory && includes(blueCategories, accountCategory) ?
                (theme.palette.mode==='dark' ? <CompetitionBadgeBlueDark/> : <CompetitionBadgeBlue/>) : isWalletAccount(account) ?
                  (theme.palette.mode==='dark' ? <CompetitionBadgePurpleDark/> : <CompetitionBadgePurple/>): <></>}
          </SvgIcon>
        </LoadingWrapper>
        }
        {((isForexAccount(account) && copyTradingEnabled) || provideCopyTrading) &&
        <LoadingWrapper loading={loading}>
          <Box className={classes.accountIcons}>
            <SvgIcon>
              {accountSubtype && includes(pinkSubtypes, accountSubtype.key) ? (theme.palette.mode==='dark'? <ProvidingCopyingPinkDark/> : <ProvidingCopyingPink/>) :
                includes(blueCategories, accountCategory) ? (theme.palette.mode==='dark'? <ProvidingCopyingBlueDark/> : <ProvidingCopyingBlue/>) :
                  isWalletAccount(account) ? (theme.palette.mode==='dark' ? <ProvidingCopyingPurpleDark/> : <ProvidingCopyingPurple/>) :<></>}
            </SvgIcon>
          </Box>
        </LoadingWrapper>}
        {hasActiveBonus && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
        <LoadingWrapper loading={loading}>
          <Box className={classes.accountIcons}>
            <SvgIcon>
              {accountSubtype && includes(pinkSubtypes, accountSubtype.key) ? (theme.palette.mode==='dark' ? <BonusBadgePinkDark/> : <BonusBadgePink/>) : includes(blueCategories, accountCategory) ?
                (theme.palette.mode==='dark' ? <BonusBadgeBlueDark/> : <BonusBadgeBlue/>) : <></>}
            </SvgIcon>
          </Box>
        </LoadingWrapper>
        }
      </Box>}
      <Box className={classNames(classes.labelBalanceContainer, {notShowBadges: !showBadges})}>
        <LoadingWrapper loading={loading}>
          <Typography variant='subtitle1' className={classes.accountLabel}>
            {accountLabel} {isPaymentAgentWallet ? '' : showSensitiveData ? ` · #${account.remoteId}` : `${hideSensitiveData}`}
          </Typography>
        </LoadingWrapper>
        <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
          {showSensitiveData
            ? <Amount
              classes={{
                value: classes.balanceLabel,
                currency: classes.balanceLabel,
                fraction: classes.balanceLabel,
              }}
              value={account.balance}
              currency={account.currency}
              locale='en-US'
            />
            : <span> {hideSensitiveData} </span> }
        </LoadingWrapper>
      </Box>
    </Card>
  }

  renderAddNewAccountCard() {
    const {clientId} = this.context
    const {t, classes, history, loading} = this.props

    return <Card
      className={classes.addNewAccountCardContainer}
      onClick={() => {
        logEventCustomParams('newTradingAccountAdded', {userId: clientId, source: 'Home'})
        history.push({
          pathname: '/accounts/add-account',
          state: {prevPath: history.location.pathname}
        })}
      }
      key={'newAccount'}
    >
      <Box className={classes.addNewAccountCardContent}>
        <LoadingWrapper loading={loading} variant='circular'>
          <img width='24px' height='24px' src={Images['addNewAccount.svg']} alt='icon'/>
        </LoadingWrapper>
        <LoadingWrapper loading={loading}>
          <Typography className={classes.addAccount}> {t(messages.addAccount.i18nKey, messages.addAccount.defaults)} </Typography>
        </LoadingWrapper>
      </Box>
    </Card>
  }

  render() {
    const {ibPartnersEmail} = config
    const {classes, theme} = this.props
    const {additionalTransfrom, showIBPopup, showIbAccountSelectionModal, ibAccountSelected} = this.state
    const responsive = this.getResponsiveObject(theme)
    const accounts = this.sortAccountsByIsDemoAndBalance()
    return (
      <React.Fragment>
        <Carousel
          arrows={!isMobile()}
          responsive={responsive}
          partialVisible={isMobile()}
          itemClass={classes.itemClass}
          rtl={theme.direction === 'rtl'}
          className={classes.carouselRoot}
          sliderClass={classes.sliderClass}
          additionalTransfrom={additionalTransfrom}
          children={[...map(accounts, (a) => this.renderAccountCard(a)), this.renderAddNewAccountCard()]}
          customLeftArrow={<CustomCarouselArrow
            direction='left'
          />}
          customRightArrow={<CustomCarouselArrow
            direction='right'
          />}
          beforeChange={(nextSlide, carouselState) => {
            this.getItemTransform(nextSlide, carouselState)
          }}
        />
        <AlertDialog
          open={showIBPopup}
          onClose={() => this.setState({showIBPopup: false})}
          title={<Trans {...messages.welcome} />}
          agreeText={<Trans {...messages.continue} />}
          onAgree={() => this.setState({showIBPopup: false})}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"> <Trans {...messages.ibAccountNotApproved} /></Typography>
              <Typography variant="body1"> <Trans {...messages.ibAccountNotApprovedText2} values={{email: ibPartnersEmail}}/></Typography>
            </Grid>
          </Grid>
        </AlertDialog>
        <IbAccountSelectModal
          open={showIbAccountSelectionModal}
          onClose={() => this.setState({showIbAccountSelectionModal: false})}
          account={ibAccountSelected}
          onSelectAccount={(ibId) => this.toIBPortal(ibAccountSelected, ibId)}
        />
      </React.Fragment>
    )
  }
}

AccountsCarousel.propTypes = {
  loading: PropTypes.bool,
  accounts: PropTypes.array,
  maxItemWidth: PropTypes.shape({
    lgUp: PropTypes.string,
    lgDown: PropTypes.string,
    mdDown: PropTypes.string,
  }),
  showSensitiveData: PropTypes.bool,
}

export default compose(
  withApollo,
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
)(AccountsCarousel)
