import Images from '../../Common/Images'

export function getResponsiveObject(theme) {
  if (!theme) return {
    'xl': {
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    'lg': {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    'sm': {
      breakpoint: {max: 1024, min: 464},
      items: 1,
      partialVisibilityGutter: 10,
    },
    'xs': {
      breakpoint: {max: 464, min: 0},
      items: 1,
      partialVisibilityGutter: 10,
    }
  }
  return ({
    'xl': {
      breakpoint: {max: Number.MAX_SAFE_INTEGER, min: theme.breakpoints.values['xl']},
      items: 1
    },
    'lg': {
      breakpoint: {max: theme.breakpoints.values['xl'], min: theme.breakpoints.values['lg']},
      items: 1
    },
    'md': {
      breakpoint: {max: theme.breakpoints.values['lg'], min: theme.breakpoints.values['md']},
      items: 1,
    },
    'sm': {
      breakpoint: {max: theme.breakpoints.values['md'], min: theme.breakpoints.values['sm']},
      items: 1,
    },
    'xs': {
      breakpoint: {max: theme.breakpoints.values['sm'], min: theme.breakpoints.values['xs']},
      items: 1,
      partialVisibilityGutter: theme.spacing(1).replace('px', ''),
    }
  })
}

export const promotionalBannersOptions = {
  options: {
    interval: 10000,
    showDots: false,
    arrows: false,
  },
  banners: {
    partnerLoyalty: {
      key: 'partnerLoyalty',
      background: 'radial-gradient(73.93% 73.93% at 56.69% 55.18%, rgba(41, 89, 242, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #001832',
      titleKey: 'partnerLoyaltyProgramTitle',
      content: {
        isList: false,
        descKeys: ['partnerLoyaltyProgramDescPart1', 'partnerLoyaltyProgramDescPart2']
      },
      imageSrc: Images['partnerLoyalty.png'],
      alt: 'banners',
      link: '/partner-zone/loyalty-program',
      maxHeight: 140,
      order: 1,
      resolutions: {
        default: {
          width: 360,
          height: 208,
          spacing: 96,
        },
        sm: {
          width: 280,
          height: 167,
          spacing: 48,
        },
        md: {
          width: 360,
          height: 214,
          spacing: 32,
        },
      }
    },
    sponsoredEvent: {
      key: 'sponsoredEvent',
      background: 'radial-gradient(73.93% 73.93% at 56.69% 55.18%, rgba(41, 89, 242, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #001832',
      titleKey: 'partnerSponsoredTitle',
      content: {
        isList: true,
        descKeys: ['partnerSponsoredDescPart1', 'partnerSponsoredDescPart2', 'partnerSponsoredDescPart3']
      },
      imageSrc: Images['sponsoredEvent.png'],
      alt: 'sponsoredEvent',
      link: '/partner-zone/events',
      maxHeight: 164,
      order: 2,
      resolutions: {
        default: {
          width: 296,
          height: 212,
          spacing: 96,
        },
        sm: {
          width: 260.0,
          height: 184.46,
          spacing: 48,
        },
        md: {
          width: 290,
          height: 215,
          spacing: 0,
        },
      }
    },
    tools: {
      key: 'tools',
      background: 'radial-gradient(73.93% 73.93% at 56.69% 55.18%, rgba(41, 89, 242, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #001832',
      titleKey: 'partnerToolsTitle',
      content: {
        isList: true,
        descKeys: ['partnerToolsDescPart1', 'partnerToolsDescPart2', 'partnerToolsDescPart3']
      },
      imageSrc: Images['marketingTools.png'],
      alt: 'tools',
      link: '/partner-zone/promo-materials/banners',
      maxHeight: 164,
      order: 3,
      resolutions: {
        default: {
          width: 360,
          height: 298,
          spacing: 96,
        },
        sm: {
          width: 280,
          height: 246,
          spacing: 80,
        },
        md: {
          width: 340,
          height: 295,
          spacing: 24,
        },
      }
    },
  }
}

export const containerQueryBreakpointMappings = {
  xs: {min: 0, max: 450},
  sm: {min: 450, max: 750},
  md: {min: 750 , max: 1000},
  lg: {min: 1000, max: Number.MAX_SAFE_INTEGER},
}
