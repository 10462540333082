import React, {forwardRef} from 'react'
import {useFormControl} from '@mui/material/FormControl'
import styled from '@mui/system/styled'
import MuiTextField from '@mui/material/TextField'
import MuiFormHelperText from '@mui/material/FormHelperText'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InputLabel from './InputLabel'

const StyledTextField = styled(MuiTextField)(({theme, size, error, disabled, multiline}) => ({
  '& .MuiInputBase-root': {
    ...(!multiline ? {height: size === 'small' ? 40 : 48} : {padding: 14}),
    fontSize: 14,
    caretColor: error ? theme.palette.error.main : theme.palette.primary.main,
    '& .MuiInputBase-input:not(textarea)': {
      padding: `0 ${theme.spacing(2)}`,
    },
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? '#474747' : '#D6D6D6',
    },
    '& textarea': {
      padding: 0,
    }
  },
  '& .MuiInputBase-adornedStart .MuiInputBase-input': {
    paddingLeft: 0,
  },
  '& .MuiInputBase-adornedEnd .MuiInputBase-input': {
    paddingRight: 0,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
    cursor: disabled ? 'auto' : 'pointer',
  },
  '&:hover fieldset, & .Mui-focused fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.primary.main} !important`,
  },
  '& .Mui-disabled fieldset': {
    borderColor: '#474747 !important',
  },
  '& .Mui-error': {
    '& fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
    '& .MuiInputBase-root': {
      caretColor: theme.palette.error.main,
    },
  },
}))

const FormHelperText = styled(MuiFormHelperText)({
  fontSize: 14,
  fontWeight: 400,
  margin: 0,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  textAlign: 'left',
})

const TextField = forwardRef(({
  name,
  label,
  value,
  disabled,
  helperText,
  resetHandle,
  InputProps,
  InputLabelProps,
  FormHelperTextProps,
  ...TextFieldProps
}, ref) => {
  const {hiddenLabel, error} = useFormControl()
  return (
    <>
      {!hiddenLabel && <InputLabel shrink={true} disabled={disabled} {...InputLabelProps}>{label}</InputLabel>}
      <StyledTextField variant='outlined' value={value} name={name} error={error} ref={ref} disabled={disabled} {...TextFieldProps} InputProps={{
        ...(value && resetHandle && !disabled && {endAdornment: <CancelOutlinedIcon onClick={resetHandle.bind(null, name)}/>}),
        ...(error && {endAdornment: <InfoOutlinedIcon />}),
        ...InputProps,
      }}/>
      <FormHelperText error={error} disabled={disabled} {...FormHelperTextProps}>{helperText}</FormHelperText>
    </>
  )
})

export default TextField
