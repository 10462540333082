import React from 'react'
import {debounce, isFunction, get} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Sidebar from './sidebar/Sidebar'
import UiNotification from '../Common/UiNotification'
import Header from './Header'
import AppRoutes from './routes'
import classNames from 'classnames'
import LivechatBubble from '../Common/LivechatBubble'
import {getPlatform} from '../../common/utils'

const styles = theme => ({
  wrap: {
    width: `calc(100% + ${theme.sidebarWidth})`,
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: `-${theme.sidebarWidth}`,
    height: '100%',
    transition: 'margin .2s',
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  },
  wrapVisible: {
    width:'100%',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 0,
    [theme.breakpoints.down('md')]: {
      '& .sidebar': {
        flex:' 1 0 auto',
        height: '100%',
        display: 'flex',
        zIndex: '1200',
        outline: 'none',
        position: 'fixed',
        overflowY: 'auto',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        top: '85px'
      },
      '& .main': {
        [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 0,
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    wrap: {
      width: '100%',
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: theme.direction === 'rtl' ? 'inherit' : 0,
    },
  },
  sidebar: {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    width: `${theme.sidebarWidth}`,
    height: '100%',
    marginLeft: 0,
    paddingRight: 40,
  },
  main: {
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: theme.sidebarWidth,
  },
  mainBody: {
    maxWidth: theme.mainBodyWidth,
    margin: 'auto',
    minHeight: '80vh',
    position: 'relative',
    paddingRight: theme.spacing(3),
  },
  wrapSafari: {
    overflow: 'visible'
  },
  wrapVisibleSafari: {
    transform: 'translate3d(0,0,0)',
    [theme.breakpoints.down('md')]: {
      '& .sidebar':{
        top: 0,
        overflow: 'visible'
      },
    },
  },
})

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {visibleSidebar: false}
  }

  componentDidMount() {
    window.addEventListener('resize', this.hideSidebar)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.hideSidebar)
  }

  hideSidebar = debounce(() => {
    this.setState({visibleSidebar: false})
  }, 200)

  handleSidebarToggle = () => {
    this.setState({visibleSidebar: !this.state.visibleSidebar})
  }

  render() {
    const {notification, classes, viewer: {firstName, lastName, email}} = this.props
    const fullName =[firstName, lastName].join(' ')
    const {visibleSidebar} = this.state
    const isSafari = get(getPlatform(), 'browser') === 'Safari'

    return (<div  className={classes.mainBody}>
      <Header handleSidebarToggle={this.handleSidebarToggle} viewer={this.props.viewer}/>
      <div
        className={[
          classes.wrap,
          visibleSidebar ? classes.wrapVisible : '',
          isSafari ? classes.wrapSafari : '',
          visibleSidebar && isSafari ? classes.wrapVisibleSafari : '',
        ].join(' ')}
      >
        <Sidebar className={classNames(classes.sidebar,'sidebar')} />
        <main className={classNames(classes.main,'main')}>
          <AppRoutes />
        </main>
        {
          notification &&
            <UiNotification
              open={!!notification}
              onClose={() => {
                isFunction(notification.onClose) && notification.onClose()
                this.props.onClose()
              }}
              type={notification.type}
              status={notification.status}
              buttonMessage={notification.buttonMessage}
              hideButton={notification.hideButton}
              subTitle={notification.subTitle}
              subTitleCenter={notification.subTitleCenter}
              buttonAction={notification.buttonAction}
              linkAction={notification.linkAction}
              linkActionMessage={notification.linkActionMessage}
              title={notification.title}
              hideTitle={notification.hideTitle}
              subTitleChildren={notification.subTitleChildren}
            >
              {notification.content}
            </UiNotification>
        }
        <LivechatBubble fullName={fullName} email={email} />
      </div>
    </div>)
  }
}

export default withStyles(styles, {withTheme: true})(Desktop)
