import React, {useContext} from 'react'
import {withNamespaces} from 'react-i18next'
import {withStyles} from '@mui/styles'
import {flowRight as compose} from 'lodash'
import {Skeleton} from '@mui/material'
import {ReactComponent as LoadingIcon} from '../../../assets/images/promoMaterialsLoading.svg'
import AppContext from '../../Common/contexts/AppContext'
import PropTypes from 'prop-types'
import {isDarkTheme} from '../../../common/utils'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    gap: '8px',
  },
  loadingIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '220px',
    borderRadius: '6px',
    backgroundColor: theme.palette.extralightgrey.color,
    transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)',
    '& svg path': {
      fill: '#EBEBEB',
    },
    '&.dark': {
      backgroundColor: theme.palette.lightgrey.color,
      '& svg path': {
        fill: '#474747',
      },
    },
  },
  loadingSkeletonContainer: {
  },
  loadingSkeleton: {
    borderRadius: '8px',
    backgroundColor: '#EBEBEB',
    '&.dark': {
      backgroundColor: '#474747',
    },
  },
})

/*
Usage:
  <SkeletonLoading
    customClasses={{
      root: 'classes.root',
      loadingIconContainer: 'classes.loadingIconContainer',
      loadingSkeletonContainer: 'classes.loadingSkeletonContainer',
      loadingSkeleton: 'classes.loadingSkeleton',
    }}
    skeletonRows=[{width: '80%'}, {width: '60%'}]
  />
*/
const SkeletonLoading = ({classes, customClasses = {}, skeletonRows=[]}) => {
  const {themePreference} = useContext(AppContext)
  const isDark = isDarkTheme(themePreference)
  return (
    <div className={classNames(classes.root, customClasses.root, {dark: isDark})}>
      <div className={classNames(classes.loadingIconContainer, customClasses.loadingIconContainer, {dark: isDark})}>
        <LoadingIcon />
      </div>
      {skeletonRows.length && <div className={classNames(classes.loadingSkeletonContainer, customClasses.loadingSkeletonContainer, {dark: isDark})}>
        {skeletonRows.map(({width}, index) => (
          <Skeleton
            className={classNames(classes.loadingSkeleton, customClasses.loadingSkeleton, {dark: isDark})}
            key={`skeleton_${index}`}
            animation='wave'
            width={width}
          />
        ))}
      </div>}
    </div>
  )
}

SkeletonLoading.propTypes = {
  customClasses: PropTypes.object,
  skeletonRows: PropTypes.array.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(SkeletonLoading)
