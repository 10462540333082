import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material'
import {withStyles} from '@mui/styles'
import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {IconButton} from '@mui/material'
import PropTypes from 'prop-types'
import {containerQueryBreakpointMappings} from './utils'

const styles = theme => ({
  arrows: {
    position: 'absolute',
    top: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: theme.direction === 'rtl'? 'row-reverse' : 'row',
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: theme.spacing(3),
    justifyContent: 'end',
    alignItems: 'center',
    zIndex: 1,
    pointerEvents: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down(containerQueryBreakpointMappings.md.max).replace('@media', '@container')]: {
      display: 'none',
    },
  },
  arrow: {
    fontSize: 14,
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
    pointerEvents: 'auto',
  },
})

export class PromotionalBannerButtonGroup extends Component {
  handlePreviousSlide = () => {
    const {previous} = this.props
    previous()
  }

  handleNextSlide = () => {
    const {next} = this.props
    next()
  }

  render() {
    const {classes, hideOnDeviceTypes, hide, carouselState:{deviceType}} = this.props
    const hideBasedOnDeviceType = hideOnDeviceTypes.includes(deviceType)
    if (hide || hideBasedOnDeviceType) return null
    return (
      <div className={classes.arrows} id='promotionalBannerNavigation'>
        <IconButton
          className={classes.iconButton}
          onClick={() => this.handlePreviousSlide()}
          size='large'>
          <ArrowBackIos className={classes.arrow} />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          onClick={() => this.handleNextSlide()}
          size='large'>
          <ArrowForwardIos className={classes.arrow} />
        </IconButton>
      </div>
    )
  }
}

PromotionalBannerButtonGroup.propTypes = {
  hideOnDeviceTypes: PropTypes.array.isRequired,
  hide: PropTypes.bool.isRequired,
}

export default compose(withStyles(styles, {withTheme: true}))(PromotionalBannerButtonGroup)
