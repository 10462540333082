import React from 'react'
import {Link} from 'react-router-dom'
import {get, flowRight as compose, find, isEmpty, omitBy, cloneDeep, last, orderBy, filter, first, includes,
  toString, toUpper, size} from 'lodash'
import classnames from 'classnames'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import Badge from '@mui/material/Badge'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import SvgIcon from '@mui/material/SvgIcon'
import withStyles from '@mui/styles/withStyles'
import {kycStatuses, dueDiligenceStatuses, languages} from '@bdswiss/common-enums'
import Typography from '@mui/material/Typography'
import ListItemLink from './ListItemLink'
import Header from '../Header'
import config from '../../../config'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY, REFERRAL_DETAILS_QUERY} from '../../../graphql/queries'
import AppContext from '../../Common/contexts/AppContext'
import PageBody from '../../Common/PageBody'
import {getPendingVerificationsCount, isDarkTheme} from '../../../common/utils/general'
import ThemeSelection from '../../ThemeSelection'
import {hasWalletProductAccount, hasOnlyWalletProductAccount, hasIntroducingBrokerAccount, isIBClient,
  hasAffiliateAccount, isAffiliateClient} from '../../../common/utils/accounts'
import moment from 'moment'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import ListItemIcon from '@mui/material/ListItemIcon'
import {ReactComponent as VerifiedIcon} from '../../../assets/images/verified.svg'
import {ReactComponent as UnVerifiedIcon} from '../../../assets/images/unverified.svg'
import {ReactComponent as ToolsIconLight} from '../../../assets/images/tools-light.svg'
import {ReactComponent as ToolsIconDark} from '../../../assets/images/tools-dark.svg'
import {ReactComponent as ProfileSettingsIconLight} from '../../../assets/images/profileSettings-light.svg'
import {ReactComponent as ProfileSettingsIconDark} from '../../../assets/images/profileSettings-dark.svg'
import {ReactComponent as NotificationsIconLight} from '../../../assets/images/notifications-light.svg'
import {ReactComponent as NotificationsIconDark} from '../../../assets/images/notifications-dark.svg'
import {ReactComponent as LanguageIconLight} from '../../../assets/images/language-light.svg'
import {ReactComponent as LanguageIconDark} from '../../../assets/images/language-dark.svg'
import {ReactComponent as ThemeIconLight} from '../../../assets/images/theme-light.svg'
import {ReactComponent as ThemeIconDark} from '../../../assets/images/theme-dark.svg'
import {ReactComponent as LogoutIcon} from '../../../assets/images/logout.svg'
import {ReactComponent as LoyaltyProgramIconDark} from '../../../assets/images/loyaltyProgram-dark.svg'
import {ReactComponent as LoyaltyProgramIconLight} from '../../../assets/images/loyaltyProgram-light.svg'
import {ReactComponent as SupportIconLight} from '../../../assets/images/support-light.svg'
import {ReactComponent as SupportIconDark} from '../../../assets/images/support-dark.svg'
import {ReactComponent as EducationalVideosIconDark} from '../../../assets/images/educationalVideos-dark.svg'
import {ReactComponent as EducationalVideosIconLight} from '../../../assets/images/educationalVideos-light.svg'
import {ReactComponent as PromotionsIconDark} from '../../../assets/images/promotions-dark.svg'
import {ReactComponent as PromotionsIconLight} from '../../../assets/images/promotions-light.svg'
import {ReactComponent as SuitecaseIconGrey} from '../../../assets/images/suitcase-grey.svg'
import {ReactComponent as SuitecaseIconWhite} from '../../../assets/images/suitcase-white.svg'
import BecomePartnerDialog from '../../Dashboard/BecomePartnerDialog'

const styles = (theme) => ({
  invisible: {
    display: 'none'
  },
  badge: {
    top: 18,
    right: theme.direction === 'rtl' ? 20 : -20,
    backgroundColor: `${get(theme.palette, 'mainDark.color') || theme.palette.red.color}`,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' : 'absolute',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  },
  listItemText: {
    // [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 12,
    fontWeight: 400,
  },
  newBadge: {
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 12,
  },
  nameGrid: {
    padding: '10px 24px 30px',
    letterSpacing: '0.1px',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      padding: 16,
      flexDirection: 'column',
      alignItems: 'flex-start',
      lineHeight: '24px',
    },
  },
  statusIcon: {
    'vertical-align':'text-top',
    'font-size':'15px',
    'margin-right':'4px',
  },
  pendingIcon: {
    'color': theme.palette.yellow.color,
    display: 'flex',
    alignItems: 'center',
  },
  doneIcon: {
    display: 'flex',
    alignItems: 'center',
    'color': theme.palette.green.color
  },
  verificationLink:{
    'color': theme.palette.yellow.color
  },
  buttonClass: {
    marginTop: 20,
    width: 192,
    height: 40,
    padding: 4,
    minHeight: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'inherit',
      marginTop: 0,
    },
  },
  listItem: {
    fontStyle: 'normal',
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: 24,
    display: 'flex',
    alignItems: 'center',
    '&:hover':{
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.extralightgreyBackground.color : theme.palette.extralightgrey.color
    },
  },
  badgeNew: {
    width: 'fit-content',
    height: 16,
    top: -7,
    right: theme.direction === 'rtl' ? 0 : -30,
    backgroundColor: `${get(theme.palette, 'mainDark.color') || theme.palette.red.color}`,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' : 'absolute',
    borderRadius: '32px',
    padding: '6px 5px 5px 5px',
  },
  extraToggleSpace: {
    minHeight: 'calc(100% + 60px)',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  menuItemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoutItem: {
    color: theme.palette.red.color,
  },
  icon: {
    marginRight: theme.spacing(2),
    minWidth: 'auto',
    display: 'inline-flex',
    alignItems: 'center',
  },
  root: {
    lineHeight: '2.2rem',
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px'
    }
  },
  itemLanguage: {
    color: '#858585',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px'
  },
  customBadge: {
    display: 'flex',
    padding: '0px 6px',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '32px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: theme.direction === 'rtl' ? -10 : 16,
    backgroundColor: `${get(theme.palette, 'mainDark.color') || theme.palette.red.color}`,
    color: theme.palette.red.textColor,
  },
  mobileHeight: {
    [theme.breakpoints.down('sm')]: {
      height: '80vh'
    }
  },
  customItem:{
    ...theme.typography.body1,
    lineHeight: '2.2rem',
    color: theme.palette.secondary.main,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.secondary.dark,
    }
  }
})
const accountVerification = (kycStatus, classes) => {
  let verificationIcon
  if (kycStatus === kycStatuses.approved.value) {
    verificationIcon = <React.Fragment>
      <Typography variant="body2" className={classes.doneIcon}>
        <VerifiedIcon className={classnames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
        <Trans {...messages.verified} />
      </Typography>
    </React.Fragment>
  } else {
    verificationIcon = <React.Fragment>
      <Typography variant="body2" className={classes.pendingIcon}>
        <UnVerifiedIcon className={classnames({[classes.statusIcon]: true, [classes.pendingIcon]: true})}/>
        <Link to={'/accounts/verification'} className={classes.verificationLink}><Trans {...messages.unVerified} /> </Link>
      </Typography>
    </React.Fragment>
  }
  return verificationIcon
}
// const pendingStatuses = [referralStatuses.deposited.value, referralStatuses.verified.value, referralStatuses.pendingActivation.value]
class Sidebar extends React.Component {
  static contextType = AppContext
  constructor() {
    super()
    this.state = {
      showBecomePartner: false,
    }
  }

  logOut() {
    const {viewer} = this.props
    const clientId = get(viewer, 'id')
    const params = {
      reason: `logout clicked - ${clientId}`,
      userId: clientId,
    }
    logEventCustomParams('logout', params)
    return this.context.logout()
  }
  handleOpenDialog() {
    this.setState({showBecomePartner : true})
  }
  handleCloseDialog() {
    this.setState({showBecomePartner: false})
  }

  render() {
    const {classes, className, loading, viewer: {id, promotionsEligibility, pendingNoticesCount = 0},
      viewer, appropTests, globalQuestionnaire, documents, linkedPartnerClientId, referAndEarn, t, referralDetails, referrals} = this.props
    const {tradingCentralEnabled} = viewer
    const {clientType, blockedDeposit, accounts, companyObject} = this.context
    const {featuresConfig:{tools, referAFriend}, common:{partnersLoyaltyNewBadgeEndDate, partnersLoyaltyTerminationDate, partnersLoyaltyAllowed, hideReferCountries,
      plugitAllowedClientIds}, partnersLoyaltyProgram, showPartnerZone, hideMt4Mentions, sidebar} = config
    const {showBecomePartner} = this.state
    if (loading) {
      return null
    }

    const showPlugit = includes(plugitAllowedClientIds, toString(viewer?.id))

    // const pendingReferral = find(referrals, (r) => includes(pendingStatuses, r.status))
    // const conditions = referralDetails && JSON.parse(referralDetails.conditions)
    // const activated = filter(referrals, (r) => r.status === referralStatuses.activated.value)
    // const claimed = filter(referrals, (r) => r.status === referralStatuses.claimed.value)
    // const limitReached = referrals && (activated.length + claimed.length) >= conditions.maxReferrals

    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId || isIBClient(clientType)
    const countryDisallowNew = includes(hideReferCountries, toUpper(get(viewer, 'address.country')))
    const showReferral = !get(referAFriend, 'disabled') && referAndEarn && ((!isIbOrAffiliate || (isIbOrAffiliate && !!get(referralDetails, 'rewardsObj.pendingRewards', 0))))
      && (!countryDisallowNew || (countryDisallowNew && !!get(referralDetails, 'link'))) && !isEmpty(referrals)

    const pendingVerificationsCount = getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire,
      documents, clientType, blockedDeposit)

    const referralAwardNotification = this.props.activatedReferralAwards && !get(this.props.activatedReferralAwards,'claimedAt')

    const badgeClasses = [classes.badge]
    const settingsBadgeClasses = [classes.badge]
    const toolsBadgeClasses = [classes.badgeNew]
    const totalNotificationsCount = referralAwardNotification ? pendingNoticesCount+1 : pendingNoticesCount
    totalNotificationsCount < 1 && badgeClasses.push(classes.invisible)
    pendingVerificationsCount < 1 && settingsBadgeClasses.push(classes.invisible)
    const onlyWalletAccount = hasOnlyWalletProductAccount(accounts)
    const hasIbAccount = hasIntroducingBrokerAccount(accounts)

    const toolCheck = omitBy(cloneDeep(tools), (tool)=> !!(tool.clientDataMandatory ? find(tool.clientDataMandatory, (data) => !viewer[data]) : onlyWalletAccount))
    const newToolsLabel = find(toolCheck, (tool) => get(tool, 'newLabelUntil') && moment(tool.newLabelUntil).isSameOrAfter(moment(), 'day'))
    !newToolsLabel && toolsBadgeClasses.push(classes.invisible)

    const dueDiligences = get(viewer, 'dueDiligences')
    const dueDiligence = last(orderBy(filter(dueDiligences, (a)=> a.status !== dueDiligenceStatuses.rejected.value)))
    const showPartnerZoneConditions = ((!isEmpty(dueDiligence)) || (includes(partnersLoyaltyAllowed, toString(id)))) && showPartnerZone
    const isIb = isIBClient(clientType)
    const partnersLoyaltyAnalytics = first(get(viewer, 'partnersLoyaltyAnalytics'))
    const today = moment()
    const eligiblePrize = find(get(partnersLoyaltyAnalytics, 'partnersLoyaltyEligibility'), (prize) => moment(get(prize, 'endEligibilityDate')) > today)
    const showNewBadge = today < moment(partnersLoyaltyNewBadgeEndDate)
    const partnerLoyaltyTerminated = (today > moment(partnersLoyaltyTerminationDate)) && isEmpty(eligiblePrize)
    const isAffiliate = isAffiliateClient(clientType) && hasAffiliateAccount(accounts)
    const brand = get(companyObject, 'brandLabel')
    const tradingVideosCondition = !isEmpty(get(viewer, 'tradingVideos'))

    return (
      <div className={className}>
        <List disablePadding component="nav">
          <ListItemLink to={'/dashboard'} isIndex classesItem={classes.listItem}>
            <Trans {...messages.dashboard} />
          </ListItemLink>
          {isIb && showPartnerZoneConditions && <ListItemLink to={'/partner-zone'} isIndex classesItem={classes.listItem} onClick={ () => logEventCustomParams('partnerZoneMenuItemClicked',
            {userId: get(viewer, 'id'),
              clientType: clientType
            })}>
            <Trans {...messages.partnerZone} />
          </ListItemLink>}
          {!isIb && !isAffiliate && (
            <ListItem
              style={{cursor:'pointer'}}
              className={classes.listItem}
              onClick={() => {
                this.handleOpenDialog()
                logEventCustomParams(
                  'partnerZoneMenuItemClicked', {
                    userId: get(viewer, 'id'),
                    clientType: clientType
                  }
                )
              }}
            >
              <div className={classes.customItem} >
                <Trans {...messages.partnerZone} />
              </div>
            </ListItem>
          )}
          {showBecomePartner && <BecomePartnerDialog open={showBecomePartner} viewer={viewer} onClose={() => this.handleCloseDialog()} />}
          <ListItemLink to={'/accounts'} isIndex classesItem={classes.listItem}>
            <Trans {...messages.accounts} />
          </ListItemLink>
          {isAffiliate && !partnerLoyaltyTerminated && partnersLoyaltyProgram && <ListItemLink to={'/loyalty-program'} classesItem={classes.listItem}>
            <Badge badgeContent={showNewBadge && <Trans {...messages.new} />} classes={{badge: showNewBadge ? classnames(classes.badgeNew) :classnames(classes.invisible)}}>
              <div className={classes.listItemText}>
                <Trans {...messages.loyaltyProgram} />
              </div>
            </Badge>
          </ListItemLink>}
          <ListItemLink to={'/transactions'} classesItem={classes.listItem} onClick={()=> logEventCustomParams('viewTransactions', {
            source: 'direct',
            userId: get(viewer, 'id'),
          })}>
            <Trans {...messages.payments} />
          </ListItemLink>
          {(!onlyWalletAccount || (tradingCentralEnabled && hasIbAccount)) && !hideMt4Mentions && <ListItemLink to={'/trading-tools'} classesItem={classes.listItem} onClick={()=> logEventCustomParams('tradingToolsIntent', {
            userId: get(viewer, 'id'),
          })}>
            <Badge badgeContent={(newToolsLabel) ? t(messages.new.i18nKey, messages.new.defaults) : ''} classes={{badge: classnames(toolsBadgeClasses)}}>
              <div className={classes.listItemText}>
                <Trans {...messages.tools} />
              </div>
            </Badge>
          </ListItemLink>}
          <ListItemLink to={'/settings'} classesItem={classes.listItem}>
            <Badge badgeContent={pendingVerificationsCount} classes={{badge: classnames(settingsBadgeClasses)}}>
              <div className={classes.listItemText}>
                <Trans {...messages.profileAndSettingsNew} />
              </div>
            </Badge>
          </ListItemLink>
          {showPlugit && promotionsEligibility && <ListItemLink to={'/promotions'} classesItem={classes.listItem} onClick={()=> logEventCustomParams('promotionsTabClicked', {
            source: 'Dashboard',
            brand: brand,
            userId: get(viewer, 'id'),
          })}>
            <Badge badgeContent={<Trans {...messages.new} />} classes={{badge: classnames(classes.badge)}}>
              <div className={classnames(classes.listItemText, classes.newBadge)}>
                <Trans {...messages.promotions} />
              </div>
            </Badge>
          </ListItemLink>}
          {showReferral && <ListItemLink to={'/referAFriend'} classesItem={classes.listItem} onClick={()=> logEventCustomParams('referAndEarnClicked', {
            userId: get(viewer, 'id'),
          })}>
            <Trans {...messages.referAFriend} />
          </ListItemLink>}
          {get(sidebar, 'educationalVideos.show', false) && !isEmpty(get(viewer, 'educationalVideosLevels')) &&
            <ListItemLink to={'/educational-videos'} classesItem={classes.listItem}>
              <Trans {...messages.educationalVideosNew} />
            </ListItemLink>
          }
          {get(sidebar, 'tradingVideos.show', false) && tradingVideosCondition &&
            <ListItemLink to={'/trading-videos'} classesItem={classes.listItem}>
              <Trans {...messages.tradingVideos} />
            </ListItemLink>
          }
          <ListItemLink to={'/support'} classesItem={classes.listItem} onClick={()=> logEventCustomParams('supportIntent', {
            source: 'menu',
            login: id,
            userId: get(viewer, 'id'),
          })}>
            <Trans {...messages.supportTitle} />
          </ListItemLink>
        </List>
      </div>
    )
  }
}

class MobileSidebar extends React.Component {
  static contextType = AppContext
  constructor() {
    super()
    this.state = {
      showBecomePartner: false,
    }
  }

  logOut() {
    const {viewer} = this.props
    const clientId = get(viewer, 'id')
    const params = {
      reason: `logout clicked - ${clientId}`,
      userId: clientId,
    }
    logEventCustomParams('logout', params)
    return this.context.logout()
  }

  handleOpenDialog() {
    this.setState({showBecomePartner : true})
  }

  handleCloseDialog() {
    this.setState({showBecomePartner: false})
  }

  render() {
    const {classes, loading, viewer: {locale, firstName, lastName, id, promotionsEligibility},
      viewer, appropTests, globalQuestionnaire, location,
      documents, linkedPartnerClientId, referAndEarn, referralDetails, referrals} = this.props
    const {kycStatus, tradingCentralEnabled} = viewer
    const {clientType, accounts, companyObject, themePreference} = this.context
    const {featuresConfig:{referAFriend}, common:{partnersLoyaltyNewBadgeEndDate, partnersLoyaltyTerminationDate, partnersLoyaltyAllowed, plugitAllowedClientIds},
      partnersLoyaltyProgram, showPartnerZone, hideMt4Mentions, hideReferCountries, sidebar} = config
    const {showBecomePartner} = this.state
    if (loading) {
      return null
    }

    const showPlugit = includes(plugitAllowedClientIds, toString(viewer?.id))

    const pendingVerificationsCount = getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire, documents, clientType)

    const settingsBadgeClasses = [classes.badge]
    pendingVerificationsCount < 1 && settingsBadgeClasses.push(classes.invisible)
    // const pendingReferral = find(referrals, (r) => includes(pendingStatuses, r.status))
    // const conditions = referralDetails && JSON.parse(referralDetails.conditions)
    // const activated = filter(referrals, (r) => r.status === referralStatuses.activated.value)
    // const claimed = filter(referrals, (r) => r.status === referralStatuses.claimed.value)
    // const limitReached = referrals && (activated.length + claimed.length) >= conditions.maxReferrals
    const countryDisallowNew = includes(hideReferCountries, toUpper(get(viewer, 'address.country')))
    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId
    const showReferral = !get(referAFriend, 'disabled') && referAndEarn && ((!isIbOrAffiliate || (isIbOrAffiliate && !!get(referralDetails, 'rewardsObj.pendingRewards', 0))))
      && (!countryDisallowNew || (countryDisallowNew && !!get(referralDetails, 'link'))) && !isEmpty(referrals)
    const onlyWalletAccount = hasOnlyWalletProductAccount(accounts)
    const hasIbAccount = hasIntroducingBrokerAccount(accounts)

    const badgeClasses = [classes.badge]
    const referralAwardNotification = this.props.activatedReferralAwards && !get(this.props.activatedReferralAwards,'claimedAt')
    const totalNotificationsCount = referralAwardNotification ? size(get(viewer, 'pendingNotices')) + 1 : size(get(viewer, 'pendingNotices'))
    totalNotificationsCount < 1 && badgeClasses.push(classes.invisible)
    const dueDiligences = get(viewer, 'dueDiligences')
    const dueDiligence = last(orderBy(filter(dueDiligences, (a)=> a.status !== dueDiligenceStatuses.rejected.value)))
    const showPartnerZoneConditions = ((!isEmpty(dueDiligence)) || (includes(partnersLoyaltyAllowed, toString(id)))) && showPartnerZone
    const isIb = isIBClient(clientType)
    const partnersLoyaltyAnalytics = first(get(viewer, 'partnersLoyaltyAnalytics'))
    const today = moment()
    const eligiblePrize = find(get(partnersLoyaltyAnalytics, 'partnersLoyaltyEligibility'), (prize) => moment(get(prize, 'endEligibilityDate')) > today)
    const showNewBadge = today < moment(partnersLoyaltyNewBadgeEndDate)
    const partnerLoyaltyTerminated = (today > moment(partnersLoyaltyTerminationDate)) && isEmpty(eligiblePrize)
    const isAffiliate = isAffiliateClient(clientType) && hasAffiliateAccount(accounts)
    const brand = get(companyObject, 'brandLabel')
    const tradingVideosCondition = !isEmpty(get(viewer, 'tradingVideos'))

    return (
      <PageBody
        style={{padding: 0, margin: 0}}
      >
        <Header viewer={this.props.viewer}/>
        <Divider />
        <Grid container className={classes.nameGrid} direction="column">
          <Typography variant="body2">{[firstName, lastName].join(' ')}</Typography>
          <Typography variant="body2">
            <Trans {...messages.profileInformation}
              values={{
                company: brand,
                clientId: get(viewer, 'id'),
              }} />
          </Typography>
          {accountVerification(kycStatus, classes)}
        </Grid>
        <Divider />
        <List disablePadding component="nav" className={classes.mobileHeight}>
          {!isIb && !isAffiliate && <ListItem onClick={() => this.handleOpenDialog()}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  <SvgIcon
                    viewBox='-2 -1 24 24'
                    className={classes.suitcaseIconGrey}
                    component={isDarkTheme(themePreference) ? SuitecaseIconWhite : SuitecaseIconGrey}
                  />
                </ListItemIcon>
                <ListItemText primary={<Typography  variant= 'body2' className={classes.itemWrapper}>
                  <Trans {...messages.partnerZoneLowerCase} /> </Typography> } />
              </span>
            </Grid>
          </ListItem>
          }
          {showBecomePartner && <BecomePartnerDialog open={showBecomePartner} viewer={viewer} onClose={() => this.handleCloseDialog()} /> }
          {(!onlyWalletAccount || (tradingCentralEnabled && hasIbAccount)) && !hideMt4Mentions && <ListItemLink to={'/tools'} className={classes.root}>
            <Typography variant='body2' className={classes.itemWrapper}>
              <ListItemIcon className={classes.icon}>
                {isDarkTheme(themePreference) ? <ToolsIconDark /> : <ToolsIconLight />}
              </ListItemIcon>
              <Trans {...messages.tools} />
            </Typography>
          </ListItemLink>}
          <ListItemLink to={'/settings/profile'} className={classes.listItem}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  {isDarkTheme(themePreference) ? <ProfileSettingsIconDark /> :<SvgIcon
                    viewBox='0 0 24 24'
                    component={ProfileSettingsIconLight}
                  />
                  }
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.profileAndSettingsNew} /></Typography>} />
              </span>
              <span>
                <Badge badgeContent={pendingVerificationsCount} classes={{badge: classes.customBadge}} />
              </span>
            </Grid>
          </ListItemLink>
          {showPlugit && promotionsEligibility && <ListItemLink to={'/promotions'} onClick={()=> logEventCustomParams('promotionsTabClicked', {
            source: 'Dashboard',
            brand: brand,
            userId: get(viewer, 'id'),
          })}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  {isDarkTheme(themePreference) ? <PromotionsIconDark /> : <PromotionsIconLight />}
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.promotions} /></Typography>} />
              </span>
              <span>
                <Badge badgeContent={<Trans {...messages.new} />} classes={{badge: classes.customBadge}} />
              </span>
            </Grid>
          </ListItemLink>}
          {isIb && showPartnerZoneConditions && <ListItemLink to={{pathname: '/support', state: {prevPath: location.pathname}}} className={classes.listItem}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  {isDarkTheme(themePreference) ? <SupportIconDark /> :<SvgIcon
                    viewBox='0 0 24 24'
                    component={SupportIconLight}
                  />
                  }
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.supportTitle} /></Typography>} />
              </span>
            </Grid>
          </ListItemLink>}
          <Divider />
          <ListItemLink to={'/notifications'} className={classes.root}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  {isDarkTheme(themePreference) ? <NotificationsIconDark /> : <NotificationsIconLight />}
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.notifications} /></Typography>} />
              </span>
              <span>
                <Badge badgeContent={3} classes={{badge: classes.customBadge}} />
              </span>
            </Grid>
          </ListItemLink>
          <ListItemLink to={'/change-language'}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  {isDarkTheme(themePreference) ? <LanguageIconDark /> : <LanguageIconLight />}
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.language} /></Typography>} />
              </span>
              <span><Typography variant='body2' className={classes.itemLanguage}>{languages[locale].native}</Typography></span>
            </Grid>
          </ListItemLink>
          <ListItem secondaryAction={<ThemeSelection />}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  {isDarkTheme(themePreference) ? <ThemeIconDark /> : <ThemeIconLight />}
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.darkTheme} /></Typography>} />
              </span>
            </Grid>
          </ListItem>
          {showReferral && <ListItemLink to={'/referAFriend'} onClick={()=> logEventCustomParams('referAndEarnClicked', {
            userId: get(viewer, 'id'),
          })}>
            <Trans {...messages.referAFriend} />
          </ListItemLink>}
          {get(sidebar, 'educationalVideos.show', false) && !isEmpty(get(viewer, 'educationalVideosLevels')) &&
            <ListItemLink to={'/educational-videos'}>
              <Grid className={classes.menuItemContent}>
                <span style={{display: 'flex'}}>
                  <ListItemIcon className={classes.icon}>
                    {isDarkTheme(themePreference) ? <EducationalVideosIconDark /> : <EducationalVideosIconLight />}
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.educationalVideosNew} /></Typography>} />
                </span>
              </Grid>
            </ListItemLink>
          }
          {get(sidebar, 'tradingVideos.show', false) && tradingVideosCondition &&
            <ListItemLink to={'/trading-videos'}>
              <Grid className={classes.menuItemContent}>
                <span style={{display: 'flex'}}>
                  <ListItemIcon className={classes.icon}>
                    {isDarkTheme(themePreference) ? <EducationalVideosIconDark /> : <EducationalVideosIconLight />}
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}><Trans {...messages.tradingVideos} /></Typography>} />
                </span>
              </Grid>
            </ListItemLink>
          }
          {isAffiliate && !partnerLoyaltyTerminated && partnersLoyaltyProgram && <ListItemLink to={'/loyalty-program'}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  {isDarkTheme(themePreference) ? <LoyaltyProgramIconDark /> : <LoyaltyProgramIconLight />}
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classes.itemWrapper}>
                  <Trans {...messages.loyaltyProgram} /></Typography>} />
              </span>
              <span>
                <Badge badgeContent={showNewBadge && <Trans {...messages.new} />} classes={{badge: showNewBadge ? classnames(classes.badgeNew) :classnames(classes.invisible)}} />
              </span>
            </Grid>
          </ListItemLink>}
          <ListItemLink to={'/logout'} onClick={() => this.logOut()}>
            <Grid className={classes.menuItemContent}>
              <span style={{display: 'flex'}}>
                <ListItemIcon className={classes.icon}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2' className={classnames(classes.logoutItem, classes.itemWrapper)}><Trans {...messages.logout} /></Typography>} />
              </span>
            </Grid>
          </ListItemLink>
        </List>
      </PageBody>
    )
  }
}


export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading, viewer}}) => {
      const globalQuestionnaire = get(viewer, 'globalQuestionnaire')
      const appropTests = get(viewer, 'appropTests')
      const documents = get(viewer, 'documents')

      return {
        error,
        loading,
        globalQuestionnaire,
        appropTests,
        documents,
      }
    }}),
  graphql(REFERRAL_DETAILS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      referralError: error,
      referralLoading: loading,
      referralDetails: get(data, 'viewer.referralDetails'),
      referrals: get(data, 'viewer.referralDetails.referrals'),
      activatedReferralAwards: get(data, 'viewer.referralDetails.activatedReferralAwards'),
      linkedPartnerClientId:  get(data, 'viewer.linkedPartnerClientId'),
      referAndEarn:  get(data, 'viewer.referAndEarn'),
    })
  }),
)(isMobile() ? MobileSidebar : Sidebar)
