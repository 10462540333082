import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces} from 'react-i18next'
import {localDepositorStatuses} from '@bdswiss/common-enums'
import {Loading} from '../../../Common/Loading'
import {isEmpty, get, flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import PageTitle from '../../../Common/PageTitle'
import messages from '../../../../assets/messages'
import {CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import AppContext from '../../../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom'
import LocalDepositorForm from './LocalDepositorForm'

const gridScroll = 'scroll-grid'

const styles = (theme) => ({
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px 16px 16px',
    },
  }
})

class LocalDepositor extends Component {
  static contextType = AppContext

  componentDidMount() {
    const {viewer, history} = this.props

    if (!get(viewer, 'isLocalDepositor'))
      history.push('/settings/profile')
  }

  handleClose(history) {
    return history.push('/settings/profile')
  }

  render() {
    const {classes} = this.props
    const {themePreference} = this.context
    const {t, loading, history, viewer, viewer: {localDepositorStatus}} = this.props
    if (loading || !viewer) return <Loading />
    const localDepositorComponent =
      <Grid container id={gridScroll}>
        <Grid item xs={12}>
          <PageTitle
            withoutPadding
            themePreference={themePreference}
            onBack={() => history.push('/settings/profile')}
            title={localDepositorStatus === localDepositorStatuses.pendingRegistration.value
              ? t(messages.localDepositorCaptionPendingRegistration.i18nKey, messages.localDepositorCaptionPendingRegistration.defaults)
              : t(messages.localDepositorCaption.i18nKey, messages.localDepositorCaption.defaults)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formContainer}>
          <LocalDepositorForm
            showEmail={!isEmpty(get(viewer, 'localDepositorEmail'))}
            showPhone={!isEmpty(get(viewer, 'localDepositorPhone'))}
          />
        </Grid>

      </Grid>

    return (
      <React.Fragment>
        {localDepositorComponent}
      </React.Fragment>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const clientId = get(data, 'viewer.id')
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        clientId,
        viewer,
      }
    }
  }),
)(LocalDepositor)
