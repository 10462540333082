import React from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {graphql} from 'react-apollo'
import {get, flowRight as compose, isEmpty, isNil} from 'lodash'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import FormHelperText from '@mui/material/FormHelperText'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {GDPR_OPT_IN_MARKETING_MUTATION, GDPR_DOWNLOAD_DATA_MUTATION,
  GDPR_OPT_IN_SMS_MUTATION, GDPR_DELETE_ACCOUNT_MUTATION,
  ADD_METADATA_TO_CLIENT_MUTATION, TOGGLE_HAS_HOT_ASSETS} from '../../../graphql/mutations'
import {AlertDialog} from '../../Common/Dialog'
import {logoutRequest} from '../../../common/utils/requests'
import {isIBClient, isWhiteLabel, removeSessInfoFromLocalStorage} from '../../../common/utils'
import messages from '../../../assets/messages'
import classNames from 'classnames'
import Grid from '@mui/material/Grid'
import AppContext from '../../Common/contexts/AppContext'
import {whiteLabels} from '@bdswiss/common-enums'
import SwitchButton from '../../Common/SwitchButton'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'

const styles = theme => ({
  deleteAccount: {
    color: theme.palette.red.color,
  },
  noPadding: {
    padding: 0,
  },
  infoMessage: {
    color: theme.palette.lightgreen.textColor
  },
  listItem: {
    padding: '5px 0',
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  subtitleColor: {
    color: theme.palette.secondary.main,
  },
  subtitlePadding: {
    paddingBottom: '24px',
  },
  optionsGroup: {
    paddingTop: '16px',
  },
  bottomPadding: {
    paddingBottom: '16px',
  },
  topPadding: {
    paddingTop: '16px',
  },
  sidePadding: {
    '& .MuiFormControlLabel-label': {
      [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: '16px',
    }
  },
  textStyle: {
    '& .MuiFormControlLabel-label, .MuiButton-text': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    }
  },
  errorMsg: {
    fontWeight: 400,
  },
})

class Privacy extends React.Component {
  static contextType = AppContext

  state = {
    deleteDialogOpen: false,
    deleteError: '',
    password: '',
    optInSmsError: false,
    downloadDataMessageShow: false,
    loading: false,
    optInPartnerBonusesAndCompetitionsError: false,
    optInPartnerClientRegisteredEmailError: false,
  }

  handleOptInSmsClick = e => {
    const {toggleOptInSms} = this.props
    this.clearAllErrors()
    toggleOptInSms({variables: {value: e.target.checked}})
      .then(() => this.setState({optInSmsError: false}))
      .catch(err => {
        if (err.message.indexOf('No phone') !== -1) {
          this.setState({optInSmsError: true})
        } else {
        console.log(err) // eslint-disable-line
        }
      })
  }

  handleHotAssetsClick = () => {
    const {toggleHasHotAssets, viewer} = this.props
    this.setState({loading: true})

    toggleHasHotAssets({variables: {clientId: viewer.id, hasHotAssets: !viewer.hasHotAssets}})
      .finally(() => this.setState({loading: false}))
  }

  handleDownloadDataClick = () => {
    const {downloadData} = this.props
    this.clearAllErrors()
    downloadData()
    this.setState({downloadDataMessageShow: true})
  }

  handleDeleteAccountClick = () => {
    const {deleteAccount, viewer} = this.props
    const {password, apolloClient} = this.state
    const clientId = get(viewer, 'id')
    this.setState({loading: true})
    deleteAccount({variables: {password}})
      .then((_) => {
        this.setState({deleteDialogOpen: false, loading: false})
        const params = {
          userId: get(viewer, 'id'),
          type: 'delete'
        }
        logEventCustomParams('closeProfileIntent', params)
        this.context.showNotification({
          type: 'document-upload',
          status: 'success',
          content: <Trans {...messages.deleteAccountSuccess} />,
          buttonMessage: <Trans {...messages.continue} />,
          onClose: () => {
            logoutRequest()
              .then(() => {
                const params ={
                  userId: get(viewer, 'id'),
                  type: 'delete',
                }
                logEventCustomParams('closeProfileSuccess', params)
                logEventCustomParams('logout', {
                  reason: `accountDeleted - ${clientId}`,
                  userId: clientId,
                })
                removeSessInfoFromLocalStorage()
                apolloClient && apolloClient.clearStore()
                window.location.href = '/login'
              })
              .catch(err => {
                const params = {
                  userId: get(viewer, 'id'),
                  type: 'delete',
                  message: err.message.replace('GraphQL error: ', ''),
                }
                logEventCustomParams('closeProfileError', params)
              })
          }
        })
      })
      .catch(err => {
        const params = {
          userId: get(viewer, 'id'),
          message: err.message.replace('GraphQL error: ', ''),
        }
        logEventCustomParams('closeProfileReject', params)
        this.setState({deleteError: err.message.replace('GraphQL error: ', ''), loading: false})
      })
  }

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value,
      deleteError: '',
    })
  }

  cancelDeleteAccount() {
    const {viewer} = this.props
    const params = {
      userId: get(viewer, 'id'),
      type: 'delete',
    }
    logEventCustomParams('closeProfileCancel', params)
    this.setState({deleteDialogOpen: false})
  }

  clearAllErrors = () => this.setState({
    optInPartnerBonusesAndCompetitionsError: false,
    optInPartnerClientRegisteredEmailError: false,
    optInSmsError: false
  })

  handleAddMetadataToClient = (e, key, errorKey) => {
    const {addMetadataToClient} = this.props
    this.clearAllErrors()
    addMetadataToClient({variables: {metadata: JSON.stringify({[key]: e.target.checked})}})
      .then((_) => {
        this.setState({[errorKey]: false})
      })
      .catch((_) => {
        this.setState({[errorKey]: true})
      })
  }

  partnerNPOptions = () => {
    const {classes, t, viewer: {partnerBonusesAndCompetitions, partnerClientRegisteredEmail}} = this.props
    return <div className={classes.optionsGroup}>
      <Typography variant='body1' className={classNames(classes.text, classes.subtitleColor, classes.subtitlePadding)}>
        <Trans {...messages.partner} />
      </Typography>
      <SwitchButton
        name='partnerBonusesPromotionsCompetitionsSwitch'
        classes={{root: classNames(classes.bottomPadding, classes.textStyle, classes.sidePadding)}}
        iOSSwitch={true}
        checked={partnerBonusesAndCompetitions}
        onChange={(e) => this.handleAddMetadataToClient(e, 'partnerBonusesAndCompetitions', 'optInPartnerBonusesAndCompetitionsError')}
        label={t(messages.partnerBonusPromotionsCompetitionsPrivacy.i18nKey, messages.partnerBonusPromotionsCompetitionsPrivacy.defaults)}
      />
      <SwitchButton
        name='newClientRegistrationEmailSwitch'
        classes={{root: classNames(classes.bottomPadding, classes.textStyle, classes.sidePadding)}}
        iOSSwitch={true}
        checked={isNil(partnerClientRegisteredEmail) || partnerClientRegisteredEmail}
        onChange={(e) => this.handleAddMetadataToClient(e, 'partnerClientRegisteredEmail', 'optInPartnerClientRegisteredEmailError')}
        label={t(messages.partnerClientRegistrationEmailPrivacy.i18nKey, messages.partnerClientRegistrationEmailPrivacy.defaults)}
      />
    </div>
  }

  partnerNPPrivacyErrors = () => {
    const {classes} = this.props
    const {optInPartnerBonusesAndCompetitionsError, optInPartnerClientRegisteredEmailError} = this.state
    if (optInPartnerBonusesAndCompetitionsError) {
      return (
        <ListItem disableGutters className={classNames(classes.listItem, classes.bottomPadding)}>
          <Typography color='error' variant='body1' className={classes.errorMsg}>
            <Trans {...messages.connectionError} />
          </Typography>
        </ListItem>
      )
    }
    if (optInPartnerClientRegisteredEmailError) {
      return (
        <ListItem disableGutters className={classNames(classes.listItem, classes.bottomPadding)}>
          <Typography color='error' variant='body1' className={classes.errorMsg}>
            <Trans {...messages.connectionError} />
          </Typography>
        </ListItem>
      )
    }
    return null
  }

  handleOptInMarketing = (e) => {
    const {toggleOptInMarketing} = this.props
    this.clearAllErrors()
    toggleOptInMarketing({variables: {value: e.target.checked}}).then().catch()
  }

  handleDeleteAccount = () => {
    this.clearAllErrors()
    this.setState({deleteDialogOpen: true})
  }

  render() {
    const {classes, t, viewer: {optInMarketing, optInSms, whiteLabel, hasHotAssets}} = this.props
    const {deleteDialogOpen, password, deleteError, optInSmsError, downloadDataMessageShow, loading} = this.state
    const {clientType} = this.context
    const isPartnerNP = isIBClient(clientType)
    let hideOptInMarketing
    if (isWhiteLabel()) {
      hideOptInMarketing = whiteLabels[whiteLabel].disableMarketingCommunications
    }
    return (
      <Grid container>
        <Grid item xs={12} sm={8}>
          <div>
            {!hideOptInMarketing &&  <SwitchButton
              id="optInMarketingSwitch"
              checked={optInMarketing}
              onChange={e => this.handleOptInMarketing(e)}
              classes={{root: classNames(classes.bottomPadding, classes.textStyle, classes.sidePadding)}}
              iOSSwitch={true}
              label={t(messages.optInMarketing.i18nKey, messages.optInMarketing.defaults)}
            />}
            <SwitchButton
              id="optInSms"
              checked={optInSms}
              onChange={e => this.handleOptInSmsClick(e)}
              classes={{root: classNames(classes.bottomPadding, classes.textStyle, classes.sidePadding)}}
              iOSSwitch={true}
              label={t(messages.optInSms.i18nKey, messages.optInSms.defaults)}
            />
            <SwitchButton
              id="hotAssetsToggleText"
              checked={hasHotAssets}
              onChange={e => this.handleHotAssetsClick(e)}
              classLabel={{root: classes.switchRoot}}
              iOSSwitch={true}
              label={t(messages.hotAssetsToggleText.i18nKey, messages.hotAssetsToggleText.defaults)}
            />
          </div>
          {isPartnerNP && this.partnerNPOptions()}
          <List>
            {isPartnerNP && this.partnerNPPrivacyErrors()}
            {optInSmsError &&
              <ListItem disableGutters className={classNames(classes.listItem, classes.bottomPadding)}>
                <Typography color="error" variant='body1' className={classes.errorMsg}>
                  <Trans {...messages.noPhone} />
                </Typography>
              </ListItem>
            }

            <ListItem disableGutters className={classNames(classes.listItem, classes.topPadding, classes.bottomPadding, classes.textStyle)}>
              <Button
                color="primary"
                onClick={this.handleDownloadDataClick}
                className={classNames(classes.noPadding)}
              >
                <Trans {...messages.gdprExportData} />
              </Button>
            </ListItem>

            {downloadDataMessageShow &&
              <ListItem disableGutters className={classNames(classes.listItem, classes.bottomPadding)}>
                <Typography className={classes.infoMessage} variant='body1'>
                  <Trans {...messages.gdprExportDataSuccess} />
                </Typography>
              </ListItem>}

            <ListItem disableGutters className={classNames(classes.listItem, classes.bottomPadding, classes.textStyle)}>
              <Button
                className={classNames(classes.noPadding, classes.deleteAccount)}
                onClick={() => this.handleDeleteAccount()}
              >
                <Trans {...messages.gdprDeleteData} />
              </Button>
              <AlertDialog
                open={deleteDialogOpen}
                title={t(messages.gdprDeleteDataConfirmTitle.i18nKey, messages.gdprDeleteDataConfirmTitle.defaults)}
                agreeText={t(messages.delete.i18nKey, messages.delete.defaults)}
                onAgree={this.handleDeleteAccountClick}
                disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
                onDisagree={() => this.cancelDeleteAccount()}
                buttonDisabled={loading || isEmpty(password)}
                hideProgressBar={isEmpty(password)}
              >
                <FormControl variant="standard" error={deleteError !== ''}>
                  <InputLabel htmlFor="confirm-delete-account">
                    <Trans {...messages.enterPassword} />
                  </InputLabel>
                  <Input id="confirm-delete-account"
                    value={password}
                    type="password"
                    onChange={this.handlePasswordChange} />
                  {deleteError !== '' &&
                    <FormHelperText id="delete-account-error-text">{deleteError}</FormHelperText>}
                </FormControl>
              </AlertDialog>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(GDPR_OPT_IN_MARKETING_MUTATION, {
    name: 'toggleOptInMarketing',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(TOGGLE_HAS_HOT_ASSETS, {
    name: 'toggleHasHotAssets',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(GDPR_OPT_IN_SMS_MUTATION, {
    name: 'toggleOptInSms',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(GDPR_DOWNLOAD_DATA_MUTATION, {
    name: 'downloadData',
  }),
  graphql(GDPR_DELETE_ACCOUNT_MUTATION, {
    name: 'deleteAccount'
  }),
  graphql(ADD_METADATA_TO_CLIENT_MUTATION, {
    name: 'addMetadataToClient',
    options: () => ({
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }),
  }),
)(Privacy)
