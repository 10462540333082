import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import {frontends, rpnpayUsdtOptions} from '@bdswiss/common-enums'
import {debounce, isEmpty, values, flowRight as compose, map, get} from 'lodash'
import SelfPostingForm from './SelfPostingForm'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
})

const validators = {
  usdtOption: (value) => {
    if (!value) return messages.requiredField
  },
  walletAddress: (value) => {
    if (!value) return messages.requiredField
  }
}

const initialState = {
  form: {walletAddress: '', usdtOption: ''},
  errors: {},
  creatingDeposit: false,
  doSubmitForm: false,
  submitDisabled: false,
}

class RPNPayUSDTProvider extends Component {
  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    this.state = initialState
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    const {account, providerProperties: {provider, paymentKey, id: paymentOptionId}, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props
    const {form: {walletAddress, usdtOption}} = this.state

    if (!this.formIsValid()) return

    this.setState({creatingDeposit: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        walletAddress,
        usdtOption,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const parsedFormData = JSON.parse(payment.url)
        this.setState({formData: parsedFormData, doSubmitForm: true, creatingDeposit: false, submitDisabled: true})
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, canProceed} = this.props
    const {form: {walletAddress, usdtOption}, errors, creatingDeposit, formData, doSubmitForm, submitDisabled} = this.state

    return (
      <React.Fragment>
        <SelfPostingForm formData={formData} doSubmitForm={doSubmitForm} />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="usdtOption"><Trans {...messages.usdtOption} /></InputLabel>
              <Select
                variant="standard"
                value={usdtOption}
                onChange={(e) => this.handleChange('usdtOption', e.target.value)}
                inputProps={{
                  name: 'usdtOption',
                  id: 'usdtOption',
                }}>
                {map(rpnpayUsdtOptions, o => <MenuItem value={o.value} key={o.key}>{o.label}</MenuItem>)}
              </Select>
              {
                errors.usdtOption &&
                <FormHelperText className={classes.error}><Trans {...errors.usdtOption} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="walletAddress">
                <Trans {...messages.walletAddress} />
              </InputLabel>
              <Input
                id="walletAddress"
                type="text"
                error={!!errors.walletAddress}
                value={walletAddress}
                onChange={(e) => this.handleChange('walletAddress', e.target.value)}
              />
              {
                errors.walletAddress &&
                <FormHelperText className={classes.error}><Trans {...errors.walletAddress} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <PaymentActionButton
            loading={creatingDeposit}
            disable={canProceed || !this.formIsValid() || submitDisabled}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

RPNPayUSDTProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withNamespaces(),
  withCreateDeposit
)(RPNPayUSDTProvider)
