import React, {Component, forwardRef} from 'react'
import classNames from 'classnames'
import Select from 'react-select'
import Typography from '@mui/material/Typography'
import NoSsr from '@mui/material/NoSsr'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import {find, flowRight as compose, get, filter, first} from 'lodash'
import {getAccountLabel, getAccountSubtype, isIntroducingBrokerAccount} from '../../common/utils/accounts'
import {getFormattedAmount} from '../../common/utils/general'

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
    marginTop:-3,
  },
  placeholder: {
    position: 'absolute',
    [theme.direction === 'rtl' ? 'right' : 'left']: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  accountsOption: {
    width: '100%',
    display: 'flex',
    gap: '10px',
  },
  account: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    '& span': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
    },
  },
  grey: {
    color: theme.palette.secondary.main
  },
  spanBalance:{
    position: 'absolute',
    [theme.direction === 'rtl' ? 'left' : 'right']: 50
  },
  spanAccountBalanceRight:{
    position: 'relative',
    [theme.direction === 'rtl' ? 'left' : 'right']:0,
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  spanBalanceRight:{
    position: 'absolute',
    [theme.direction === 'rtl' ? 'left' : 'right']: 16
  },
  fontLight:{
    fontWeight: 300
  },
  menuItemRoot: {
    paddingTop: 10,
    paddingBottom: 10
  },
})

const InputComponent = forwardRef((props, ref) => <div ref={ref} {...props} />)

function Control(props) {
  return (
    <TextField
      variant="standard"
      fullWidth
      inputRef={props.ref}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps} />
  )
}

function Option(props) {
  const {selectProps: {accounts, clients, hiddenBalance, localDepositorSubscriptions}, data: {locale, t}} = props
  const suggestions = accounts || clients || localDepositorSubscriptions
  const option = accounts && (suggestions.filter(suggestion => suggestion.id === props.data.value))[0]
  const optionClient = clients && (suggestions.filter(suggestion => suggestion.id === props.data.value))[0]
  const optionLocalDepositor = localDepositorSubscriptions && (filter(suggestions, (suggestion) => suggestion.subscribedMemberId === props.data.value))[0]
  const accountsOption = accounts && option
  const formattedBalance = accountsOption && getFormattedAmount({locale, amount: option.balance, currency: option.currency})
  const hasBalance = accountsOption && (option.balance || option.balance >= 0)
  const ldOption = localDepositorSubscriptions && optionLocalDepositor

  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      classes={{root: props.selectProps.classes.menuItemRoot}}
      {...props.innerProps}
    >
      {(ldOption)?
        <span className={props.selectProps.classes.fontLight}>{ldOption?.subscribedMemberFullName}
          <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanBalance)}>
            <Trans {...messages.login} />: {ldOption?.subscribedMemberId}
          </span>
        </span>
        :(accountsOption)
          ?<span className={classNames(props.selectProps.classes.accountsOption, props.selectProps.classes.fontLight)}>
            <span className={props.selectProps.classes.account}>
              <span>{getAccountLabel(option, locale, getAccountSubtype(get(option,'accountSubtype')), t)}
                <span className={props.selectProps.classes.grey}> - {get(option, 'remoteId') || get(option, 'id')}</span>
              </span>
            </span>
            {!hiddenBalance && hasBalance && <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanAccountBalanceRight)}>{formattedBalance}</span>}
          </span>
          :(clients && optionClient)
            ?<span className={props.selectProps.classes.fontLight}>{optionClient.firstName} {optionClient.lastName}
              <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanBalanceRight)}>
                <Trans {...messages.login} />: {optionClient.id}
              </span>
            </span>
            :<span className={props.selectProps.classes.fontLight}>{props.children}</span>
      }
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      variant={'body2'}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  const {selectProps: {accounts, clients, localDepositorSubscriptions, hiddenBalance}, locale, data: {t}} = props
  const suggestions = accounts || clients || localDepositorSubscriptions
  const option = accounts && (suggestions.filter(suggestion => suggestion.id === props.data.value))[0]
  const optionClient = clients && (suggestions.filter(suggestion => suggestion.id === props.data.value))[0]
  const optionLocalDepositor = localDepositorSubscriptions && (filter(suggestions, (suggestion) => suggestion.subscribedMemberId === props.data.value))[0]
  const accountsOption = accounts && option
  const formattedBalance = accountsOption && getFormattedAmount({locale, amount: option.balance, currency: option.currency})
  const hasBalance = accountsOption && (option.balance || option.balance >= 0)
  const ldOption = localDepositorSubscriptions && optionLocalDepositor
  return (
    <Typography className={props.selectProps.classes.singleValue} variant={'body2'} {...props.innerProps}>
      {(ldOption)?
        <span className={props.selectProps.classes.fontLight}>{ldOption?.subscribedMemberFullName}
          <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanBalance)}>
            <Trans {...messages.login} />: {ldOption?.subscribedMemberId}
          </span>
        </span>
        :(accountsOption)
          ?<span className={props.selectProps.classes.fontLight}>{getAccountLabel(option, locale, getAccountSubtype(get(option,'accountSubtype')), t)}
            <span className={props.selectProps.classes.grey}> - {(isIntroducingBrokerAccount(option) && first(get(option, 'ibId', []))) || option.remoteId || props?.selectProps?.clientId || option?.id}</span>
            {!hiddenBalance && hasBalance && <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanBalance)}>{formattedBalance}</span>}
          </span>
          :(clients && optionClient)
            ?<span className={props.selectProps.classes.fontLight}>{optionClient.firstName} {optionClient.lastName}
              <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanBalance)}>
                <Trans {...messages.login} />: {optionClient.id}
              </span>
            </span>
            :(props.children==='Self')?<span className={props.selectProps.classes.fontLight}>{props.children}</span>:''
      }
    </Typography>
  )
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      variant={'body2'}
      {...props.innerProps}
    >
      {<Trans {...messages.noOptions}/>}
    </Typography>
  )
}

export class AccountSearchSelect extends Component {

  findValue(options, value) {
    return find(options,(option) => option.value === value)
  }

  render() {
    const {classes, accounts, clients, localDepositorSubscriptions, label, locale, self, errors, value, hiddenBalance, disabled, t, clientId} = this.props
    const suggestions = clients || accounts || localDepositorSubscriptions || []
    const options = suggestions.map(suggestion => ({
      value: suggestion.__typename === 'LocalDepositorSubscription' ? suggestion.subscribedMemberId : suggestion.id,
      label: (localDepositorSubscriptions) ? `${suggestion.subscribedMemberFullName} ${suggestion.subscribedMemberId || suggestion.localDepositorId}` : (accounts) ?`${getAccountLabel(suggestion, locale, getAccountSubtype(get(suggestion,'accountSubtype')), t)} ${(isIntroducingBrokerAccount(suggestion) && first(get(suggestion, 'ibId', []))) || suggestion.remoteId} ${suggestion.balance}`
        :`${suggestion.firstName} ${suggestion.lastName} ${suggestion.id}`,
      t,
      locale,
    }))
    if (self) options.push({value: 'Self', label:'Self'})

    const components = {
      Control,
      Menu,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
    }

    return <div className={classes.root}>
      <NoSsr>
        <Select
          accounts={accounts}
          clients={clients}
          localDepositorSubscriptions={localDepositorSubscriptions}
          locale={locale}
          classes={classes}
          options={options}
          components={components}
          value={this.findValue(options,value) || ''}
          onChange={(e) => {
            this.props.handleAccountChange(this.props.name,e.value)
          }}
          placeholder={(value)?'':`${label}${this.props.removeRequired ? '' : ' *'}`}
          textFieldProps={{
            label: (!value)?' ':`${label}${this.props.removeRequired ? '' : ' *'}`,
            InputLabelProps: {
              shrink: true
            },
            error:errors[this.props.name],
            disabled: disabled
          }}
          hiddenBalance={hiddenBalance}
          clientId={clientId}
        />
      </NoSsr>
    </div>
  }
}

export default compose(withStyles(styles, {withTheme: true}), withNamespaces())(AccountSearchSelect)
