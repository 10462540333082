import React, {Component} from 'react'
import {flowRight as compose, endsWith, includes, get} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces} from 'react-i18next'
import AppContext from './contexts/AppContext'
import config from '../../config'
import LiveChat from 'react-livechat'
import {isMobile, addClassToElement, removeClassToElement, isDarkTheme, getUrlParam} from '../../common/utils'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

class LivechatBubble extends Component {
  static contextType = AppContext
  componentDidMount() {
    const {themePreference} = this.context

    const checkExist = setInterval(() => {
      const element = document.getElementById('chat-widget')
      if (element) {
        const parent = element.parentNode
        const child = document.createElement('span')
        child.setAttribute('id', 'closeLiveChat')
        if (!includes(get(window, 'location.pathname'), '/trade/')) {
          child.setAttribute('class', 'closeLiveChat displayNone')
        } else {
          child.setAttribute('class', 'closeLiveChat')
          isDarkTheme(themePreference) ? addClassToElement('closeLiveChat', 'closeLiveChatDark') : removeClassToElement('closeLiveChat', 'closeLiveChatDark')
        }
        child.onclick = function() {
          addClassToElement('chat-widget-container', 'displayNone')
        }
        parent && parent.appendChild(child)
        clearInterval(checkExist)
      }
    }, 100)
  }

  componentWillUnmount() {
    isMobile() && window.liveChat && window.LC_API.hide_chat_window()
  }

  render() {
    const {lng: locale, fullName, email, openLiveChat, hideLiveChat} = this.props
    const {featuresConfig: {livechat}, common: {autoTestEmails}} = config
    const {themePreference} = this.context

    if (hideLiveChat) return

    const autoTestEmailsEndsWith = get(autoTestEmails, 'endsWith')
    const autoTestEmailsContains = get(autoTestEmails, 'contains')

    let automationEmail = false
    if (window.liveChat && ((autoTestEmailsEndsWith && endsWith(email, autoTestEmailsEndsWith))
      || (autoTestEmailsContains && includes(email, autoTestEmailsContains) && endsWith(email, '@bdswiss.com')))) {
      automationEmail = true
    }

    if (openLiveChat) {
      window.liveChat && window.LC_API.open_chat_window()
    }

    if (includes(get(window, 'location.pathname'), '/trade/')) {
      removeClassToElement('closeLiveChat', 'displayNone')
      isDarkTheme(themePreference) ? addClassToElement('closeLiveChat', 'closeLiveChatDark') : removeClassToElement('closeLiveChat', 'closeLiveChatDark')
    } else {
      addClassToElement('closeLiveChat', 'displayNone')
      removeClassToElement('chat-widget-container', 'displayNone')
    }

    return <React.Fragment>
      {!automationEmail && livechat && <LiveChat
        license={Number(livechat.key)}
        group={livechat[locale] || livechat['en']}
        visitor={{name: fullName, email: email}}
        chatBetweenGroups={false}
        onChatLoaded = {(ref) => {
          typeof ref !== 'string' && (ref.on_after_load = function () {
            const chatSelector = document.getElementById('chat-widget-container')
            chatSelector.classList.add('livechat-bubble')
            window.liveChat = true
            logEventCustomParams('chatIntent', {userId: get(this.context, 'clientId')})
            if (getUrlParam('showLiveChat') === 'true') {
              window.LC_API?.open_chat_window() /* eslint-disable-line */
            }
          })
        }}
        onChatWindowMinimized = {()=> {
          const chatSelector = document.getElementById('chat-widget-container')
          chatSelector.classList.add('livechat-bubble')
          includes(get(window, 'location.pathname'), '/trade/') && removeClassToElement('closeLiveChat', 'displayNone')
        }}
        onChatWindowOpened= {()=> {
          const chatSelector = document.getElementById('chat-widget-container')
          chatSelector.classList.remove('livechat-bubble')
          addClassToElement('closeLiveChat', 'displayNone')
          logEventCustomParams('chatStarted', {userId: get(this.context, 'clientId')})
        }}
      />
      }
    </React.Fragment>
  }
}

export default compose(
  withStyles({withTheme: true}),
  withNamespaces(),
)(LivechatBubble)
