import React, {Component} from 'react'
import {Button, Card, Grid, Typography} from '@mui/material'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import classNames from 'classnames'
import CustomTooltip from '../Common/CustomTooltip'
import HelpIcon from '@mui/icons-material/HelpOutline'
import {isMobile} from '../../common/utils'

export class PartnerStatus extends Component {
  render() {
    const {t, classes, partnerStatus, campaignName, showUpgrade, pendingCurrency, unpaidComm, selectedAccount,
      toIBPortal, openShowUpgrade} = this.props
    return (
      <Card classes={{root: classes.cardHover}} sx={{
        '& .MuiPaper-root': {
          display: 'flex',
        }
      }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={isMobile() ? 6 : 3}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='caption' className={classes.inline}><Trans {...messages.yourStatus} /></Typography>
                <CustomTooltip
                  title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.partnerStatusTooltip.i18nKey, messages.partnerStatusTooltip.defaults)}</Typography>}
                  placement={'top'}
                >
                  <HelpIcon className={classes.icon} onClick={() => this.setState({partnerStatusTooltip: true})}/>
                </CustomTooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' className={classes.inline}>
                  {partnerStatus}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isMobile() ? 6 : 3}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='caption' className={classes.inline}><Trans {...messages.campaign} /></Typography>
                <CustomTooltip
                  title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.campaignTooltip.i18nKey, messages.campaignTooltip.defaults)}</Typography>}
                  placement={'top'}
                >
                  <HelpIcon className={classes.icon} onClick={() => this.setState({campaignTooltip: true})}/>
                </CustomTooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' className={classes.inline}>{campaignName}</Typography>
                {showUpgrade &&  <Typography variant='body1' onClick={() => openShowUpgrade({updgradeDialog: true})}
                  className={classNames(classes.link, classes.subLink)}><Trans {...messages.upgrade} />
                </Typography>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isMobile() ? 6 : 3}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='caption' className={classes.inline}><Trans {...messages.pending} /></Typography>
                <CustomTooltip
                  title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.pendingTooltip.i18nKey, messages.pendingTooltip.defaults)}</Typography>}
                  placement={'top'}
                >
                  <HelpIcon className={classes.icon} onClick={() => this.setState({openStatus: true})}/>
                </CustomTooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  {`${pendingCurrency} ${unpaidComm}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isMobile() ? 6 : 3} className={classes.goToPortal}>
            <Button
              onClick={()=> toIBPortal(selectedAccount)}
              variant="contained"
              size={isMobile() ? 'small' : 'medium'}
              color="primary"
            >
              <Trans {...messages.goToPortal} />
            </Button>
          </Grid>
        </Grid>
      </Card>
    )
  }
}
