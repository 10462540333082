import React, {Component} from 'react'
import {flowRight as compose, map} from 'lodash'
import {Paper, Typography} from '@mui/material'
import {withStyles} from '@mui/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import CircleIcon from '@mui/icons-material/Circle'
import {containerQueryBreakpointMappings} from './utils'

const styles = theme => ({
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    background: ({item}) => item.background,
    border: ({isDark}) => `1px solid ${isDark ? theme.palette.lightgrey.color : '#EBEBEB'}`,
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    height: 'auto',
    maxHeight: ({item}) => item.maxHeight,
    cursor: 'pointer',
  },
  content: {
    padding: theme.spacing(3),
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    [theme.breakpoints.down(containerQueryBreakpointMappings.md.max).replace('@media', '@container')]: {
      padding: theme.spacing(3),
      maxWidth: '75%',
    },
    [theme.breakpoints.down(containerQueryBreakpointMappings.sm.max).replace('@media', '@container')]: {
      padding: theme.spacing(2),
      maxWidth: '65%',
    },
    [theme.breakpoints.down(containerQueryBreakpointMappings.xs.max).replace('@media', '@container')]: {
      padding: theme.spacing(2),
      maxWidth: '95%',
    },
  },
  noMargin: {
    margin: 0,
  },
  contentColor: {
    color: theme.palette.primary.contrastText,
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.18px',
    [theme.breakpoints.down(containerQueryBreakpointMappings.sm.max).replace('@media', '@container')]: {
      fontSize:'16px',
      lineHeight:'24px',
      letterSpacing: '0.5px',
    },
  },
  message: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    [theme.breakpoints.down(containerQueryBreakpointMappings.sm.max).replace('@media', '@container')]: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  imgBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage: ({item}) => `url('${item.imageSrc}')`,
    backgroundSize: ({item}) => (`${item.resolutions.default.width}px ${item.resolutions.default.height}px`),
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)',
    [theme.direction === 'rtl' ? 'left' : 'right']: ({item}) => (`${item.resolutions.default.spacing}px`),
    [theme.breakpoints.between(containerQueryBreakpointMappings.md.min, containerQueryBreakpointMappings.lg.min).replace('@media', '@container')]: {
      [theme.direction === 'rtl' ? 'right' : 'left']: ({item}) => (`${item.resolutions.md.spacing}px`),
      backgroundSize: ({item}) => (`${item.resolutions.md.width}px ${item.resolutions.md.height}px`),
    },
    [theme.breakpoints.between(containerQueryBreakpointMappings.sm.min, containerQueryBreakpointMappings.md.min).replace('@media', '@container')]: {
      [theme.direction === 'rtl' ? 'right' : 'left']: ({item}) => (`${item.resolutions.sm.spacing}px`),
      backgroundSize: ({item}) => (`${item.resolutions.sm.width}px ${item.resolutions.sm.height}px`),
    },
    [theme.breakpoints.between(containerQueryBreakpointMappings.xs.min, containerQueryBreakpointMappings.sm.min).replace('@media', '@container')]: {
      display: 'none',
    },
  },
})

export class PromotionalBannerSlide extends Component {
  handleOnClick = (item) => {
    const {isMoving} = this.props
    if (isMoving) return
    window.open(item.link, '_blank', 'noreferrer')
  }

  render() {
    const {classes, item} = this.props
    return (
      <Paper
        key={`item_${item.key}`}
        className={classes.paper}
        elevation={0}
        onClick={() => this.handleOnClick(item)}
      >
        <div className={classes.content}>
          <Typography variant='h3' className={classNames(classes.noMargin, classes.contentColor, classes.title)}>
            <Trans {...messages[item.titleKey]}/>
          </Typography>
          <Typography variant='body1' className={classNames(classes.contentColor, classes.message)}>
            {map(item.content.descKeys, ((key, index) => (
              <span className={classes.list}  key={index}>
                {item.content.isList && <CircleIcon style={{fontSize: 2.5}}/>}
                <Trans {...messages[key]} />
              </span>
            )))}
          </Typography>
        </div>
        <div className={classes.imgBackground} />
      </Paper>
    )
  }
}

PromotionalBannerSlide.propTypes = {
  item: PropTypes.object.isRequired,
  isDark: PropTypes.bool.isRequired,
  isMoving: PropTypes.bool.isRequired,
}

export default compose(withStyles(styles, {withTheme: true}))(PromotionalBannerSlide)
