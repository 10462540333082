import React, {useState, useCallback, useContext, useRef, useEffect} from 'react'
import {useQuery, useMutation} from 'react-apollo'
import {get, some, pick, find, defaults, omit} from 'lodash'
import Typography from '@mui/material/Typography'
import {Trans, withNamespaces} from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import messages from '../../assets/messages'
import styled from '@mui/system/styled'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import SvgIcon from '@mui/material/SvgIcon'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import {fnsLocales} from '../../common/utils/uioptions'
import 'react-image-lightbox/style.css'
import LoadingButton from '../Common/LoadingButton'
import {countries} from '@bdswiss/common-enums'
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import * as dateFnsLocales from 'date-fns/locale'
import AppContext from '../Common/contexts/AppContext'
import moment from 'moment'
import {validateDate} from '../../common/utils/validations'
import {detectTimeFormat, isMobile} from '../../common/utils'
import {CREATE_EVENT_REQUEST_FORM} from '../../graphql/mutations'
import {PARTNER_GALLERY_QUERY} from '../../graphql/queries'
import Loading from '../Common/Loading'
import Images from '../Common/Images'
import {ReactComponent as GalleryIcon} from '../../assets/images/events-gallery-link-icon.svg'
import Lightbox from 'react-image-lightbox'
import config from '../../config'
import NotificationBar from '../Common/NotificationBar'
import TextField from '../Common/TextField'
import Select from '../Common/Select'
import CountriesSelectV2 from '../Common/CountriesSelectV2'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'

const LoadingButtonWrap = styled((props) => <Grid item xs={12} {...props}/>)(({theme}) => ({
  justifyContent: 'center',
  display: 'flex',
  '& .MuiButton-root': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
}))

const Header = styled((props) => <Typography variant={'h3'} {...props}/>)(({theme}) => ({
  lineHeight: '32px',
  fontWeight: 500,
  letterSpacing: 0.18,
  margin: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
}))

const Intro = styled(Typography)(({theme}) => ({
  lineHeight: '24px',
  letterSpacing: '0.5px',
  fontWeight: 400,
  marginTop: -8,
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
  },
}))

const List = styled('ul')(({theme}) => ({
  color: theme.palette.primary.main,
  margin: 0,
  padding: 0,
}))

const ListItem = styled('li')(({theme}) => ({
  background: `url(${Images['star-outline.svg']}) no-repeat left center`,
  padding: `4px 0px 0px ${theme.spacing(4)}`,
  listStyle: 'none',
  margin: `0 0 ${theme.spacing(3)}`,
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1),
  },
  ':last-child': {
    marginBottom: 0,
  },
  '& p': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
}))

const EventsArchiveLink = styled(Link)(({theme}) => ({
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.5px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  '& svg': {
    margin: '0 0 0 8px',
    position: 'relative',
    top: 7,
  },
}))

const EventsArchiveButton = styled(Button)(({theme}) => ({
  color: theme.palette.text.primary,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
  }
}))

const ImageEvent = styled('img')(({theme}) => ({
  width: '100%',
  borderRadius: 6,
  ':hover': {
    cursor: 'pointer',
  },
}))

const useStyles = makeStyles((theme) => ({
  eventsGalleryWrap: {
    '& img': {
      borderRadius: 6,
    },
  },
}))

const handleEventArchiveClick = () => {
  window.open(`${get(config, 'weblinks.websiteUrl', '').replace(/\/$/, '')}/forex-events`, '_blank')
}

const validateDateTimeFormat = (date) => {
  const eventDateFormat =  date && validateDate(date, true)
  return date && moment(date, eventDateFormat).format('YYYY-MM-DD HH:mm')
}

const is12hTimeFormat = detectTimeFormat() === '12-hour'
const countryList= Object.values(countries)
  .filter((country) => !country.forbidden && !country.hidden)
  .map((country) => ({
    value: country.value.toLowerCase(),
    label: country.label,
  }))

const requiredFields = ['country', 'guests', 'expectedDeposits', 'startDateTime', 'endDateTime']

export const Events = (props) => {
  const [activeIndexEventsGalleryImage, setActiveIndexEventsGalleryImage] = useState(0)
  const [showEventsGallery, setShowEventsGallery] = useState(false)
  const [formState, setFormState] = useState({values: {}, errors: {}, status: ''})
  const [createEventRequestForm, createEventRequestFormResult] = useMutation(CREATE_EVENT_REQUEST_FORM)
  const {loading: loadingPartnerGallery, data: partnerGalleryData} = useQuery(PARTNER_GALLERY_QUERY, {fetchPolicy: 'network-only', variables: {limit: 4}})
  const {clientId, locale, country} = useContext(AppContext)
  const notificationContainerRef = useRef(null)
  const {t} = props
  const classes = useStyles()
  const partnerGallery = get(partnerGalleryData, ['partnerGallery'], [])
  const activePartnerGalleryImage = get(partnerGallery, [activeIndexEventsGalleryImage])
  const disabledSubmit = createEventRequestFormResult?.loading || some(formState.errors) || some(defaults(pick(
    formState.values, requiredFields), requiredFields.reduce((acc, field) => ({...acc, [field]: null}), {})), (value) => !value)

  const handlePreviousSlideEventsGallery = useCallback(() => {
    setActiveIndexEventsGalleryImage((prevIndex) => (prevIndex + partnerGallery.length - 1) % partnerGallery.length)
  }, [partnerGallery.length])

  const handleNextSlideEventsGallery = useCallback(() => {
    setActiveIndexEventsGalleryImage((prevIndex) => (prevIndex + 1) % partnerGallery.length)
  }, [partnerGallery.length])

  const handlePreviewClickEventsGallery = useCallback((e) => {
    const index = e.target?.dataset?.index
    index && setActiveIndexEventsGalleryImage(Number(index))
    setShowEventsGallery(true)
  }, [])

  const handleCloseEventsGallery = useCallback(() => {
    setShowEventsGallery(false)
  }, [])

  const handleFormFieldChange = useCallback((e, context = {}) => {
    const {name, value} = e.target
    const {endDateTime} = formState.values
    const shouldBeResetEndDateTimeField = name === 'startDateTime' && value && endDateTime && moment(value).isSameOrAfter(endDateTime)

    setFormState((state) => ({
      ...state,
      values: {...(shouldBeResetEndDateTimeField ? omit(state.values, 'endDateTime') : state.values), [name]: value},
      errors: {...(shouldBeResetEndDateTimeField ? omit(state.errors, 'endDateTime') : state.errors), [name]: context?.validationError || (requiredFields.includes(name) && !value)},
    }))
  }, [formState.values])

  const resetFormFieldHandle = useCallback((name) => {
    setFormState((state) => ({
      ...state,
      values: {...state.values, [name]: null},
      errors: {
        ...state.errors,
        ...(requiredFields.includes(name) && {[name]: true}),
      },
    }))
  }, [])

  const handleSendEventForm = useCallback(() => {
    if (!disabledSubmit) {
      const {country: countryField, ...values} = formState.values
      createEventRequestForm({variables: {type: 'eventsForm', clientId, country: get(find(countryList, (a) => [a.key, a.value].includes(countryField)), 'label'), ...values}})
        .then(() => {
          setFormState((state) => ({...state, status: 'success', values: {country}, errors: {}}))
        })
        .catch(() => setFormState((state) => ({...state, status: 'error'})))
        .finally(() => notificationContainerRef.current?.scrollIntoView({behavior: 'smooth'}))
    }
  }, [clientId, country, createEventRequestForm, disabledSubmit, formState.values, notificationContainerRef])

  useEffect(() => {
    if (!formState.values?.country) {
      handleFormFieldChange({target: {name: 'country', value: country}})
    }
  }, [country, formState.values?.country, handleFormFieldChange])

  if (loadingPartnerGallery) {
    return <Loading speed={1} />
  }

  return (
    <Grid container spacing={3} ref={notificationContainerRef}>
      {['success', 'error'].includes(formState.status) && (
        <Grow in={true}>
          <Grid item xs={12} sx={{pb: {xs: 0, md: 2}}}>
            <NotificationBar
              status={formState.status}
              title={<Trans {...(formState.status === 'success'
                ? messages.eventsFormRequestSubmitted : messages.somethingWrongError)}
              />}
            />
          </Grid>
        </Grow>
      )}
      <Grid item xs={12}>
        <Header><Trans {...messages.eventsTitle} /></Header>
      </Grid>
      <Grid item xs={12}>
        <Intro><Trans {...messages.eventsText} /></Intro>
      </Grid>
      <Grid item xs={12}>
        <List key="information">
          {[messages.eventsListText1, messages.eventsListText2, messages.eventsListText3].map((props, index) => (
            <ListItem key={index}>
              <Typography variant='body1'>
                <Trans {...props}/>
              </Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
      {!!partnerGallery.length && (
        <React.Fragment>
          <Grid item xs={12} sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 2,
          }}>
            <Header><Trans {...messages.mediaTitle} /></Header>
            <EventsArchiveLink underline={'none'} onClick={handleEventArchiveClick}>
              <Trans {...messages.eventsArchive} />
              <SvgIcon component={GalleryIcon} />
            </EventsArchiveLink>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={isMobile() ? 2 : 3} sx={{
              justifyContent: 'space-between',
              paddingBottom: isMobile() ? 1 : 2,
              display: 'flex',
            }}>
              <React.Fragment>
                {partnerGallery.map((image, index) => <Grid item xs={6} lg={3} key={index}><ImageEvent src={image.full} alt={image.title} key={index} onClick={handlePreviewClickEventsGallery} data-index={index}/></Grid>)}
                {showEventsGallery && <Lightbox
                  wrapperClassName={classes.eventsGalleryWrap}
                  mainSrc={activePartnerGalleryImage?.full}
                  nextSrc={partnerGallery[(activeIndexEventsGalleryImage + 1) % partnerGallery.length]?.full}
                  prevSrc={partnerGallery[(activeIndexEventsGalleryImage + partnerGallery.length - 1) % partnerGallery.length]?.full}
                  onCloseRequest={handleCloseEventsGallery}
                  onMovePrevRequest={handlePreviousSlideEventsGallery}
                  onMoveNextRequest={handleNextSlideEventsGallery}
                  imagePadding={isMobile() ? 0 : 80}
                  enableZoom={false}
                />}
                <Grid item xs={12} sx={{display: {xs: 'flex', sm: 'none'}, mb: 2}}>
                  <EventsArchiveButton variant="outlined" color="secondary" onClick={handleEventArchiveClick}>
                    <Trans {...messages.eventsArchive} />
                  </EventsArchiveButton>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12}>
        <Header><Trans {...messages.eventsRequestForm} /></Header>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth required error={formState.errors?.guests}>
              <CountriesSelectV2 name="country"
                countryList={countryList}
                displayEmpty={true}
                placeholder={t(messages.select.i18nKey, messages.select.defaults)}
                label={t(messages.countryOfEvent.i18nKey, messages.countryOfEvent.defaults)}
                value={formState.values?.country || ''}
                onChange={handleFormFieldChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl fullWidth required error={formState.errors?.guests}>
              <Select name="guests" labelId="guest-select"
                displayEmpty={true}
                placeholder={t(messages.select.i18nKey, messages.select.defaults)}
                label={t(messages.guests.i18nKey, messages.guests.defaults)}
                value={formState.values?.guests || ''}
                onChange={handleFormFieldChange}
              >
                <MenuItem value={'less than 50'}>{'less than 50'}</MenuItem>
                <MenuItem value={'50-99'}>{'50-99'}</MenuItem>
                <MenuItem value={'100+'}>{'100+'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl fullWidth required
              error={formState.errors?.expectedDeposits}
            >
              <TextField name="expectedDeposits"
                label={t(messages.expectedDeposits.i18nKey, messages.expectedDeposits.defaults)}
                placeholder={t(messages.expectedDepositsPlaceholder.i18nKey, messages.expectedDepositsPlaceholder.defaults)}
                value={formState.values?.expectedDeposits || ''}
                onChange={handleFormFieldChange}
                resetHandle={resetFormFieldHandle}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth required error={formState.errors?.startDateTime}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])} >
                <DateTimePicker
                  format="dd/MM/yyyy HH:mm"
                  ampm={is12hTimeFormat}
                  label={t(messages.startDateTime.i18nKey, messages.startDateTime.defaults)}
                  value={formState.values?.startDateTime ? new Date(validateDateTimeFormat(formState.values?.startDateTime)) : null}
                  onChange={(date, context) => handleFormFieldChange({target: {name: 'startDateTime', value: validateDateTimeFormat(date)}}, context)}
                  minDateTime={new Date(moment().format('YYYY-MM-DD HH:mm'))}
                  maxDateTime={formState.values.endDateTime
                    ? new Date(moment(formState.values.endDateTime).subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm'))
                    : new Date(moment().add(1, 'years').endOf('day').format('YYYY-MM-DD HH:mm'))
                  }
                  slots={{
                    textField: TextField,
                    openPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  slotProps={{
                    textField: {
                      placeholder: t(messages.startDateTimePlaceholder.i18nKey, messages.startDateTimePlaceholder.defaults),
                      helperText: null,
                      required: true,
                      error: !!formState.errors?.startDateTime,
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth required>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])} >
                <DateTimePicker
                  ampm={is12hTimeFormat}
                  format="dd/MM/yyyy HH:mm"
                  label={t(messages.endDateTime.i18nKey, messages.endDateTime.defaults)}
                  value={formState.values?.endDateTime ? new Date(validateDateTimeFormat(formState.values?.endDateTime)) : null}
                  onChange={(date, context) => handleFormFieldChange({target: {name: 'endDateTime', value: validateDateTimeFormat(date)}}, context)}
                  minDateTime={new Date(moment(formState.values.startDateTime).add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm'))}
                  maxDateTime={new Date(moment().add(1, 'years').endOf('day').format('YYYY-MM-DD HH:mm'))}
                  slots={{
                    textField: TextField,
                    openPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  slotProps={{
                    textField: {
                      placeholder: t(messages.endDateTimePlaceholder.i18nKey, messages.endDateTimePlaceholder.defaults),
                      helperText: null,
                      required: true,
                      error: !!formState.errors?.endDateTime
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={formState.errors?.venueName}>
              <TextField name="venueName"
                label={t(messages.venueName.i18nKey, messages.venueName.defaults)}
                placeholder={t(messages.venueNamePlaceholder.i18nKey, messages.venueNamePlaceholder.defaults)}
                value={formState.values?.venueName || ''}
                onChange={handleFormFieldChange}
                resetHandle={resetFormFieldHandle}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={formState.errors?.venueLocation}>
              <TextField name="venueLocation"
                label={t(messages.venueLocation.i18nKey, messages.venueLocation.defaults)}
                placeholder={t(messages.venueLocationPlaceholder.i18nKey, messages.venueLocationPlaceholder.defaults)}
                value={formState.values?.venueLocation || ''}
                onChange={handleFormFieldChange}
                resetHandle={resetFormFieldHandle}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={formState.errors?.budgetBreakdown}>
              <TextField name="budgetBreakdown"
                placeholder={t(messages.budgetBreakdownPlaceholder.i18nKey, messages.budgetBreakdownPlaceholder.defaults)}
                label={t(messages.budgetBreakdown.i18nKey, messages.budgetBreakdown.defaults)}
                value={formState.values?.budgetBreakdown || ''}
                onChange={handleFormFieldChange}
                multiline
                rows={4}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={formState.errors?.scheduleBreakdown}>
              <TextField name="scheduleBreakdown"
                placeholder={t(messages.scheduleBreakdownPlaceholder.i18nKey, messages.scheduleBreakdownPlaceholder.defaults)}
                label={t(messages.scheduleBreakdown.i18nKey, messages.scheduleBreakdown.defaults)}
                value={formState.values?.scheduleBreakdown || ''}
                onChange={handleFormFieldChange}
                multiline
                rows={4}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth>
              <TextField name="marketingMaterial"
                placeholder={t(messages.marketingMaterialPlaceholder.i18nKey, messages.marketingMaterialPlaceholder.defaults)}
                label={t(messages.marketingMaterial.i18nKey, messages.marketingMaterial.defaults)}
                value={formState.values?.marketingMaterial || ''}
                error={formState.errors?.marketingMaterial}
                onChange={handleFormFieldChange}
                multiline
                rows={4}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={formState.errors?.details}>
              <TextField name="details"
                placeholder={t(messages.detailsPlaceholder.i18nKey, messages.detailsPlaceholder.defaults)}
                label={t(messages.details.i18nKey, messages.details.defaults)}
                value={formState.values?.details || ''}
                onChange={handleFormFieldChange}
                multiline
                rows={4}
              />
            </FormControl>
          </Grid>
          <LoadingButtonWrap>
            <LoadingButton
              disabled={disabledSubmit}
              hideProgressBar={!createEventRequestFormResult.loading}
              onClick={handleSendEventForm}
              style={{margin: '15px 0'}}
            >
              <Trans {...messages.submit} />
            </LoadingButton>
          </LoadingButtonWrap>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withNamespaces()(Events)
