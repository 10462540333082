import React from 'react'
import {isMobile} from '../../common/utils'

const PageBody = ({removePadding, children, ...props}) => {
  const mobile = isMobile()

  const styles = mobile ? {flex: 1, padding: '16px'} : {}
  if (mobile && removePadding) {
    styles.padding='0'
  }
  return <div style={styles} {...props}>{children}</div>
}

export default PageBody
