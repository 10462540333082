import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Trans, withNamespaces} from 'react-i18next'
import {capitalize, find, flowRight as compose, get} from 'lodash'
import classnames from 'classnames'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import {companies, countries} from '@bdswiss/common-enums'
import {getCurrentTheme, isDarkTheme, safeParseJSON} from '../../../../common/utils'
import config from '../../../../config'
import messages from '../../../../assets/messages'
import {BillingAddressDetails, PaymentActionButton} from './helpers'
import AppContext from '../../../Common/contexts/AppContext'

const style = (theme) => ({
  formControl: {
    margin: `${theme.spacing(1)} 0`
  },
  cardNumber: {
    padding: '6px 0 7px',
    borderBottom: '1px solid #CCC'
  },
  top: {
    paddingTop: 25,
  },
  error: {
    color: theme.palette.red.color,
    borderColor: theme.palette.red.color,
  },
})

const errorMessageMapping = {
  incomplete_cvc: messages.cvv,
  incomplete_date: messages.date,
  date_in_past: messages.date,
  invalid_expiration_year: messages.invalidYear,
  incomplete_card_number: messages.invalidCardNumber,
  incorrect_card_number: messages.invalidCardNumber,
}

class SafeChargeProvider extends Component {
  static contextType = AppContext
  state = {
    isCardNumberValid: false,
    creatingPayment: false,
    cardError: ''
  }

  componentDidMount() {
    if (window.SafeCharge) {
      this.init()
    } else {
      const script = document.getElementById('safecharge-sdk-script')
      script.addEventListener('load', () => this.init())
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.payload && prevProps.payload !== this.props.payload) {
      this.handleSafechargeResponse()
    }
  }

  init () {
    const {providerProperties} = this.props
    const additionalFields = safeParseJSON(providerProperties.additionalFields)

    this.safecharge = window.SafeCharge({
      env: config.common.safecharge.env,
      merchantId: additionalFields.merchantId,
      merchantSiteId: additionalFields.merchantSiteId,
    })

    if (this.props.payload) {
      this.handleSafechargeResponse()
    } else {
      this.createSafechargeFields()
    }
  }

  createSafechargeFields() {
    const {viewer, theme} = this.props
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)

    const safeChargeFields = this.safecharge.fields({
      fonts: [{cssUrl: 'https://fonts.googleapis.com/css2?family=Roboto'}],
      locale: viewer.locale
    })

    this.cardNumber = safeChargeFields.create('card', {
      style: {
        base: {
          fontFamily: 'Roboto, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          ...(isDark && {color: theme.palette.secondary.light}),
        }},
    })

    this.cardNumber.on('change', (evt) => {
      if (evt.error) {
        this.setState({cardError: evt.error.code})
      }

      if (this.state.isCardNumberValid !== evt.complete) {
        this.setState((state) => ({
          isCardNumberValid: evt.complete,
          cardError: evt.complete ? '' : state.cardError
        }))
      }
    })

    this.cardNumber.attach('#safecharge-card-number')
  }

  handleSafechargeResponse() {
    const {providerProperties, viewer, history, form, payload} = this.props
    const {userPaymentOptionId, sessionToken, clientUniqueId} = payload
    const additionalFields = safeParseJSON(providerProperties.additionalFields)

    const personalInfo = {
      email: viewer.email,
      country: form.country || get(find(countries, {key: get(viewer, 'address.country')}), 'value'),
    }
    const browserDetails = {
      acceptHeader: 'text/html,application/xhtml+xml',
      ip: payload?.ip,
      javaEnabled: window.navigator?.javaEnabled() ?? false,
      javaScriptEnabled: true,
      language: window.navigator.language,
      colorDepth: window.screen.colorDepth,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      timeZone: new Date().getTimezoneOffset(),
      userAgent: payload?.userAgent
    }

    const paymentOption = userPaymentOptionId ? {userPaymentOptionId} : this.cardNumber
    Object.assign(paymentOption , {card:{threeD: {browserDetails: browserDetails}}})
    const paymentPayload = {
      sessionToken: sessionToken,
      merchantId: additionalFields.merchantId,
      merchantSiteId: additionalFields.merchantSiteId,
      clientUniqueId: clientUniqueId,
      paymentOption: paymentOption,
      cardHolderName: form.name,
      billingAddress: {
        address: form.street,
        zip: form.zip,
        city: form.city,
        ...personalInfo
      }
    }
    this.setState({creatingPayment: true})
    this.safecharge.createPayment(paymentPayload, (res) => {
      if (res.cancelled) {
        history.push('deposit/result/failed')
      } else if (res.result === 'APPROVED') {
        history.push('deposit/result/success')
      } else {
        history.push('deposit/result/failed')
      }
    })
  }

  render() {
    const {classes, errors, form, handleChange, doCreateDeposit, viewer, creatingDeposit, formIsValid, payload, canProceed} = this.props
    const {isCardNumberValid, creatingPayment, cardError} = this.state
    const company = find(companies, {value: viewer.company})
    const disablePayButton = !!errors.name || !isCardNumberValid || canProceed

    if (payload?.userPaymentOptionId) {
      return ''
    }

    return (
      <Grid container spacing={1} className={classes.top}>
        <Grid item xs={12}>
          <div id="safecharge-card-number" className={classnames(classes.cardNumber, {[classes.error]: !!cardError})}/>
          {cardError &&
            <FormHelperText className={classes.error}>
              <Trans {...errorMessageMapping[cardError]} />
            </FormHelperText>
          }
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="name">
              <Trans {...messages.fullName} />
            </InputLabel>
            <Input
              id="name"
              type="text"
              name="name"
              error={!!errors.name}
              onChange={handleChange}
              inputProps={{autoComplete: 'off'}}
            />
            {
              errors.name &&
              <FormHelperText className={classes.error}><Trans {...errors.name} /></FormHelperText>
            }
          </FormControl>
        </Grid>
        <BillingAddressDetails
          errors={errors}
          form={form}
          handleChange={handleChange}
        />
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'body1'}>
                <Trans {...messages.safeChargeLegal} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'}>
                <Trans {...messages['safeChargeOwnership' + capitalize(get(company, 'entity.value'))]} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'}>
                <Trans {...messages.safeChargeDescription} values={{company: get(company, 'brandLabel')}}/>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <PaymentActionButton
          loading={creatingDeposit || creatingPayment}
          disable={disablePayButton}
          onClick={() => {
            if (!formIsValid()) {
              return
            }
            doCreateDeposit()
          }}
        />
      </Grid>
    )
  }
}

export default compose(
  withStyles(style, {withTheme: true}),
  withNamespaces(),
  withRouter,
)(SafeChargeProvider)
