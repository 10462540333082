import React, {Component} from 'react'
import {get, map, isEmpty, orderBy,first, has, flowRight as compose, includes} from 'lodash'
import moment from 'moment'
import withStyles from '@mui/styles/withStyles'
import {depositVendors,transactionTypes, withdrawalTypes} from '@bdswiss/common-enums'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {graphql} from 'react-apollo'
import {TRANSACTIONS_CLIENT_QUERY, WITHDRAWAL_PAYMENT_OPTIONS} from '../../../graphql/queries'
import classNames from 'classnames'
import {safeParseJSON} from '../../../common/utils/general'
import {Loading} from '../../Common/Loading'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import {hasVipOrRaw, blockedDepositAccount} from '../../../common/utils/accounts'
import NotificationBar from '../../Common/NotificationBar'
import config from '../../../config'
import * as PaymentsHistoryHelper from './PaymentsHistoryHelper'
import AppContext from '../../Common/contexts/AppContext'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import MoreVert from '@mui/icons-material/MoreVert'
import {Menu, MenuItem} from '@mui/material'

const styles = theme => ({
  table: {
    minWidth: 700,
    border: 'none',
    marginBottom: 50
  },
  statusIcon: {
    verticalAlign:'middle',
    fontSize:20,
    marginRight: 4,
    marginTop: -3,
  },
  doneIcon: {
    'color': theme.palette.green.color
  },
  pendingIcon: {
    'color': theme.palette.yellow.color
  },
  deposit: {
    'color': theme.palette.green.color,
    whiteSpace: 'nowrap',
    padding: '4px 56px 4px 24px',
  },
  withdrawal: {
    color: theme.palette.red.color,
    whiteSpace: 'nowrap',
    padding: '4px 56px 4px 24px',
  },
  subscription: {
    'color': theme.palette.green.color,
    whiteSpace: 'nowrap',
  },
  transfer: {
    'color': theme.palette.yellow.color
  },
  action: {
    'color': theme.palette.primary.main,
    fontWeight: 400
  },
  dateTime:{
    color: theme.palette.secondary.main
  },
  statusCell: {
    whiteSpace: 'nowrap',
  },
  filterButton: {
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.primary.main,
  },
  status: {
    maxWidth: 130,
  },
  cellRoot: {
    padding: '4px 0px 4px 30px',
    position: 'relative'
  },
  cancelBtn:{
    marginTop:0
  },
  waitingBtn:{
    padding:0
  },
  detailsInfo: {
    padding: 0,
    minWidth: '28px',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  textCenter:{
    textAlign: 'center'
  },
  textRight:{
    textAlign: 'right'
  },
  cancelPartialBtn: {
    color: theme.palette.red.color,
  },
  rowButton: {
    borderBottom: 'none'
  },
  notificationBox: {
    marginTop: 20
  },
  showMore: {
    display: 'inline',
    fontWeight: 400,
    padding: '8px 0',
    '&:hover, &:active':{
      backgroundColor: 'transparent',
    },
  },
  loading: {
    paddingTop: 20
  },
  highlight:{
    fontWeight:400
  },
  selectedRow: {
    backgroundColor:  theme.palette.lightyellow.color,
  },
  selectedRowRemove: {
    backgroundColor:  theme.palette.background.default,
    transition: 'background-color 1000ms linear'
  },
  spanDetails: {
    paddingLeft: 12
  },
  threeDot: {
    float: 'right',
    cursor: 'pointer',
    fontSize: '1.2rem',
    overflowY: 'hidden',
    position: 'absolute',
    top: '33%',
    right: 0,
  },
})


class DesktopPaymentsList extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: '',
      open: {},
      showMoreClicked: false,
    }
  }

  componentDidUpdate(prevProps) {
    const {scrollTo} = this.props
    const {showMoreClicked} = this.state
    if (showMoreClicked) {
      scrollTo()
    }
  }

  cancelWithdrawalAction (row) {
    const {cancelPendingWithdrawal} = this.props
    logEventCustomParams('cancelWithdrawalIntent', {
      userId: get(this.context, 'clientId'),
    })
    return cancelPendingWithdrawal(row)
  }

  vipLink() {
    logEventCustomParams('skipWaitingListClicked', {
      type: 'withdrawal',
      userId: get(this.context, 'clientId'),
    })
    return this.props.history.push('/vip-account')
  }

  handleClick(event, key) {

    this.setState({showMoreClicked: false, anchorEl: event.currentTarget})
    this.setState(state => ({open: {...state.open, [key]: true}}))
  }

  handleRequestClose = () => {
    this.setState({open: false})
  }

  threeDotButtons(row) {
    const {classes, state, accounts, history, setLoading, state: {loading: buttonLoading}} = this.props
    const {featuresConfig :{skipWaitingList}} = config
    const {blockedDeposit} = this.context
    let buttons = []
    const partialDeposit = PaymentsHistoryHelper.getPartialDepositButtons(row, this.props, state, setLoading)

    if (PaymentsHistoryHelper.pendingWithdrawalStatuses.includes(row.status) && !hasVipOrRaw(accounts) && !blockedDeposit && !blockedDepositAccount(row.account) && skipWaitingList) {
      buttons.push({
        message: 'skipWaitingList',
        onClick: () => this.vipLink(),
      })
    }

    if (PaymentsHistoryHelper.canCancelWithdrawal(row)) {
      buttons.push({
        message: 'cancel',
        onClick: (row) => this.cancelWithdrawalAction(row),
        disabled: () => !!buttonLoading,
      })
    }

    if (row.transactionType === transactionTypes.deposit.key && PaymentsHistoryHelper.pendingStatuses.includes(row.status)) {
      buttons.push({
        message: 'verifyAccount',
        onClick: (row) => {
          const meta = safeParseJSON(get(row, 'meta') || '{}')
          if (get(row, 'vendor', '') === depositVendors.isignthis.value && has(meta, 'iSignthisUrl')) {
            window.location = get(meta, 'iSignthisUrl')
          } else {
            history.push('/accounts/verification')
          }
        },
      })
    }

    if (!isEmpty(partialDeposit)) {
      buttons = partialDeposit
    }

    return buttons
  }

  renderThreeDotButton(threeDotButtons, row) {
    const {classes} = this.props
    return <React.Fragment>
      <MoreVert id={'helpIcon'}className={classes.threeDot} onClick={(e) => this.handleClick(e, row.id)}/>
      <Menu
        anchorEl={this.state.anchorEl}
        open={!!this.state.open[row.id]}
        onClose={this.handleRequestClose}
      >
        {map(threeDotButtons, (button) => (
          <MenuItem key={button.message} value={button.message}
            onClick={() => {
              this.setState(state => ({open: {...state.open, [row.id]: false}}))
              button.onClick(row)
            }}
            disabled={has(button, 'disabled') && button.disabled()}
          >
            <Trans {...messages[button.message]} />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  }

  render() {
    const {classes, state, transactions, accounts, transactionsCount, setState,
      state: {limit, localLimit, limitEnd, filter, disableShowMore},
      t, loading, accountsLoading, lastRow, withdrawalPaymentOptions: {withdrawalPaymentOptions}} = this.props
    const {locale} = this.context

    if (loading || accountsLoading) {
      return (<Loading />)
    }

    const sortedTransactions = !isEmpty(transactions) ? orderBy(transactions, ['createdAt'], ['desc']) : []
    const shownTransactions = PaymentsHistoryHelper.applyFilter(sortedTransactions, state.filter)

    const showMoreCheck = transactions && shownTransactions && shownTransactions.length <= 0
    const moreTransactions = transactions && transactions.length < transactionsCount

    const showMore = <span variant="subtitle2" disabled={disableShowMore} className={classNames(classes.link, classes.filterButton, classes.showMore)}
      onClick={() => {
        setState({limit: localLimit || first(PaymentsHistoryHelper.transactionsLimits), limitEnd: limitEnd + (limit || first(PaymentsHistoryHelper.transactionsLimits)), disableShowMore: true})
        this.props.highlightRow(shownTransactions.length)
        this.setState({showMoreClicked: true})
      }}
    ><Trans {...messages.showMore} /></span>

    return (<React.Fragment>
      {showMoreCheck &&
        <NotificationBar
          status="info"
          classes={{notificationBox: classes.notificationBox}}
          title={<Trans {...messages.noTransactionsCurrent}
            components={[moreTransactions && showMore, moreTransactions && <span className={classes.highlight}>highlight</span>]}
            values={{showMore: moreTransactions ? t(messages.showMore.i18nKey, messages.showMore.defaults) : '',
              totalTransactions: moreTransactions ? t(messages.entriesOfInline.i18nKey,
                {entries: shownTransactions.length, entriesCount: transactionsCount, showStar: ''}) : ''}}
          />}
        />}
      {!showMoreCheck && <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t(messages.account.i18nKey, messages.account.defaults)}</TableCell>
            <TableCell>{t(messages.time.i18nKey, messages.time.defaults)}</TableCell>
            <TableCell>{t(messages.details.i18nKey, messages.details.defaults)}</TableCell>
            <TableCell className={classes.cellRoot}>{t(messages.type.i18nKey, messages.type.defaults)}</TableCell>
            <TableCell className={classes.cellRoot}>{t(messages.amount.i18nKey, messages.amount.defaults)}</TableCell>
            <TableCell className={classes.cellRoot}>{t(messages.status.i18nKey, messages.status.defaults)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(shownTransactions, (row, index) => {
            let amountClass = row.transactionType.toLowerCase()
            if (row.transactionType === transactionTypes.dividend.key) {
              amountClass = row.withdrawalType === withdrawalTypes.dividend.key ? 'withdrawal' : 'deposit'
            }
            const threeDotButtons = this.threeDotButtons(row)
            return <TableRow key={row.id} id={lastRow === index ? 'scroll-row' : row.id}
              className={(lastRow === index && state.highlightRow) ? classes.selectedRow : classes.selectedRowRemove}>
              <TableCell className={classes.statusCell}>{PaymentsHistoryHelper.transactionAccountName(row, locale, t, accounts)}</TableCell>
              <TableCell className={classes.statusCell} component="th" scope="row">
                <Tooltip title={moment(row.createdAt).format('lll')} placement="top-start">
                  <Typography variant="subtitle1" className={classes.dateTime}>
                    {moment(row.createdAt).format('DD MMM YYYY, HH:mm')}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.cellRoot}>
                {PaymentsHistoryHelper.showAllDetails(row, this.props, locale)}
              </TableCell>
              <TableCell className={classes.cellRoot}>{PaymentsHistoryHelper.transactionType(row)}</TableCell>
              <TableCell className={includes([transactionTypes.transfer.key, transactionTypes.ldTransfer.key], get(row, 'vendor')) ? classes.transfer : classes[amountClass]}>
                {PaymentsHistoryHelper.showAmount(row)}
              </TableCell>
              <TableCell className={classNames([classes.status, classes.cellRoot])}>
                <Tooltip title={PaymentsHistoryHelper.fieldStatus(row, classes, accounts, locale, true, withdrawalPaymentOptions)} placement="top">
                  <span>{PaymentsHistoryHelper.fieldStatus(row, classes, accounts, locale, false, withdrawalPaymentOptions)}</span>
                </Tooltip>
                {!isEmpty(threeDotButtons) && this.renderThreeDotButton(threeDotButtons, row)}
              </TableCell>
            </TableRow>}
          )}
          <TableRow>
            <TableCell className={classNames(classes.textRight, classes.rowButton)} colSpan={7}>
              <Typography variant="caption"><Trans {...messages.entriesOf}
                values={{entries: shownTransactions.length, entriesCount: transactionsCount, showStar: PaymentsHistoryHelper.renderHelperText(filter, classes) ? '*' : ''}}/></Typography>
              {PaymentsHistoryHelper.renderHelperText(filter, classes)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classNames(classes.textCenter, classes.rowButton)} colSpan={7}>
              {(transactions.length < transactionsCount) && showMore}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>}
    </React.Fragment>)
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  graphql(TRANSACTIONS_CLIENT_QUERY, {
    options: (props) => ({
      variables: {limit: props.state.limit + props.state.limitEnd, offset: 0},
    }),
    props: ({data: {error, loading}, data})  => {
      const transactions = get(data, 'viewer.transactions')
      return {
        error,
        loading,
        transactions,
        transactionsCount: get(data, 'viewer.transactionsCount'),
        refetchPayments: data.refetch
      }
    }}
  ),
  graphql(WITHDRAWAL_PAYMENT_OPTIONS, {
    name: 'withdrawalPaymentOptions'
  })
)(DesktopPaymentsList)
