import React, {Component} from 'react'
import {get, map, isEmpty, orderBy,first, flowRight as compose} from 'lodash'
import moment from 'moment'
import withStyles from '@mui/styles/withStyles'
import {depositVendors, transactionTypes, withdrawalTypes} from '@bdswiss/common-enums'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {graphql} from 'react-apollo'
import {TRANSACTIONS_CLIENT_QUERY, WITHDRAWAL_PAYMENT_OPTIONS} from '../../../graphql/queries'
import classNames from 'classnames'
import {safeParseJSON} from '../../../common/utils/general'
import {Loading} from '../../Common/Loading'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import LoadingButton from '../../Common/LoadingButton'
import {hasVipOrRaw, blockedDepositAccount} from '../../../common/utils/accounts'
import NotificationBar from '../../Common/NotificationBar'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import config from '../../../config'
import * as PaymentsHistoryHelper from './PaymentsHistoryHelper'
import AppContext from '../../Common/contexts/AppContext'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'


const styles = theme => ({
  statusIcon: {
    'vertical-align':'middle',
    'font-size':'20px',
    'margin-right':'8px',
  },
  doneIcon: {
    'color': theme.palette.green.color
  },
  pendingIcon: {
    'color': theme.palette.yellow.color
  },
  deposit: {
    'color': theme.palette.green.color,
    whiteSpace: 'nowrap',
  },
  withdrawal: {
    color: theme.palette.red.color,
    whiteSpace: 'nowrap',
  },
  subscription: {
    'color': theme.palette.green.color,
    whiteSpace: 'nowrap',
  },
  transfer: {
    'color': theme.palette.yellow.color
  },
  action: {
    'color': theme.palette.primary.main,
    fontWeight: 400
  },
  dateTime:{
    color: theme.palette.secondary.main
  },
  statusCell: {
    whiteSpace: 'nowrap',
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  filterButton: {
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.primary.main,
  },
  status: {
    width: '30%',
  },
  cancelBtn:{
    marginTop:0
  },
  waitingBtn:{
    padding:0
  },
  detailsInfo: {
    padding: 0,
    minWidth: '28px',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  textRight:{
    textAlign: 'right'
  },
  cancelPartialBtn: {
    color: theme.palette.red.color,
  },
  listShowMore: {
    textAlign: 'center',
    display: 'block'
  },
  notificationBox: {
    marginTop: 20
  },
  showMore: {
    display: 'inline',
    fontWeight: 400,
    padding: '8px 0',
    '&:hover, &:active':{
      backgroundColor: 'transparent',
    },
  },
  highlight:{
    fontWeight:400
  },
  selectedRow: {
    backgroundColor:  theme.palette.lightyellow.color,
  },
  selectedRowRemove: {
    backgroundColor:  theme.palette.background.default,
    transition: 'background-color 1000ms linear'
  }
})


class MobilePaymentsList extends Component {
  static contextType = AppContext

  cancelWithdrawalAction (row) {
    const {cancelPendingWithdrawal} = this.props
    logEventCustomParams('cancelWithdrawalIntent', {
      userId: get(this.context, 'clientId'),
    })
    return cancelPendingWithdrawal(row)
  }

  vipLink() {
    logEventCustomParams('skipWaitingListClicked', {
      type: 'withdrawal',
      userId: get(this.context, 'clientId'),
    })
    return this.props.history.push('/vip-account')
  }

  render() {
    const {classes, state, transactions, accounts, transactionsCount, setLoading, setState, lastRow,
      state: {limit, limitEnd, filter, disableShowMore}, handleOpen, t, loading, accountsLoading, scrollTo, withdrawalPaymentOptions: {withdrawalPaymentOptions}} = this.props
    const {featuresConfig :{skipWaitingList}} = config
    const {locale} = this.context
    if (loading || accountsLoading) {
      return (<Loading />)
    }
    scrollTo()
    const sortedTransactions = !isEmpty(transactions) ? orderBy(transactions, ['createdAt'], ['desc']) : []
    const shownTransactions = PaymentsHistoryHelper.applyFilter(sortedTransactions, state.filter)

    const showMoreCheck = transactions && shownTransactions && shownTransactions.length <= 0
    const moreTransactions = transactions && transactions.length < transactionsCount

    const showMore = <span variant="subtitle2" disabled={disableShowMore} className={classNames(classes.link, classes.filterButton, classes.showMore)}
      onClick={() => {
        setState({limit: filter.limit || first(PaymentsHistoryHelper.transactionsLimits), limitEnd: limitEnd + (limit || first(PaymentsHistoryHelper.transactionsLimits)), disableShowMore: true})
        this.props.highlightRow(shownTransactions.length)
      }}
    ><Trans {...messages.showMore} /></span>

    return (showMoreCheck
      ? <NotificationBar
        status="info"
        classes={{notificationBox: classes.notificationBox}}
        title={<Trans {...messages.noTransactionsCurrent}
          components={[moreTransactions && showMore, moreTransactions && <span className={classes.highlight}>highlight</span>]}
          values={{showMore: moreTransactions ? t(messages.showMore.i18nKey, messages.showMore.defaults) : '',
            totalTransactions: moreTransactions ? t(messages.entriesOfInline.i18nKey,
              {entries: shownTransactions.length, entriesCount: transactionsCount, showStar: ''}) : ''}} />}
      />
      : <List>
        {map(shownTransactions, (row, index) => {
          const verifyAccountLink = get(row, 'vendor', '') === depositVendors.isignthis.value
            ? get(safeParseJSON(get(row, 'meta') || '{}'), 'iSignthisUrl', '#')
            : '/accounts/verification'
          let amountClass = row.transactionType.toLowerCase()
          if (row.transactionType === transactionTypes.dividend.key) {
            amountClass = row.withdrawalType === withdrawalTypes.dividend.key ? 'withdrawal' : 'deposit'
          }
          const dividendSymbol = PaymentsHistoryHelper.isDividend(row) && PaymentsHistoryHelper.showAllDetails(row, this.props, locale)
          return (<React.Fragment key={row.id}>
            <ListItem dense classes={{root: classes.listItem}} id={(lastRow === index) ? 'scroll-row' : row.id}
              className={(lastRow === index && state.highlightRow) ? classes.selectedRow : classes.selectedRowRemove}>
              <ListItemText>
                <Grid container justifyContent="space-between" direction="row">
                  <Grid item>
                    <Typography variant="caption" className={classes.dateTime}>
                      {moment(row.createdAt).format('MMM D YY, HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" className={row.payment && row.vendor === 'transfer' ?
                      classes.transfer : classes[amountClass]}>{PaymentsHistoryHelper.showAmount(row)}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1">{PaymentsHistoryHelper.transactionType(row)}</Typography>
                    <Typography variant="body1">{PaymentsHistoryHelper.transactionAccountName(row, locale, t, accounts)}</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" direction="row">
                  <Grid item className={classes.statusCell}>
                    <Typography variant="body1">
                      {PaymentsHistoryHelper.fieldStatus(row, classes, accounts, this.context.locale, false, withdrawalPaymentOptions)}
                      {(PaymentsHistoryHelper.isWithdrawal(row) || PaymentsHistoryHelper.isTransfer(row) ||
                        (PaymentsHistoryHelper.isDeposit(row) && row.vendor === depositVendors.bankWire.value)) &&
                        <Button onClick={()=> handleOpen(row, this.context.locale)} className={classes.detailsInfo}>
                          <InfoIcon style={{fontSize: 16}}/>
                        </Button>}
                    </Typography>
                    {(dividendSymbol && get(dividendSymbol, 'props.children') !== '-') ? <Typography variant="body1">
                      {dividendSymbol}
                    </Typography> : ''}
                  </Grid>
                  <Grid item className={classes.action}>
                    {PaymentsHistoryHelper.pendingWithdrawalStatuses.includes(row.status) && !hasVipOrRaw(accounts) &&
                  !this.context.blockedDeposit && !blockedDepositAccount(row.account) && skipWaitingList &&
                  <Button
                    disableFocusRipple
                    disableRipple
                    variant="text"
                    color="primary"
                    classes={{root:classes.waitingBtn}}
                    onClick={() => this.vipLink()}
                  >
                    <Trans {...messages.skipWaitingList} />
                  </Button>
                    }
                    {PaymentsHistoryHelper.canCancelWithdrawal(row) && <LoadingButton
                      disableFocusRipple
                      disableRipple
                      variant="text"
                      color="primary"
                      disabled={state.loading}
                      status=""
                      size="medium"
                      classes={{root:classes.cancelBtn}}
                      onClick={() => this.cancelWithdrawalAction(row)}
                    >
                      <Trans {...messages.cancel} />
                    </LoadingButton>
                    }
                    {row.transactionType === transactionTypes.deposit.key && PaymentsHistoryHelper.pendingStatuses.includes(row.status) &&
                    <a href={verifyAccountLink}
                      className={classes.action}
                    >
                      <Trans {...messages.verifyAccount} />
                    </a>
                    }
                    {PaymentsHistoryHelper.getPartialDepositButtons(row, this.props, state, setLoading)}
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
            <Divider />
          </React.Fragment>)
        })}
        <ListItem>
          <ListItemText className={classes.textRight}>
            <Typography variant="caption"><Trans {...messages.entriesOf}
              values={{entries: shownTransactions.length, entriesCount: transactionsCount, showStar: PaymentsHistoryHelper.renderHelperText(filter, classes) ? '*' : ''}}/></Typography>
            {PaymentsHistoryHelper.renderHelperText(filter, classes)}
          </ListItemText>
        </ListItem>
        {<ListItem classes={{root: classes.listShowMore}}>
          {transactions.length < transactionsCount && showMore}
        </ListItem>}
      </List>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  graphql(TRANSACTIONS_CLIENT_QUERY, {
    options: (props) => ({
      variables: {limit: props.state.limit + props.state.limitEnd, offset: 0},
    }),
    props: ({data: {error, loading}, data})  => {
      const transactions = get(data, 'viewer.transactions')
      return {
        error,
        loading,
        transactions,
        transactionsCount: get(data, 'viewer.transactionsCount'),
        refetchPayments: data.refetch
      }
    }}
  ),
  graphql(WITHDRAWAL_PAYMENT_OPTIONS, {
    name: 'withdrawalPaymentOptions'
  })
)(MobilePaymentsList)
