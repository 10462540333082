import React, {Component} from 'react'
import {withApollo, graphql} from 'react-apollo'
import {Redirect, Link} from 'react-router-dom'
import {get, isEmpty, find, map, includes, filter, capitalize, flowRight as compose, toLower, toUpper, replace,
  keys, isNil} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import moment from 'moment'
import LinearProgress from '@mui/material/LinearProgress'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import config from '../../../config'
import messages from '../../../assets/messages'
import PageTitle from '../../Common/PageTitle'
import {
  companies,
  countries,
  accountTypes,
  signupSteps,
  whiteLabels,
  regulators,
  regionsPartners,
  clientTypes,
  accountSubtypes,
} from '@bdswiss/common-enums'
import {checkReferralCode, getConfigVars} from '../../../common/utils/requests'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import AppContext from '../../Common/contexts/AppContext'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import queryString from 'qs'
import {europeCountries, ageLimitWarning, websiteLocales} from '../../../common/utils/uioptions'
import GroupTradingStats from '../../Common/GroupTradingStats'
import RegulationDialog from '../../Common/RegulationDialog'
import Header from '../../App/Header'
import {
  getItem,
  findCompany,
  isWhiteLabel,
  isMobile,
  getCookie,
  setCookie,
  setSmartPixelScript,
} from '../../../common/utils'
import {isMt5ForexAccount, getAccountSubtype} from '../../../common/utils/accounts'
import Hidden from '@mui/material/Hidden'
import NotificationBar from '../../Common/NotificationBar'
import UiNotification from '../../Common/UiNotification'
import {AlertDialog, CustomDialog} from '../../Common/Dialog'
import Sponsorships from '../../Common/Sponsorships'
import {firebaseInit, logEventCustomParams} from '../../../common/utils/firebaseEvents'
import RegisterWidget from './RegisterWidget'
import {getBrandCompanies} from '../../../common/utils/general'
import classNames from 'classnames'
import PageSubTitle from '../../Common/PageSubTitle'

const styles = theme => ({
  mainBody: {
    maxWidth: theme.mainBodyWidth,
    margin: 'auto',
    minHeight: '100%',
    position: 'relative',
  },
  groupTitle: {
    fontWeight: 400,
    backgroundColor: theme.palette.background.default,
  },
  form: {
    padding: '40px 0px 40px 0px',
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
    [theme.breakpoints.down('lg')]: {
      padding: '20px 10px 15px 10px',
      marginBottom: 0,
    },
  },
  riskTitle: {
    fontWeight: 500
  },
  riskText:{
    fontWeight:300
  },
  risk: {
    paddingTop: 30,
    [theme.breakpoints.down('lg')]: {
      margin: '0 15px 15px',
    },
  },
  divCentered: {
    flexBasis: '50%',
    borderRadius: 12,
    padding: 60,
    paddingTop: 0,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      padding: 0,
      margin: '10px auto',
    },
    boxShadow: theme.boxShadowStyleOpacified,
  },
  divWithBackground: {
    background: theme.palette.background.default,
  },
  steps: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  regDiv: {
    position: 'relative',
    [theme.breakpoints.between('md', 'xl')]: {
      padding: '0 15px',
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 400,
  },
  highlightedText: {
    fontWeight: 400,
  },
  highlightText: {
    fontWeight: 400,
    color: get(theme.palette, 'main.color') || theme.palette.red.color,
  },
  customBackground: {
    marginTop: 50,
  },
  customFormAlign: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  header: {
    marginBottom: 5,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  headerCentered: {
    textAlign: 'center',
  },
  subtitle: {
    paddingBottom: 20,
  },
})

let companyObject = findCompany(get(config, 'forcedCompany'))
const isWhiteLabelCompany = isWhiteLabel()

class Register extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    const product = this.findProduct()
    this.state = {
      recaptcha: '',
      loading: false,
      status: '',
      submitMessageError: '',
      activeStep: 0,
      prefixChanged: false,
      countryChanged: false,
      company: null,
      showCountries: false,
      prefixFlag: '',
      countryFlag: '',
      showCountriesPrefix: false,
      visibleSidebar: true,
      selectedProduct: product,
      companyReg: '',
      showEmbeddedUi: false,
      embedded: window.sessionStorage && sessionStorage.getItem('embedded'),
      showReferral: false,
      referralDetails: {},
      showRedirectionModal: false,
      showForbiddenCountryPopup: false,
      showForbiddenCountryPopupStrict: false,
      listCountries: [],
      licenseAcknowledgement: '',
      regionCountriesValues: map(regionsPartners, 'value'),
      showAgeWarningLimit: false,
      showPartnerEntityNotice: false,
      partnerCountryRegulator: '',
      partnerCountryCompany: '',
      lastInput: '',
    }
  }

  async componentDidMount() {
    const {gTagContainerId, captchaDisabled} = config
    const {location} = this.props

    const urlParams = queryString.parse(location.search.replace('?', ''))

    if (!captchaDisabled) {
      getConfigVars()
        .then((res) => {
          this.setState({isRecaptchaEnabled: res.recaptchaEnabled})
        })
        .catch((err) => {
          this.setState({isRecaptchaEnabled: true})
        })
    }

    if (gTagContainerId) {
      //tagmanager body noscript
      const gtmExternal = document.createElement('noscript')
      const gtmIframe = document.createElement('iframe')
      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${gTagContainerId}`
      gtmIframe.height = '0'
      gtmIframe.width = '0'
      gtmIframe.setAttribute('style', 'display:none;visibility:hidden')
      gtmExternal.appendChild(gtmIframe)

      //tagmanager head script
      const gtmElement = document.createElement('script')
      gtmElement.id = 'gtm-script'
      gtmElement.type = 'text/javascript'
      gtmElement.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gTagContainerId}');`
      document.getElementsByTagName('head').item(0).appendChild(gtmElement)
      document.getElementsByTagName('body').item(0).appendChild(gtmExternal)
    }

    // Smart pixel body script
    setSmartPixelScript()

    firebaseInit()

    if (get(urlParams, 'embedded')) window.sessionStorage && sessionStorage.setItem('embedded', true)

    if (get(urlParams, 'webinarid')) window.sessionStorage && sessionStorage.setItem('webinarid', get(urlParams, 'webinarid'))

    // TODO: #refactor: remove code soon ( language detection in upper level - appProvider.js )
    //
    // const queryParams = queryString.parse(location.search.replace('?', ''))
    // const urlLang = (queryParams && get(queryParams, 'lang')) || getItem('locale')
    // const supportedLanguages = map(filter(languages, (a) => a.client && !a.disabled), 'value')
    // if (urlLang && includes(supportedLanguages,urlLang)) {
    //   const storageLang = window.sessionStorage && sessionStorage.getItem('lang')
    //   if (!storageLang || storageLang!==urlLang) {
    //     window.sessionStorage && sessionStorage.setItem('lang', urlLang)
    //     i18nApp.init(urlLang)
    //     storeItem('locale', urlLang)
    //   }
    // }

    if (get(urlParams, 'referralCode')) {
      checkReferralCode({referralCode: urlParams.referralCode})
        .then((res) => {
          if (get(res, 'result.redirectUrl')) window.location.href = res.result.redirectUrl
          this.setState({showReferral: res.result.valid,
            referralDetails: {awards: res.result.awards, conditions: res.result.conditions}})
        })
        .catch((err) => {
          this.setState({showReferral: false})
        })
    }
  }

  componentWillUnmount () {
    const {lastInput, selectedProduct} = this.state
    const params = {
      lastInput: lastInput,
      type: get(selectedProduct, 'value'),
    }
    logEventCustomParams('signupUserLeftScreen', params)
  }

  componentDidUpdate(prevProps, prevState) {
    // const {registrationCountriesRedirect, supportedCompanies, registrationDynamicForm} = config
    const {registrationCountriesRedirect, supportedCompanies} = config
    const {countryFromIp} = this.state
    const {match, history} = this.props
    const paramType = match.params && match.params.type
    if (paramType === 'copy_vip' && !isNil(countryFromIp)) {
      countryFromIp !== 'CY' && history.push('/register')
    }

    let prevStateCountries = get(prevState, 'listCountries')
    if (supportedCompanies) prevStateCountries = filter(prevStateCountries, (country) => !country.isEU)
    if (includes(get(registrationCountriesRedirect, 'from'), countryFromIp) && isEmpty(prevStateCountries)
      && !isEmpty(get(this.state, 'listCountries'))) {
      this.handleChangeCountry(countryFromIp)
    }
  }

  findRegulatorFromParams(regulator) {
    return get(find(regulators, (r) => toLower(r.label) === regulator), 'value')
  }

  findAllowedRegulators(country) {
    const selectedCountry = find(get(this.state, 'listCountries') || [],(c) => c.countryCode === country)
    return  get(selectedCountry,'regulators')
  }

  findDefaultRegulator(selectedCountry = false) {
    const country = selectedCountry || get(this.state, 'country', '') ||  get(this.state, 'countryFromIp', '')
    const urlParams = queryString.parse(this.props.location.search.replace('?', ''))
    if (urlParams.regulator && selectedCountry) {
      const regulatorFromParams = this.findRegulatorFromParams(urlParams.regulator)
      const companyFromParams = this.getRegulatorCompany(regulatorFromParams)
      if (!includes(europeCountries, selectedCountry) &&
        includes(this.findAllowedRegulators(selectedCountry), companyFromParams.value)) {
        return this.findRegulatorFromParams(urlParams.regulator)
      }
    }

    const forbiddenCountries = map(filter(countries, country => country.forbidden), 'value')
    const forbiddenCountry = country && includes(forbiddenCountries, country)
    let regulator = '', defaultSelectedCompany
    const allowedRegulators = !forbiddenCountry && this.findAllowedRegulators(country)

    if (country && allowedRegulators && !isEmpty(allowedRegulators)) {
      if (allowedRegulators.length === 1) {
        defaultSelectedCompany = allowedRegulators[0]
      } else {
        const selectedCountry = find(get(this.state, 'listCountries') || [],(c) => c.countryCode === country)
        defaultSelectedCompany = get(selectedCountry,'defaultRegulator')
      }
      regulator = defaultSelectedCompany && companies[defaultSelectedCompany].regulator
    }

    return regulator
  }

  findRegulator(company) {
    const {location} = this.props
    const {supportedCompanies} = config
    const urlParams = queryString.parse(location.search.replace('?', ''))
    let companyReg
    if (urlParams.regulator)
      companyReg = this.getRegulatorCompany(this.findRegulatorFromParams(urlParams.regulator))

    if (!urlParams.regulator && supportedCompanies) {
      let regulator = this.findDefaultRegulator()

      if (!regulator)
        regulator =  companies[company].regulator

      companyReg = this.getRegulatorCompany(regulator)
    }

    return companyReg
  }

  getCurrentLocale() {
    return getItem('locale','en')
  }

  getRegulatorCompany(regulator) {
    const brand = isWhiteLabel() ? (get(config, 'companyBrand') || config.brand)  : config.brand
    return find(companies, (company) => company.regulator === regulator && company.brand === brand)
  }

  handleChangeCountry(value) {
    const {prefixChanged, countryChanged, selectedRegulator} = this.state
    const {registrationCountriesRedirect, supportedCompanies} = config
    let countriesCheck = countries
    if (supportedCompanies) countriesCheck = filter(countriesCheck, (country) => !country.isEU)
    const selectedCountry = find(countriesCheck,(country) => country.value === value)
    if (registrationCountriesRedirect && includes(get(registrationCountriesRedirect, 'from'), value))
      this.setState({showRedirectionModal: true})
    if (prefixChanged && !countryChanged)
      this.setState({prefixChanged:false})
    if (!prefixChanged || !countryChanged) {
      this.handleChangePrefix(value)
      this.handleChange ('prefix', `+${get(selectedCountry,'callingCode','')}`)
    }
    this.setState({showCountries:false,countryFlag:selectedCountry})

    //check regulator based on country selection
    const allowedRegulators = this.findAllowedRegulators(value)

    if (!selectedRegulator || (europeCountries.indexOf(value) !== -1)
     || (selectedRegulator && !includes(allowedRegulators, selectedRegulator))) {
      const regulator = this.findDefaultRegulator(value)
      const companyReg = this.getRegulatorCompany(regulator)
      if (companyReg) {
        this.setState({selectedRegulator:companyReg.key})
      } else {
        this.setState({selectedRegulator:null})
      }
    }
  }

  findAccountSubType(type, subType, company, accountTypeObj) {
    const {supportedCompanies} = config
    const selectedSubType = subType ? `${type}_${subType}` : type
    const selectedSubTypeDetails = getAccountSubtype(selectedSubType)
    const validSubType = selectedSubTypeDetails && !selectedSubTypeDetails.hideFromClients
    const brandCompanies = supportedCompanies && map(getBrandCompanies(company), 'key')
    const selectedRegulator = get(this, 'state.selectedRegulator')
    const checkSubtype = type
     && find(accountTypes, (accountType) =>
       (supportedCompanies
         ? ((selectedRegulator)
           ? (accountType.company === selectedRegulator) : includes(brandCompanies, accountType.company))
         : accountType.company === company)
      && accountType.clientOpenPermitted && validSubType
      && (includes(accountType.supportedSubtypes,(subType && toUpper(`${type}_${subType}`)) || toUpper(type))
       || includes(accountType.supportedSubtypes, selectedSubTypeDetails.value)
       || includes(accountType.supportedSubtypes, toUpper(selectedSubTypeDetails.value))))

    return checkSubtype ?
      (accountTypeObj ? checkSubtype : selectedSubType) : false
  }

  findAccountType(type, subType, company) {
    const {key, supportedCompanies} = config
    const paramType = type || 'forex'
    const brandCompanies = supportedCompanies && map(getBrandCompanies(company), 'key')
    const selectedRegulator = get(this, 'state.selectedRegulator')

    const selectedAccountType = paramType
      && find(accountTypes, (accountType) =>
        (supportedCompanies
          ? ((selectedRegulator)
            ? (accountType.company === selectedRegulator) : includes(brandCompanies, accountType.company))
          : accountType.company === company)
      && accountType.clientOpenPermitted && (!subType && !accountType.subscription)
      && ((!subType && includes(accountType.productName,paramType)) || accountType.category === paramType || accountType.subCategory === paramType)
      && (isWhiteLabelCompany && whiteLabels[key].baseProducts ? includes(whiteLabels[key].baseProducts, accountType.key) : true))

    return selectedAccountType || this.findAccountSubType(type, subType, company, true)
  }

  findProduct() {
    const {supportedCompanies, allowPartnerRegistration} = config
    const {match} = this.props
    const paramType = match.params && match.params.type
    const paramSubType = match.params && match.params.subType
    let company = companyObject.key
    const companyReg = this.findRegulator(company)
    if (companyReg && supportedCompanies) {
      company = companyReg.value
    }

    const accountType = this.findAccountType(paramType, paramSubType, company)
    const accountSubType = this.findAccountSubType(paramType, paramSubType, company)

    if ((paramType && !accountType && !accountSubType && !get(clientTypes[paramType], 'allowClientRegistration')
        && !allowPartnerRegistration) || get(accountSubtypes[accountSubType], 'disallowClientRegistration')) {
      window.location.href = '/register'
    }

    return accountType
  }

  findCurrencyByCountry(country, disallowedCurrencies) {
    if ((['PL'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'PLN'))) {
      return 'PLN'
    }
    else if ((['CH'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'CHF'))) {
      return 'CHF'
    }
    else if ((['GB', 'UK'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'GBP'))) {
      return 'GBP'
    }
    else if (europeCountries.indexOf(country) !== -1) {
      return 'EUR'
    }
    else {
      return 'USD'
    }
  }

  isAffiliate() {
    const {match} = this.props
    const product = this.state.selectedProduct
    return !!(match.params && match.params.type ===  clientTypes.affiliate.value && includes(product.category, 'affiliate'))
  }

  isIb() {
    const {match} = this.props
    const product = this.state.selectedProduct
    return !!(match.params && match.params.type === clientTypes.ib.value && includes(product.subCategory, 'ib'))
  }

  isCorporate() {
    const {match} = this.props
    return !!(match.params && match.params.type === clientTypes.corporate.value)
  }

  isPartner() {
    const {match} = this.props
    return !!(match.params && match.params.type === 'partner')
  }
  getSteps() {
    const locale = getItem('locale','en')
    const steps = []

    for (const step of companyObject.registrationSteps)
      steps.push(signupSteps[step].localization.t(locale))

    return steps
  }

  steps() {
    const {activeStep} = this.state
    const {classes} = this.props
    const steps = this.getSteps()
    return (
      <div>
        <Stepper activeStep={activeStep} alternativeLabel classes={{root:classes.steps}}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    )
  }

  errorHandling(res) {
    const {t, classes, match : {params}} = this.props
    const paramType = params && params.type
    const {form:{country}} = this.state
    const key = get(res, 'key')
    const message = get(res, 'message')
    const countryObj = find(countries, {value: country})
    const obj = {
      reason: get(res, 'message'),
      type: paramType,
    }
    logEventCustomParams('signupError', obj)
    this.setState({
      loading: false,
      status: 'failure',
      submitMessageError: messages[key] ?
        <Trans {...messages[key]} values={{entity: companyObject.trademark, country: get(countryObj, 'label')}} components={[<Link to={'/login'} className={classes.link}> logging </Link>]} />
        : (message && key) ? message.toString() : t(messages.registrationError.i18nKey)})
  }

  handleChangePrefix(value) {
    this.setState({showCountriesPrefix:false,prefixFlag:value})
  }

  closeEmbeddedIframe() {
    sessionStorage.removeItem('embedded')
    const {weblinks:{websiteUrl}} = config
    window.parent.postMessage('success', websiteUrl)
  }

  handleChangeParent (obj) {
    this.setState(obj)
  }

  render() {
    const {classes, t, loadingProp, viewer, location, match, history} = this.props
    const {countryFromIp, countryFlag, showEmbeddedUi, showRedirectionModal, showForbiddenCountryPopup,
      campaignCookie, listCountries, showForbiddenCountryPopupStrict, forbiddenCountryPopupStrictShown,
      forbiddenCountryPopupShown, partnerId, selectedRegulator, showAgeWarningLimit,
      showPartnerEntityNotice, partnerCountryRegulator, partnerCountryCompany} = this.state
    const {weblinks: {termsAndConditions, complaints, legalDocuments, privacyPolicy,
      websiteUrl, orderExecutionPolicy, clientCategorizationPolicy, summaryConflictsInterestPolicy, keyInformationDocuments, riskDisclosure}, complaintsEmail, riskWarning, entityWebsite,
    supportedCompanies, euRegulation: {showRegulationModal, redirectTo, excludeProducts, cancelationRedirect, showEuRedirectionModal}, riskWarningPercentage: percentage,
    registrationCountriesRedirect, featuresConfig:{tradingSponsorships},
    registrationDisabledTemporary, registrationBackground, registrationDisabledPermanently, hideGroupNumbers, common:{signupGroupMetrics, signupSeconds, allowedRegisterEUCountriesUnderCampaign}} = config
    const stepNumber = companyObject.registrationSteps.length
    const accountType = this.state.selectedProduct

    const riskWarningKey = riskWarning
    const locale = this.getCurrentLocale()

    let weblinks = {
      complaints: complaints.replace('{lang}', locale),
      privacyPolicy: privacyPolicy,
      termsAndConditions: termsAndConditions,
      complaintsEmail: complaintsEmail,
      entityWebsite: entityWebsite,
      legalDocuments: legalDocuments,
      websiteUrl,
      orderExecutionPolicy,
      clientCategorizationPolicy,
      summaryConflictsInterestPolicy,
      keyInformationDocuments,
      riskDisclosure,
    }

    const urlParamsString = location.search.replace('?', '')
    const urlParams = queryString.parse(urlParamsString)

    const checkExcludedProducts = (accountType && excludeProducts && (includes(excludeProducts, accountType.category) || includes(excludeProducts, accountType.subCategory)))
    const forbiddenCountries = map(filter(countries, country => country.forbidden), 'value')
    const forbiddenCountry = countryFromIp && includes(forbiddenCountries, countryFromIp)
    const reverseRedirect = !forbiddenCountry && countryFromIp && (redirectTo && redirectTo.from && get(redirectTo, 'reverse', false) ? redirectTo.from.indexOf(countryFromIp) === -1 : redirectTo.from.indexOf(countryFromIp) !== -1)
    const checkRegulationRedirect =  (!checkExcludedProducts && redirectTo && redirectTo.from && reverseRedirect && !isEmpty(redirectTo.to))
    checkRegulationRedirect && !urlParams.noredirect && (window.location.href = (redirectTo.to + window.location.pathname +  window.location.search))
    const europeNotForbiddenCountries = map(filter(countries, (country) => country.isEU &&  !country.forbidden), 'value')
    const checkRegulation = (europeNotForbiddenCountries.indexOf(countryFromIp) !== -1  && (!getCookie('RegulationAccepted')) && showRegulationModal && !includes(excludeProducts,accountType.category))
    const companyReg = (typeof selectedRegulator !== 'undefined') ? get(companies,selectedRegulator) : this.findRegulator(companyObject.key)
    const fallbackWarning =  (typeof selectedRegulator === 'undefined') && forbiddenCountry
    const defaultSupportCompany = find(keys(supportedCompanies), (supportedCompany) => supportedCompanies[supportedCompany]?.default)

    //remove BDSwiss EU Registration
    if (registrationDisabledPermanently) {
      let urlWebsite = websiteUrl
      if (get(urlParams, 'lang') && includes(websiteLocales, toLower(urlParams.lang))) urlWebsite = `${urlWebsite}/${toLower(urlParams.lang)}`
      return <UiNotification
        open={!!registrationDisabledPermanently}
        status={'failure'}
        title={'fourOfour'}
        subTitleChildren={<Typography variant='h3'>
          <Trans {...messages.redirectToHomePage}  components={[<a className={classes.link} href={urlWebsite}>here</a>]}/>
        </Typography>}
        type="page-not-found"
        hideButton={true}
        hideClose={true}
      />
    }

    if (supportedCompanies && companyReg) {
      const supportedCompany = supportedCompanies[companyReg.value] || supportedCompanies[defaultSupportCompany]
      companyObject =  isWhiteLabelCompany ? findCompany(companyReg.value) : companyObject
      weblinks = !fallbackWarning ? supportedCompany : weblinks
      // riskWarningKey = (!fallbackWarning && supportedCompanies[companyReg.value]?.riskWarning) ? supportedCompanies[companyReg.value].riskWarning : riskWarning
    }

    /*Add noredirect flag on group registration page => access correct regulator docs from anywhere*/
    if (supportedCompanies || this.isIb()) {
      const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/) ?
        window.location.hostname.match(/[^.]+\.\w+$/)[0] : window.location.hostname
      setCookie('noredirect_flag', true, (5/(24*60)), '.'+cookieDomain)
    }

    if (loadingProp) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }

    const viewerType = get(viewer, '__typename')
    if (viewer && viewerType === 'Client') {
      return <Redirect to='/' />
    }

    const paramType = match.params && match.params.type
    const paramSubType = match.params && match.params.subType
    const accountSubType = this.findAccountSubType(paramType,paramSubType, companyObject.key)
    const corporate = this.isCorporate()
    const isPartner = this.isPartner()
    const isIb = this.isIb() || isPartner
    const isAffiliate = this.isAffiliate()
    const mt4mt5Signup = accountType && get(messages, `signUp${accountType.shortLabel}`) ? `signUp${accountType.shortLabel}` :
      isMt5ForexAccount(accountType.baseProduct) ? 'signUpMt5' : (corporate) ? 'signUpCompany' : 'signUpRetail'
    const isRetailLabel = mt4mt5Signup === 'signUpRetail'
    const selectedSubType = getAccountSubtype(accountSubType)
    const signupTextType = ((get(accountType, 'subCategory') && !selectedSubType) || (get(accountType, 'subCategory') === get(selectedSubType,'key')))  ? capitalize(accountType.subCategory)
      : `${capitalize(accountType.subCategory)} ${get(selectedSubType,'label')}`
    const ibRegistrationElement = <Trans {...messages.signUpIBHighlighted} components={[<span className={classes.highlightText}>Introducing Broker</span>]}/>
    const signUpText = accountSubType ?
      <Trans {...messages.signUpSubTypes} values={{accountSubType: signupTextType}}/>
      : isIb ? ibRegistrationElement : <Trans {...messages[mt4mt5Signup]} />

    const trackingEventsUrl = queryString.parse(this.props.location.search.replace('?', ''))
    const eventUrlKey = get(trackingEventsUrl,'subcampid','')
    const eventID = !eventUrlKey.includes('LTCOMP') && eventUrlKey //exclude Live Trading Competition Events
    const eventCountry = eventID && get(get(countries, toLower(eventID.substring(0,2))),'label')
    const eventDate = eventID && moment(eventID.substring(2), 'YYYYMMDD').isValid() && moment(eventID.substring(2), 'YYYYMMDD').format('DD/MM/YYYY')
    const showEventNotification = eventID && eventCountry && eventDate

    const countryObj = find(countries, {value: countryFromIp})
    const forbiddenCountryMessage = showForbiddenCountryPopup &&
      messages.forbiddenCountryPopupSpecific
    const countryValue = get(countryFlag, 'value')
    const forbiddenCountryMessageStrict = showForbiddenCountryPopupStrict &&
    messages.forbiddenCountryPopupSpecificStrict
    const notForbiddenSpecific = !forbiddenCountryMessage && !forbiddenCountryMessageStrict

    const showRedirectionEuPopup = includes(europeCountries, countryValue) && showEuRedirectionModal && !!partnerId && !get(accountType, 'walletProduct')
    if (showRedirectionEuPopup) {
      window.location.href = `${cancelationRedirect}${urlParamsString ? `?${urlParamsString}`: ''}`
    }
    return (
      <div className={classes.mainBody} id={'scroll-div'}>
        {countryValue && accountType && !showRedirectionModal && notForbiddenSpecific && checkRegulation
          && campaignCookie !== undefined && !showRedirectionEuPopup && !includes(allowedRegisterEUCountriesUnderCampaign, countryFromIp) && <RegulationDialog />}
        <Header linkTo={urlParamsString ? `/login?${urlParamsString}` : '/'}
          customBackground={get(registrationBackground, paramType)} outer/>
        <div>
          <Grid container>
            {!isMobile() && (
              <Hidden smDown>
                <Grid item sm={3} />
              </Hidden>
            )}
            <Grid item sm={!isMobile() ? 9 : 12} className={classNames((isAffiliate || !signupGroupMetrics) && classes.divCentered, !!get(registrationBackground, paramType) && classes.divWithBackground )}>
              <Grid container className={classes.form}>
                <Grid item sm={12} xs={12}>
                  {stepNumber <= 1 && <Grid container spacing={1} className={(isAffiliate || !signupGroupMetrics) && classes.customFormAlign}>
                    <Grid item sm={12} xs={12}>
                      <PageTitle modal customClasses={isRetailLabel && {header: classNames(classes.header, !signupGroupMetrics && classes.headerCentered)}}>
                        {isAffiliate ? <span className={classes.highlightedText}><Trans {...messages.signUpAffiliate}
                          components={[<span className={classes.highlightText}>Affiliate Partner</span>]}/></span>
                          : isPartner ? <span className={classes.highlightedText}>
                            <Trans {...messages.signUpPartner}
                              components={[<span className={classes.highlightText}>Partner</span>]}/>
                          </span> : <span className={classes.highlightedText}>{signUpText}</span>}
                      </PageTitle>
                    </Grid>
                    {(isRetailLabel && !isPartner) && <Grid container spacing={1} className={!signupGroupMetrics && classes.customFormAlign}>
                      <Grid item md={!signupGroupMetrics ? 12 : 6} xl={!signupGroupMetrics ? 12 : 6} xs={12}>
                        <PageSubTitle component='h6' variant='subtitle1' className={classes.highlightText}>
                          <Trans {...messages.signUp2minHighlighted} values={{seconds: signupSeconds}} components={[<span className={classes.highlightText}>&nbsp;seconds</span>]}/>
                        </PageSubTitle>
                      </Grid>
                    </Grid>
                    }
                  </Grid>}
                  {showEventNotification && (
                    <Grid container spacing={1}>
                      <Grid item md={!signupGroupMetrics ? 12 : 6} xl={!signupGroupMetrics ? 12 : 6} xs={12}>
                        <NotificationBar
                          status='info'
                          title={<Trans
                            {...messages.registerForEventRegistration}
                            values={{eventCountry: eventCountry, eventDate: eventDate}}
                            components={[<span className={classes.highlightedText}>registration</span>]}
                          />}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(listCountries === undefined || (registrationDisabledTemporary && !isIb && !isAffiliate)) && (
                    <Grid container spacing={1}>
                      <Grid item md={!signupGroupMetrics ? 12 : 6} xl={!signupGroupMetrics ? 12 : 6} xs={12}>
                        <NotificationBar
                          status={'warning'}
                          title={<Trans {...messages[`${(listCountries === undefined) ? 'registrationServer' : 'registrationDisabled'}Error`]} />}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <RegisterWidget
                    location={location}
                    match={match}
                    history={history}
                    removeNotification={true}
                    handleChangeParent={this.handleChangeParent.bind(this)}
                  />
                  {!isAffiliate && <Grid item md={2} xl={2} className={classes.middleLine}></Grid>}
                  {!isAffiliate && !hideGroupNumbers && signupGroupMetrics && (
                    <Grid item md={4} xl={4} xs={12} className={classes.regDiv}>
                      {!isMobile() &&
                      !isWhiteLabelCompany &&
                      !urlParams.embedded &&
                      !this.isAffiliate() &&
                      signupGroupMetrics && (
                        <React.Fragment>
                          <Typography variant="h2" className={classes.groupTitle}>
                            <Trans {...messages.groupNumbers} />
                          </Typography>
                          <GroupTradingStats />
                          {tradingSponsorships && <Sponsorships />}
                        </React.Fragment>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.risk}>
                  <Typography variant="caption" className={classes.riskText}>
                    <span className={classes.riskTitle}>
                      *<Trans {...messages.riskWarningTitle} classes={classes.riskTitle} />
                    </span>
                  &nbsp;
                    <Trans
                      {...messages[riskWarningKey]}
                      values={{complaintsEmail: weblinks.complaintsEmail, percentage}}
                      components={[
                        <a
                          href={weblinks.legalDocuments.replace('{lang}', locale)}
                          target="_blank"
                          rel="noreferrer noopener"
                          className={classes.link}
                        >
                          here
                        </a>,
                        <a href={`mailto:${weblinks.complaintsEmail}`} className={classes.link}>
                          complaintsEmail
                        </a>,
                        <a
                          href={weblinks.entityWebsite}
                          target="_blank"
                          rel="noreferrer noopener"
                          className={classes.link}
                        >
                          entityWebsite
                        </a>,
                        <p key={'p'} />,
                        <div key={'div'} />,
                        <p key={'p2'} />,
                        <p key={'p3'} />,
                        <a href={weblinks.websiteUrl} target="_blank" rel="noreferrer noopener" className={classes.link}>
                          bdstrading.com
                        </a>,
                        <i key={'i'}>itallic</i>,
                        <a
                          href={replace(weblinks.riskDisclosure, '{lang}', locale)}
                          target="_blank"
                          rel="noreferrer noopener"
                          className={classes.link}
                        >
                          riskDisclosure
                        </a>,
                      ]}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {showEmbeddedUi && <UiNotification
          open={true}
          status={'success'}
          type="document-upload"
          buttonMessage={t(messages.close.i18nKey)}
          onClose={() => this.closeEmbeddedIframe()}
        />}
        {notForbiddenSpecific && !showRedirectionEuPopup && <AlertDialog
          open={showRedirectionModal || false}
          title={t(messages.entityRedirectionHeader.i18nKey, messages.entityRedirectionHeader.defaults)}
          children={<Typography variant="body1"> <Trans {...messages[get(get(config, 'registrationCountriesRedirect'),'redirectMessage')]} values={{company: companyObject.trademark}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          onAgree={()=> window.location.href = `${get(registrationCountriesRedirect, 'to')}${paramType ? `/${paramType}` : ''}${paramSubType ? `/${paramSubType}` : ''}${urlParamsString ? `?${urlParamsString}`: ''}`}
          onDisagree={()=> this.setState(state => ({form: {...state.form,
            country: '', prefix: ''}, showRedirectionModal: false, countryFlag: '', prefixFlag: ''}))}
        />}
        {forbiddenCountryMessage && <CustomDialog open={showForbiddenCountryPopup && !forbiddenCountryPopupShown} title={<Trans {...messages.importantNotification} />} onClose={() => this.setState({forbiddenCountryPopupShown: true})}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <Trans {...forbiddenCountryMessage} values={{country:countryObj.localization.t(locale)}} components={[<br key={'br'} />, <br key={'br2'} />]} />
              </Typography>
            </Grid>
          </Grid>
        </CustomDialog>}
        {forbiddenCountryMessageStrict && <CustomDialog open={showForbiddenCountryPopupStrict && !forbiddenCountryPopupStrictShown} title={<Trans {...messages.importantNotification} />} onClose={() => this.setState({forbiddenCountryPopupStrictShown: true})}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <Trans {...forbiddenCountryMessageStrict} values={{country:countryObj.localization.t(locale)}} />
              </Typography>
            </Grid>
          </Grid>
        </CustomDialog>}
        {showAgeWarningLimit && <AlertDialog
          open={showAgeWarningLimit}
          title={<Trans {...messages.riskWarningTitle1} />}
          onClose={() => this.setState(state => ({showAgeWarningLimit: false, form: {...state.form, birthday: ''}}))}
          disagreeText={t(messages.quitRegistration.i18nKey,messages.quitRegistration.defaults)}
          agreeText={t(messages.understandWishToProceed.i18nKey,messages.understandWishToProceed.defaults)}
          onAgree={() => this.setState({showAgeWarningLimit: false})}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Trans {...messages.yearsOldWarning} values={{ageLimitWarning}} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Trans {...messages.cfdComplexInstruments} />
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>}
        {(isIb || isAffiliate) && <AlertDialog
          open={showPartnerEntityNotice}
          title={<Trans {...messages.importantNotice} />}
          agreeText={t(messages.accept.i18nKey, messages.accept.defaults)}
          onAgree={() => this.setState({showPartnerEntityNotice: false})}
          onClose={() => this.setState(state => ({showPartnerEntityNotice: false}))}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Trans {...messages.partnerEntityNotice}
                  values={{
                    brandLabel: get(partnerCountryCompany, 'trademark'),
                    licenceNumber: get(partnerCountryRegulator, 'license'),
                    regulatorLabel: get(partnerCountryRegulator, 'name.label'),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>}
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withApollo,
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loadingProp:loading,
      redirectUrl: get(error, 'networkError.result.data.redirectUrl'),
      viewer: get(data, 'viewer')
    })
  }),
)(Register)
