import React from 'react'
import PropTypes from 'prop-types'
import {withApollo} from 'react-apollo'
import {flowRight as compose, get, includes, map, filter, replace, find, size, isEmpty, startsWith} from 'lodash'
import {Link, withRouter} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import AppBar from '@mui/material/AppBar'
import {kycStatuses, languages} from '@bdswiss/common-enums'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import withStyles from '@mui/styles/withStyles'
import IconButton from '@mui/material/IconButton'
import ArrowDown from '@mui/icons-material/ExpandMore'
import AppContext from '../Common/contexts/AppContext'
import config from '../../config'
import classNames from 'classnames'
import {
  getItem, getSessInfoFromLocalStorage, isAffiliatesAccount, isBitnukAccount, isCentAccount, isForexAccount,
  isIntroducingBrokerAccount, isMAMAccount, isLdWalletAccount, disableDepositWithSpecificCountries, isZuluAccount,
  isCopyTradingAccount} from '../../common/utils'
import {isDarkTheme, getCurrentTheme} from '../../common/utils/general'
import TopLevelRoutes from '../../routes'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import Button from '@mui/material/Button'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import {isAndroid, isIos, isMobile} from '../../common/utils/browser'
import Popover from '@mui/material/Popover'
import {Badge, Box, Dialog, DialogContent, DialogTitle, Divider, Menu, MenuItem} from '@mui/material'
import {bentoMenuOptions, isRtlLang} from '../../common/utils/uioptions'
import {AFFILIATE_SSO_LINK_QUERY, IB_SSO_LINK_MUTATION} from '../../graphql/mutations'
import ForexWebtraderModal from '../Accounts/Forex/ForexWebtraderModal'
import Images from '../Common/Images'
import Typography from '@mui/material/Typography'
import QRCode from 'react-qr-code'
import {ReactComponent as NotificationsIcon} from '../../assets/images/yourCustomSVGIcon.svg'
import {ReactComponent as AccountCircle} from '../../assets/images/profileIcon.svg'
import {ReactComponent as VerifiedIcon} from '../../assets/images/verified.svg'
import {ReactComponent as Missing} from '../../assets/images/unverified.svg'
import ThemeSelection from '../ThemeSelection'
import FlagIcon from '../FlagIcon'
import ForexTradeNow from '../Accounts/Forex/ForexTradeNow'
import {withMediaQuery} from '../Common'

const styles = theme => ({
  rootBtn: {
    margin: 0,
    padding: 0,
    minWidth: 40,
    minHeight: 40,
    backgroundColor: theme.palette.extralightgrey.color,
    color: theme.palette.primary.main,
    '& > *': {
      margin: 0,
      padding: 0,
      minWidth: 40,
      minHeight: 40,
    },
  },
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 2,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
      marginBottom: 0,
    },
  },
  rootRegistration: {
    backgroundColor: 'transparent',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    margin: '0 auto',
    paddingTop: 32,
  },
  menuIcon: {
    marginRight: '14px'
  },
  logo: {
    maxWidth: 180,
    maxHeight: 28,
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxWidth: 156,
      height: 24,
      paddingRight: 12,
    },
  },
  fullWidth: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  link: {
    paddingLeft: 25,
    fontSize: 14,
    color: theme.palette.grey.color
  },
  arrowLink:{
    verticalAlign: 'middle',
    padding: 1,
    marginTop: '-3px',
    color: theme.palette.secondary.dark,
  },
  arrowLinkPartner:{
    verticalAlign: 'middle',
    padding: 1,
    marginTop: '-3px',
    color: theme.palette.secondary.light,
  },
  pointer:{
    cursor: 'pointer'
  },
  headerPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 6,
    },
  },
  toolbarWithDepositButton: {
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  depositButton: {
    height: 40,
    padding: 12,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: 4,
      minHeight: 40,
      height: 'inherit',
    }
  },
  bentoMenuIconButtonRoot: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  bentoMenuIcon: {
    borderRadius: 8,
    padding: 7,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.2s',
    '&:hover, &.active': {
      cursor: 'pointer',
      backgroundColor: '#EBEBEB',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      flexShrink: 0,
      width: 24,
      height: 24,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      '&.active': {
        zIndex: '1301',
        backgroundColor: theme.palette.common.white,
      }
    },
    '&.dark': {
      '&:hover, &.active': {
        cursor: 'pointer',
        backgroundColor: '#1F1F1F',
      },
      [theme.breakpoints.down('md')]: {
        '&.active': {
          zIndex: '1301',
          backgroundColor: '#1F1F1F',
        }
      },
    },
    checkIcon: {
      '& svg path': {
        color: '#ff6600',
        fill: theme.palette.secondary.dark,
      },
    },
  },
  bentoMenuListWrap: {
    width: 552,
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: 260,
      padding: '20px 12px',
    },
  },
  bentoMenuGrid: {
    padding: 0,
    '& .MuiGrid-root': {
      width: 'calc(100% + 24px)',
    },
    '& .MuiGrid-item': {
      padding: '8px 16px',
      [theme.breakpoints.up('sm')]: {
        '&:not(:nth-of-type(-n+2))': {
          paddingTop: 0,
        },
      },
    },
  },
  bentoMenuListItem: {
    display: 'flex',
    borderRadius: 8,
    width: 240,
    padding: 16,
    transition: '0.2s',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    '&.dark': {
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          backgroundColor: 'rgb(42, 42, 42)',
        },
      },
    },
  },
  bentoMenuDivider: {
    backgroundColor: '#EBEBEB',
    '&.dark': {
      backgroundColor: '#474747'
    },
  },
  popoverContainer: {
    '& .MuiPopover-paper': {
      borderRadius: 8,
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.12)',
      backgroundColor: theme.palette.background.paper,
    },
    '&.dark': {
      '& .MuiPopover-paper': {
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#1F1F1F',
      },
    }
  },
  bentoMenuListItemTitle: {
    fontSize: '14px',
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: '14px',
  },
  bentoMenuListItemSubtitle: {
    color: '#858585',
    fontSize: '10px',
    letterSpacing: '0.4px',
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: '27px',
  },
  downloadAppDialogContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
    },
  },
  downloadAppButton: {
    fontSize: '14px',
    color: '#858585',
    borderRadius: '48px',
    border: '1.5px solid #D6EAFF',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.active': {
      color: '#007AFF',
      border: '1.5px solid #007AFF',
    },
    '&.dark-active': {
      color: '#007AFF',
      border: '1.5px solid #007AFF',
    },
    '&.dark': {
      border: '1.5px solid rgba(0, 122, 255, 0.5)'
    },
  },
  box: {
    minWidth: 400
  },
  menu: {
    borderRadius: 4,
    marginTop: 10,
    minWidth: 320,
    maxWidth: 480,
    boxShadow: theme.boxShadowStyle,
    backgroundColor: theme.palette.extralightgreyBackground.color,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },
  firstMenuItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
  },
  nameText:{
    lineHeight: '24px',
    fontSize: 14,
  },
  accountName: {
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  statusIcon: {
    'vertical-align':'text-top',
    'font-size':'15px',
    'margin-right':'4px',
  },
  pendingIcon: {
    'color': theme.palette.yellow.color,
    fontSize: 14,
  },
  doneIcon: {
    'color': theme.palette.green.color,
    fontSize: 14,
  },
  verificationLink:{
    'color': theme.palette.yellow.color,
    fontSize: 14,
  },
  menuItemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom:16,
    height: 48,
    'align-self': 'stretch',
  },
  logoutItem: {
    color: theme.palette.red.color,
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontStyle: 'mormal',
    fontWeight: 400,
  },
  noticesButton: {
    backgroundColor: theme.palette.extralightgrey.color,
    marginLeft: 2,
    borderRadius: 6,
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dot: {
    '& .MuiBadge-dot': {
      height: 4,
      minWidth: 4,
      borderRadius: 10
    }
  },
  logoAlign: {
    marginLeft: 24,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  profileIcon: {
    padding: 0,
    margin: 0,
    '& path': {
      fill: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
    },
  },
  buttonContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'baseline',
    gap: '7px',
  },
  topButton: {
    width: 48,
    height: 48,
    minWidth: 48,
    background: theme.palette.lightgreyBackgroundSolid.color,
    boxShadow:theme.boxShadowStyle,
    color: theme.palette.primary.main,
    '&:hover':{
      background: theme.palette.lightgreyBackgroundSolid.color,
    },
  },
  languageItem: {
    color: theme.palette.mode === 'dark' ?'#999999' : '#858585',
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontStyle: 'mormal',
    fontWeight: 400,
  },
  textHeaderMenu: {
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontStyle: 'mormal',
    fontWeight: 400,
  }
})

class Header extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      showAccountsModal: false,
      downloadMobileAppOpen: false,
      qrCodeSelected: 'store',
      anchorProfile: null,
    }
  }

  closeLanguage() {
    logEventCustomParams('changeLanguageIntent', {userId: get(this.context, 'clientId')})
    if (this.props.location.pathname==='/change-language' || this.props.location.pathname==='/language') {
      if (get(this.props.history.location, 'state.prevPath') === '/login') {
        this.props.history.push('/accounts')
      } else {
        this.props.history.goBack()
      }
    }
  }

  logoClick() {
    const {history, linkTo} = this.props
    const onClickLink = linkTo || '/dashboard'
    logEventCustomParams('clickedLogo', {
      userId: get(this.context, 'clientId'),
    })
    return history.push(onClickLink)
  }

  handlePopoverOpen = (event) => {
    this.setState({anchorEl: event.currentTarget})
  }

  handlePopoverClose = () => {
    this.setState({anchorEl: null})
  }

  handleDownloadMobileAppModalOpen() {
    this.setState({downloadMobileAppOpen: true})
  }

  handleDownloadMobileAppModalClose() {
    this.setState({downloadMobileAppOpen: false})
  }

  handleToggleDownloadStore() {
    this.setState({qrCodeSelected: 'store'})
  }

  handleToggleDownloadApk() {
    this.setState({qrCodeSelected: 'apk'})
  }

  toAffiliatePortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')

    apolloClient.query({
      query: AFFILIATE_SSO_LINK_QUERY,
      variables: {accountId: account.id},
      fetchPolicy:'network-only'
    }).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  toIBPortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')

    apolloClient.mutate({
      mutation: IB_SSO_LINK_MUTATION,
      variables: {accountId: account.id},
    }).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  openWebtraderAccountsModal() {
    this.setState({showAccountsModal: true})
  }

  closeWebtraderAccountsModal() {
    this.setState({showAccountsModal: false})
  }

  handleBentoOnClick(key) {
    const firebaseParams = {
      BentoMenuItem: bentoMenuOptions[key].firebaseEvent,
      userId: get(this.context, 'clientId'),
    }
    logEventCustomParams('bentoMenuItemClick', firebaseParams)
    switch (key) {
      case 'webtrader': {
        this.handlePopoverClose()
        this.openWebtraderAccountsModal()
        break
      }
      case 'affiliate': {
        const {accounts} = this.context
        const affiliateAccount = find(accounts, (account) => isAffiliatesAccount(account))
        this.handlePopoverClose()
        this.toAffiliatePortal(affiliateAccount)
        break
      }
      case 'website': {
        this.handlePopoverClose()
        window.open(`${get(config, 'weblinks.websiteUrl')}`, '_blank')
        break
      }
      case 'ib': {
        const {accounts} = this.context
        const ibAccount = find(accounts, (account) => isIntroducingBrokerAccount(account))
        this.handlePopoverClose()
        this.toIBPortal(ibAccount)
        break
      }
      case 'telegramGroup':
      case 'tradingCentral': {
        const {featuresConfig: {tools}} = config
        const {locale, clientId: userId} = this.context
        key === 'ebook' ? logEventCustomParams('forexEbookClicked', {userId}) : logEventCustomParams(`${key}Clicked`, {userId})
        this.handlePopoverClose()
        if (get(tools[key], 'internal')) {
          window.open(`/trading-tools/${key}`, '_blank')
        } else {
          if (get(tools[key], 'url')) {
            if (get(tools[key], `${locale}Url`)) {
              window.open(tools[key][`${locale}Url`], '_blank')
            } else {
              window.open(tools[key].url, '_blank')
            }
          } else {
            window.open(replace(tools[key], '{lang}', locale), '_blank')
          }
        }
        break
      }
      case 'mobileQR': {
        const {featuresConfig: {bentoMenu}} = config
        const {companyObject} = this.context

        const firebaseParams = {
          type: isMobile() ? 'links' : 'QR',
          brand: companyObject.brandLabel,
          source: isIos() ? 'iOS' : isAndroid() ? 'Android' : 'Desktop',
          userId: get(this.context, 'clientId'),
        }
        logEventCustomParams('onQRCodeButtonClicked', firebaseParams)

        if (isIos()) {
          this.handlePopoverClose()
          window.location.href = bentoMenu.options.mobileQR.downloadStoreUrl
        } else {
          this.handlePopoverClose()
          this.handleDownloadMobileAppModalOpen()
          logEventCustomParams('onQRCodePopupPresented', firebaseParams)
        }
        break
      }
      default: {
        this.handlePopoverClose()
        break
      }
    }
  }

  renderDownloadMobileAppModalContent() {
    const {qrCodeSelected} = this.state
    const {featuresConfig: {bentoMenu}} = config
    const themePreference = getCurrentTheme(this.context)
    const {classes} = this.props

    return isMobile()
      ? <Box display='flex' flexDirection='column' alignItems='center' gap='11px'>
        {get(bentoMenu, 'options.mobileQR.downloadStoreUrl') && <img
          alt='playstore'
          src={isDarkTheme(themePreference) ? Images['download-playstore-dark.svg'] : Images['download-playstore.svg']}
          onClick={() => window.location.href = bentoMenu.options.mobileQR.downloadStoreUrl}
        />}
        {get(bentoMenu, 'options.mobileQR.downloadApkUrl') && <img
          alt='apk'
          src={isDarkTheme(themePreference) ? Images['download-apk-dark.svg'] : Images['download-apk.svg']}
          onClick={() => window.location.href = bentoMenu.options.mobileQR.downloadApkUrl}
        />}
      </Box>
      : <Box display='flex' flexDirection='column' alignItems='center' gap='24px' className={classes.box}>
        <Typography style={{fontSize: '18px'}}> <Trans {...messages.downloadMobileAppModalTitle} values={{company: this.context.companyObject.brandLabel}}/> </Typography>
        <Box display='flex'>
          <QRCode
            size={150}
            value={qrCodeSelected === 'store' ? bentoMenu.options.mobileQR.downloadStoreUrl : bentoMenu.options.mobileQR.downloadApkUrl}
            fgColor={isDarkTheme(themePreference) ? '#FFFFFF' : '#1F1F1F'}
            bgColor={isDarkTheme(themePreference) ? '#1F1F1F' : '#FFFFFF'}
          />
        </Box>
        {(get(bentoMenu, 'options.mobileQR.downloadStoreUrl') && get(bentoMenu, 'options.mobileQR.downloadApkUrl')) && <Box display='flex' flexDirection='row' gap='12px'>
          {get(bentoMenu, 'options.mobileQR.downloadStoreUrl') && <Button
            onClick={() => this.handleToggleDownloadStore()}
            className={classNames(
              classes.downloadAppButton,
              qrCodeSelected === 'store'
                ? isDarkTheme(themePreference) ? 'dark-active' : 'active'
                : isDarkTheme(themePreference) ? 'dark' : '',
            )}
          >
            <Trans {...messages.downloadApp}/>
          </Button>}
          {get(bentoMenu, 'options.mobileQR.downloadApkUrl') &&  <Button
            onClick={() => this.handleToggleDownloadApk()}
            className={classNames(
              classes.downloadAppButton,
              qrCodeSelected === 'apk'
                ? isDarkTheme(themePreference) ? 'dark-active' : 'active'
                : isDarkTheme(themePreference) ? 'dark' : '',
            )}
          >
            <Trans {...messages.androidApk}/>
          </Button>}
        </Box>}
      </Box>
  }

  renderBentoMenuContent() {
    const {accounts, companyObject} = this.context
    const {viewer, classes} = this.props
    const {featuresConfig: {bentoMenu}} = config
    const themePreference = getCurrentTheme(this.context)
    const tradingAccounts = filter(accounts, (account) => !isAffiliatesAccount(account) && !isIntroducingBrokerAccount(account)
    && !isMAMAccount(account) && (isForexAccount(account) || isCentAccount(account))
    && !account.isArchived && !account.isViewOnly && !isBitnukAccount(account))
    const section1 = filter(bentoMenuOptions, (option) => option.enabled(bentoMenu) && option.show(viewer, tradingAccounts))

    return <div className={classes.bentoMenuListWrap}>
      <Grid container spacing={2} className={classes.bentoMenuGrid}>
        {map(section1, (option) => {
          if (option.titleKey === 'divider') {
            return <Grid item xs={12} md={12} key={option.key} className={classes.bentoMenuGrid}>
              <Divider className={classNames(classes.bentoMenuDivider, isDarkTheme(themePreference) ? 'dark' : '')} />
            </Grid>
          } else {
            return <Grid item xs={12} md={6} key={option.key} className={classes.bentoMenuGrid}>
              <div className={isDarkTheme(themePreference) ? classNames(classes.bentoMenuListItem, 'dark') : classes.bentoMenuListItem} onClick={() => this.handleBentoOnClick(option.key)}>
                <Box display='flex' flexDirection='column'>
                  <Box display='flex' flexDirection='row'>
                    <Box display='flex' alignItems='center'>
                      <img
                        src={isMobile() ? option.mobileLightIcon : option.lightIcon}
                        width={isMobile() ? option.iconWidthMobile : option.iconWidth}
                        height={isMobile() ? option.iconHeightMobile : option.iconHeight}
                        alt='icon'
                      />
                    </Box>
                    <div className={classes.bentoMenuListItemTitle}> <Trans {...messages[option.titleKey]} values={{company: companyObject.brandLabel}}/> </div>
                  </Box>
                  {!isMobile() && option.subtitleKey && <div className={classes.bentoMenuListItemSubtitle}> <Trans {...messages[option.subtitleKey]} /> </div>}
                </Box>
              </div>
            </Grid>
          }
        })}
      </Grid>
    </div>
  }

  accountVerification(kycStatus, classes) {
    let verificationIcon
    if (kycStatus === kycStatuses.approved.value) {
      verificationIcon = <React.Fragment>
        <Typography variant="caption" className={classes.doneIcon}>
          <VerifiedIcon className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          <Trans {...messages.verified} />
        </Typography>
      </React.Fragment>
    } else {
      verificationIcon = <React.Fragment>
        <Typography variant="caption" className={classes.pendingIcon}>
          <Missing className={classNames({[classes.statusIcon]: true, [classes.pendingIcon]: true})}/>
          <Link to={'/accounts/verification'} className={classes.verificationLink}><Trans {...messages.unVerified} /> </Link>
        </Typography>
      </React.Fragment>
    }
    return verificationIcon
  }

  logOut() {
    const {viewer} = this.props
    const clientId = get(viewer, 'id')
    const params = {
      reason: `logout clicked - ${clientId}`,
      userId: clientId,
    }
    logEventCustomParams('logout', params)
    return this.context.logout()
  }

  renderBentoMenu() {
    const {classes} = this.props
    const {anchorEl} = this.state
    const {locale} = this.context
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const themePreference = getCurrentTheme(this.context)

    return <Popover
      id={id}
      open={open}
      onClose={this.handlePopoverClose}
      className={isDarkTheme(themePreference) ? classNames(classes.popoverContainer, 'dark') : classes.popoverContainer}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: isMobile() ? 50 : 45,
        horizontal: includes(isRtlLang, locale) ? 'right' : 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: isMobile() ? 'center' : includes(isRtlLang, locale) ? 'right' : 'left'
      }}
      BackdropProps={{invisible: !isMobile()}}
    >
      {this.renderBentoMenuContent()}
    </Popover>
  }

  depositIntent() {
    const {history} = this.props
    logEventCustomParams('depositIntent', {source: 'Dashboard', userId: get(this.context, 'clientId')})
    return history.push('/transactions/deposit')
  }

  handleProfileMenuOpen = (event) => {
    this.setState({anchorProfile: event.currentTarget})
  }

  handleProfileMenuClose = () => {
    this.setState({anchorProfile: null})
  }

  render() {
    const {accounts, companyObject} = this.context
    const {anchorEl, downloadMobileAppOpen, showAccountsModal, anchorProfile} = this.state
    const {classes, handleSidebarToggle, outer, customBackground, location: {pathname}, history, viewer, widthDown} = this.props
    const {logoFileName, logoWhiteFileName, name: logoAlt, common:{signupGroupMetrics},
      featuresConfig: {bentoMenu}} = config
    let locale = (this.context.clientType) ? this.context.locale:getItem('locale','en')

    if (!includes(map(filter(languages, (lang) => !lang.disabled && lang.client), 'value'), locale)) locale = 'en'

    const themePreference = getCurrentTheme(this.context)

    let logo = ''
    const logoAsset = !customBackground && isDarkTheme(themePreference) ? logoWhiteFileName : logoFileName
    try {
      logo = require(`../../assets/images/${logoAsset}`)
    } catch (e)
    {
      //
    }
    const isMobileView = this.props.location.pathname==='/more'
    if (isMobileView && !isMobile()) history.push('/')
    const topLevelRoutes = get(TopLevelRoutes(), 'props.children')
    const excludeRoutes = topLevelRoutes && filter(map(topLevelRoutes, (child) => get(child, 'props.path') || get(child, 'props.to')), (route) => route && !includes(['/'], route))
    const isRegistrationPath = includes(pathname, '/register')
    const isLoginPath = includes(pathname, '/login')

    const tradingAccounts = filter(accounts, (account) => !isAffiliatesAccount(account) && !isIntroducingBrokerAccount(account)  && (!isZuluAccount(account) || !isCopyTradingAccount(account))
      && !isMAMAccount(account) && (isForexAccount(account) || (isCentAccount(account) || includes(account?.accountSubType, 'cent')))
      && !account.isArchived && !account.isViewOnly && !isBitnukAccount(account)
    )

    const hasLdWalletAccount = filter(accounts, (account) => isLdWalletAccount(account)).length

    const showDepositButton =
      disableDepositWithSpecificCountries(viewer)
        ? false
        : (!outer && !isMobile() && !isMobileView && !includes(excludeRoutes, pathname) && !!(tradingAccounts.length || hasLdWalletAccount ))

    const showTradeNowButton = showDepositButton && !startsWith(pathname, '/accounts/')
    const isLoggedIn = !isEmpty(viewer)
    return (
      <AppBar position='static'
        elevation={0}
        color='default'
        className={customBackground || (isRegistrationPath && !signupGroupMetrics) ? classes.rootRegistration : classes.root}
      >
        <Toolbar className={classNames(isRegistrationPath && !signupGroupMetrics && classes.headerPadding, (showDepositButton || isLoggedIn) && classes.toolbarWithDepositButton)}>
          <Hidden mdUp>
            {!isMobileView && getSessInfoFromLocalStorage() && !includes(excludeRoutes, pathname) && <IconButton
              aria-label='Open drawer'
              className={classes.menuIcon}
              onClick={(e) => handleSidebarToggle && handleSidebarToggle(e)}
            >
              <MenuIcon />
            </IconButton>}
          </Hidden>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item className={classes.logoAlign}>
              <Grid container alignItems='center'>
                <Grid item style={{display: 'flex', alignItems: 'center'}}>
                  <img src={logo} alt={logoAlt} className={classNames(classes.logo,classes.pointer)} onClick={()=> this.logoClick()} />
                </Grid>
                {!outer && (bentoMenu && bentoMenu.enabled) && <Grid item style={{display: 'flex', alignItems: 'center'}}>
                  <IconButton classes={{root: classes.bentoMenuIconButtonRoot}} onClick={this.handlePopoverOpen}>
                    <img
                      alt='icon'
                      src={isDarkTheme(themePreference) ? Images['bento-icon-dark.svg'] : Images['bento-icon-light.svg']}
                      className={classNames(
                        isDarkTheme(themePreference) ? classNames(classes.bentoMenuIcon, 'dark') : classes.bentoMenuIcon,
                        Boolean(anchorEl) ? 'active' : ''
                      )}
                    />
                  </IconButton>
                  {this.renderBentoMenu()}
                  <Dialog
                    open={downloadMobileAppOpen}
                    className={classes.downloadAppDialogContainer}
                    onClose={() => this.handleDownloadMobileAppModalClose()}
                  >
                    <DialogTitle disableTypography>
                      <Box display='flex' flexDirection='row' justifyContent={isMobile() ? 'space-between' : 'flex-end'}>
                        {isMobile() ? <Box display='flex'>
                          <Typography variant='h6' style={{fontSize: '18px', fontWeight: 400}}>
                            <Trans {...messages.downloadMobileAppModalTitleMobile} values={{company: companyObject.brandLabel}} />
                          </Typography>
                        </Box> : null}
                        <Box display='flex' flexDirection='column' justifyContent='center'>
                          <img
                            src={isDarkTheme(themePreference) ? Images['DialogCloseIconDark.svg'] : Images['DialogCloseIcon.svg']}
                            alt='test'
                            width='15px'
                            height='15px'
                            onClick={() => this.handleDownloadMobileAppModalClose()}
                          />
                        </Box>
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      {get(bentoMenu, 'options.mobileQR.enabled') && this.renderDownloadMobileAppModalContent()}
                    </DialogContent>
                  </Dialog>
                </Grid>}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems='center' spacing={2}>
                {!showDepositButton && (isLoginPath || isRegistrationPath) && <Grid item>
                  <Link to={{pathname: (outer)?'/language':'/change-language', state: {prevLocation: this.props.location}}}
                    className={classes.fullWidth} onClick={() => {this.closeLanguage()}}>
                    <Grid lang={locale}>
                      <FlagIcon
                        code={languages[locale].flag}
                        className={classes.link}
                        size={'lg'}
                      />
                      <Box
                        component='span'
                        className={classes.arrowLink}
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        {widthDown ? ' ' : languages[locale].native}
                      </Box>
                      <ArrowDown className={classes.arrowLink} />
                    </Grid>
                  </Link>
                </Grid>}
                {showDepositButton && <Grid item>
                  <Button
                    className={classes.depositButton}
                    onClick={() => this.depositIntent()}
                    variant='contained'
                    size='large'
                    color='secondary'
                    style={{padding: '8px 24px'}}
                  >
                    <Trans {...messages.deposit} />
                  </Button>
                </Grid>}
                {/* {showDepositButton && <Grid item>
                  <Button
                    className={classes.depositButton}
                    onClick={() => this.openWebtraderAccountsModal()}
                    variant='contained'
                    size='large'
                    color='primary'
                    style={{padding: '8px 24px'}}
                  >
                    <Trans {...messages.trade} />
                  </Button>
                </Grid>} */}
                {showTradeNowButton && <Grid item>
                  <ForexTradeNow accounts={accounts} type='button' testSwissMarkets={true}/>
                </Grid>}
                {(!isMobile() && isLoggedIn) && <Grid item className={classes.profileIcon}>
                  <IconButton className={classes.noticesButton} onClick={()=> history.push('/notifications')}>
                    <Badge
                      className={classes.dot}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      variant={size(get(viewer, 'pendingNotices')) > 0 ? 'dot' : 'standard'}
                      color="error"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Grid>}
                {(!isMobile() && isLoggedIn) && <Grid item className={classes.profileIcon}>
                  <IconButton edge="start" color='secondary' classes={{root: classes.noticesButton}} onMouseOver={(e)=> this.handleProfileMenuOpen(e)}>
                    <AccountCircle className={classes.checkIcon}/>
                  </IconButton>
                  <Menu
                    id="primary-menu"
                    elevation={0}
                    anchorEl={anchorProfile}
                    disableAutoFocusItem
                    open={Boolean(anchorProfile)}
                    onClose={this.handleProfileMenuClose}
                    onMouseLeave={this.handleProfileMenuClose}
                    autoFocus={false}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: includes(isRtlLang, locale) ? 'left' : 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: includes(isRtlLang, locale) ? 'left' : 'right',
                    }}
                    marginThreshold={2}
                    slotProps={{paper: {className: classes.menu}}}
                    dir={includes(isRtlLang, locale) ? 'rtl' : 'ltr'}
                  >
                    <MenuItem dir={includes(isRtlLang, locale) ? 'rtl' : 'ltr'} className={classes.firstMenuItem} onClick={this.handleProfileMenuClose}>
                      <Grid container direction="column">
                        <Typography variant="body2" className={classes.nameText}>{[get(viewer, 'firstName'), get(viewer, 'lastName')].join(' ')}</Typography>
                        <Typography variant="body2" className={classes.nameText}>
                          <Trans {...messages.profileInformation}
                            values={{
                              company: companyObject?.brandLabel,
                              clientId: get(viewer, 'id'),
                            }} />
                        </Typography>
                        {this.accountVerification(get(viewer, 'kycStatus'), classes)}
                      </Grid>
                    </MenuItem>
                    <MenuItem onClick={this.handleProfileMenuClose} className={classes.menuItemContent}>
                      <Link to={{pathname: (outer)?'/language':'/change-language', state: {prevLocation: this.props.location}}}
                        className={classes.fullWidth} onClick={() => {this.closeLanguage()}}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="body2" className={classes.textHeaderMenu}><Trans {...messages.language} /></Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" className={classes.languageItem}>{widthDown ? ' ' : languages[locale].native}</Typography>
                          </Grid>
                        </Grid>
                      </Link>
                    </MenuItem>
                    <MenuItem className={classNames(classes.menuItemContent, classes.fullWidth)}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography variant="body2" className={classes.textHeaderMenu}><Trans {...messages.darkTheme} /></Typography>
                        </Grid>
                        <Grid item>
                          <ThemeSelection />
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem  to={'/logout'} onClick={() => this.logOut()} className={classNames(classes.menuItemContent, classes.fullWidth)}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography variant="body2" className={classes.logoutItem}><Trans {...messages.logout} /></Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Menu>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          <ForexWebtraderModal
            tradingAccounts={tradingAccounts}
            showAccountsModal={showAccountsModal}
            onClose={() => this.closeWebtraderAccountsModal()}
          />
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  handleSidebarToggle: PropTypes.func,
}

export default compose(
  withApollo,
  withRouter,
  withStyles(styles, {withTheme: true}),
  withMediaQuery('md')
)(Header)
