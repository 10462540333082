import React, {Component} from 'react'
import AppContext from './contexts/AppContext'
import {flowRight as compose, get, isBoolean, isEmpty, map, slice} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText, Typography} from '@mui/material'
import classNames from 'classnames'
import {ReactComponent as ExpandMoreIcon} from '../../assets/images/expand_arrow.svg'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'

const styles = theme => ({
  pointer: {
    cursor: 'pointer',
  },
  accordionFull: {
    display: 'inline-block',
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  heading: {
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  faqContent: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight' ]: '16px',
    '& a' : {
      color: theme.palette.primary.main
    },
    '& em' : {
      fontStyle: 'normal'
    }
  },
  centerLink: {
    textAlign: 'center',
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  itemRoot: {
    border: `1px solid ${theme.palette.mode === 'dark' ? '#474747' : '#D6D6D6'}`,
    borderRadius: '6px',
    boxShadow: 'none',
  },
  notExpandedIcon : {
    fill: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '12px',
    }
  },
  expandedIcon: {
    fill: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
    }
  },
  accordionSummaryRoot: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    }
  },
  borderBelowExpanded: {
    width: '100%',
    margin: 'auto',
  },
  itemTitleExpanded: {
    color: theme.palette.primary.main,
  },
  expandedTitle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  faqSpanStyle: {
    '& p': {
      margin: 0,
    }
  },
  seeAllFontStyle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
})

class AccordionCustom extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const initialItems = props.initialItems || 5
    this.state = {
      expanded: false,
      activeQuestion: false,
      setShow: false,
      initialItems: initialItems,
      listItems: props.viewMore ? !isEmpty(props.items) && slice(props.items, 0, initialItems) : props.items
    }
  }

  handleChange(key) {
    this.setState(state => ({
      activeQuestion: key,
      expanded: !state.expanded ? `panel${key}`: false,
    }))
  }

  viewMoreClick() {
    this.setState(state => ({
      setShow: !state.setShow,
      listItems: !state.setShow ? this.props.items : slice(this.props.items, 0, state.initialItems),
    }))
  }

  render() {
    const {classes, viewMore} = this.props
    const {expanded, listItems, setShow, activeQuestion} = this.state
    return (<List>
      {map(listItems, (faq, key) => <React.Fragment key={key}>
        <ListItem classes={{root: classNames(classes.pointer, classes.accordionFull)}}>
          <Accordion
            expanded={expanded === `panel${key}`}
            onChange={() => this.handleChange(key)}
            className={classes.itemRoot}
            square
            disableGutters
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  className={classNames(
                    !isBoolean(expanded) && activeQuestion === key ? classes.notExpandedIcon : '',
                    isBoolean(expanded) ? classes.expandedIcon : '',
                  )}
                />
              }
              aria-controls='panel1bh-content'
              id='panel1bh-header'
              classes={{root: classes.accordionSummaryRoot, expanded: classes.expandedTitle}}
            >
              <span className={!isBoolean(expanded) && activeQuestion === key ? classes.borderBelowExpanded : ''}>
                <Typography className={classNames(classes.heading, !isBoolean(expanded) && activeQuestion === key ? classes.itemTitleExpanded : '')}>
                  {get(faq, 'faqQuestion')}
                </Typography>
              </span>
            </AccordionSummary>
            <AccordionDetails sx={{pb: 2, pl: 2, pr: 2, pt: 0}}>
              <Typography variant='body1' className={classNames(classes.faqContent)}>
                <span dangerouslySetInnerHTML={{__html: faq.faqAnswer}} className={classes.faqSpanStyle} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ListItem>
      </React.Fragment>
      )}
      {viewMore && <ListItem classes={{root: classNames(classes.pointer, classes.centerLink)}}
        onClick={() => this.viewMoreClick()}>
        <ListItemText>
          <Typography variant='subtitle1' className={classNames(classes.link, classes.seeAllFontStyle)}>
            {!setShow ? <Trans {...messages.seeAll} /> : <Trans {...messages.seeLess} /> }
          </Typography>
        </ListItemText>
      </ListItem>
      }
    </List>)
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(AccordionCustom)
