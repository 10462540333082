import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose, get, includes, size, startCase} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import AppContext from '../Common/contexts/AppContext'
import {Button, Card, Grid, IconButton, Typography, SvgIcon, Tooltip, Box} from '@mui/material'
import messages from '../../assets/messages'
import classNames from 'classnames'
import {ReactComponent as Visibility} from '../../assets/images/visibilityNew.svg'
import {ReactComponent as VisibilityDark} from '../../assets/images/visibilityNewDark.svg'
import {ReactComponent as VisibilityOff} from '../../assets/images/visibilityOff.svg'
import {ReactComponent as VisibilityOffDark} from '../../assets/images/visibilityOffDark.svg'
import {ReactComponent as OpenLinksIcon} from '../../assets/images/open-links-purple.svg'
import {clientTypes, kycStatuses} from '@bdswiss/common-enums'
import {ReactComponent as VerifiedIcon} from '../../assets/images/verifiedIcon.svg'
import {ReactComponent as VerifiedIconDark} from '../../assets/images/verifiedIconDark.svg'
import {ReactComponent as CopyIcon} from '../../assets/images/copyLink.svg'
import {ReactComponent as Missing} from '../../assets/images/unverifiedNew.svg'
import {ReactComponent as MissingDark} from '../../assets/images/unverifiedDark.svg'
import CopyToClipboard from 'react-copy-to-clipboard'
import {isAffiliateClient, isDarkTheme, isIBClient, isIBClientSubIb, isMobile, logEventCustomParams, truncateEmail} from '../../common/utils'
import BecomePartnerDialog from './BecomePartnerDialog'
import PartnerLinksDialog from './PartnerLinksDialog'
import LoadingWrapper from './LoadingWrapper'
import {GET_COMPETITIONS_APPLICABLE_COUNT_QUERY} from '../../graphql/competitions/queries'
import {graphql} from 'react-apollo'
import config from '../../config'

const styles = theme => ({
  cardRoot: {
    position:'relative',
    height: '100%',
    boxShadow: 'none',
    borderRadius: '8px',
    padding: '24px',
    border: theme.palette.mode === 'dark' ? '1px solid var(--stroke-1, #1F1F1F)' : '1px solid var(--stroke-1, #EBEBEB)',
    background: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
    }
  },
  iconButton: {
    padding: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  becomePartner : {
    float: theme.direction === 'rtl' ? 'left' : 'right',
    padding: 0,
    '&.buttons': {
      gap: '16px',
      display: 'flex',
    }
  },
  copyButton: {
    display: 'flex',
    padding: '4px 10px',
    'justify-content': 'flex-end',
    'align-items': 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#38136A' : theme.palette.lightpurple.color,
    color: theme.palette.mode === 'dark' ?'#A967FD' : '#6F00FF',
    '&:hover':{
      backgroundColor: theme.palette.mode === 'dark' ? '#38136A' : theme.palette.lightpurple.color,
    },
    boxShadow: 'none',
    height:'32px',
    [theme.breakpoints.down('md')]:{
      height:'40px',
    },
    gap: '8px',
    '&.competition': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.lightblue.color,
    },
    '&.links':{
      display: 'flex',
    }
  },
  linkButtonMobile: {
    display: 'flex',
    padding: '8px 16px 8px 16px',
    'align-items': 'center',
    gap: '8px',
    backgroundColor: theme.palette.mode === 'dark' ? '#303033' : '#E3E6ED',
    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
    width: '100%',
    height: '40px',
    marginTop: 0,
    boxShadow: 'none',
  },
  partnerIcon: {
    backgroundColor: 'transparent',
    verticalAlign: 'middle',
    color: theme.palette.mode === 'dark' ?'#A967FD' : '#6F00FF',
    [theme.direction === 'rtl' ? 'left' : 'right']: '15px'
  },
  eyeArea: {
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: '10px',
  },
  fullName: {
    color: theme.palette.mode === 'dark' ? '#FFFFFF': '#000',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },
  pointer: {
    cursor: 'pointer',
    'text-align': 'right'
  },
  link: {
    color: theme.palette.primary.main,
    'font-weight': '500',
  },
  bold: {
    'font-weight': 400,
  },
  customTooltip: {
    backgroundColor: '#5C5C5C',
    color: '#FFFFFF',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  lightGreyLoading: {
    backgroundColor: theme.palette.extralightgrey.color,
  },
  mobileContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
  },
  mobileTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  mobileFullName: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.18px',
  },
  mobileClientInfoContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
  mobileFieldContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  body2Text: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    '&.greyText': {
      color: theme.palette.secondary.main,
    }
  },
  subtitle1Text: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  desktopClientInfoCellContainer: {
    gap: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  desktopDataCellContainer: {
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  becomePartnerLinksText:{
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    '&.purpleText': {
      color: '#6F00FF'
    }
  },
  mobileButtonsContainer:{
    display: 'flex',
    gap: '16px',
    flexDirection: 'column-reverse',
    paddingTop: '16px',
  }
})

const hideSensitiveDate = '***'

class ClientInfoSection extends Component {
  static contextType = AppContext

  constructor() {
    super()
    this.state = {
      loading: true,
      clickedEvent: false,
      becomePartnerClick: false,
      partnerLinksClick: false,
      showBecomePartnerDialog: true,
      showPartnerLinks: true
    }
  }

  getClientTypeLabel(clientType) {
    const {t} = this.props
    if (includes([clientTypes.ib.value, clientTypes.affiliate.value], clientType)) {
      return t(messages.clientTypesPartner.i18nKey, messages.clientTypesPartner.defaults)
    } else if (includes([clientTypes.ibCorporate.value, clientTypes.affiliateCorporate.value], clientType)) {
      return t(messages.clientTypesPartnerCorporate.i18nKey, messages.clientTypesPartnerCorporate.defaults)
    } else {
      return clientTypes[clientType].label
    }
  }

  setEye() {
    const {handleSensitiveData, showSensitiveData} = this.props
    window.localStorage.setItem('showSensitiveData', !showSensitiveData)
    handleSensitiveData('showSensitiveData', !showSensitiveData)
    if (!showSensitiveData) {
      logEventCustomParams('hideAccountInformationClicked',{
        userId:  get(this.props, 'viewer.id')
      })
    }
  }

  accountVerification(kycStatus) {
    const {classes} = this.props
    const {themePreference} = this.context

    let verificationIcon
    if (kycStatus === kycStatuses.approved.value) {
      verificationIcon = <Box className={classes.desktopDataCellContainer}>
        {isDarkTheme(themePreference) ? <VerifiedIconDark /> : <VerifiedIcon />}
        <Trans {...messages.verified} />
      </Box>
    } else {
      verificationIcon = <Box className={classes.desktopDataCellContainer}>
        {isDarkTheme(themePreference) ? <MissingDark /> : <Missing />}
        <Trans {...messages.unVerified} />
      </Box>
    }
    return verificationIcon
  }

  copyLink() {
    this.setState({clickedEvent: true})
  }
  handleMobileTooltipOpen = (event) => {
    setTimeout(() => {
      this.setState({clickedEvent: false})
    }, '2000')
    this.setState({anchorEl: event.currentTarget})
  }
  hideBecomePartnerDialog() {
    this.setState({showBecomePartnerDialog: false})
  }
  becomePartnerClicked() {
    this.setState({becomePartnerClick: true})
    this.setState({showBecomePartnerDialog: true})
  }
  partnerLinksClicked() {
    this.setState({partnerLinksClick: true})
    this.setState({showPartnerLinks: true})
  }
  hideIblinks() {
    this.setState({showPartnerLinks: false})
  }
  render() {
    const {companyObject, clientType, themePreference, country} = this.context
    const {t, classes, viewer, showSensitiveData, loading, history, competitions} = this.props
    const {showBecomePartnerDialog, becomePartnerClick, partnerLinksClick, showPartnerLinks}= this.state
    const {featuresConfig: {competitions: competitionsAllowed}} = config
    const isIb = isIBClient(clientType)
    const isAffiliate = isAffiliateClient(clientType)
    const email = get(viewer, 'email', '')
    const [user] = email.split('@')
    let showCompetitions = competitionsAllowed && competitions > 0 && !includes(get(competitionsAllowed, 'blockedCountries'), country.toUpperCase())
    if (isIBClient(clientType) && !isIBClientSubIb(clientType, viewer?.registrationCampaigns)) showCompetitions = false

    return (!isMobile() ? <Card classes={{root: classes.cardRoot}}>
      <Grid container direction="row" alignItems="center" spacing={0}
        justifyContent={isMobile() ? 'space-between' : 'flex-start'} style={{paddingBottom: 24, display: 'flex',
          flexDirection: 'row', justifyContent: 'space-between'}}>
        <Grid display="flex">
          <Grid item>
            <LoadingWrapper loading={loading}>
              <Typography className={classes.fullName} variant={'body2'}>
                {showSensitiveData ? [get(viewer, 'firstName'), get(viewer, 'lastName')].join(' ') : hideSensitiveDate}
              </Typography>
            </LoadingWrapper>
          </Grid>
          <Grid item className={classes.eyeArea}>
            <LoadingWrapper loading={loading} variant='circular'>
              <IconButton
                className={classes.iconButton}
                aria-label="Toggle sensitive data visibility"
                onClick={() => this.setEye()}
              >
                {showSensitiveData ?
                  isDarkTheme(themePreference) ? <VisibilityOffDark />: <VisibilityOff /> :
                  isDarkTheme(themePreference) ? <VisibilityDark /> :<Visibility />}
              </IconButton>
            </LoadingWrapper>
          </Grid>
        </Grid>
        <Grid display="flex" style={{gap: '16px'}}>
          {showCompetitions &&
          <Grid item className={classNames(classes.paper, classes.grow, classes.becomePartner)}>
            <span className={classNames(classes.paper, classes.becomePartner)}>
              <LoadingWrapper loading={loading}>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classNames(classes.copyButton, {competition: true})}
                  onClick={() => history.push('/trading-competitions')}
                >
                  <Trans {...messages.enterCompetitionLowcase} />
                </Button>
              </LoadingWrapper>
            </span>
          </Grid>
          }
          {!isMobile() && !isIb && !isAffiliate && <Grid item className={classNames(classes.paper, classes.grow, classes.becomePartner)}>
            <span className={classNames(classes.paper, classes.becomePartner)}>
              <LoadingWrapper loading={loading}>
                <Button
                  onClick={() => {
                    this.becomePartnerClicked()
                    logEventCustomParams('becomePartnerClicked', {
                      userId: get(this.props, 'viewer.id'),
                    })
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.copyButton}
                >
                  <Trans {...messages.becomeAPartner} />
                </Button>
              </LoadingWrapper>
            </span>
          </Grid>}
          {becomePartnerClick && showBecomePartnerDialog && <BecomePartnerDialog
            open={showBecomePartnerDialog}
            viewer={viewer}
            onClose={() => this.hideBecomePartnerDialog()}
          />}
          {!isMobile() && isIb  && <Grid item className={classNames(classes.paper, classes.grow, classes.becomePartner)}>
            <span className={classNames(classes.paper, classes.becomePartner)}>
              <LoadingWrapper loading={loading}>
                <Button
                  onClick={() => {
                    this.partnerLinksClicked()
                    logEventCustomParams('partnerLinksClicked', {
                      userId: get(this.props, 'viewer.id'),
                    })
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classNames(classes.copyButton, {links: true})}
                >
                  <Trans {...messages.partnerlinks} />
                  <SvgIcon
                    viewBox='3 -1 24 24'
                    className={classes.partnerIcon}
                    component={OpenLinksIcon}
                  />
                </Button>
              </LoadingWrapper>
            </span>
          </Grid>}
        </Grid>
        {partnerLinksClick && isIb && showPartnerLinks && <PartnerLinksDialog
          open={showPartnerLinks}
          viewer={viewer}
          onClose={() => this.hideIblinks()}
        />
        }
      </Grid>
      <Grid container>
        <Grid item lg={3}>
          <Box className={classes.desktopClientInfoCellContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                <Trans {...messages.status} />:
              </Typography>
            </LoadingWrapper>
            <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
              <Typography variant='subtitle1' className={classes.subtitle1Text}>
                {this.accountVerification(get(viewer, 'kycStatus'), classes)}
              </Typography>
            </LoadingWrapper>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <Box className={classes.desktopClientInfoCellContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                <Trans {...messages.profileInformation}
                  values={{
                    company: companyObject?.brandLabel,
                    clientId: '',
                  }} />
              </Typography>
            </LoadingWrapper>
            <Box className={classes.desktopDataCellContainer}>
              <LoadingWrapper loading={loading}>
                <Typography variant='body1' className={classNames(classes.inline, classes.clientLink, classes.displayPadding, classes.bold)}>{showSensitiveData ? get(viewer, 'id') : hideSensitiveDate}</Typography>
              </LoadingWrapper>
              {loading ? null : <React.Fragment>
                <CopyToClipboard text={get(viewer, 'id')}>
                  <Typography variant='caption' className={classes.pointer}
                    onClick={()=> this.copyLink()}
                    onMouseLeave={()=> this.setState({clickedEvent: false})}>
                    <SvgIcon
                      viewBox='0 0 20 20'
                      component={CopyIcon}
                    />
                  </Typography>
                </CopyToClipboard>
                <Tooltip
                  open={this.state.clickedEvent}
                  title={t(messages.partnerLinkCopied.i18nKey,messages.partnerLinkCopied.defaults)}
                  placement="right"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  onClose={() => this.setState({clickedEvent: false})}
                ><span></span>
                </Tooltip>
              </React.Fragment>}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <Box className={classes.desktopClientInfoCellContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                {`${startCase(t(messages.type.i18nKey, messages.type.defaults))}:`}
              </Typography>
            </LoadingWrapper>
            <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
              <Typography variant='body1' className={classes.bold}>
                {this.getClientTypeLabel(clientType)}
              </Typography>
            </LoadingWrapper>
          </Box>
        </Grid>
        <Grid item lg={3}>
          <Box className={classes.desktopClientInfoCellContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                {`${startCase(t(messages.email.i18nKey, messages.email.defaults))}:`}
              </Typography>
            </LoadingWrapper>
            <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
              {size(user) > 15 ? <Tooltip title={email} placement="bottom" classes={{tooltip: classNames(classes.customTooltip, classes.toolTipPartner)}}>
                <Typography variant='body1' className={classes.bold}>
                  {showSensitiveData ? truncateEmail(email, 9, 4) : hideSensitiveDate}
                </Typography></Tooltip> : <Typography variant='body1' className={classes.bold}> {showSensitiveData ? truncateEmail(email, 9, 4) : hideSensitiveDate}</Typography>}
            </LoadingWrapper>
          </Box>
        </Grid>
        {becomePartnerClick && showBecomePartnerDialog && <BecomePartnerDialog
          open={showBecomePartnerDialog}
          viewer={viewer}
          onClose={() => this.hideBecomePartnerDialog()}
        />}
      </Grid>
    </Card>
      : <Box className={classes.mobileContainer}>
        <Box className={classes.mobileTitleContainer}>
          <LoadingWrapper loading={loading}>
            <Typography className={classes.mobileFullName} variant='h5'>
              {showSensitiveData ? [get(viewer, 'firstName'), get(viewer, 'lastName')].join(' ') : hideSensitiveDate}
            </Typography>
          </LoadingWrapper>
          <LoadingWrapper loading={loading} variant='circular'>
            <IconButton
              className={classes.iconButton}
              onClick={() => this.setEye()}
              aria-label="Toggle sensitive data visibility"
            >
              {showSensitiveData ?
                isDarkTheme(themePreference) ? <VisibilityOffDark />: <VisibilityOff /> :
                isDarkTheme(themePreference) ? <VisibilityDark /> :<Visibility />}
            </IconButton>
          </LoadingWrapper>
        </Box>
        <Box className={classes.mobileClientInfoContainer}>
          <Box className={classes.mobileFieldContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                <Trans {...messages.status} />:
              </Typography>
            </LoadingWrapper>
            <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
              <Typography variant='body2' className={classes.body2Text}>
                {this.accountVerification(get(viewer, 'kycStatus'), classes)}
              </Typography>
            </LoadingWrapper>
          </Box>
          <Box className={classes.mobileFieldContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                <Trans {...messages.profileInformation}
                  values={{
                    company: companyObject?.brandLabel,
                    clientId: '',
                  }} />
              </Typography>
            </LoadingWrapper>
            <Box sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(1),
            })}>
              <LoadingWrapper loading={loading}>
                <Typography variant='body2' className={classes.body2Text}> {showSensitiveData ? get(viewer, 'id') : hideSensitiveDate} </Typography>
              </LoadingWrapper>
              {loading ? null : <div>
                <CopyToClipboard text={get(viewer, 'id')}>
                  <Typography variant='caption'
                    style={{height: '20px', width: '20px'}}
                    onClick={(event) => {
                      this.copyLink()
                      this.handleMobileTooltipOpen(event)
                    }}>
                    <SvgIcon>
                      <CopyIcon />
                    </SvgIcon>
                  </Typography>
                </CopyToClipboard>
                <Tooltip
                  open={this.state.clickedEvent}
                  title={t(messages.partnerLinkCopied.i18nKey,messages.partnerLinkCopied.defaults)}
                  placement="right"
                />
              </div>}
            </Box>
          </Box>
          <Box className={classes.mobileFieldContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                {`${startCase(t(messages.type.i18nKey, messages.type.defaults))}:`}
              </Typography>
            </LoadingWrapper>
            <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
              <Typography variant='body2' className={classes.body2Text}>
                {this.getClientTypeLabel(clientType)}
              </Typography>
            </LoadingWrapper>
          </Box>
          <Box className={classes.mobileFieldContainer}>
            <LoadingWrapper loading={loading}>
              <Typography variant='body2' className={classNames(classes.body2Text, {greyText: true})}>
                {`${startCase(t(messages.email.i18nKey, messages.email.defaults))}:`}
              </Typography>
            </LoadingWrapper>
            <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
              {size(user) > 15 ? <Tooltip title={email} placement="bottom" classes={{tooltip: classNames(classes.customTooltip, classes.toolTipPartner)}}>
                <Typography variant='body1' className={classNames(classes.bold, classes.body2Text)}>
                  {showSensitiveData ? truncateEmail(email, 9, 4) : hideSensitiveDate}
                </Typography></Tooltip> : <Typography variant='body1' className={classNames(classes.bold, classes.body2Text)}> {showSensitiveData ? truncateEmail(email, 9, 4) : hideSensitiveDate}</Typography>}
            </LoadingWrapper>
          </Box>
          <Box className={classes.mobileButtonsContainer}>
            {showCompetitions  && <Grid>
              <LoadingWrapper loading={false}>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.linkButtonMobile}
                  onClick={() => history.push('/trading-competitions')}
                >
                  <Typography className={classes.becomePartnerLinksText}> <Trans {...messages.enterCompetitionLowcase} /> </Typography>
                </Button>
              </LoadingWrapper>
            </Grid>
            }
            {!isIb && !isAffiliate && <Grid item xs={isMobile() ? 12 : 'auto'} className={classNames(classes.grow)} style={{textAlign: 'center'}} justifyContent={'center'}>
              <LoadingWrapper loading={loading}>
                <Button
                  onClick={() => {
                    this.becomePartnerClicked()
                    logEventCustomParams('becomePartnerClicked', {
                      userId: get(this.props, 'viewer.id'),
                    })
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.linkButtonMobile}
                  fullWidth={isMobile()}
                >
                  <Typography className={classes.becomePartnerLinksText} ><Trans {...messages.becomeAPartner}/> </Typography>
                </Button>
              </LoadingWrapper>
              {becomePartnerClick && showBecomePartnerDialog && <BecomePartnerDialog
                open={showBecomePartnerDialog}
                viewer={viewer}
                onClose={() => this.hideBecomePartnerDialog()}
              />}
            </Grid>}
            {isIb && <Grid item xs={isMobile() ? 12 : 'auto'} className={classNames( classes.grow)} style={{textAlign: 'center'}} justifyContent={'center'}>
              <LoadingWrapper loading={loading}>
                <Button
                  onClick={() => {
                    this.partnerLinksClicked()
                    logEventCustomParams('partnerLinksClicked', {
                      userId: get(this.props, 'viewer.id'),
                    })
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.linkButtonMobile}
                  fullWidth={isMobile()}
                >
                  <Typography className={classes.becomePartnerLinksText}><Trans {...messages.partnerlinks}/> </Typography>
                </Button>
              </LoadingWrapper>
              {partnerLinksClick && isIb && showPartnerLinks && <PartnerLinksDialog
                open={showPartnerLinks}
                viewer={viewer}
                onClose={() => this.hideIblinks()}
              />}
            </Grid>}
          </Box>
        </Box>
      </Box>
    )
  }
}
export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  graphql(GET_COMPETITIONS_APPLICABLE_COUNT_QUERY, {
    skip: props => !props.viewer,
    options: (props) => ({
      variables: {memberId: props.viewer.id},
    }),
    props: ({data}) => ({
      competitions: get(data, 'competitionsApplicableCount', 0),
    })
  }),
)(ClientInfoSection)
