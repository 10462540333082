import React, {Component} from 'react'
import {
  find, head, keys, map, groupBy, pickBy, keyBy, merge, get, values, last, has, includes, isEmpty, omit,
  sortBy, filter, flowRight as compose, omitBy, toUpper, some, orderBy, reduce,
} from 'lodash'
import {graphql, withApollo} from 'react-apollo'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import SumsubWebSdk from '@sumsub/websdk-react'
import CheckIcon from '@mui/icons-material/Check'
import ListItem from '@mui/material/ListItem'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import withStyles from '@mui/styles/withStyles'
import SelectDocument from './SelectDocument'
import PageTitle from '../../Common/PageTitle'
import PageSubTitle from '../../Common/PageSubTitle'
import LoadingButton from '../../Common/LoadingButton'
import AppContext from '../../Common/contexts/AppContext'
import messages from '../../../assets/messages'
import {isMobile, putFile} from '../../../common/utils/browser'
import {hasVipOrRaw, isAffiliateClient} from '../../../common/utils/accounts'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY, ACCOUNTS_QUERY, AVAILABLE_POF_DOCUMENTS_QUERY} from '../../../graphql/queries'
import {DOCUMENT_CATEGORY_TYPES, safeParseJSON, getMissingDocs} from '../../../common/utils/general'
import {SIGN_UPLOAD_URL_MUTATION, CREATE_OWN_DOCUMENT_MUTATION} from '../../../graphql/mutations'
import config from '../../../config'
import {checkFileMimeType} from '../../../common/utils/validations'
import Images from '../../Common/Images'
import {acceptedFormats, maxFileSize} from '../../../common/utils/variables'
import {documentTypes, fundingCategories, clientTypes, countries} from '@bdswiss/common-enums'
import {withRouter} from 'react-router-dom'
import Card from '@mui/material/Card'
import classNames from 'classnames'
import {Loading} from '../../Common/Loading'
import {documentHelperText} from '../../../common/utils/uioptions'
import Tooltip from '@mui/material/Tooltip'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import {getSettings} from '../../../common/utils/requests'
import MakeSureSection from './MakeSureSection'
import {withMediaQuery} from '../../Common'
import PageBody from '../../Common/PageBody'

const styles = theme => ({
  checkIcon: {
    color: theme.palette.primary.main
  },
  listItem: {
    borderBottom: '1px solid #e0e0e0',
  },
  listItemBottom: {
    backgroundColor: 'none',
  },
  listExamplesBottom: {
    border: 'none',
    display: 'inline-flex',
    backgroundColor: 'none',
  },
  uploadTypeText: {
    textAlign: 'center',
  },
  description: {
    fontSize:15,
    fontStyle:'italic'
  },
  exampleImg: {
    width:'70%',
    marginTop: '5%'
  },
  pofExampleImg: {
    width:'100%',
  },
  exampleText:{
    lineHeight:1
  },
  exampleBtn:{
    padding:'0 11px',
    textAlign: 'left',
    wordBreak:'break-word',
    '&:hover':{
      background:'none'
    },
    display: 'block',
  },
  defaultImage:{
    width: '100%',
  },
  zeroMarginTop:{
    marginTop:0
  },
  highlightText:{
    fontWeight:400,
    fontStyle:'italic'
  },
  cell: {
    padding: 0,
    verticalAlign: 'middle',
    borderBottom: 0
  },
  logoCell: {
    width: '15%',
  },
  logo: {
    width: 30,
    marginTop: 5,
    marginLeft: 5
  },
  greenText:{
    color: theme.palette.green.color,
    lineHeight:'40px'
  },
  textRight:{
    textAlign: 'right'
  },
  typeBadge: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginRight: 18,
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px',
      marginTop: '-7px'
    },
  },
  card:{
    backgroundColor: theme.palette.lightred.color
  },
  bankWire:{
    backgroundColor: theme.palette.lightcyan.color
  },
  onlineBanking:{
    backgroundColor: theme.palette.lightblue.color
  },
  eWallet:{
    backgroundColor: theme.palette.lightgreen.color
  },
  crypto:{
    backgroundColor: theme.palette.lightorange.color
  },
  onlineDeposit:{
    backgroundColor: theme.palette.lightpurple.color
  },
  otherPof:{
    backgroundColor: theme.palette.lightgrey.color
  },
  cardContent:{
    flexGrow:1,
    marginTop: '4px'
  },
  inline: {
    display: 'inline'
  },
  makeSureDiv: {
    marginTop: 30,
    padding: '10px 20px',
    backgroundColor: theme.palette.lightyellow.color,
    color: theme.palette.black.color,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 5,
  },
  list: {
    padding: '5px 0'
  },
  greyText:{
    color: theme.palette.grey.color,
  },
  pointer: {
    cursor: 'pointer',
  },
  uploadDiv: {
    marginTop: 20
  },
  disabled: {
    opacity: 0.5,
    '&:hover':{
      backgroundColor: 'inherit',
      cursor: 'default'
    },
  },
  icon: {
    marginTop: 5
  },
  capitalize: {
    textTransform: 'capitalize'
  },
})

const partnerTypesToSubcategoryMapping = {
  [clientTypes.affiliate.key]: 'affiliate',
  [clientTypes.affiliateCorporate.key]: 'affiliate',
  [clientTypes.ib.key]: 'ib',
  [clientTypes.ibCorporate.key]: 'ib',
}

const SuccessfulUpload = ({classes, queuePosition, accounts, skipWaitingTime, docProcessingTime, translations, docType, clientType, clientId}) => {
  logEventCustomParams('documentUploadCompleted', {type: docType, userId: clientId})
  const dueCourse = isAffiliateClient(clientType) && get(documentTypes[docType], 'dueCourse', false)
  const checkTime = docProcessingTime >= 15
  return (<Grid container direction="row" spacing={1}>
    <Grid item xs={12}>
      <Typography variant="body1"><Trans {...messages.documentUploaded} /></Typography>
    </Grid>
    {!hasVipOrRaw(accounts) &&
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="body1"><Trans {...messages.documentUploadedMessage} /></Typography>
        </Grid>
        {!skipWaitingTime && <Grid item xs={12}>
          <Typography variant="body2" className={'italicText'}>
            {dueCourse ? <Trans {...messages.waitingTimeDue}/> : checkTime ? <React.Fragment><Trans {...messages.documentProcessed}/><sup>*</sup></React.Fragment>
              : <Trans {...messages.waitingTime} values={{docProcessingTime}}/>}
          </Typography>
          {!dueCourse && checkTime && <Typography variant="caption">*<Trans {...messages.waitingTimeFootnote} /></Typography>}
          <Typography variant="caption"><Trans {...messages.customerSupport}/> </Typography>
        </Grid>}
      </React.Fragment>}
  </Grid>
  )}

const FailedUpload = () => (
  <Grid container direction="row" spacing={3}>
    <Grid item xs={12}>
      <Typography variant="body1">
        <Trans {...messages.documentFailedUploadMessage} />
      </Typography>
    </Grid>
  </Grid>
)

class ChooseDocument extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const {clientType, missingDocuments} = this.props.viewer
    const category = get(props, 'match.params.category')
    const isPof = category === 'pof'
    const documentCategoryType = find(DOCUMENT_CATEGORY_TYPES, {category: category})
    const paymentMethod = safeParseJSON(get(props, 'location.state.paymentMethod', '{}'))
    const paymentMethods = get(props, 'viewer.paymentMethods') || []
    const fundingCategory = isPof && paymentMethods.length === 1 ? paymentMethods[0].fundingCategory : get(paymentMethod, 'fundingCategory', '')
    const paymentMethodId = isPof && paymentMethods.length === 1 ? paymentMethods[0].id : get(paymentMethod, 'id')
    const documentDisabled = paymentMethods.length === 1 && !get(paymentMethods, '[0].pendingUpload.allowUpload')

    let availableTypes = fundingCategory && isPof ?
      pickBy(documentCategoryType.types, (t) =>  includes(t.fundingCategories, fundingCategory) &&
      (!documentDisabled || (documentDisabled && includes(t.key, 'other')))) : documentCategoryType.types
    // Handle doubleSided documents
    const missingDocs = safeParseJSON(missingDocuments)
    const keysMissingDoc = keys(pickBy(missingDocs, (doc) => doc === true))
    const missingAvailableTypes = filter(availableTypes, 'missingDocumentCheckbox')
    const documentsTypesMissing = !isEmpty(keysMissingDoc) && filter(missingAvailableTypes, (type) => includes(keysMissingDoc, type.missingDocumentCheckbox))
    if (!isEmpty(documentsTypesMissing)) {
      availableTypes = documentsTypesMissing
    } else if (!isEmpty(missingAvailableTypes)) {
      const docValues = map(missingAvailableTypes, 'value')
      availableTypes = omitBy(availableTypes, (type) => includes(docValues, type.value))
    }
    availableTypes = pickBy(availableTypes, (t) => {
      const country = toUpper(get(this.props, 'viewer.address.country', ''))
      return !has(t, 'applicableCountries') || includes(t.applicableCountries, country)
    })

    const doubleSidedTypes = groupBy(pickBy(availableTypes, 'doubleSided'), 'groupName')
    const normalisedDoubleSidedTypes = keyBy(map(doubleSidedTypes, (t) => ({
      key: head(t).groupName,
      label: messages[head(t).groupName],
      doubleSided: true,
      fundingCategories: head(t).fundingCategories
    })), 'key')

    let displayTypes = merge({}, pickBy(availableTypes, (t) => !t.doubleSided), normalisedDoubleSidedTypes)
    if (category === 'partner') {
      displayTypes = pickBy(displayTypes, (t) => t.subCategories.includes(partnerTypesToSubcategoryMapping[clientType]))
    }
    this.state = {
      selectedDocTypeKey: head(keys(displayTypes)),
      allAvailableTypes: displayTypes,
      displayTypes,
      uploadedFile: '',
      secUploadedFile: '',
      uploadLoading: false,
      category,
      isPof,
      errorFiles: {},
      open: false,
      fundingCategory,
      paymentMethodId,
      availablePOFDocumentsLoading: isPof,
      availablePOFDocuments: [],
    }
  }

  componentDidMount() {
    const {viewer, history} = this.props
    const {category} = this.state
    const missingDocs = getMissingDocs(viewer)
    if (get(missingDocs , category) === false) history.push('/settings/profile/documents')

    logEventCustomParams('documentCategorySelected', {
      type: category,
      userId: get(viewer, 'id'),
    })

    getSettings().then((res) => {
      this.setState({documentVerification: res.documentVerification})
    })

    this.setAvailablePOFDocuments()
  }

  /*
    In case the client has no payment methods yet should display this way the POF documents.
    Available POF Documents should be based on the availablePOFDocuments from viewer.
    In case there none of them, 'POF Other' document type is used as a fallback.
  */
  setAvailablePOFDocuments() {
    const {category} = this.state
    if (category !== 'pof') return
    const {client: apolloClient} = this.props
    apolloClient.query({query: AVAILABLE_POF_DOCUMENTS_QUERY, fetchPolicy: 'network-only'})
      .then(({loading: availablePOFDocumentsLoading, data}) => {
        const availablePOFDocuments = get(data, 'viewer.availablePOFDocuments', [])
        this.setState({
          availablePOFDocumentsLoading,
          ...this.getPOFDetails(availablePOFDocuments),
        })
      })
      .catch((_) => {
        const availablePOFDocuments = [documentTypes.otherPOF]
        this.setState({
          availablePOFDocumentsLoading: false,
          ...this.getPOFDetails(availablePOFDocuments)
        })
      })
  }

  // Helper function to calculate availablePOFDocuments, displayTypes, allAvailableTypes and selectedDocTypeKey for POF documents.
  getPOFDetails(availablePOFDocuments) {
    let {displayTypes} = this.state
    const availableDisplayTypes = orderBy(pickBy(displayTypes, (t) =>
      some(availablePOFDocuments, (d) => d?.key === t.key || d?.groupName === t.key) || documentTypes.otherPOF), ['key'], ['asc'])
    displayTypes = reduce(availableDisplayTypes, (type, item) => Object.assign(type, {[item.key]: {...item}}), {})
    return {
      availablePOFDocuments,
      displayTypes,
      allAvailableTypes: displayTypes,
      selectedDocTypeKey: head(keys(displayTypes))
    }
  }

  generateDocumentTag(clientId, documentType) {
    documentType = (documentType || '').replace(/(Front|Back)/, '')
    return `${clientId}-${documentType}-${Date.now()}`
  }

  uploadDocument(docType, file, internalTag) {
    const {paymentMethodId} = this.state

    if (!file) return Promise.resolve()
    const {signUploadUrl, createOwnDocument, viewer: {id}} = this.props

    return signUploadUrl({variables: {clientId: id}}).then((res) => {
      const {key, signedUrl} = res.data.signedDetails

      return putFile(file, signedUrl).then(() =>
        createOwnDocument({variables: {type: docType, key, internalTag, paymentMethodId}})
      )
    })
  }

  vipLink() {
    logEventCustomParams('skipWaitingListClicked', {
      type: 'doc_upload',
      userId: get(this.props, 'viewer.id'),
    })
    return '/vip-account'
  }

  onUploadDocument() {
    const {selectedDocTypeKey: docType, uploadedFile: file, secUploadedFile: secFile} = this.state
    const {clientType, clientId} = this.context
    const {featuresConfig :{skipWaitingList}} = config
    this.setState({uploadLoading: true})

    const {match, classes, accounts, history, viewer: {id}, t} = this.props
    const uploadingDocumentCategory = get(match, 'params.category')
    const documentCategoryType = find(DOCUMENT_CATEGORY_TYPES, {category: uploadingDocumentCategory})
    const docs = values(pickBy(documentCategoryType.types, (t) => t.groupName === docType || t.key === docType))

    const internalTag = this.generateDocumentTag(id, docType)


    this.uploadDocument(head(docs).key, file, internalTag).then((res) => {
      if (!secFile) return Promise.resolve(res)
      return this.uploadDocument(last(docs).key, secFile, internalTag)
    }).then((res) => {
      logEventCustomParams('submittedDocument', {
        userId: id,
      })
      this.setState({uploadLoading: false, uploadStatus: 'success'})
      const queuePosition = get(res, 'data.newDocument.queuePosition')
      const docProcessingTime = get(res, 'data.newDocument.docProcessingTime')
      const skipWaitingTime = [documentTypes.promotionalMaterial.key, documentTypes.businessLicense.key].includes(docType) ||
        ['pof'].includes(uploadingDocumentCategory)
      const notificationParams = {
        type: 'document-upload',
        status: 'success',
        buttonMessage: <Trans {...messages.continue} />,
        content: <SuccessfulUpload classes={classes} queuePosition={queuePosition} accounts={accounts}
          skipWaitingTime={skipWaitingTime} docProcessingTime={docProcessingTime} translations={t} docType={docType} clientType={clientType} clientId={clientId}/>,
        linkAction: !skipWaitingTime && (!hasVipOrRaw(accounts) && skipWaitingList ? this.vipLink() : false),
        linkActionMessage: !hasVipOrRaw(accounts) && skipWaitingList
          ? <Trans {...messages.skipWaitingList} /> : <Trans {...messages.close} />,
        onClose: (includes(this.props.location.pathname, 'register'))?history.push('/register/step3'):history.push('../upload')
      }
      this.context.showNotification(notificationParams)
    }).catch((e) => {
      logEventCustomParams('documentUploadError', {
        reason: get( e, 'graphQLErrors[0].message') || e.message,
        userId: id,
      })
      this.setState({uploadLoading: false, uploadStatus: 'failure', uploadedFile: '', secUploadedFile: ''})
      const notificationParams = {
        type: 'document-upload',
        status: 'failure',
        buttonMessage: <Trans {...messages.close} />,
        content: <FailedUpload />,
      }
      this.context.showNotification(notificationParams)
    })
  }

  handlSelectDocType(selectedDocTypeKey) {
    this.setState({selectedDocTypeKey})
  }

  handleFileUpload(prop, e, files, element) {
    const filesToUpload =  get(e, 'target.files') ? e.target.files : files
    this.setState({[prop]: filesToUpload[0], uploadStatus: ''})
    const {errorFiles} = this.state
    map(filesToUpload,(file) => {
      try {
        checkFileMimeType(file).then( res => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            logEventCustomParams('documentUploadError', {
              reason: 'wrong fileFormat or fileSize',
              userId: get(this.props, 'viewer.id'),
            })
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [prop]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[prop])
            this.setState({errorFiles:errors})
          }
        })
        //reset input value to force reupload action in failure
        if (e.target)
          e.target.value = null
        else if (document.getElementById(`paper-upload-file-${element}`))
          document.getElementById(`paper-upload-file-${element}`).value = null
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  closeChooseDocument(multiDocs) {
    const {fundingCategory, isPof} = this.state
    if (isPof && fundingCategory && multiDocs) {
      this.setState({fundingCategory: ''})
    } else if (this.props.location.state && this.props.location.state.prevPath === '/settings/profile') {
      return this.props.history.push('/settings/profile')
    } else if (includes(this.props.location.pathname, 'register')) {
      return this.props.history.push('/register/step3')
    } else if (this.props?.location?.state?.prevPath === '/transactions/withdraw') {
      return this.props.history.push('/transactions/withdraw')
    } else {
      return this.props.history.push('/settings/profile/documents/upload')
    }
  }

  getLocalizedLabel(type) {
    const {t} = this.props
    const {locale} = this.context
    const localizedLabel = has(type, 'label.i18nKey') ?
      t(type.label.i18nKey, type.label.defaults) :
      type.localization.t(locale)
    return localizedLabel
  }

  getPaymentMethodText(deposit) {
    const {locale} = this.context
    const {t} = this.props
    let paymentMethodText = fundingCategories[deposit.fundingCategory]?.localization.t(locale)
    const depositDetails = (deposit.details !== '-') ? deposit.details : ''
    switch (deposit.fundingCategory) {
      case fundingCategories.card.value:
        paymentMethodText = `${paymentMethodText} - ${t(messages.endingIn.i18nKey)} ${depositDetails}`
        break
      default:
        paymentMethodText = `${paymentMethodText}${depositDetails ? ` - ${depositDetails}` : ''}`
    }
    return paymentMethodText
  }

  onSelectPaymentMethod(m, documentDisabled) {
    const {isPof, allAvailableTypes, selectedDocTypeKey} = this.state
    const availableTypes = isPof ? pickBy(allAvailableTypes, (t) => includes(t.fundingCategories, m.fundingCategory) &&
      (!documentDisabled || (documentDisabled && includes(t.key, 'other')))) : {}

    this.setState({
      fundingCategory: m.fundingCategory,
      paymentMethodId: m.id,
      displayTypes: availableTypes,
      selectedDocTypeKey: !isEmpty(availableTypes) ? head(keys(availableTypes)) : selectedDocTypeKey
    })
  }

  renderPofDocuments() {
    const {classes, viewer, t, documents, history} = this.props
    const {themePreference} = this.context
    const paymentMethods = get(viewer, 'paymentMethods') || []
    const sortedPaymentMethods = sortBy(paymentMethods, (m) => m.fundingCategory)
    const pofDocuments = filter(documents, (d) => documentTypes[d.type] &&
      (documentTypes[d.type].category === 'POF' ||
        get(documentTypes[d.type], 'subCategories', []).includes('POF')
      ))

    return (
      <React.Fragment>
        <PageTitle
          withoutPadding={isMobile()}
          themePreference={themePreference}
          title={t(messages.paymentDocuments.i18nKey, messages.paymentDocuments.defaults)}
          onBack={() => history.push('/settings/profile')}
        />
        <PageBody>
          <Grid container spacing={isMobile() ? 2 : 3}>
            {map(sortedPaymentMethods, (m, index) => {
              const documentDisabled = find(pofDocuments, (d) => !get(m.pendingUpload,'allowUpload'))
              return <Grid item xs={12} key={index}>
                <Card onClick={() => this.onSelectPaymentMethod(m, documentDisabled)}>
                  <Grid container>
                    <Grid item className={classNames(classes.typeBadge, classes[m.fundingCategory] || classes['otherPof'])}>
                      <img
                        alt={m.fundingCategory}
                        src={Images[`${m.fundingCategory}.svg`] || Images['otherPof.svg']}
                        className={classes.logo}
                      />
                    </Grid>
                    <Grid item className={classes.cardContent}>
                      <Typography variant='subtitle1'>{this.getPaymentMethodText(m)}</Typography>
                    </Grid>
                    {m.confirmed && <Grid item>
                      <Typography variant='caption' className={classes.greenText}><Trans {...messages.verified} /></Typography>
                    </Grid>
                    }
                    {!m.confirmed && documentDisabled && <Grid item className={classes.icon}>
                      <Tooltip title={t(messages[documentDisabled.status].i18nKey, messages[documentDisabled.status].default)} placement="top">
                        <Typography variant='caption' ><Trans {...messages[documentDisabled.status]} /></Typography>
                      </Tooltip>
                    </Grid>
                    }
                  </Grid>
                </Card>
              </Grid>})}
          </Grid>
        </PageBody>
      </React.Fragment>
    )
  }

  renderSection(section, subsection) {
    const {classes} = this.props
    const {category, selectedDocTypeKey, displayTypes} = this.state
    const helper = subsection ? documentHelperText[category][selectedDocTypeKey] : documentHelperText[category]

    switch (section) {
      case 'acceptedDocuments': {
        return get(helper, 'acceptedDocuments') && <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body2"> <Trans {...messages.acceptedDocuments}/> </Typography>
          </Grid>
          <ul className={classNames(classes.zeroMarginTop)}>
            {map(helper.acceptedDocuments, (key) => (
              <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
            ))}
          </ul>
        </React.Fragment>
      }
      case 'makeSure': {
        return <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body2"> <Trans {...messages.pleaseMakeSure} /> </Typography>
          </Grid>
          <ul className={classNames(classes.zeroMarginTop)}>
            {map(helper.makeSure[selectedDocTypeKey] ?
              helper.makeSure[selectedDocTypeKey] :
              helper.makeSure, (key) => {
              const checkDoubleSide = get(displayTypes[selectedDocTypeKey], 'doubleSided', false)
              if (!checkDoubleSide && key === 'makeSureDocumentsTextPoi3') return
              return <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
            })}
          </ul>
        </React.Fragment>
      }
      default: {
        return (helper.shouldInclude || get(helper, `${selectedDocTypeKey}.shouldInclude`)) && <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body2"> <Trans {...messages.shouldInclude}/> </Typography>
          </Grid>
          <ul className={classNames(classes.zeroMarginTop)}>
            {map(get(helper, `${selectedDocTypeKey}.shouldInclude`) ? helper[selectedDocTypeKey].shouldInclude : helper.shouldInclude, (key) => (
              <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
            ))}
          </ul>
        </React.Fragment>
      }
    }
  }

  uploadFlow() {
    const {clientType, themePreference} = this.context
    const {t, classes, widthDown, viewer, loadingViewer} = this.props
    const {selectedDocTypeKey, uploadedFile, secUploadedFile, displayTypes, uploadLoading, category, uploadStatus,
      errorFiles, availablePOFDocumentsLoading} = this.state

    if (loadingViewer || availablePOFDocumentsLoading) return <Loading />

    // Handle empty availableTypes
    // let availableTypes = displayTypes
    // if (fundingCategory && isPof && isEmpty(availableTypes)) {
    //   const documentCategoryType = find(DOCUMENT_CATEGORY_TYPES, {category: category})
    //   availableTypes = documentCategoryType.types
    // }

    const paymentMethods = get(viewer, 'paymentMethods') || []
    const selectedDocType = find(displayTypes, {key: selectedDocTypeKey})
    const showUploadButton = uploadedFile ? selectedDocType.doubleSided ?
      !!secUploadedFile : !!uploadedFile : false
    const messageTitle = category === 'partner' ? messages[`${clientType}VerificationTitle`] :
      messages[`${category}Upload`]
    const multiPaymentMethods = paymentMethods.length > 1
    const formatsAccepted = acceptedFormats.split(', ')
    const bankStatementDocuments = [documentTypes.bankStatementPOR.key, documentTypes.bankStatementPOF.key]
    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PageTitle
              withoutPadding={isMobile()}
              themePreference={themePreference}
              title={t(messageTitle.i18nKey, messageTitle.defaults)}
              onBack={() => this.closeChooseDocument(multiPaymentMethods)}
              customClasses={{header: classes.capitalize}}>
            </PageTitle>
          </Grid>
        </Grid>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12} sm={7} md={7}>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12}>
                <PageSubTitle>
                  <Trans {...messages.documentType} />
                </PageSubTitle>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {map(displayTypes, (type) => (<ListItem
                    className={classes.listItem}
                    key={type.key}
                    button
                    onClick={() => this.handlSelectDocType(type.key)}
                  >
                    <ListItemText primary={this.getLocalizedLabel(type)} />
                    {selectedDocTypeKey === type.key && <CheckIcon className={classes.checkIcon} />}
                  </ListItem>)
                  )}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.greyText}>
                  <Trans {...messages.acceptedFormats} values={{acceptedFormats: acceptedFormats.replace(/(.*),.*/, '$1'), lastAccepted: formatsAccepted[formatsAccepted.length-1]}}/>&nbsp;
                  <Trans {...messages.maxFileSizeText} values={{maxSize: maxFileSize}}/>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.uploadDiv} spacing={3}>
                  <Grid item xs={12} md={6} >
                    <SelectDocument
                      uploadedFile={uploadedFile}
                      onChange={(e, files, element) => this.handleFileUpload('uploadedFile', e, files, element)}
                      fileKey="1"
                      showLabel = {selectedDocType.doubleSided}
                      category={`${category}-front`}
                      status={uploadedFile && (includes(keys(errorFiles), 'uploadedFile') ? 'error': 'success')}
                      errors={!isEmpty(errorFiles) && errorFiles.uploadedFile}
                      selectedDocTypeKey={selectedDocTypeKey}
                      src={includes(bankStatementDocuments, selectedDocTypeKey) ? Images['upload-bankStatement.png'] : (Images[`upload-${category}-front-${selectedDocTypeKey}.png`] || Images[`upload-${category}-front.png`]) || Images['upload-generic.png']}
                      srcSuccess={includes(bankStatementDocuments, selectedDocTypeKey) ? Images['upload-bankStatement-success.png'] : (Images[`upload-${category}-front-${selectedDocTypeKey}-success.png`] || Images[`upload-${category}-front-success.png`]) || Images['upload-generic-success.png']}
                      srcError={includes(bankStatementDocuments, selectedDocTypeKey) ? Images['upload-bankStatement-error.png'] : (Images[`upload-${category}-front-${selectedDocTypeKey}-error.png`] || Images[`upload-${category}-front-error.png`]) || Images['upload-generic-error.png']}
                    />
                  </Grid>
                  {selectedDocType.doubleSided && <Grid item xs={12} md={6}>
                    <SelectDocument
                      uploadedFile={secUploadedFile}
                      onChange={(e, files, element) => this.handleFileUpload('secUploadedFile', e, files, element)}
                      fileKey="2"
                      showLabel
                      category={`${category}-back`}
                      status={secUploadedFile && (includes(keys(errorFiles), 'secUploadedFile') ? 'error': 'success')}
                      errors={!isEmpty(errorFiles) && errorFiles.secUploadedFile}
                      selectedDocTypeKey={selectedDocTypeKey}
                      src={Images[`upload-${category}-back-${selectedDocTypeKey}.png`] || Images[`upload-${category}-back.png`] || Images['upload-generic.png']}
                      srcSuccess={Images[`upload-${category}-back-${selectedDocTypeKey}-success.png`] || Images[`upload-${category}-back-success.png`] || Images['upload-generic-success.png']}
                      srcError={Images[`upload-${category}-back-${selectedDocTypeKey}-error.png`] || Images[`upload-${category}-back-error.png`] || Images['upload-generic-error.png']}
                    />
                  </Grid>}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} className={classes.uploadTypeText}>
                    <LoadingButton
                      id='uploadDocLoadingButton'
                      onClick={() => this.onUploadDocument()}
                      disabled={!showUploadButton || !isEmpty(errorFiles) || (uploadLoading && !uploadStatus) || (uploadStatus==='failure')}
                      status={uploadStatus || ''}
                      fullWidth={widthDown}
                      hideProgressBar={!showUploadButton || !isEmpty(errorFiles)}
                      noMargin
                    >
                      <Trans {...messages.submitDocument} />
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} md={5} padding={2}>
            <MakeSureSection documentHelperText={documentHelperText} category={category} selectedDocTypeKey={selectedDocTypeKey} displayTypes={displayTypes} />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  sdkFlow() {
    const {themePreference} = this.context
    const country = get(this.props.viewer, 'address.country')
    return <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <PageTitle
            withoutPadding={isMobile()}
            themePreference={themePreference}
            title={this.props.t(messages.identityVerification.i18nKey, messages.identityVerification.defaults)}
            onBack={() => this.closeChooseDocument(false)}>
          </PageTitle>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <SumsubWebSdk
            accessToken={get(this.state.documentVerification, 'accessToken')}
            expirationHandler={() => {}}
            config={{
              lang: 'en',
              email: this.props.viewer.email,
              country: get(find(countries, {key: country}), 'alpha3Code'),
            }}
            options={{addViewportTag: false, adaptIframeHeight: true}}
            onMessage={() => {}}
            onError={() => {}}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  }

  render() {
    const {viewer, loadingViewer} = this.props
    const {category, fundingCategory, isPof, availablePOFDocumentsLoading} = this.state
    const {hideSdkFlow} = config
    if (loadingViewer || availablePOFDocumentsLoading || !has(this.state, 'documentVerification')) return <Loading />

    const paymentMethods = get(viewer, 'paymentMethods') || []
    const multiPaymentMethods = paymentMethods.length > 1

    const documentCategoryType = find(DOCUMENT_CATEGORY_TYPES, {category: category})
    const sdkFlow = get(this.state.documentVerification, 'enabled') && documentCategoryType.hasImplementation && !hideSdkFlow

    return (
      <React.Fragment>
        {(isPof && !fundingCategory && multiPaymentMethods)
          ? <Grid container spacing={0}>
            <Grid item xs={12} lg={9}>{this.renderPofDocuments()}</Grid>
          </Grid>
          : sdkFlow ? this.sdkFlow() : this.uploadFlow()}
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withMediaQuery('md'),
  withApollo,
  withRouter,
  withNamespaces(),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(CREATE_OWN_DOCUMENT_MUTATION, {
    name: 'createOwnDocument',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading: loadingViewer, error: errorViewer}, data}) => ({
      loadingViewer,
      errorViewer,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      accounts: get(data, 'viewer.accounts'),
    })
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error: documentsError, loading: documentsLoading}, data}) => ({
      documentsError,
      documentsLoading,
      documents: get(data, 'viewer.documents', []),
    })
  }),
)(ChooseDocument)
