import React, {Component} from 'react'
import {Card, FormControl, Grid, InputBase, MenuItem, Select, Tooltip, Typography} from '@mui/material'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import classNames from 'classnames'
import CustomTooltip from '../Common/CustomTooltip'
import HelpIcon from '@mui/icons-material/HelpOutline'
import CopyToClipboard from 'react-copy-to-clipboard'
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined'
import {isMobile} from '../../common/utils'
import withStyles from '@mui/styles/withStyles'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.lightgrey.color}`,
    fontSize: 16,
    padding: '5px 6px 5px 5px',
    margin: 0,
    color: `${theme.palette.black.color}`,
  }
}))(InputBase)

export class ClientLinkSection extends Component {
  state = {
    clickedEvent: false,
  }
  copyLink() {
    this.setState({clickedEvent: true})
  }
  render() {
    const {t, classes, singleClientLink, multipleClientLink, clientCampaignUrl, selectedAccount,
      changeAccount} = this.props
    const {clickedEvent} = this.state
    return (
      <Card classes={{root: classes.cardHover}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='caption' className={classes.inline}><Trans {...messages.linkToIntroduceClients} /></Typography>
            <CustomTooltip
              title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.introduceClientsTooltip.i18nKey, messages.introduceClientsTooltip.defaults)}</Typography>}
              placement={'top'}
            >
              <HelpIcon className={classes.icon} onClick={() => this.setState({linkClientsTooltip: true})}/>
            </CustomTooltip>
          </Grid>
          {singleClientLink && <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item md={10} xs={12}>
                <Typography variant='body1' className={classNames(classes.inline, classes.clientLink, classes.displayPadding)}>{clientCampaignUrl}</Typography>
              </Grid>
              <Grid item md={2} xs={12} className={classes.displayPadding}>
                <CopyToClipboard text={clientCampaignUrl}>
                  <Typography variant='caption' className={classNames(classes.link, classes.pointer)}
                    onClick={()=> this.copyLink()}
                    onMouseLeave={()=> this.setState({clickedEvent: false})}>
                    <FileCopyOutlined />
                  </Typography>
                </CopyToClipboard>
                <Tooltip
                  open={clickedEvent}
                  title={t(messages.partnerLinkCopied.i18nKey,messages.partnerLinkCopied.defaults)}
                  placement="right"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  onClose={() => this.setState({clickedEvent: false})}
                ><span></span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>}
          {multipleClientLink && <Grid item xs={12}>
            <Grid container  spacing={0} alignItems="center">
              <Grid item xs={!isMobile() ? 2 : 12}>
                <FormControl variant='outlined' size='small'>
                  <Select
                    variant="standard"
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={selectedAccount}
                    onClick={()=> changeAccount({openSelectAccount: true})}
                    input={<BootstrapInput />}
                    disabled>
                    <MenuItem value={selectedAccount}>
                      <em>{selectedAccount}</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={!isMobile() ? 8 : 12} className={classes.multipleClientLink}>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <Typography variant='body1' className={classNames(classes.inline, classes.clientLink)}>{clientCampaignUrl}</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={!isMobile() ? 2 : 12}>
                <CopyToClipboard text={clientCampaignUrl}>
                  <Typography variant='caption' className={classNames(classes.link, classes.pointer)}
                    onClick={()=> this.copyLink()}
                    onMouseLeave={()=> this.setState({clickedEvent: false})}>
                    <FileCopyOutlined />
                  </Typography>
                </CopyToClipboard>
                <Tooltip
                  open={clickedEvent}
                  title={t(messages.partnerLinkCopied.i18nKey, messages.partnerLinkCopied.defaults)}
                  placement="right"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  onClose={() => this.setState({clickedEvent: false})}
                ><span></span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </Card>
    )
  }
}
