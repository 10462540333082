import React from 'react'
import {graphql} from 'react-apollo'
import {get, first, findIndex, find, isEmpty, includes, filter, flowRight as compose} from 'lodash'
import {withRouter, Link, Redirect} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import ForexRoutes from './routes'
import {isMobile} from '../../../common/utils/browser'
import {getItem, hasWebTrader, isDarkTheme, logEventCustomParams} from '../../../common/utils'
import ForexTradeNow from './ForexTradeNow'
import Typography from '@mui/material/Typography'
import {accountTypes} from '@bdswiss/common-enums'
import {getAccountLabel, getAccountSubtype, isCopyTradingAccount} from '../../../common/utils/accounts'
import config from '../../../config'
import AppContext from '../../Common/contexts/AppContext'
import {getStrategiesDetails} from '../../../common/utils/requests'
import Grid from '@mui/material/Grid'
import {REQUEST_ZULU_TRADE_SSO_LINK} from '../../../graphql/mutations'
import {Avatar, Box, SvgIcon} from '@mui/material'
import QRCode from 'react-qr-code'
import CustomTooltip from '../../Common/CustomTooltip'
import {pelicanButtons} from '../../../common/utils/uioptions'

const styles = theme => ({
  tradeNowButton: {
    marginTop: 6,
  },
  successMessage: {
    color:  theme.palette.green.color,
    textAlign: 'center',
    display: 'inline-block',
  },
  errorMessage: {
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  errorRoot: {
    lineHeight: 1.5
  },
  successRoot: {
    width: '100%',
    lineHeight: 1.5,
    borderRadius: '6px',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.lightgreen.color,
    padding: `${theme.spacing(1)} 0px`,
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  avatarIcon: {
    backgroundColor: theme.palette.mode === 'dark' ? '#303033' : '#E3E6ED',
    cursor: 'pointer',
    borderRadius: 6,
  },
  customIcon: {
    width: 24,
    height: 24,
    '& svg path': {
      color: '#ff6600',
      fill: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
  toolTipFlex: {
    display: 'flex',
    width: 180,
    padding: 16,
    'flex-direction': 'column',
    borderRadius: 8,
    alignItems: 'center',
  }
})

class ForexAccountView extends React.Component {
  static contextType = AppContext

  state = {
    strategies: {},
    hoveredButton: null,
  }
  handleMouseEnter = (button) => {
    this.setState({hoveredButton: button})
  }

  handleMouseLeave = () => {
    this.setState({hoveredButton: null})
  }

  handleChange(tabs, value) {
    const {history, match} = this.props
    const currentTab = tabs[value]
    history.push(`${match.url}${currentTab.path}`)
  }

  componentDidMount() {
    const {account} = this.props
    getStrategiesDetails(account.id).then((res) => {
      this.setState({strategies: res})
    })
  }

  getZuluSSOLink (account, label) {
    const {t, classes} = this.props
    const variables = {
      accountId: get(account, 'id')
    }
    this.setState({loadingZuluBtn: true})
    logEventCustomParams('openZulutradeWebClicked', {
      userId: get(this.context, 'clientId'),
      accountClicked: label,
    })
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')
    this.props.requestZuluTradeSSOLink({variables})
      .then((res) => {
        if (res.data.createZuluSSOLink?.statusCode === 500) {
          this.setState((state) => ({
            loadingZuluBtn: false,
            statusZuluBtn: '',
            showZuluError: true,
          }))
          newTab.close()
          this.context.showNotification({
            type: 'document-upload',
            status: 'failure',
            title: 'somethingWrongError',
            buttonMessage: <Trans {...messages.close} />,
            content: <Trans
              {...messages.somethingWrongErrorZulu}
              components={[
                <a target="_blank" rel="noopener noreferrer" href={'https://www.zulutrade.com'} className={classes.link}>Zulutrade.com</a>
              ]}
            />
          })
        } else {
          this.setState((state) => ({
            loadingZuluBtn: false,
            statusZuluBtn: 'success',
            submitMessageSuccess: t(messages.success.i18nKey, messages.success.defaults)
          }))
          newTab.location = res?.data?.createZuluSSOLink?.ssoUrl
        }
      })
      .catch((error) => {
        this.setState({
          loadingZuluBtn: false,
          statusZuluBtn: '',
          submitMessageError: t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults)
        })
      })
  }

  render() {
    const {themePreference} = this.context
    const {location, match, loading,
      viewerLoading, account, account:{competitions}, classes, history, t,
      viewer: {company, locale, address}, match:{url}} = this.props
    const {downloadTrader, featuresConfig:{copyTradingEnabled, competitions: competitionsAllowed},
      common:{globalCopyTradingEnabled}, hideMt4Mentions} = config

    if (loading || viewerLoading || !account) return <Loading />
    const accountType = accountTypes[account.__typename]
    const downloadTraderLabel = accountType && downloadTrader[accountType.category]
      ? downloadTrader[accountType.category].shortLabel : downloadTrader.default.shortLabel
    if (accountType.subscription && isEmpty(account.subscription) && location.pathname.indexOf('plan') === -1)
      return <Redirect to={{pathname: `/accounts/${account.id}/plan/payment`}}/>
    const hasSubscription =  !isEmpty(account.subscription)
    const showTradeNow = accountType.subscription ? hasSubscription : true
    const accountSubtype = get(account,'accountSubtype')
    const label = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
    const checkAccountCopyTrading = includes(get(copyTradingEnabled,'accountSubTypes',[]), accountSubtype) &&
      includes(get(copyTradingEnabled,'accountTypesCategory',[]), accountType.category) && !account.isDemo
      && !account.isViewOnly && !account.isArchived && isEmpty(find(get(account,'competitions',[]), 'active'))
      && account?.leverageType === 'default_leverage'
    // eslint-disable-next-line
    console.log('copyTradingEnabled - ', copyTradingEnabled)
    // eslint-disable-next-line
    console.log('check Copy trading--', includes(get(copyTradingEnabled,'accountSubTypes',[]), accountSubtype),
      includes(get(copyTradingEnabled,'accountTypesCategory',[]), accountType.category), !account.isDemo
      ,!account.isViewOnly, !account.isArchived, isEmpty(find(get(account,'competitions',[]), 'active'))
      , account?.leverageType === 'default_leverage')
    // eslint-disable-next-line
    console.log('this.state.strategies - ', this.state.strategies)
    // eslint-disable-next-line
    console.log('checkAccountCopyTrading - ', checkAccountCopyTrading)
    let enableCopyTrading = !isEmpty(this.state.strategies) && checkAccountCopyTrading

    if (enableCopyTrading) {
      const providerType = get(this.state.strategies[0], 'copyTradingProvider')
      enableCopyTrading = get(globalCopyTradingEnabled, providerType)
      || (!get(globalCopyTradingEnabled, providerType) && get(account.copyTrading,'isActive'))
    }
    // eslint-disable-next-line
    console.log('enableCopyTrading - ', enableCopyTrading)

    const showCompetitions = competitionsAllowed && competitions.length > 0 &&
      !includes(get(competitionsAllowed, 'blockedCountries'), get(address, 'country', '').toUpperCase())
    const showProviderStats = get(account, 'showProviderStats')
    let  tabs
    if (account.isArchived || account.isViewOnly) {
      tabs = [
        {path: '', label: t(messages.overview.i18nKey, messages.overview.defaults)},
        {path: '/transactions/history', label: t(messages.payments.i18nKey, messages.payments.defaults)},
      ]
    } else {
      tabs = [
        {path: '', label: t(messages.overview.i18nKey, messages.overview.defaults)},
        {path: '/transactions', label: t(messages.payments.i18nKey, messages.payments.defaults)},
        {path: '/downloadTrader', label: downloadTraderLabel, hidden: hideMt4Mentions},
        {path: '/copyTrading', label: t(messages.copyTrading.i18nKey, messages.copyTrading.defaults), hidden: !enableCopyTrading},
        {path: '/tradingCompetitions', label: t(messages.tradingCompetitions.i18nKey, messages.tradingCompetitions.defaults), hidden: !showCompetitions},
        {path: '/providerStats', label: t(messages.providerStats.i18nKey, messages.providerStats.defaults), hidden: !showProviderStats},
        {path: '/settings', label: t(messages.settings.i18nKey, messages.settings.defaults)},
        {path: '/plan', label: t(messages.plan.i18nKey, messages.plan.defaults), hidden: !hasSubscription},
      ]
    }

    const currentTab =
      find(tabs, (tab) => tab.path.length > 0 && !tab.hidden && location.pathname.indexOf(`${match.url}${tab.path}`) > -1) ||
        first(tabs)
    const currentTabIndex = findIndex(filter(tabs, (t) => !t.hidden), currentTab)
    const showCopyTradeNow = isCopyTradingAccount(account)

    const tooltipBg = isDarkTheme(themePreference)
    return (<React.Fragment>
      <PageTitle
        themePreference={themePreference}
        title={label}
        withoutPadding={isMobile()}
        rightAction={
          <div className={classes.tradeNowButton}>
            {hasWebTrader() && showTradeNow && !showCopyTradeNow &&
              <ForexTradeNow type='button' account={account} company={company} locale={locale || getItem('locale', 'en')}/>}
            {!hideMt4Mentions && !showCopyTradeNow && <Link to={`${url}/downloadTrader`} >
              <Typography variant="body2" color="primary" align='center'>
                <Trans {...messages.downloadMT4} values={{product:downloadTraderLabel}}/>
              </Typography>
            </Link>}
            {showCopyTradeNow && <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {pelicanButtons.map((button, index) => <Box
                  key={index}
                  mx={1}
                  onMouseEnter={() => this.handleMouseEnter(button.name)}
                  onMouseLeave={this.handleMouseLeave}
                  style={{marginLeft: !isMobile() ? 12 : 6}}
                  onClick={()=> (isMobile() || button?.linkOnly) && window.open(button?.qrValue, '_blank')}
                >
                  {isMobile() || button?.linkOnly ? (
                    <Avatar className={classes.avatarIcon} variant="rounded">
                      <SvgIcon className={classes.customIcon}>{button.icon}</SvgIcon>
                    </Avatar>
                  ) : (<CustomTooltip
                    open={true}
                    tooltipLight={!tooltipBg}
                    title={
                      <Box display='flex' className={classes.toolTipFlex}>
                        <Typography variant="body2" align='center' style={{fontSize: 12, lineHeight: '16px'}}>
                          <Trans {...messages.scanDownloadApp} values={{platform: button?.name}} />
                        </Typography>
                        <QRCode
                          size={150}
                          value={button?.qrValue}
                          style={{paddingTop: 8}}
                        />
                      </Box>
                    }
                    placement='bottom'
                    hideArrow={true}
                  >
                    <Avatar className={classes.avatarIcon} variant="rounded">
                      <SvgIcon className={classes.customIcon}>{button.icon}</SvgIcon>
                    </Avatar>
                  </CustomTooltip>
                  )}
                </Box>)}
              </Box>
            </Grid>}
          </div>
        }
        subtitle={
          <Trans {...messages.accountID} values={{accountId: account.remoteId}} />
        }
      >
        <Tabs
          variant={isMobile()? 'scrollable' : 'standard'}
          value={currentTabIndex}
          onChange={(_, i) => this.handleChange(tabs, i)}
        >
          {tabs.map((tab, key) => !tab.hidden &&
            <Tab
              key={key}
              label={tab.label}
              textColor="inherit"
              onClick={() => history.push(`/accounts/${account.id}${tab.path}`)} />
          )}
        </Tabs>
      </PageTitle>
      <PageBody>
        <ForexRoutes/>
      </PageBody>
    </React.Fragment>)
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(REQUEST_ZULU_TRADE_SSO_LINK, {
    name: 'requestZuluTradeSSOLink',
  }),
)(ForexAccountView)
