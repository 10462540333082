import React from 'react'
import _, {
  get,
  map,
  isEmpty,
  includes,
  omitBy,
  isNull,
  pickBy,
  keys,
  union,
  isFunction,
  has,
  find,
  size,
  first,
} from 'lodash'
import moment from 'moment'
import {withdrawalRejectionReasons, withdrawalPendingReasons, withdrawalStatuses, depositVendors, depositStatuses,
  withdrawalTypes, withdrawalPaymentFields, withdrawalPaymentVendors, transactionTypes, countries,
  directa24TaxNumberLabels, currencies, FieldType,} from '@bdswiss/common-enums'
import {Trans} from 'react-i18next'
import classNames from 'classnames'
import messages from '../../../assets/messages'
import FormHelperText from '@mui/material/FormHelperText'
import Done from '@mui/icons-material/Done'
import Schedule from '@mui/icons-material/Schedule'
import Missing from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import {getFormattedAmount, safeParseJSON} from '../../../common/utils/general'
import {
  getAccountLabel,
  getAccountSubtype,
  hasVipOrRaw,
  isCentAccount,
  isIntroducingBrokerAccount,
} from '../../../common/utils/accounts'
import LoadingButton from '../../Common/LoadingButton'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import {friendlyFormatIBAN} from 'ibantools'
import {isMobile} from '../../../common/utils'

export const isDeposit = ({transactionType}) => (transactionType === transactionTypes.deposit.key)
export const isWithdrawal = ({transactionType}) => (transactionType === transactionTypes.withdrawal.key)
export const isSubscription = ({transactionType}) => (transactionType === transactionTypes.subscription.key)
export const isLdTransferVendor = ({transactionType}) => (transactionType.vendor === 'ldTransfer')
export const isLdTransfer = ({transactionType}) => (transactionType === 'ldTransfer')
export const isTransfer = ({category}) => (category === depositVendors.transfer.key)
export const isDividend = ({category}) => (category === depositVendors.dividend.key)

export const pendingWithdrawalStatuses = [
  withdrawalStatuses.notProcessed.key,
  withdrawalStatuses.pending.key,
  withdrawalStatuses.onHold.key,
]

export const completedStatuses = [
  depositStatuses.authorized.key,
  depositStatuses.chargeback.key,
  depositStatuses.completed.key,
  depositStatuses.reversed.key,
  depositStatuses.partiallyReversed.key,
  withdrawalStatuses.accepted.key,
  withdrawalStatuses.processing.key,
  withdrawalStatuses.reversed.key,
  withdrawalStatuses.partiallyReversed.key,
]

export const pendingStatuses = [
  depositStatuses.held.key,
  withdrawalStatuses.notProcessed.key,
  withdrawalStatuses.pending.key,
]

export const rejectedStatuses = [
  depositStatuses.cancelled.key,
  depositStatuses.failed.key,
  depositStatuses.rejected.key,
  withdrawalStatuses.rejected.key,
]

export const fieldStatus = (row, classes, accounts, locale, showElement, withdrawalPaymentOptions) => {
  if (completedStatuses.includes(row.status)) {
    const selectedPaymentOption = find(withdrawalPaymentOptions, {key: row.vendor})
    const processTime = isWithdrawal(row) && renderProcessingTime(selectedPaymentOption)
    return <React.Fragment>
      <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})} />
      <Trans {...messages.completed} />
      {isWithdrawal(row) && showElement && processTime &&
        <Typography variant="body1" color="secondary" className={classes.lineHeight}> <Trans {...messages.expectedIn} values={{processTime}} /> </Typography>}
    </React.Fragment>
  } else if (rejectedStatuses.includes(row.status))
    return <React.Fragment>
      <Missing color='error' className={classes.statusIcon}/>
      <Trans {...messages.rejected} />
    </React.Fragment>
  else {
    return <React.Fragment>
      <Schedule className={classNames({[classes.statusIcon]: true, [classes.pendingIcon]: true})}/>
      {row.status !== depositStatuses.held.key && <Trans {...messages.inProgress} />}
      {row.status === depositStatuses.held.key && depositStatuses.held.localization.t(locale)}
      {isWithdrawal(row) && !hasVipOrRaw(accounts) && row.status === withdrawalStatuses.notProcessed.key && <Typography variant="caption">
        <Trans {...messages.inQueue} values={{position: `${row.withdrawalQueuePosition}`}} />
      </Typography>}
    </React.Fragment>
  }
}
export const fieldDetails = (row, locale, country, classes, withdrawalPaymentOptions) => {
  if (isTransfer(row) || isLdTransfer(row)) {
    const accountDetails = get(row, 'transferAccount')
    const metaData = JSON.parse(get(row, 'meta'))
    const label= get(row, 'vendor') === 'ldTransfer' ? `${accountDetails?.firstName} ${accountDetails?.lastName}` : getAccountLabel(accountDetails, locale)
    const type = get(metaData, 'fromClientId') ? 'transferFromDetails' : 'transferToDetails'
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.lineHeight}>
            <Trans {...messages[type]}
              values={{
                type: label,
                id: get(accountDetails, 'remoteId') || get(accountDetails, 'memberId')
              }} />
          </Typography>
        </Grid>
      </Grid>
    )
  }
  else if (isWithdrawal(row)) {
    const showCurrencyFields =[withdrawalPaymentFields.withdrawalFee.key, withdrawalPaymentFields.withdrawalNetAmount.key]
    if (row.status === withdrawalStatuses.rejected.key) {
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2"><Trans {...messages.rejectionReasonTitle} /></Typography>
          {row.withdrawalRejectionReason && row.withdrawalRejectionReason !== 'other' && <Typography variant="body1" className={classes.lineHeight}>
            {withdrawalRejectionReasons[row.withdrawalRejectionReason].localization.t(locale)}
          </Typography>}
          {row.withdrawalRejectionReason && row.withdrawalRejectionReason === 'other' && <Typography variant="body1" className={classes.lineHeight}>
            <Trans {...messages.withdrawalsOtherReasonMessage} />
          </Typography>}
        </Grid>
      </Grid>
    } else if (row.status === withdrawalStatuses.pending.key) {
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2"><Trans {...messages.pendingReasonTitle} /></Typography>
          {row.withdrawalPendingReason && <Typography variant="body1" className={classes.lineHeight}>
            {withdrawalPendingReasons[row.withdrawalPendingReason].localization.t(locale)}
          </Typography>}
        </Grid>
      </Grid>
    } else if (row.withdrawalType === withdrawalTypes.performanceFee.key) {
      const meta = safeParseJSON(get(row, 'meta', '{}'))
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2"><Trans {...messages.performanceFee} /></Typography>
          {get(meta,'providerName') && <Typography variant="body1" className={classes.lineHeight}>
            <Trans {...messages.performanceFeeDetails} values={{providerName: get(meta,'providerName')}} />
          </Typography>}
        </Grid>
      </Grid>
    } else {
      const withdrawalDetails = omitBy(row.paymentFields, (field, key) => {
        if (key === '__typename' || key === 'termsAndConditions' || isNull(field)) {
          return key
        }
      })
      //const processTime = isWithdrawal(row) && withdrawalPaymentVendors[row.vendor] && withdrawalPaymentVendors[row.vendor].processTime && withdrawalPaymentVendors[row.vendor].processTime.localization.t(locale)
      const vendor = find(withdrawalPaymentOptions, {provider: row.vendor})
      const processTime = isWithdrawal(row) && renderProcessingTime(vendor)
      const withdrawalPaymentLabel = get(vendor, 'label', get(vendor, 'label', row.vendor))
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2"> <Trans {...messages.withdrawalMethod} /> </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" className={classes.lineHeight}> {withdrawalPaymentLabel} </Typography>
            {completedStatuses.includes(row.status) && processTime && <Typography variant="body1" color="secondary" className={classes.lineHeight}> <Trans {...messages.expectedIn} values={{processTime}} /> </Typography>}
          </Grid>
          {map(withdrawalDetails, (field, key) => {
            const withdrawalField = withdrawalPaymentFields[key]
            let options = []
            if (has(withdrawalField, 'options')) {
              options = isFunction(withdrawalField.options)
                ? withdrawalField.options({
                  withdrawalPaymentVendor: row.vendor,
                  dynamicFields: [],
                  country: get(countries[country], 'value'),
                  paymentOption: get(row.paymentFields, 'paymentOption'),
                  bankCode: get(row.paymentFields, 'provider'),
                }) : withdrawalField.options
            }

            const localeParams = {}
            if (row.vendor === withdrawalPaymentVendors.directa24.value) {
              localeParams.id_type = get(directa24TaxNumberLabels, country.toLowerCase())
                ? directa24TaxNumberLabels[country.toLowerCase()].label : 'ID'
            } else if (row.vendor === withdrawalPaymentVendors.payRetailers.value) {
              localeParams.id_type = '#'
            }

            // Amount fields on virtual currencies should be displayed using base currency (USD)
            const currencyFormatSettings = {amount: field, currency: row.currency}
            if (has(currencies[row.currency], 'baseCurrency') && has(currencies[row.currency], 'baseCurrencyRate')
              && withdrawalField?.fieldType === FieldType.amount) {
              currencyFormatSettings.amount /= currencies[row.currency].baseCurrencyRate
              currencyFormatSettings.currency = currencies[row.currency].baseCurrency
            }

            return (<Grid key={key} container>
              <Grid key={`${key}1`} item xs={6}>
                <Typography variant="body2"> {withdrawalField.localization.t(locale, localeParams)} </Typography>
              </Grid>
              <Grid key={`${key}2`} item xs={6}>
                <Typography variant="body1" className={classes.lineHeight}>{includes(showCurrencyFields, key)
                  ? getFormattedAmount(currencyFormatSettings)
                  : !isEmpty(options) ? get(find(options, {key: field}), 'label') || field : field}
                </Typography>
              </Grid>
            </Grid>)
          })}
        </Grid>
      )
    }
  }
  else {
    const details = get(row, 'depositPaymentFields') ||  JSON.parse(get(row, 'depositDetails'))
    const depositDetails = omitBy(details, (field, key) => {if (key === '__typename' || isNull(field)) return key })
    return (
      <Grid container>
        {map(depositDetails, (field, key) =>
          <Grid key={key} container>
            <Grid key={`${key}1`} item xs={6}>
              <Typography variant="body2"> {withdrawalPaymentFields[key].localization.t(locale)} </Typography>
            </Grid>
            <Grid key={`${key}2`} item xs={6}>
              <Typography variant="body1" className={classes.lineHeight}>{key !== 'iban' ? field : friendlyFormatIBAN(field)}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  }
}

export const showAmount = row => {
  let amount = row.amount, currency = row.currency
  const transferTypes = [transactionTypes.transfer.key, transactionTypes.ldTransfer.key]
  if (isCentAccount(row.account)) {
    amount = row.amount / currencies.CUD.baseCurrencyRate
    currency = currencies.CUD.baseCurrency
  }
  let transactionAmount
  if (row.transactionType === transactionTypes.deposit.key) {
    transactionAmount = `+ ${getFormattedAmount({amount, currency})}`
  } else if (row.transactionType === transactionTypes.withdrawal.key) {
    transactionAmount = `- ${getFormattedAmount({amount, currency})}`
  } else if ([transactionTypes.subscription.key, transactionTypes.dividend.key].includes(row.transactionType)) {
    const isWithdrawalDividend = row.withdrawalType === withdrawalTypes.dividend.key
    transactionAmount = `${isWithdrawalDividend ? '- ' : ''}${getFormattedAmount({amount, currency})}`
  } else if (row.transactionType === transactionTypes.ldTransfer.key) {
    transactionAmount = `${row?.withdrawalType === 'ldTransfer' ? '- ' : '+ '}${getFormattedAmount({amount, currency})}`
  } else if (includes(transferTypes, get(row, 'transactionType'))) {
    if (!isEmpty(row.vendor)) {
      transactionAmount = `+ ${getFormattedAmount({amount, currency})}`
    } else if (!isEmpty(row.withdrawalType)) {
      transactionAmount = `- ${getFormattedAmount({amount, currency})}`
    }
  }
  return transactionAmount
}

export const applyFilter = (transactions, _filter) => {
  const statuses = [
    ...(_filter.completed ? completedStatuses : []),
    ...(_filter.pending ? pendingStatuses : []),
    ...(_filter.rejected ? rejectedStatuses : []),
  ]

  const types = [
    ...(_filter.deposit ? [transactionTypes.deposit.key] : []),
    ...(_filter.withdrawal ? [transactionTypes.withdrawal.key] : []),
    ...(_filter.subscription ? [transactionTypes.subscription.key] : []),
    ...(_filter.transfer ? [transactionTypes.transfer.key] : []),
    ...(_filter.dividend ? [transactionTypes.dividend.key] : []),
    ...(_filter.ldTransfer ? [transactionTypes.ldTransfer.key] : []),
  ]

  const includingTransfers = get(_filter, transactionTypes.transfer.value) === true
  const onlyTransfers = size(types) === 1 && includes(types, transactionTypes.transfer.value)

  transactions = map(transactions, (a) => a.vendor === transactionTypes.ldTransfer.key ?
    {...a,
      transactionType: transactionTypes.ldTransfer.value,
      category: transactionTypes.ldTransfer.value,
    } : {...a})
  return _.chain(transactions).filter(t =>
    (!_filter.accountId ||  _filter.accountId === 'all' || Number(_filter.accountId) === t.account.id) &&
      (isEmpty(statuses) || includes(statuses, t.status)) &&
      ((!onlyTransfers && (isEmpty(types) || (includes(types, t.transactionType) && (includingTransfers || (!includingTransfers && t.category !== 'transfer'))))) ||
        (includingTransfers && t.category === transactionTypes.transfer.value)
      )
  ).value()
}

export const canCancelWithdrawal = row => {
  const isWithin24Hrs = moment(row.createdAt).add(1, 'day').isAfter(moment())
  const clientCancelPermitted = isWithdrawal(row) && get(withdrawalTypes, [row.withdrawalType, 'clientCreatePermitted'])
  return clientCancelPermitted &&
        isWithin24Hrs &&
        clientCancelPermitted &&
        pendingWithdrawalStatuses.includes(row.status)
}

export const showAllDetails = (row, props, locale) => {
  let details = '-'
  if (isSubscription(row) || (isDeposit(row) && row.vendor !== depositVendors.bankWire.value) ||
    isDividend(row) || isLdTransferVendor(row)
  ) {
    details = get(row, 'depositDetails')
    if (isDividend(row) && details === '-') details = get(JSON.parse(get(row, 'meta')), 'stock') || '-'
  } else {
    details = <Tooltip title={props.t(messages.viewDetails.i18nKey)} placement="top">
      <Button onClick={()=> props.handleOpen(row, locale)} className={props.classes.detailsInfo}>
        <InfoIcon style={{fontSize: 16}}/>
      </Button>
    </Tooltip>
  }
  if (details === '-') details = <span className={props.classes.spanDetails}>{details}</span>
  return details
}

export const transactionType = row => {
  if (isDeposit(row)) {
    const depositVendor = get(row, 'vendor', '')
    if (depositVendors[depositVendor]?.manuallyCreated && depositVendor !== depositVendors.manual.key) {
      return <Trans {...messages[depositVendor]} />
    } else {
      if (depositVendor === depositVendors.depositBonus.key) return <Trans {...messages.bonus} />
      return <Trans {...messages.deposit} />
    }
  }
  if (isWithdrawal(row)) {
    let message
    switch (row.withdrawalType) {
      case withdrawalTypes.vps_fee.key: {
        message = messages.vpsFee
        break
      }
      case withdrawalTypes.inactivityFee.key: {
        message = messages.inactivityFee
        break
      }
      case withdrawalTypes.performanceFee.key: {
        message = messages.performanceFee
        break
      }
      case withdrawalTypes.ldTransfer.key: {
        message = messages.ldTransfer
        break
      }
      default: {
        message = messages.withdrawal
      }
    }
    return <Trans {...message} />
  }
  if (isSubscription(row)) {
    return <Trans {...messages.subscription} />
  }
  if (isLdTransfer(row)) {
    return <Trans {...messages.ldTransfer} />
  }
  if (isDividend(row)) {
    return <Trans {...messages.dividend} />
  }
  if (isTransfer(row)) {
    let message

    if (!isEmpty(row.vendor)) {
      switch (row.vendor) {
        case depositVendors.transfer.key: {
          message = messages.transferDeposit
          break
        }
        case depositVendors.transferb2b.key:
        case depositVendors.transferDepositCommisions.key: {
          message = messages[row.vendor]
          break
        }
        case depositVendors.transferSpoa.key:
        case depositVendors.transferSpoab2b.key: {
          message = messages.transferSpoaFrom
          message.values = {account: row.transferAccount.remoteId}
          break
        }
        case depositVendors.ldTransfer.key: {
          message = messages.ldTransfer
          break
        }
        default: {
          message = messages.transfer
          break
        }
      }
    }

    if (!isEmpty(row.withdrawalType)) {
      switch (row.withdrawalType) {
        case withdrawalTypes.transfer.key: {
          message = messages.transferWithdrawal
          break
        }
        case withdrawalTypes.transferb2b.key:
        case withdrawalTypes.transferWithdrawalCommisions.key: {
          message = messages[row.withdrawalType]
          break
        }
        case withdrawalTypes.transferSpoa.key:
        case withdrawalTypes.transferSpoab2b.key: {
          message = messages.transferSpoaTo
          message.values = {account: row.transferAccount.remoteId}
          break
        }
        default: {
          message = messages.transfer
          break
        }
      }
    }

    return <Trans {...message} />
  }
}

export const transactionAccountName = (row, locale, t, accounts) => {
  const account = find(accounts, (a) => a.id === get(row, 'account.id'))
  const label = getAccountLabel(row.account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
  return `${label} - ${(isIntroducingBrokerAccount(row?.account) && first(get(row, 'account.ibId', []))) || row.account?.remoteId || row.account?.id}`
}

export const partialDepositAction = (depositAction, row, history, setLoading) => {
  setLoading(true)
  depositAction({
    variables: {depositId: row.id}
  }).then((res) => {
    const resultStatus = res.data.action.status === depositStatuses.completed.key
      ? 'success' : res.data.action.status === depositStatuses.failed.key ? 'failed' : 'pending'
    history.push(`/transactions/${get(row, 'account.id')}/deposit/result/${resultStatus}`)
  }).catch((e) => {
    history.push(`/transactions/${get(row, 'account.id')}/deposit/result/pending`)
  })
}

export const getPartialDepositButtons = (row, props, state, setLoading) => {
  const {classes, history} = props
  let buttons = []
  if (row.transactionType === transactionTypes.deposit.key && row.status === depositStatuses.incomplete.key) {
    const meta = safeParseJSON(get(row, 'meta', '{}'))
    if (!get(meta, 'isPartialApproval')) {
      return
    }
    if (isMobile()) {
      buttons = [
        <LoadingButton
          key="credit"
          size="small"
          variant="text"
          color="primary"
          disabled={state.loading}
          classes={{root: classes.cancelBtn}}
          onClick={() => partialDepositAction(props.completeDeposit, row, history, setLoading)}
        >
          <Trans {...messages.credit} />
        </LoadingButton>
      ]
    }
    else {
      buttons.push({
        message: 'credit',
        onClick: () => partialDepositAction(props.completeDeposit, row, history, setLoading),
      })
    }

    if (row.showCancelPartialDeposit) {
      if (isMobile()) {
        buttons.push(<LoadingButton
          key="cancel"
          size="small"
          variant="text"
          classes={{root: classes.cancelBtn}}
          className={classes.cancelPartialBtn}
          disabled={state.loading}
          onClick={() => partialDepositAction(props.cancelDeposit, row, history, setLoading)}
        >
          <Trans {...messages.cancel} />
        </LoadingButton>)
      } else {
        buttons.push({
          message: 'cancel',
          onClick: () => partialDepositAction(props.cancelDeposit, row, history, setLoading),
        })
      }
    }
    return buttons
  }
}

export const transactionsLimits = [10, 20, 40]


export const renderHelperText = (filter, classes) => {
  const allowedStatus = [withdrawalStatuses.rejected.key, withdrawalStatuses.pending.key, depositStatuses.completed.key]
  const filteredStatuses = keys(pickBy(filter, (value, key) => includes(allowedStatus, key) && value === true ))
  const allowedTypes = [transactionTypes.deposit.key, transactionTypes.withdrawal.key, transactionTypes.subscription.key]
  const filteredTypes = keys(pickBy(filter, (value, key) => includes(allowedTypes, key) && value === true ))
  const filters = union(filteredStatuses, filteredTypes)
  const accountId = get(filter, 'accountId')

  return (!isEmpty(filters) || !isNaN(accountId)) && <FormHelperText className={classes.textRight}>
    * <Trans {...messages.filtersApplied} />
  </FormHelperText>
}

export const renderProcessingTime = (vendor) => {
  let processingTimeLabel = ''
  const processingTimeValue = get(vendor, 'processingTimeValue', null)
  const processingTime = get(vendor, 'processingTime', null)

  if (isEmpty(processingTimeValue) && isEmpty(processingTime)) {
    return processingTimeLabel
  }

  if (processingTimeValue === '1' && processingTime === 'days') {
    processingTimeLabel = processingTimeValue + ' ' + messages.day?.defaults
  } else if (processingTimeValue === '1' && processingTime === 'hours') {
    processingTimeLabel = processingTimeValue + ' ' + messages.hour?.defaults
  } else if (processingTimeValue === '1' && processingTime === 'minutes') {
    processingTimeLabel = processingTimeValue + ' ' + messages.minute?.defaults
  } else {
    processingTimeLabel = processingTimeValue + ' ' + messages[processingTime]?.defaults
  }

  return processingTimeLabel
}
